import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface CustomTooltipProps {
  arrow: boolean,
  title: string,
  placement: 'top' | 'bottom' | 'left' | 'right',
  children: React.ReactElement,
  classes: {
    tooltip: string,
    title: string,
  }
}

const useStyles = makeStyles()((theme) => ({
  // @ts-ignore
  ...theme.details,
}));

// New components are done in typescript

export const CustomTooltip = (props: CustomTooltipProps) => {
  const { classes } = useStyles();
  const {
    arrow, title, placement, children, classes: classesProp,
  } = props;
  const { tooltip: tooltipClass, title: titleClass } = classesProp ?? {};
  return (
    <Tooltip
      arrow={arrow}
      className={tooltipClass}
      placement={placement}
      title={(
        // @ts-ignore
        <Typography className={titleClass ?? classes.searchFieldToolTipText}>
          {title}
        </Typography>
      )}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
