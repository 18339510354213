import { getReasonPhrase } from 'http-status-codes';
import { edgeTypeNames, markerIds, nodeTypeNames } from '../Constants/FlowConstants';
import theme from '../theme';

// Converts an uploaded (local) text file to string for use in react-flow
export const blobToString = async (blob) => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev) => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
};

export const getMarkerEndId = (type) => {
  switch (type) {
  case edgeTypeNames.input:
    return markerIds.input;
  case edgeTypeNames.output:
    return markerIds.output;
  case edgeTypeNames.sourceData:
    return markerIds.sourceData;
  case edgeTypeNames.targetData:
    return markerIds.targetData;
  default:
    return 'react-flow__arrow';
  }
};

export const getEdgeStrokeColor = (type) => {
  switch (type) {
  case edgeTypeNames.input:
    return theme.nodes.connector.inputColor;
  case edgeTypeNames.output:
    return theme.nodes.connector.outputColor;
  case edgeTypeNames.sourceData:
    return theme.nodes.data.sourceColor;
  case edgeTypeNames.targetData:
    return theme.nodes.data.targetColor;
  default:
    return theme.nodes.edge.defaultEdgeColor;
  }
};

export const getMiniMapNodeColor = (node) => {
  switch (node.type) {
  case nodeTypeNames.input:
    return theme.nodes.connector.inputColor;
  case 'default':
    return theme.nodes.miniMap.caseDefaultColor;
  case nodeTypeNames.output:
    return theme.nodes.connector.outputColor;
  case nodeTypeNames.bus:
    return theme.nodes.bus.busColor;
  case nodeTypeNames.dataConnector:
    return theme.nodes.connector.dataColor;
  case nodeTypeNames.sourceData:
    return theme.nodes.data.sourceColor;
  case nodeTypeNames.targetData:
    return theme.nodes.data.targetColor;
  default:
    return theme.nodes.miniMap.defaultColor;
  }
};

/**
 * Gets the next available node id.
 * @param {Array} nodes - The nodes array.
 * @returns {string} The next available node id.
 */
export const getNextNodeId = (nodes) => {
  const res = Math.max(...nodes.map((el) => {
    const parsed = parseInt(el.id.substring(5, el.id.length), 10);
    if (Number.isNaN(parsed)) return 0;
    return parsed;
  }));
  const id = `node_${res + 1}`;
  return id;
};

// Generates some dialog text with response status code and reason phrase for the http status code
// eslint-disable-next-line max-len
export const generateDialogText = (statusCode) => `Response was: ${statusCode} - ${getReasonPhrase(statusCode)}`;
