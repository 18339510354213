import { advSearchEntriesToRemove, INTEGRATION_RUN_STATUS, RUN_ERRORCODE } from '../Constants/Constants';

/**
 * Checks for a deadletter in a run
 * @param {*} run
 * @returns true if it probably has a DL, false if not
 */
export const checkForDeadLetter = (run) => {
  try {
    const {
      type, errorCode, deadLetterStatus, dataObjectsJson,
    } = run;
    // Need to be an ERROR rejeceted run + no deadletterstatus (resent/deleted)
    if (type !== INTEGRATION_RUN_STATUS.FAILED // resolved as well?
      || errorCode !== RUN_ERRORCODE.ERROR
      || deadLetterStatus) return false;
    const obj = JSON.parse(dataObjectsJson);
    // Only IBOD's with BODID might have a deadletter (for now)
    if (obj?.ApplicationArea?.BODID) return true;
  } catch (error) {
    return false;
  }
  return false;
};

// eslint-disable-next-line max-len
// modified from https://stackoverflow.com/questions/55464403/how-to-print-out-all-of-an-objects-properties-in-dot-notation-for-an-array-of-u?answertab=scoredesc#tab-top
/**
 * Get dot notation of properties in runs
 * @param {Array} data
 * @param {Set} s
 * @returns {Set}
 */
export const dotList = (data, s = new Set()) => {
  const o = data?.map((run) => {
    try {
      return JSON.parse(run.dataObjectsJson);
    } catch {
      return null;
    }
  }).filter((obj) => obj !== null);

  function dotIt(ov, p = '') {
    Object.entries(ov).forEach(([k, v]) => {
      if (typeof v === 'object' && v) {
        if (Array.isArray(v)) {
          v.forEach((vv, ix) => {
            if (typeof vv === 'string') s.add((`${p}${k}.${ix}`).replace('Nouns.0.', ''));
            else dotIt(vv, `${p}${k}.${ix}.`);
          });
        } else dotIt(v, `${p + k}.`);
      }
      const str = (p + k).replace('Nouns.0.', '');
      s.add(str);
    });
  }

  if (Array.isArray(o)) o.forEach((obj) => dotIt(obj));
  else dotIt(o);

  return Array.from(s).filter((elm) => !advSearchEntriesToRemove.some((e) => elm.startsWith(e)) && elm !== 'Nouns');
};

export default checkForDeadLetter;
