const detailsPage = {
  // TODO Create some common values "library"
  // Fonts, borders, etc (to reduce duplicates!)
  detailsSortByText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#333333',
    height: '24px',
    textAlign: 'left',
  },
  detailsSortByBox: {
    height: '24px',
    marginTop: '8px',
    userSelect: 'none',
    marginLeft: '5px',
    position: 'absolute',
    top: 90,
    zIndex: 999,
  },
  detailsSortByIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
    display: 'inline-block',
    width: '24px',
    height: '24px',
    pointerEvents: 'none',
    paddingTop: 1,
  },
  detailsSortBySelect: {
    width: '130px',
    height: '24px',
  },
  detailsSortByInput: {
    height: '24px',
    padding: 0,
  },
  detailsSortByMenuItem: {
    padding: '3px 0px 3px 0px',
  },
  detailsSortByPaper: {
    background: '#F0F0F0',
    boxShadow: 'none',
    borderRadius: '5px',
    width: '130px',
  },

  /* ERROR */
  detailsIntegrationErrorText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '43px',
    letterSpacing: '0.3px',
    color: '#de0000',
  },

  /* INTEGRATION CARDS */

  detailsIntegrationsBox: {
    position: 'relative',
    height: '103px',
    marginLeft: '21px',
    marginRight: '21px',
  },
  integrationCardBox: {
    position: 'relative',
    width: 267,
    height: '57px',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginTop: '23px',

  },
  integrationCardMargin: {
    marginRight: 21,
  },
  integrationCardOk: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
  },
  integrationCardFailed: {
    background: '#EE9282',
    border: '1px solid #F0B1A8',
  },
  integrationCardSelected: {
    position: 'relative',
    width: 278,
    height: '88px',
    zIndex: -1,
    marginTop: '16px',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px 8px 0px 0px',
  },
  integrationCardIconBox: {
    position: 'absolute',
    top: '8px',
    left: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '41px',
  },
  integrationCardIconBoxSelected: {
    position: 'absolute',
    top: '18px',
    left: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '53px',
    height: '56px',
  },
  integrationCardIcon: {
    width: 36,
    height: 36,
  },
  integrationCardIconSelected: {
    width: 53,
    height: 53,
  },
  integrationCardTextBox: {
    position: 'absolute',
    top: 4,
    left: '85px',
    height: 50,
    display: 'flex',
    alignItems: 'center',
  },
  integrationCardTextBoxSelected: {
    position: 'absolute',
    top: 10,
    left: '90px',
    height: 70,
    display: 'flex',
    alignItems: 'center',
  },
  integrationCardText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#333333',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    width: 175,
    textAlign: 'start',
    textOverflow: 'ellipsis',
  },
  integrationCardTextSelected: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '23.4153px',
    lineHeight: '23px',
    color: '#333333',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    width: 175,
    textAlign: 'start',
    textOverflow: 'ellipsis',
  },

  /* MAIN BOX "paper" */

  detailsMainBox: {
    background: '#FFFFFF',
    boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    border: '3px solid #FFFFFF',
    boxSizing: 'border-box',
    position: 'relative',
  },
  detailsMainFailedBox: {
    border: '3px solid #EE9282',
  },

  /* SEARCH HEADER */

  detailsMainBoxSearchHeader: {
    minHeight: '100px',
    paddingTop: '0.1px',
  },
  detailsSearchHeaderInfoBox: {
    textAlign: 'right',
    marginRight: 29,
    marginTop: 28,
  },
  detailsSearchHeaderInfoText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#333333',
  },
  detailsSearchHeaderInfoSubText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#333333',
  },

  /* SEARCH FIELDS */

  detailsSearchBox: {
    marginTop: 20,
    marginLeft: 29,
  },
  searchFieldSize: {
    width: '179px',
    height: '40px',
    background: '#FFFFFF',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    borderRadius: '5px',
    marginBottom: 5,
  },
  searchFieldError: {
    border: '1px solid #EE9282',
  },
  searchFieldErrorCode: {
    width: '93px',
  },
  searchFieldDate: {
    width: '192px',
  },
  searchFieldStatus: {
    width: 100,
  },
  searchFieldStatusMenuItem: {
    padding: '0px 10px 0px 10px',
    justifyContent: 'center',
  },
  searchButton: {
    width: '74px',
    height: '40px',
    background: '#dfe0dc',
    borderRadius: '5px',
    marginBottom: 5,
  },
  searchButtonText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#1c2336',
  },
  searchFieldText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#1c2336',
  },
  searchFieldToolTipText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
  },
  searchFieldInput: {
    height: '40px',
    padding: 0,
    marginLeft: '10px',
    marginRight: '10px',
  },
  searchGridItemSpacing: {
    marginRight: '22px',
  },
  searchGridItemWithBorderText: {
    position: 'relative',
  },
  searchFieldBorderText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '100%',
    letterSpacing: '0.1em',
    top: -8,
    left: 10,
    position: 'absolute',
    background: '#FFFFFF',
    padding: 3,
    userSelect: 'none',
    color: '#2E3A59',
  },

  /* RUNS TABLE */

  runsTableLoadingBar: {
    height: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  runsTableContainer: {
    margin: '0px 20px 20px 20px',
    width: 'calc(100% - 40px)',
    overflowX: 'auto',
  },
  runsTableScroller: {
    height: 'calc(100vh - 420px)',
    maxHeight: 1000,
  },
  runsTableHeader: {

  },
  runsTableHeaderText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#999999',
  },
  runsTableRowText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#003366',
  },
  runsTableMessageText: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  },
  runsTableRowErrorText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#E45B43',
  },
  runsTableExpandTableCell: {
    cursor: 'pointer',
    width: '36px',
    maxWidth: '36px',
    borderBottom: 'unset',
  },
  runsTableDateCell: {
    width: '140px',
  },
  runsTableStatusCell: {
    width: '100px',
  },
  runsTableErrorCodeCell: {
    width: '110px',
  },
  runsTableMessageCell: {
  },
  runsTableResolvedCell: {
    minWidth: '85px',
  },
  runsTableResolvedCellCursor: {
    cursor: 'pointer',
  },
  runsTableBody: {
  },
  runsTableRow: {
    height: 47,
    maxHeight: 141,
  },
  runsTableRowSelected: {
    background: '#F5F5F5',
  },
  runsTableRowCells: {
    padding: '0px 10px 0px 10px',
  },
  runsTableFooter: {
    '& > tr > td > div': {
      height: 50,
      minHeight: 50,
      overflowY: 'hidden',
    },
  },
  runsTableDLCell: {
    width: 34,
    maxWidth: 34,
    minWidth: 34,
  },

  /* EXPANDED TABLE */
  runsTableExpandedBox: {
    margin: '10px 0px 10px 0px',
    padding: 15,
    background: 'rgba(196, 196, 196, 0.1)',
    borderRadius: '5px',
    minHeight: 100,
  },
  runsTableExpandedOpenText: {
    textDecorationLine: 'underline',
    borderRadius: 8,
  },
  runsTableInfiniteScrollFooter: {
    marginTop: 10,
  },
  runsTooltipTextHeader: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#003366',
  },
  runsTooltipText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#666666',
    display: 'inline',
  },
  runsTooltipTextValue: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#003366',
    display: 'inline',
    whiteSpace: 'pre-wrap',
  },
  runsToolTipBox: {
    maxWidth: '440px',
    width: '440px',
    background: '#FFFFFF',
    border: '1px solid #F1F4E4',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '17px 25px 17px 25px',
  },

  /* INTEGRATION CARDS ARROWS */

  integrationCardRightArrow: {
    position: 'absolute',
    top: 17,
    right: -83,
    cursor: 'pointer',
    width: 70,
    height: 70,
    padding: 0,
  },
  integrationCardLeftArrow: {
    position: 'absolute',
    top: 17,
    left: -83,
    cursor: 'pointer',
    width: 70,
    height: 70,
    padding: 0,
  },

  testDivWidth: {
    position: 'absolute',
    visibility: 'hidden',
    height: 'auto',
    width: 'auto',
    whiteSpace: 'nowrap',
  },

  resolvedRunButtonBox: {
    width: '100%',
    background: 'white',
    zIndex: 10,
    position: 'absolute',
    bottom: 0,
    height: 50,
  },

  resolvedRunButton: {
    marginBottom: 10,
    textAlign: 'center',
    width: '80px',
    height: '40px',
    // background: '#F6F8F2',
    background: '#dfe0dc',
    borderRadius: '5px',
  },

  resolveAllButton: {
    textAlign: 'center',
    background: '#dfe0dc',
    borderRadius: '5px',
    paddingLeft: 10,
    paddingRight: 10,
    height: 30,
  },
  searchShowOnlyDLGrid: {
    height: 40,
    marginBottom: 5,
  },
  advSearchShowIcon: {
    marginLeft: 10,
    paddingBottom: 5,
  },
  advSearchShowIconBtn: {
    height: 40,
    width: 40,
  },
  advSearchPlus: {
    marginLeft: 5,
  },
  advSearchMinus: {
    marginLeft: -3,
  },
  advSearchEqualsGrid: {
    marginLeft: 10,
    marginRight: 8.5,
    paddingBottom: 3,
  },
  advSearchEqualsText: {
    fontSize: '18px',
  },
  advSearchAndText: {
    fontSize: '14px',
  },
  searchBoxAdvAutocompleteSize: {
    width: '250px',
  },
  searchBoxAdvAutocompletePaper: {
    boxSizing: 'border-box',
    borderRadius: '8px 8px 8px 8px',
  },
  searchBoxAdvAutocompleteListbox: {
    paddingTop: 0,
  },
  autocompleteText: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    paddingLeft: 0,
  },
  toggleServiceBtn: {
    width: '70px',
    height: '40px',
    background: '#dfe0dc',
    borderRadius: '5px',
    marginBottom: 5,
    marginRight: 5,
  },
  toggleServiceBtnText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#1c2336',
  },
  runBtnIcon: {
    fontSize: '20px',
    paddingRight: '5px',
    color: 'green',
  },
  stopBtnIcon: {
    fontSize: '20px',
    paddingRight: '5px',
    color: 'red',
  },
};

export default detailsPage;
