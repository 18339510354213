import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Typography, LinearProgress, Grid, TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { DataGrid } from '@mui/x-data-grid';
import { deadlettersDataDefinition } from '../../../Constants/ServiceBusConstants';
import renderCellExpand from './RenderCellExpand';
import IntegrationsContext from '../../../Contexts/IntegrationsContext';

const useStyles = makeStyles()(() => ({
  tablecontainer: {
    overflowX: 'initial',
  },
  dataGrid: {
    marginBottom: 10,
    marginTop: 10,
  },
  tableBox: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  button: {
    marginRight: 10,
  },
  infoText: {
    whiteSpace: 'pre-wrap',
  },
  buttonGrid: {
    marginBottom: 20,
  },
  gridRetrieve: {
    marginTop: 20,
  },
  maxMessagesInput: {
    width: 50,
    marginRight: 20,
  },
  progressText: {
    marginTop: 10,
  },
}));

// TODO Delete this component (not in use)

const DeadLettersTable = (props) => {
  const {
    deadletters, isQueue, isSubscription, apiProperties, refreshDLBtnOnClick,
  } = props;
  const { classes } = useStyles();
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [selectionModel, setSelectionModel] = useState([]);
  const [infoText, setInfoText] = useState('');
  const [showRefresh, setShowRefresh] = useState(false);
  const [isError, setIsError] = useState(false);
  const [waiting, setIsWaiting] = useState(false);
  const [refreshNum, setRefreshNum] = useState(0);

  const { api } = useContext(IntegrationsContext);

  /**
   * @returns {Array}
   */
  const createRows = () => deadletters?.map((item, index) => ({
    id: index, ...item,
  }));

  /**
   * @returns {Array}
   */
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const createColumns = () => deadlettersDataDefinition.map((item) => ({
    field: item.key,
    headerName: item.header,
    minWidth: 190,
    renderCell: renderCellExpand,
    valueFormatter: item.format,
  }));

  const getSelectedRowData = (data) => {
    const selectedIds = new Set(data ?? selectionModel);
    return createRows().filter((row) => selectedIds.has(row.id));
  };

  const getSelectedMessageIds = (data) => getSelectedRowData(data).map((row) => row.messageId);

  const resetStates = () => {
    setInfoText('');
    setIsError(false);
    setIsWaiting(true);
    setShowRefresh(false);
  };

  const resendBtnOnClick = (keepInDLQ = true) => {
    resetStates();
    if (isQueue) {
      api.resendDeadLetter(
        apiProperties.integrationId,
        {
          queueName: apiProperties.queueName,
          messageId: getSelectedMessageIds().find(Boolean),
          keepInDLQ,
        },
      )
      // ApiBackend.resendQueueDeadletters(
      //   apiProperties.integrationId,
      //   apiProperties.queueName,
      //   getSelectedMessageIds(),
      //   accessToken,
      //   keepInDLQ,
      // )
        .then((response) => {
          setInfoText(response.data);
          if (!keepInDLQ) {
            setShowRefresh(true);
            setRefreshNum((deadletters?.length ?? 10) - (selectionModel?.length ?? 0));
          }
        })
        .catch((error) => {
          setInfoText(`${error}`);
          setIsError(true);
        })
        .finally(() => setIsWaiting(false));
    }

    if (isSubscription) {
      api.resendDeadLetter(
        apiProperties.integrationId,
        {
          topicName: apiProperties.topicName,
          subscriptionName: apiProperties.subscriptionName,
          messageId: getSelectedMessageIds().find(Boolean),
          keepInDLQ,
        },
      )
      // ApiBackend.resendSubscriptionDeadLetters(
      //   apiProperties.integrationId,
      //   apiProperties.topicName,
      //   apiProperties.subscriptionName,
      //   getSelectedMessageIds(),
      //   accessToken,
      //   keepInDLQ,
      // )
        .then((response) => {
          setInfoText(response.data);
          if (!keepInDLQ) {
            setShowRefresh(true);
            setRefreshNum((deadletters?.length ?? 10) - (selectionModel?.length ?? 0));
          }
        })
        .catch((error) => {
          setIsError(true);
          setInfoText(`${error}`);
        })
        .finally(() => setIsWaiting(false));
    }
  };

  const deleteBtnOnClick = () => {
    resetStates();
    if (isQueue) {
      api.deleteDeadLetter(
        apiProperties.integrationId,
        {
          queueName: apiProperties.queueName,
          messageId: getSelectedMessageIds().find(Boolean),
        },
      )
      // ApiBackend.deleteQueueDeadLetters(
      //   apiProperties.integrationId,
      //   apiProperties.queueName,
      //   getSelectedMessageIds(),
      //   accessToken,
      // )
        .then((response) => {
          setInfoText(response.data);
          setShowRefresh(true);
          setRefreshNum((deadletters?.length ?? 10) - (selectionModel?.length ?? 0));
        })
        .catch((error) => {
          setIsError(true);
          setInfoText(`${error}`);
        })
        .finally(() => setIsWaiting(false));
    }

    if (isSubscription) {
      api.deleteDeadLetter(
        apiProperties.integrationId,
        {
          topicName: apiProperties.topicName,
          subscriptionName: apiProperties.subscriptionName,
          messageId: getSelectedMessageIds().find(Boolean),
        },
      )
      // ApiBackend.deleteSubscriptionDeadLetters(
      //   apiProperties.integrationId,
      //   apiProperties.topicName,
      //   apiProperties.subscriptionName,
      //   getSelectedMessageIds(),
      //   accessToken,
      // )
        .then((response) => {
          setInfoText(response.data);
          setShowRefresh(true);
          setRefreshNum((deadletters?.length ?? 10) - (selectionModel?.length ?? 0));
        })
        .catch((error) => {
          setIsError(true);
          setInfoText(`${error}`);
        })
        .finally(() => setIsWaiting(false));
    }
  };

  const resendDeleteBtnOnClick = () => {
    resendBtnOnClick(false);
  };

  return (
    <Box className={classes.tableBox}>
      {/* <DataGrid
        className={classes.dataGrid}
        rows={createRows()}
        columns={createColumns()}
        checkboxSelection
        autoHeight
        disableSelectionOnClick
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
      /> */}
      <Grid container item className={classes.buttonGrid} alignContent="center" justifyContent="center">
        <Button onClick={deleteBtnOnClick} variant="contained" color="error" className={classes.button} disabled={waiting || selectionModel?.length <= 0}>
          Delete
        </Button>
        <Button onClick={() => resendBtnOnClick(true)} variant="contained" className={classes.button} disabled={waiting || selectionModel?.length <= 0}>
          Resend
        </Button>
        <Button onClick={resendDeleteBtnOnClick} variant="contained" color="secondary" className={classes.button} disabled={waiting || selectionModel?.length <= 0}>
          Resend and delete
        </Button>
        {showRefresh && (
          <Grid container justifyContent="center" alignItems="center" className={classes.gridRetrieve}>
            <Grid item>
              <TextField
                className={classes.maxMessagesInput}
                type="number"
                variant="standard"
                value={refreshNum}
                onChange={(e) => setRefreshNum(e.target.value)}
                InputProps={{ inputProps: { min: 1, max: 100 } }}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => refreshDLBtnOnClick(refreshNum)}>
                Refresh dead letters
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {waiting && (
        <>
          <LinearProgress />
          <Typography align="center" className={classes.progressText}>
            Request underway
          </Typography>
        </>
      )}
      <Typography variant="body1" align="center" className={classes.infoText} color={isError ? 'red' : 'inherit'}>
        {infoText}
      </Typography>
      {/* <TableContainer className={classes.tablecontainer}>
        <Table stickyHeader>
          {generateTableHeaders(deadlettersDataDefinition, true, true)}
          <TableBody>
            {generateTableBody(deadlettersDataDefinition, deadletters)}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Box>
  );
};

DeadLettersTable.propTypes = {
  deadletters: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.string,
    deadLetterErrorDescription: PropTypes.string,
    deadLetterReason: PropTypes.string,
    deliveryCount: PropTypes.number,
    enqueuedTime: PropTypes.string,
    expiresAt: PropTypes.string,
    scheduledEnqueueTime: PropTypes.string,
  })),
  isQueue: PropTypes.bool.isRequired,
  isSubscription: PropTypes.bool.isRequired,
  apiProperties: PropTypes.shape({
    queueName: PropTypes.string,
    topicName: PropTypes.string,
    subscriptionName: PropTypes.string,
    integrationId: PropTypes.string,
  }).isRequired,
  refreshDLBtnOnClick: PropTypes.func.isRequired,
};

DeadLettersTable.defaultProps = {
  deadletters: [],
};

export default DeadLettersTable;
