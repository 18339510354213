import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { getAllRouteMatches } from '../../../Utils/RouteUtils';

const useStyles = makeStyles()((theme) => ({
  ...theme.details,
  fixLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

const DEFAULT_IMAGE_SRC = `${process.env.PUBLIC_URL}/defaultIcon.svg`;

const IntegrationCardDetail = (props) => {
  const {
    integration, selected, last, cardCallback,
  } = props;
  const { classes } = useStyles();

  const calculateStatus = () => integration.totalFailed > 0;

  const failed = calculateStatus();

  const matches = getAllRouteMatches();

  useEffect(() => {
    if (selected) cardCallback(integration, failed);
  }, [cardCallback, failed, integration, selected]);

  const gridProps = selected ? {} : {
    component: Link,
    to: `${matches[0]?.pathnameBase}/${integration.id}`,
  };

  return (
    <Grid
      item
      className={classes.fixLink}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...gridProps}
    >
      <Box
        className={`${selected ? classes.integrationCardSelected : classes.integrationCardBox} ${failed ? classes.integrationCardFailed : classes.integrationCardOk} ${!last ? classes.integrationCardMargin : ''}`}
      >
        <Box
        // eslint-disable-next-line max-len
          className={selected ? classes.integrationCardIconBoxSelected : classes.integrationCardIconBox}
        >
          <img
            src={integration?.iconUrl ?? DEFAULT_IMAGE_SRC}
            alt=""
            className={selected ? classes.integrationCardIconSelected : classes.integrationCardIcon}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null;
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = DEFAULT_IMAGE_SRC;
            }}
          />
        </Box>
        <Box
        // eslint-disable-next-line max-len
          className={selected ? classes.integrationCardTextBoxSelected : classes.integrationCardTextBox}
        >
          <Typography
            className={selected ? classes.integrationCardTextSelected : classes.integrationCardText}
          >
            {integration?.displayName?.toUpperCase() ?? integration?.name?.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

IntegrationCardDetail.propTypes = {
  integration: PropTypes.shape({
    name: PropTypes.string,
    totalFailed: PropTypes.number,
    id: PropTypes.string,
    iconUrl: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
  last: PropTypes.bool,
  cardCallback: PropTypes.func.isRequired,
};

IntegrationCardDetail.defaultProps = {
  selected: false,
  last: false,
};

export default IntegrationCardDetail;
