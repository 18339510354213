import React, { useState, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import useAxios from '../Hooks/useAxios';
import END_POINTS from '../Constants/EndPoints';
import IntegrationsContext from '../Contexts/IntegrationsContext';
import { INTEGRATIONS_SORT_BY_NAMES } from '../Constants/Constants';
import { Configuration, MilesConnectUIApi } from '../Generated/Backend';
import { getBackendAuthHeaderTokenAsync } from '../Utils/AuthUtils';

interface IIntegrationsProvider {
  children: React.ReactNode
}

const IntegrationsProvider = ({ children }: IIntegrationsProvider) => {
  const { Provider } = IntegrationsContext;

  const { instance } = useMsal();

  const [refreshIntegrationsState, setRefreshIntegrationsState] = useState(false);
  const [refreshMetricsState, setRefreshMetricsState] = useState(false);
  const [sortByState, setSortByState] = useState(INTEGRATIONS_SORT_BY_NAMES[0]);

  const configuration = new Configuration({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    basePath: (window as any).ENV.BACKEND_URL,
    apiKey: () => getBackendAuthHeaderTokenAsync(instance),
  });
  const api = new MilesConnectUIApi(configuration);

  const abortControllerRef = useRef(new AbortController());

  // TODO Use api^ instead of these sometime
  const {
    requestData, loading, isError, errorResponse, errorJson, errorRequest, errorMessage,
  } = useAxios({
    url: `${END_POINTS.INTEGRATIONS}`,
    method: 'GET',
    headers: {
      accept: '*/*',
    },
  }, refreshIntegrationsState);

  const {
    requestData: metrics, loading: mLoading,
  } = useAxios({
    url: `${END_POINTS.INTEGRATION_METRICS}`,
    method: 'GET',
    headers: {
      accept: '*/*',
    },
  }, refreshMetricsState);

  const refreshMetrics = () => {
    setRefreshMetricsState((val) => !val);
  };

  const refreshIntegrations = () => {
    setRefreshIntegrationsState((val) => !val);
  };

  const refreshAll = () => {
    refreshIntegrations();
    refreshMetrics();
  };

  return (
    <Provider value={{
      metrics,
      requestData,
      isError,
      loading,
      metricsLoading: mLoading,
      errorResponse,
      errorJson,
      errorRequest,
      errorMessage,
      refreshMetrics,
      refreshIntegrations,
      refreshAll,
      sortBy: sortByState,
      setSortBy: setSortByState,
      api,
      abortControllerRef,
    }}
    >
      {children}
    </Provider>
  );
};

export default IntegrationsProvider;
