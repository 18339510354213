import { SERVICE_RUN_STATE } from '../Constants/Constants';

export const isServiceRunning = (state) => {
  if (state === SERVICE_RUN_STATE.SLEEPING
      || state === SERVICE_RUN_STATE.STARTED
      || state === SERVICE_RUN_STATE.WORKING) {
    return true;
  }
  return false;
};

export default isServiceRunning;
