import dayjs from 'dayjs';
import 'dayjs/locale/nb';

export const toYearDateTimeStandardString = (dateTime) => dayjs(dateTime).locale('nb').format('DD.MM.YYYY HH:mm:ss');

export const toDateTimeStandardString = (dateTime) => dayjs(dateTime).locale('nb').format('DD.MM.YY HH:mm:ss');

export const toDateTimeNoSecStandardString = (dateTime) => dayjs(dateTime).locale('nb').format('DD.MM.YY HH:mm');

export const toDateTimeStringChart = (dateTime) => dayjs(dateTime).locale('nb').format('D. MMM YYYY [kl.] HH:mm');

export const toLongDateStringChart = (dateTime) => dayjs(dateTime).locale('nb').format('D. MMM YYYY');

export const toHourstringChart = (dateTime) => dayjs(dateTime).locale('nb').format('[kl.] HH:mm');

export const toDateStringChart = (dateTime) => dayjs(dateTime).locale('nb').format('D. MMM');

export const toTimeStandardString = (dateTime) => dayjs(dateTime).locale('nb').format('HH:mm:ss');

export const createTimerDate = (timerSec) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + timerSec);
  return time;
};

/**
 * Adds x hours to the date
 * @param {Date} date
 * @param {number} hours
 */
export const addHours = (date, hours) => {
  const result = new Date(date);
  result.setHours(result.getHours() + hours);
  return result;
};

/**
 * Adds x days to the date
 * @param {Date} date
 * @param {number} days
 * @returns {Date}
 */
export const addDay = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

/**
 * Converts and fills in the chart data from the backend to what is used in the linechart
 * @param {array} chartData
 * @returns {array}
 */
export const fillInDateTimeChartData = (chartData) => {
  let index = 0;
  const output = [];
  let currentDate = new Date(chartData[index].start);
  const now = new Date();
  now.setMinutes(0, 0, 0); // truncate m/s/ms

  currentDate.setHours(0, 0, 0, 0); // truncate h/m/s/ms

  while (index < chartData.length) {
    let temp = {};
    if (currentDate.getTime() === new Date(chartData[index].start).getTime()) {
      // if dates are equal, add that and continue in chartData
      const {
        start: name, totalRuns: Total, totalFailed: Failed, totalFiltered: Filtered,
      } = chartData[index];
      temp = {
        name: toDateTimeStringChart(name),
        Total,
        Failed,
        Filtered,
      };
      index += 1;
    } else {
      // if not, add an "empty" object and do not increase index
      temp = {
        name: toDateTimeStringChart(currentDate),
        Total: 0,
        Failed: 0,
        Filtered: 0,
      };
    }
    output.push(temp);
    currentDate = addHours(currentDate, 1);
  }

  // add empty hours until now
  while (currentDate <= now) {
    const temp = {
      name: toDateTimeStringChart(currentDate),
      Total: 0,
      Failed: 0,
      Filtered: 0,
    };
    output.push(temp);
    currentDate = addHours(currentDate, 1);
  }
  return output;
};

/**
 * Converts and fills in the chart data from the backend to what is used in the linechart,
 * converts to days instead of hours
 * @param {*} chartData
 * @returns {array}
 */
export const fillInDateTimeCHartDataDays = (chartData) => {
  let index = 0;
  const output = [];
  let currentDate = new Date(chartData[index].start);
  currentDate.setHours(0, 0, 0, 0);
  const now = new Date();
  now.setHours(0, 0, 0, 0); // truncate h/m/s/ms

  while (currentDate <= now) {
    const temp = {
      name: toLongDateStringChart(currentDate),
      Total: 0,
      Failed: 0,
      Filtered: 0,
    };
    // get the day for the first data in the day
    if (index < chartData.length) {
      let currentTime = new Date(chartData[index].start);
      currentTime.setHours(0, 0, 0, 0);
      // is the same day?
      // loop until the next day
      while (index < chartData.length && currentTime.getTime() === currentDate.getTime()) {
        const { totalRuns, totalFailed, totalFiltered } = chartData[index];
        temp.Total += totalRuns;
        temp.Failed += totalFailed;
        temp.Filtered += totalFiltered;
        index += 1;
        if (index < chartData.length) {
          currentTime = new Date(chartData[index].start);
          currentTime.setHours(0, 0, 0, 0);
        }
      }
    }
    // push and add 1 day
    output.push(temp);
    currentDate = addDay(currentDate, 1);
  }
  return output;
};
