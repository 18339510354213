import dayjs from 'dayjs';

export const DEFAULT_SORT_COLUMN_NAME = 'Started';

export const INTEGRATION_RUN_STATUS = {
  OK: 'OK',
  FAILED: 'FAILED',
  RESOLVED: 'RESOLVED',
  FILTER: 'FILTER',
};

export const RUN_ERRORCODE = {
  ERROR: 0,
  FILTER: 1,
};

export const SERVICE_RUN_STATE = {
  STARTED: 'Started',
  WORKING: 'Working',
  SLEEPING: 'Sleeping',
  STOPPED: 'Stopped',
  TERMINATED: 'Terminated',
};

export const headerNameVariableNameMap = {
  Status: 'integrationRunStatus',
  Started: 'startedDateTime',
  // Finished: 'finishedDateTime',
  // 'Error code': null,
  Message: null,
  Resolved: null,
  DL: null,
  '': null,
};

export const servicesHeaderNameVariableMap = {
  State: null,
  Name: 'name',
  Status: 'lastStatus',
  Succeeded: 'lastStatus',
  Failed: 'lastStatus',
  'Last Run': 'lastRun',
  'Next Run': 'nextRun',
  Actions: 'actions',
};

export const ORDER_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const STATUS_COLORS = {
  OK: '#1976d2', // primary material ui icons color: #3f51b5
  FAILED: '#d32f2f', // error material ui icons color: #f44336
  NONE: 'black',
  WARNING: 'orange',
};

export const EMAIL_HEADER = ['Emails to be notified', 'Update email', 'Rules subscribed to', ''];

const now = dayjs();

export const getTimeKind = (date) => dayjs(date).format('YYYY-MM-DDTHH:mmZ');
export const getTimeNow = () => dayjs().format('YYYY-MM-DDTHH:mm');

export const getTimeXMonthsAgo = (x) => dayjs().subtract(x, 'months').format('YYYY-MM-DDTHH:mm');

export const TIME_CONSTANTS = {
  TODAY: now.format('YYYY-MM-DDTHH:mm'),
};

export const SAVE_CONFIG_TIMER = 3;

export const REFRESH_SERVICES_TIMER = 10;

export const SORT_BY_SELECT_VALUES = {
  name: 'name',
  failed: 'failed',
};

export const MAX_CHARS_RUN_TABLECELL_EXPANDED = 25;

export const INTEGRATIONS_SORT_BY_NAMES = ['failed', 'name'];

export const RUNS_INFINITE_SCROLL_RETRIEVEAL_COUNT = 50;

export const MAX_RUNS_INFINITE_SCROLL = 1000;

export const advSearchEntriesToRemove = [
  'ApplicationArea',
  'Verb',
  'AnalyticId',
  'ConnectorId',
  'ReleaseId',
  'SystemEnvironmentCode',
  'VersionId',
  'LanguageCode',
];

export const createDefaultSearchData = () => ({
  dateStart: getTimeXMonthsAgo(1),
  dateFinished: getTimeNow(),
  errorCode: undefined,
  searchString: '',
  advSearch: [{ field: '', value: '' }],
  advSearchMode: true,
  onlyDeadletters: false,
  status: [],
  searchBtnClicked: false,
  doNotSendAdvSearch: true,
});

export const defaultSearchData = {
  dateStart: getTimeXMonthsAgo(1),
  dateFinished: getTimeNow(),
  errorCode: undefined,
  searchString: '',
  advSearch: [{ field: '', value: '' }],
  advSearchMode: true,
  onlyDeadletters: false,
  status: [],
  searchBtnClicked: false,
  doNotSendAdvSearch: true,
};
