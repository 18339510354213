import React from 'react';
import { useMsal } from '@azure/msal-react';
import { ButtonBase, Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AzureIcon from '../Redesign/Icons/AzureIcon';
import { handleLoginPopup } from '../../Utils/AuthUtils';

const useStyles = makeStyles()((theme) => ({
  ...theme.loginPage,
}));

const SignInButton = () => {
  const { instance } = useMsal();
  const { classes } = useStyles();

  return (
    <ButtonBase
      className={classes.loginBtn}
      onClick={() => handleLoginPopup(instance)}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <AzureIcon />
        <Typography className={classes.loginBtnText}>
          Login with Azure AD
        </Typography>

      </Stack>
    </ButtonBase>
  );
};

export default SignInButton;
