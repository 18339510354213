import React, { useContext, useState } from 'react';
import {
  Button, CircularProgress, Grid, TextField, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import DeadLettersTable from '../ServiceBus/Table/DeadLettersTable';
import CustomModal from './CustomModal';
import IntegrationsContext from '../../Contexts/IntegrationsContext';

const useStyles = makeStyles()(() => ({
  maxMessagesInput: {
    width: 110,
    marginRight: 20,
  },
  refreshText: {
    marginRight: 20,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
  gridRetrieve: {
    marginTop: 20,
  },
  loadingTypo: {
    marginBottom: 20,
  },
}));

// TODO Remove? => not used any more

const DeadLetterModal = (props) => {
  const {
    open, handleClose, isQueue, isSubscription,
    apiProperties, integrationId, maxDLMessages, setMaxDLMessages,
    showDeadletters, setShowDeadletters,
  } = props;
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [deadletters, setDeadletters] = useState();
  const [title, setTitle] = useState('Dead Letters');

  const { api } = useContext(IntegrationsContext);

  const btnGetDeadlettersOnClick = (messages = maxDLMessages) => {
    setTitle('Dead Letters');
    setLoading(true);
    setShowDeadletters(false);
    if (isQueue) {
      api.getQueueDeadLetters(
        integrationId,
        apiProperties.queueName,
        messages ?? maxDLMessages,
      )
        .then((response) => {
          setDeadletters(response.data);
          setShowDeadletters(true);
        })
        .catch((error) => {
          setTitle(`${error}`);
        })
        .finally(() => setLoading(false));
    } else if (isSubscription) {
      api.getSubscriptionDeadLetters(
        integrationId,
        apiProperties.topicName,
        apiProperties.subscriptionName,
        messages ?? maxDLMessages,
      )
        .then((response) => {
          setDeadletters(response.data);
          setShowDeadletters(true);
        })
        .catch((error) => {
          setTitle(`Some Error... ${error}`);
        })
        .finally(() => setLoading(false));
    }
  };

  const refreshDLBtnOnClick = (messages = 10) => {
    setMaxDLMessages(messages);
    btnGetDeadlettersOnClick(messages);
  };

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title={title}
      dialogProps={showDeadletters ? { fullScreen: true } : {}}
    >

      {loading ? (
        <Grid container alignItems="center" direction="column" justifyContent="center">
          <Grid item>
            <Typography variant="body1" className={classes.loadingTypo}>
              Loading dead letters
            </Typography>
          </Grid>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!showDeadletters && (
            <>
              <Typography variant="body1" gutterBottom>
                Select how many letters to list out
              </Typography>
              <Typography variant="body2" color="red" gutterBottom>
                NB! It is not guaranteed that the selected amount will be returned
              </Typography>
              <Grid container justifyContent="center" alignItems="center" className={classes.gridRetrieve}>
                <Grid item>
                  <TextField
                    className={classes.maxMessagesInput}
                    type="number"
                    label="Num of deadletters"
                    variant="standard"
                    value={maxDLMessages}
                    onChange={(e) => setMaxDLMessages(e.target.value)}
                    InputProps={{ inputProps: { min: 1, max: 100 } }}
                  />
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={() => btnGetDeadlettersOnClick()}>
                    Retrieve dead letters
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {showDeadletters && maxDLMessages > deadletters?.length && (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item className={classes.refreshText}>
            <Typography variant="body2" color="red">
              {/* eslint-disable-next-line max-len */}
              {`The number of messages was not the same as specified, try again later\nGot: ${deadletters?.length} of ${maxDLMessages}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => btnGetDeadlettersOnClick()}>
              Refresh
            </Button>
          </Grid>
        </Grid>
      )}
      {showDeadletters && (
        <DeadLettersTable
          deadletters={deadletters}
          isQueue={isQueue}
          isSubscription={isSubscription}
          apiProperties={{ integrationId, ...apiProperties }}
          refreshDLBtnOnClick={refreshDLBtnOnClick}
        />
      )}
    </CustomModal>
  );
};

DeadLetterModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isQueue: PropTypes.bool.isRequired,
  isSubscription: PropTypes.bool.isRequired,
  apiProperties: PropTypes.shape({
    queueName: PropTypes.string,
    topicName: PropTypes.string,
    subscriptionName: PropTypes.string,
  }).isRequired,
  integrationId: PropTypes.string.isRequired,
  maxDLMessages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setMaxDLMessages: PropTypes.func.isRequired,
  showDeadletters: PropTypes.bool.isRequired,
  setShowDeadletters: PropTypes.func.isRequired,
};

DeadLetterModal.defaultProps = {
  open: false,
};

export default DeadLetterModal;
