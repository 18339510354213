import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Box, TextField, Button, Grid, MenuItem,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { edgeTypeNames, nodeTypeNames } from '../../Constants/FlowConstants';
import IntegrationsContext from '../../Contexts/IntegrationsContext';

const useStyles = makeStyles()((_theme, props) => ({
  contentGrid: {
    marginTop: 10,
  },
  textField: {
    margin: 10,
  },
  numberTextField: {
    minWidth: 130,
  },
  button: {
    margin: 10,
  },
  dataFields: {
    display: (props.isData ? '' : 'none'),
  },
  edgeFields: {
    display: (props.isEdge ? '' : 'none'),
  },
  busFields: {
    display: (props.isBus ? '' : 'none'),
  },
  connectorFields: {
    display: (props.isConnector ? '' : 'none'),
    minWidth: 150,
    maxWidth: 250,
  },
  commonText: {
    fontFamily: 'Open Sans',
  },
}));

const ElementEditor = ({
  selectedElement, updateElement, deleteElement,
}) => {
  const { id, type, data } = selectedElement;
  const [label, setLabel] = useState(data.label ?? '');
  const [numOfTargets, setNumOfTargets] = useState(data.numOfTargets ?? 1);
  const [numOfSources, setNumOfSource] = useState(data.numOfSources ?? 1);
  const [integrationId, setIntegrationId] = useState(data.integrationId ?? '');
  const isBus = type === nodeTypeNames.bus;
  const isEdge = type === edgeTypeNames.custom;
  const isData = type === nodeTypeNames.sourceData || type === nodeTypeNames.targetData;
  const isConnector = !isBus && !isEdge && !isData;
  const { classes } = useStyles({
    isBus, isEdge, isData, isConnector,
  });
  const { requestData: integrations } = useContext(IntegrationsContext);

  useEffect(() => {
    setLabel(data.label ?? '');
    setNumOfSource(data.numOfSources ?? 1);
    setNumOfTargets(data.numOfTargets ?? 1);
    setIntegrationId(data.integrationId ?? '');
  }, [selectedElement, data.label, data.numOfSources, data.numOfTargets, data.integrationId]);

  const onUpdateBtnClick = () => {
    const updatedElement = {
      id,
      type,
      data: {
        label,
      },
    };
    if (!isEdge) {
      // Node
      const { position } = selectedElement;
      updatedElement.position = position;

      if (isBus) {
        // Bus
        updatedElement.data.numOfSources = parseInt(numOfSources, 10);
        updatedElement.data.numOfTargets = parseInt(numOfTargets, 10);
      } else if (!isData) {
        // Node but not a bus or data
        updatedElement.data.integrationId = integrationId;
      }
    } else {
      // Edge
      updatedElement.data.type = selectedElement.data.type;
    }

    updateElement(updatedElement);
  };

  const onDeleteBtnClick = () => {
    deleteElement(selectedElement);
  };

  return (
    <Box>
      <Typography variant="h6" className={classes.commonText}>
        {`Editing element ${id}, type: ${isEdge ? `edge-${data.type}` : `node-${type}`}`}
      </Typography>
      <form onSubmit={onUpdateBtnClick}>
        <Grid container className={classes.contentGrid} alignContent="center" justifyContent="center">
          <TextField
            className={classes.textField}
            sx={{ display: isEdge ? 'none' : '' }}
            variant="outlined"
            label="Element Label"
            key="label"
            value={label}
            InputProps={{ className: classes.commonText }}
            onChange={(e) => setLabel(e.target.value)}
          />
          <TextField
            className={`${classes.textField} ${classes.numberTextField} ${classes.busFields}`}
            variant="outlined"
            label="Number of inputs"
            key="inputs"
            value={numOfTargets}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 10 }, className: classes.commonText }}
            onChange={(e) => setNumOfTargets(e.target.value)}
          />
          <TextField
            className={`${classes.textField} ${classes.numberTextField} ${classes.busFields}`}
            variant="outlined"
            label="Number of outputs"
            key="outputs"
            value={numOfSources}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 10 }, className: classes.commonText }}
            onChange={(e) => setNumOfSource(e.target.value)}
          />
          <TextField
            className={`${classes.textField} ${classes.connectorFields}`}
            variant="outlined"
            label="Integration"
            select
            value={integrationId}
            InputProps={{ className: classes.commonText }}
            onChange={(e) => setIntegrationId(e.target.value)}
          >
            {integrations?.data?.map((integration) => (
              <MenuItem
                onClick={() => setLabel(integration.displayName ?? integration.name)}
                key={integration.displayName ?? integration.name}
                value={integration.id}
                className={classes.commonText}
              >
                {integration.displayName ?? integration.name}
              </MenuItem>
            ))}
            {!integrations?.data && (
              <MenuItem key="error" value="error">
                Error loading
              </MenuItem>
            )}
          </TextField>
          <Button
            className={`${classes.button} ${classes.commonText}`}
            variant="contained"
            color="success"
            onClick={onUpdateBtnClick}
            sx={{ display: isEdge ? 'none' : '' }}
          >
            Update element
          </Button>
          <Button
            className={`${classes.button} ${classes.commonText}`}
            variant="contained"
            color="warning"
            onClick={onDeleteBtnClick}
          >
            Delete element
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

ElementEditor.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    position: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    data: PropTypes.shape({
      label: PropTypes.string,
      numOfSources: PropTypes.number,
      numOfTargets: PropTypes.number,
      type: PropTypes.string,
      integrationId: PropTypes.string,
    }),
  }),
  updateElement: PropTypes.func.isRequired,
  deleteElement: PropTypes.func.isRequired,
};

ElementEditor.defaultProps = {
  selectedElement: {
    id: 'Empty',
    type: 'None',
    position: {
      x: 0,
      y: 0,
    },
    data: {
      label: '',
      numOfSources: 0,
      numOfTargets: 0,
      type: '',
      integrationId: '-1',
    },
  },
};

export default ElementEditor;
