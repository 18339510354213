import { getTimeKind } from '../Constants/Constants';

/**
 * Builds a search query with the parameters
 * @param {String} dateStart
 * @param {String} dateEnd
 * @param {*} errorCode
 * @param {String} errorMsg
 * @param {String} status
 * @returns {String} search query string (without the '?')
 */
export const buildSearchQueryParams = (dateStart = '', dateEnd = '', errorCode = '', status = [], searchString = '') => {
  let query = '';
  if (dateStart !== '') {
    query += `datestart=${dateStart}&`;
  }
  if (dateEnd !== '') {
    query += `dateend=${dateEnd}&`;
  }
  if (errorCode !== '') {
    query += `errorcode=${errorCode}&`;
  }
  if (Array.isArray(status) && status?.length > 0) {
    status.forEach((elm) => {
      query += `status=${elm}&`;
    });
  }
  if (searchString !== '') {
    query += `searchstring=${searchString}`;
  }

  return query;
};

/**
 * Builds a serch query with the search data as an object
 * @param {{}} searchData
 * @returns {String} search query string (without the '?')
 */
export const buildSearchQuery = (searchData) => {
  const {
    dateStart, dateFinished, errorCode,
    status, searchString, onlyDeadletters,
    advSearch, advSearchMode,
    doNotSendAdvSearch,
  } = searchData;

  let query = '';
  if (dateStart && dateStart !== '') {
    // Add kind / utc offset to date (backend converts to UTC date automatically)
    query += `datestart=${getTimeKind(dateStart)}&`;
  }
  if (dateFinished && dateFinished !== '') {
    query += `dateend=${getTimeKind(dateFinished)}&`;
  }
  if (errorCode && errorCode !== '') {
    query += `errorcode=${errorCode}&`;
  }
  if (Array.isArray(status) && status?.length > 0) {
    status.forEach((elm) => {
      query += `status=${elm}&`;
    });
  }

  if (onlyDeadletters === true) {
    query += 'onlyDeadletters=true&';
  }

  if (!doNotSendAdvSearch && Array.isArray(advSearch) && advSearch?.length > 0) {
    let index = 0;
    const filteredAdvSearch = advSearch.filter(
      (elm) => elm.field && (elm.value !== null || elm.value !== undefined),
    );
    filteredAdvSearch.forEach((elm) => {
      query += `advancedSearch[${index}].Field=${elm.field}&advancedSearch[${index}].Value=${elm.value}&`;
      index += 1;
    });

    if (filteredAdvSearch.length > 0 && (advSearchMode !== null || advSearchMode !== undefined)) {
      query += `advancedSearchMode=${advSearchMode}&`;
    }
  } else if (searchString && searchString !== '') {
    query += `searchstring=${searchString}&`;
  }
  return query;
};

export const queryFixDate = (date) => {
  if (date) {
    const newDate = getTimeKind(date);
    if (newDate === 'Invalid Date') return undefined;
    return newDate;
  }
  return undefined;
};

/**
 * Builds header for ServiceHost Http Api Calls
 * @param {string} key The ApiKey
 * @returns {any} The header
 */
export const buildServiceHostHeader = (key) => {
  if (key) {
    return {
      headers: {
        ApiKey: key,
      },
    };
  }
  return {};
};

export const isAxiosResponseHTML = (response) => response.headers['content-type'] === 'text/html';
