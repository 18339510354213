import {
  Box, CircularProgress, Grid, InputBase, MenuItem, Select, Typography,
} from '@mui/material';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { makeStyles } from 'tss-react/mui';
import PageContext from '../Contexts/PageContext';
import DataVisualization from '../Component/DataVisualization/DataVisualization';
import capitalizeAndLower from '../Utils/Utils';
import IntegrationsContext from '../Contexts/IntegrationsContext';

const useStyles = makeStyles()((theme) => ({
  ...theme.details,
  ...theme.statistics,
}));

const selectItems = [
  { name: 'Last day', interval: '1.00:00' },
  { name: 'Last week', interval: '7.00:00' },
  { name: 'Last 14 days', interval: '14.00:00' },
  { name: 'Last month', interval: '30.00:00' },
  { name: 'Last 3 months', interval: '90.00:00' },
];

const StatisticsPage = () => {
  const { classes } = useStyles();

  const [chartData, setChartData] = useState(null);
  const [visIsError, setVisIsError] = useState(false);
  const [visErrorMsg, setVisErrorMsg] = useState('');
  const [visLoading, setVisLoading] = useState(false);

  const { selected } = useContext(PageContext);
  const { api } = useContext(IntegrationsContext);

  const [selectInterval, setSelectInterval] = useState(selectItems[2].interval);

  useEffect(() => {
    if (api && selected) {
      setVisIsError(false);
      setVisLoading(true);
      const { name } = selected;
      api.getIntegrationDataVisualization(name, selectInterval)
        .then((response) => {
          setChartData(response?.data);
          setVisIsError(false);
        }).catch((error) => {
          setVisIsError(true);
          setVisErrorMsg(`${error?.response?.message}`);
        })
        .finally(() => setVisLoading(false));
    }
  }, [api, selectInterval, selected]);

  const handleStatusChange = (event) => {
    const { target: { value } } = event;
    setSelectInterval(value);
  };

  return (
    <Box>
      {visIsError && (
        <Typography className={classes.detailsIntegrationErrorText}>
          {visErrorMsg}
        </Typography>
      )}
      <Grid
        container
        className={classes.statsGridContainer}
        justifyContent="space-between"
      >
        <Grid item className={classes.detailsSearchBox}>
          <Grid container justifyContent="left" alignItems="center">
            <Grid item className={classes.searchGridItemSpacing}>
              <Select
                fullWidth
                onChange={handleStatusChange}
                value={selectInterval}
                displayEmpty
                className={`${classes.searchFieldSize} ${classes.statIntervalSelect}`}
                input={<InputBase className={`${classes.searchFieldText}`} />}
                inputProps={{ className: `${classes.searchFieldInput}` }}
              >
                {selectItems.map((item) => (
                  <MenuItem
                    key={item.interval}
                    className={classes.searchFieldStatusMenuItem}
                    value={item.interval}
                  >
                    <Typography className={classes.searchFieldText}>
                      {capitalizeAndLower(item.name)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {visLoading ? (
        <Box style={{ height: 300 }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataVisualization
          chartData={chartData?.chartData}
          interval={selectInterval}
        />
      )}
    </Box>
  );
};

export default StatisticsPage;
