import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

const IntegrationArrowRightIcon = ({ className, onClick }) => (
  <IconButton size="small" onClick={onClick} className={className}>
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="35" fill="#D8DCDD" />
      <path d="M50.0607 36.0607C50.6464 35.4749 50.6464 34.5251 50.0607 33.9393L40.5147 24.3934C39.9289 23.8076 38.9792 23.8076 38.3934 24.3934C37.8076 24.9792 37.8076 25.9289 38.3934 26.5147L46.8787 35L38.3934 43.4853C37.8076 44.0711 37.8076 45.0208 38.3934 45.6066C38.9792 46.1924 39.9289 46.1924 40.5147 45.6066L50.0607 36.0607ZM20 36.5H49V33.5H20V36.5Z" fill="black" />
    </svg>
  </IconButton>
);

IntegrationArrowRightIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

IntegrationArrowRightIcon.defaultProps = {
  className: '',
};

export default IntegrationArrowRightIcon;
