import axios from 'axios';
import END_POINTS, { CREATE_RULE } from '../Constants/EndPoints';

/**
 * Static functions in this are for getting RuleEngine entities (rules/workflows etc)
 * from the Rule Integration
 */
export default class ApiRuleIntegration {
  /**
   * Gets all workflows (and rules)
   * @returns {Promise<any>}
   */
  static getWorkflows(apiKey) {
    const config = this.createHeaders(false, apiKey);
    return axios.get(`${END_POINTS.GET_WORKFLOWS}`, config);
  }

  /**
   * Add a workflow
   * @param {string} workflowName
   * @param {Array<any>} rules Rules (see C# class)
   * @returns {Promise<any>}
   */
  static addWorkflow(workflowName, rules, apiKey) {
    const config = this.createHeaders(true, apiKey);
    const data = { workflowName, rules };
    return axios.post(`${END_POINTS.WORKFLOW}`, JSON.stringify(data), config);
  }

  /**
   * Delete a workflow (with all rules)
   * @param {string} workflowName
   * @returns {Promise<any>}
   */
  static deleteWorkflow(workflowName, apiKey) {
    const config = this.createHeaders(true, apiKey);
    return axios.post(`${END_POINTS.WORKFLOW}/${workflowName}`, JSON.stringify({}), config);
  }

  /**
   * Add a rule to a workflow
   * @param {string} workflowName
   * @param {Rule} rule A rule (see C# class)
   * @returns {Promise<any>}
   */
  static addRule(workflowName, rule, apiKey) {
    const config = this.createHeaders(true, apiKey);
    return axios.post(`${CREATE_RULE(workflowName)}`, JSON.stringify(rule), config);
  }

  /**
   * Delete a rule
   * @param {string} ruleName
   * @returns {Promise<any>}
   */
  static deleteRule(ruleName, apiKey) {
    const config = this.createHeaders(true, apiKey);
    return axios.post(`${END_POINTS.RULE}/${ruleName}`, JSON.stringify({}), config);
  }

  /**
   * Updates a rule
   * @param {string} ruleName
   * @param {Rule} rule A rule (see C# class)
   * @returns {Promise<any>}
   */
  static updateRule(ruleName, rule, apiKey) {
    const config = this.createHeaders(true, apiKey);
    return axios.put(`${END_POINTS.RULE}/${ruleName}`, JSON.stringify(rule), config);
  }

  /**
   * Get a rule
   * @param {string} ruleName
   * @returns {Promise<any>}
   */
  static getRule(ruleName, apiKey) {
    const config = this.createHeaders(false, apiKey);
    return axios.get(`${END_POINTS.RULE}/${ruleName}`, config);
  }

  /**
   * Get all rules
   * @param {string} apiKey
   * @returns {Promise<any>}
   */
  static getRules(apiKey) {
    const config = this.createHeaders(false, apiKey);
    return axios.get(`${END_POINTS.RULE}s`, config);
  }

  static createHeaders(isJsonData, apiKey) {
    let headers = {};
    if (isJsonData) {
      headers = { 'Content-Type': 'application/json' };
    }
    if (apiKey) {
      headers = { ...headers, ApiKey: apiKey };
    }
    return { headers };
  }
}
