import React from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, MenuItem, OutlinedInput, Select,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// Taken and edited from: https://mui.com/components/selects/#default

const MultipleSelect = (props) => {
  const theme = useTheme();
  const {
    data, selectedItems, setSelectedItems, className, label,
  } = props;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedItems(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl className={className} sx={{ width: 300 }}>
      <InputLabel id="multiple-item-label">{label}</InputLabel>
      <Select
        labelId="multiple-item-label"
        id="multiple-item"
        multiple
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        MenuProps={MenuProps}
      >
        {data?.map((item) => (
          <MenuItem
            key={item}
            value={item}
            style={getStyles(item, selectedItems, theme)}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultipleSelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string]),
};

MultipleSelect.defaultProps = {
  className: '',
  label: 'MultiSelect',
};

export default MultipleSelect;
