/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import ConnectorFlow from './ConnectorFlow';

const ConnectorFlowProvider = (props) => (
  <ReactFlowProvider>
    <ConnectorFlow
      {...props}
    />
  </ReactFlowProvider>
);

export default ConnectorFlowProvider;
