import { useMatch } from 'react-router-dom';
import ROUTES from '../Constants/Routes';

const findRoute = (name) => ROUTES.find((v) => v.name === name);

export const getAllRouteMatches = () => {
  const matches = [];

  ROUTES.forEach((route) => {
    const match = useMatch(`${route.path}/*`);

    if (match !== null && match !== undefined) {
      const { breadcrumbs } = route;
      matches.push({ ...match, breadcrumbs });
    }
  });
  return matches;
};

export default findRoute;
