import React from 'react';

const ExpandPlusIcon = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7402 0.541504V22.0114" stroke="#003366" strokeWidth="2.2" strokeMiterlimit="10" />
    <path d="M21.47 11.2815H0" stroke="#003366" strokeWidth="2.2" strokeMiterlimit="10" />
  </svg>
);

export default ExpandPlusIcon;
