const overviewPage = {
  ovIntegrationsBox: {
    height: '100%',
    marginTop: 31,
  },
  ovIntegrationCardGridItem: {
    marginRight: 31,
    marginBottom: 40,
  },
  ovIntegrationCardLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  ovIntegrationCardBox: {
    boxSizing: 'border-box',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: 278,
    height: 128,
    position: 'relative',
  },
  ovIntegrationCardBoxFailed: {
    background: '#EE9282',
    border: '1px solid #F0B1A8',
  },
  ovIntegrationCardBoxOk: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
  },
  ovIntegrationCardIconBox: {
    position: 'absolute',
    top: 15,
    left: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '41px',
  },
  ovIntegrationCardHeaderTextBox: {
    top: 11,
    position: 'absolute',
    left: '85px',
    height: 50,
    display: 'flex',
    alignItems: 'center',
  },
  ovIntegrationCardText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#333333',
    textAlign: 'left',
  },
  ovIntegrationCardSubText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#333333',
    textAlign: 'left',
  },
  ovIntegrationCardTextBox: {
    position: 'absolute',
    left: 85,
    bottom: 19,
  },
  ovFlowText: {
    fontWeight: 700,
    textAlign: 'center',
  },
};

export default overviewPage;
