import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Info, KeyboardArrowUp, KeyboardArrowDown, CheckCircle,
  PlayArrow, Loop, Timer, Stop, HelpOutline,
} from '@mui/icons-material';
import {
  ButtonBase,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from 'tss-react/mui';
import { useTimer } from 'react-timer-hook';
import StatusToolTip from './StatusToolTip';
import { SERVICE_RUN_STATE } from '../../Constants/Constants';
import { createTimerDate, toYearDateTimeStandardString } from '../../Utils/DateTimeUtils';
import { isServiceRunning } from '../../Utils/ServiceProcessUtils';
import PageContext from '../../Contexts/PageContext';
import IntegrationsContext from '../../Contexts/IntegrationsContext';

const stateTable = (state, classes) => {
  switch (state) {
  case SERVICE_RUN_STATE.STARTED:
    return (<Tooltip title={state}><PlayArrow className={classes.okIcon} /></Tooltip>);
  case SERVICE_RUN_STATE.WORKING:
    return (<Tooltip title={state}><Loop className={classes.okIcon} /></Tooltip>);
  case SERVICE_RUN_STATE.SLEEPING:
    return (<Tooltip title={state}><Timer className={classes.neutralIcon} /></Tooltip>);
  case SERVICE_RUN_STATE.STOPPED:
    return (<Tooltip title={state}><Stop className={classes.warningIcon} /></Tooltip>);
  case SERVICE_RUN_STATE.TERMINATED:
    return (<Tooltip title={state}><CheckCircle color="primary" /></Tooltip>);
  default:
    return (<Tooltip className={classes.statusIconInfo}><HelpOutline /></Tooltip>);
  }
};

const useStyle = makeStyles()((theme) => ({
  statusIconInfo: {
    backgroundColor: theme.statusColors.info,
  },
  okIcon: {
    color: theme.statusColors.ok,
  },
  neutralIcon: {
    color: theme.statusColors.neutral,
  },
  warningIcon: {
    color: theme.statusColors.warning,
  },
  activeRow: {
    backgroundColor: theme.tables.activeRowColor,
  },
  taskTableRow: {
    backgroundColor: theme.tables.subRowColor,
  },
  taskTableHeaderText: {
    color: theme.tables.subRowHeaderColor,
  },
  ...theme.details,
}));

const ServicesTableRow = ({
  service, refreshServices, updateModalAndOpen,
}) => {
  const { classes } = useStyle();
  const [openTaskTable, setOpenTaskTable] = useState(false);
  const [isLoadingStart, setIsLoadingStart] = useState(false);
  const [isLoadingStop, setIsLoadingStop] = useState(false);
  const [isLoadingRun, setIsLoadingRun] = useState(false);

  const [taskLoading, setTaskLoading] = useState(
    Object.fromEntries(service.tasks.map((t) => [t.guid, false])),
  );

  const { api } = useContext(IntegrationsContext);

  const {
    selectedId,
  } = useContext(PageContext);

  const onTimerEnd = () => {
    refreshServices();
  };

  const { restart } = useTimer(
    { expiryTimestamp: createTimerDate(100), onExpire: onTimerEnd, autoStart: false },
  );

  const onStartServiceBtnClick = () => {
    setIsLoadingStart(true);
    api.startConnectorService(selectedId, service.guid)
      .then(() => {
        restart(createTimerDate(3));
      }).catch((error) => {
        const title = `Error starting Service (${service.guid})`;
        const text = `Error was: ${error.response.data}`;
        updateModalAndOpen(title, text);
      });
  };

  const onStopServiceBtnClick = () => {
    setIsLoadingStop(true);
    api.stopConnectorService(selectedId, service.guid)
      .then(() => {
        restart(createTimerDate(3));
      }).catch((error) => {
        const title = `Error stopping Service (${service.guid})`;
        const text = `Error was: ${error.response.data}`;
        updateModalAndOpen(title, text);
      });
  };

  const onRunServiceBtnClick = () => {
    setIsLoadingRun(true);
    api.runConnectorService(selectedId, service.guid)
      .then(() => {
        restart(createTimerDate(1));
      }).catch((error) => {
        const title = `Error running Service (${service.guid})`;
        const text = `Error was: ${error.response.data}`;
        updateModalAndOpen(title, text);
      });
  };

  const onRunTaskBtnClick = (guid) => {
    setTaskLoading((old) => ({ ...old, [guid]: true }));
    api.runConnectorTask(selectedId, guid)
      .then(() => {
        restart(createTimerDate(1));
      }).catch((error) => {
        const title = `Error running Task (${guid})`;
        const text = `Error was: ${error.response.data}`;
        updateModalAndOpen(title, text);
      });
  };

  const isServiceRunningState = useCallback(() => {
    isServiceRunning(service.state);
  }, [service.state]);

  useEffect(() => {
    setIsLoadingStop(false);
    setIsLoadingStart(false);
  }, [isServiceRunningState]);

  useEffect(() => {
    setTaskLoading(Object.fromEntries(service.tasks.map((t) => [t.guid, false])));
  }, [service.tasks]);

  useEffect(() => setIsLoadingRun(false), [service.lastRun]);

  return (
    <>
      <TableRow key={service.guid} hover={!openTaskTable} className={openTaskTable ? classes.activeRow : ''}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpenTaskTable(!openTaskTable)}>
            {openTaskTable ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          {stateTable(service.state, classes)}
        </TableCell>
        <TableCell>{service.name}</TableCell>
        <Tooltip
          title={service.lastStatus !== null ? StatusToolTip(service.lastStatus) : <Typography variant="h6">No status</Typography>}
          arrow
        >
          <TableCell align="left"><Info color="action" /></TableCell>
        </Tooltip>
        <TableCell>
          {service.lastStatus !== null
            ? (
              <Grid container item>
                <Typography>
                  {service.lastStatus.succeededItems}
                </Typography>
              </Grid>
            )
            : ('no run')}
        </TableCell>
        <TableCell>
          {service.lastStatus !== null
            ? (
              <Grid container item>
                <Typography>
                  {service.lastStatus.failedItems}
                </Typography>
              </Grid>
            )
            : ('No run')}
        </TableCell>
        <TableCell>
          {
            service.lastRun === null
              ? 'No run'
              : toYearDateTimeStandardString(service.lastRun)
          }
        </TableCell>
        <TableCell>
          {
            service.nextRun === null
              ? 'No run'
              : toYearDateTimeStandardString(service.nextRun)
          }
        </TableCell>
        <TableCell>
          {isServiceRunning(service.state) ? (
            <>
              <Tooltip title="Stop service">
                {
                  isLoadingStop
                    ? <LoadingButton loading className={classes.toggleServiceBtn} />
                    : (
                      <ButtonBase
                        className={classes.toggleServiceBtn}
                        onClick={onStopServiceBtnClick}
                      >
                        <Stop className={classes.stopBtnIcon} />
                        <Typography className={classes.toggleServiceBtnText}>
                          Stop
                        </Typography>
                      </ButtonBase>
                    )
                }
              </Tooltip>
              <Tooltip title="Run service">
                {
                  isLoadingRun
                    ? <LoadingButton loading className={classes.toggleServiceBtn} />
                    : (
                      <ButtonBase
                        disabled={isLoadingStop}
                        className={classes.toggleServiceBtn}
                        onClick={onRunServiceBtnClick}
                      >
                        <PlayArrow className={classes.runBtnIcon} />
                        <Typography className={classes.toggleServiceBtnText}>
                          Run
                        </Typography>
                      </ButtonBase>
                    )
                }
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Start service">
              {isLoadingStart
                ? <LoadingButton loading className={classes.toggleServiceBtn} />
                : (
                  <ButtonBase
                    className={classes.toggleServiceBtn}
                    onClick={onStartServiceBtnClick}
                  >
                    <PlayArrow className={classes.runBtnIcon} />
                    <Typography className={classes.toggleServiceBtnText}>
                      Start
                    </Typography>
                  </ButtonBase>
                )}
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow className={classes.taskTableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={openTaskTable} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.taskTableHeaderText}>State</TableCell>
                  <TableCell className={classes.taskTableHeaderText}>Name</TableCell>
                  <TableCell className={classes.taskTableHeaderText}>Last Run</TableCell>
                  <TableCell className={classes.taskTableHeaderText} />
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  service.tasks.map((task) => (
                    <TableRow hover key={task.guid}>
                      <TableCell component="th">{task.state}</TableCell>
                      <TableCell>{task.name}</TableCell>
                      <TableCell>
                        {task.lastRun === null
                          ? 'No run'
                          : toYearDateTimeStandardString(task.lastRun)}
                      </TableCell>
                      <TableCell>
                        {taskLoading[task.guid]
                          ? (
                            <LoadingButton
                              loading
                              className={classes.toggleServiceBtn}
                            />
                          ) : (
                            <Tooltip title="Run task">
                              <ButtonBase
                                className={classes.toggleServiceBtn}
                                onClick={() => onRunTaskBtnClick(task.guid)}
                              >
                                <PlayArrow className={classes.runBtnIcon} />
                                <Typography className={classes.toggleServiceBtnText}>
                                  Run
                                </Typography>
                              </ButtonBase>
                            </Tooltip>
                          )}
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

ServicesTableRow.propTypes = {
  service: PropTypes.shape({
    guid: PropTypes.string,
    name: PropTypes.string,
    nextRun: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    lastRun: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    state: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    lastStatus: PropTypes.object,
    tasks: PropTypes.arrayOf(PropTypes.shape({
      guid: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
    })),
  }).isRequired,
  refreshServices: PropTypes.func.isRequired,
  updateModalAndOpen: PropTypes.func.isRequired,
};

ServicesTableRow.defaultProps = {
};

export default ServicesTableRow;
