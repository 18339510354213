import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase,
  TableCell, TableRow, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { MAX_CHARS_RUN_TABLECELL_EXPANDED } from '../../Constants/Constants';

const useStyles = makeStyles()((theme) => ({
  whiteSpaceFix: {
    whiteSpace: 'pre-wrap',
  },
  ...theme.details,
}));

const DataResolvedTableRow = (props) => {
  const {
    data, headerNames, onClick, showOpenBtn, columnCount,
  } = props;
  const { classes } = useStyles();

  const maxChars = MAX_CHARS_RUN_TABLECELL_EXPANDED;

  const handleHeaderNames = (headerName) => {
    let output = '';
    if (data) {
      try {
        if (typeof data[headerName] === 'object') {
          output = JSON.stringify(data[headerName], null, 2);
        } else {
          output = `${data[headerName]}`;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Getting cell data error [DataResolvedTableRow]:', e);
      }
    }
    if (output && output.length > maxChars) {
      return (
        <ButtonBase
          onClick={() => onClick(output, headerName)}
          className={`${classes.runsTableExpandedOpenText} ${classes.runsTableRowText}`}
        >
          Show
        </ButtonBase>
      );
    }
    return output;
  };

  return (
    <TableRow>
      {headerNames.map((headerName, index) => {
        if (showOpenBtn && index >= columnCount) {
          return null;
        }
        return (
          <TableCell className={classes.whiteSpaceFix} key={headerName}>
            <Typography className={classes.runsTableRowText}>
              {handleHeaderNames(headerName)}
            </Typography>
          </TableCell>
        );
      })}
      {showOpenBtn && (
        <TableCell>
          <ButtonBase
            onClick={() => onClick(data, 'Run data')}
            className={`${classes.runsTableExpandedOpenText} ${classes.runsTableRowText}`}
          >
            Open
          </ButtonBase>
        </TableCell>
      )}
    </TableRow>
  );
};

DataResolvedTableRow.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  headerNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  showOpenBtn: PropTypes.bool,
  columnCount: PropTypes.number,
};

DataResolvedTableRow.defaultProps = {
  data: '',
  showOpenBtn: false,
  columnCount: 0,
};

export default DataResolvedTableRow;
