import React from 'react';

const AzureIcon = () => (
  <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_235_338)">
      <path d="M10.1772 16.0892L15.3648 15.1762L15.4132 15.1652L12.7446 11.9983C11.8514 10.9419 10.9619 9.88119 10.076 8.81603C10.076 8.80067 12.8326 1.23122 12.848 1.20488C12.8634 1.17854 14.7378 4.42667 17.3932 9.03331L21.9648 16.9342L21.9978 16.9956H5.038L10.1772 16.0892V16.0892ZM-0.00219727 15.1257C-0.00219727 15.1257 1.25401 12.9442 2.79181 10.2974L5.5858 5.46915L8.84181 2.74335C10.6348 1.23122 12.0978 0 12.111 0C12.0999 0.0462278 12.0822 0.0905914 12.0582 0.131681L8.5228 7.69675L5.0578 15.1257H2.5322C1.144 15.1257 0.00880005 15.1257 0.00880005 15.1257H-0.00219727Z" fill="#0089D6" />
    </g>
    <defs>
      <clipPath id="clip0_235_338">
        <rect width="22" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AzureIcon;
