import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'react-flow-renderer';
import { Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  sourceData: {
    background: theme.nodes.background,
    color: theme.nodes.color,
    padding: theme.nodes.padding,
    borderWidth: theme.nodes.borderWidth,
    borderStyle: theme.nodes.borderStyle,
    borderColor: theme.nodes.data.sourceColor,
    borderRadius: theme.nodes.borderRadius,
    minWidth: theme.nodes.data.minWidth,
  },
  inputHandle: {
    background: theme.nodes.data.sourceColor,
  },
  ...theme.overviewPage,
}));

const SourceDataNode = memo(({ data, isConnectable }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.sourceData}>
      <Typography className={`${classes.ovIntegrationCardText} ${classes.ovFlowText}`}>
        {data.label}
      </Typography>
      <Handle
        className={classes.inputHandle}
        type="source"
        position={Position.Right}
        id="s"
        isConnectable={isConnectable}
      />
    </Box>
  );
});

SourceDataNode.propTypes = {
  isConnectable: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
  }),
};

SourceDataNode.defaultProps = {
  data: {
    label: 'SourceData',
  },
};

export default SourceDataNode;
