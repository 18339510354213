const ROUTES = [
  {
    name: 'home', path: '/old', breadcrumbs: 'Home',
  },
  {
    name: 'integration', path: '/integration/:id', breadcrumbs: 'Integration',
  },
  {
    name: 'notification', path: '/notification', breadcrumbs: 'Notification',
  },
  {
    name: 'runs', path: '/integration/:id/runs/:pageNumber/:numberOfRows', breadcrumbs: 'Runs',
  },
  {
    name: 'run', path: '/integration/:id/runs/:pageNumber/:numberOfRows/run/:runId', breadcrumbs: 'Run',
  },
  { name: 'services', path: '/services', breadcrumbs: 'All Services' },
  { name: 'servicesWithId', path: '/services/:id', breadcrumbs: 'All Services' },
  { name: 'service', path: '/integration/:id/services/:serviceId', breadcrumbs: 'Service' },
  { name: 'tasks', path: '/integration/:id/services/:serviceId/task', breadcrumbs: 'All Tasks' },
  { name: 'task', path: '/integration/:id/services/:serviceId/task/:taskId', breadcrumbs: 'Task' },
  { name: 'alerts', path: '/alerts', breadcrumbs: 'Alerts and notifications' },
  { name: 'details', path: '/details', breadcrumbs: 'Details' },
  { name: 'detailsWithId', path: '/details/:id', breadcrumbs: 'Details' },
  { name: 'statistics', path: '/statistics', breadcrumbs: 'Statistics' },
  { name: 'statisticsWithId', path: '/statistics/:id', breadcrumbs: 'Statistics' },
  { name: 'settings', path: '/settings', breadcrumbs: 'Settings' },
  { name: 'notifications', path: '/notifications', breadcrumbs: 'Notifications' },
  { name: 'overviewFlow', path: '/', breadcrumbs: 'Overview' },
  { name: 'overviewIntegrations', path: '/integrations', breadcrumbs: 'Overview' },
];

export const ROUTE_NAMES = {
  detailsWithId: 'detailsWithId',
  details: 'details',
  statistics: 'statistics',
  statisticsWithId: 'statisticsWithId',
  overviewIntegrations: 'overviewIntegrations',
  overviewFlow: 'overviewFlow',
  services: 'services',
  servicesWithId: 'servicesWithId',
};

export default ROUTES;
