export const msalConfig = {
  auth: {
    clientId: `${window.ENV.MSAL_APPLICATION_ID}`,
    authority: `${window.ENV.MSAL_CLOUD_INSTANCE_ID}/${window.ENV.MSAL_TENANT_INFO}`,
    redirectUri: `${window.ENV.MSAL_REDIRECT_URI}`,
  },
  cache: {
    // cacheLocation: 'sessionStorage',
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const signInConfig = {
  clientId: window.ENV.MSAL_APPLICATION_ID,
  tenantId: window.ENV.MSAL_TENANT_INFO,
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', window.ENV.BACKEND_AD_API_SCOPE],
  // scopes: ['api://321aefe0-a2ec-4fe2-9f14-f4122b61a97d/access_as_user'],
};

export const storageConfig = {
  scopes: ['https://storage.azure.com/user_impersonation'],
};

export const backendTokenRequest = {
  scopes: [window.ENV.BACKEND_AD_API_SCOPE],
};
