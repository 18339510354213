import React, { useState } from 'react';
import {
  IconButton, TableCell, TableRow, TextField, Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import MultipleSelect from '../Select/MultipleSelect';

const NotificationEmailTableRow = (props) => {
  const {
    email, updateEmail, deleteEmail, ruleNames,
  } = props;

  const [updatedEmail, setUpdatedEmail] = useState('');
  const [selectedRules, setSelectedRules] = useState(email?.rulesSubscribedTo);

  return (
    <TableRow key={`${email.id}`}>
      <TableCell align="center" key={`${email.id}-text`}>
        {email?.email}
      </TableCell>
      <TableCell align="center" key={`${email.id}-input`}>
        <TextField
          lable="Update email"
          variant="outlined"
          onChange={(e) => {
            setUpdatedEmail(e.target.value);
          }}
        />
      </TableCell>
      <TableCell>
        <MultipleSelect
          data={ruleNames}
          selectedItems={selectedRules}
          setSelectedItems={setSelectedRules}
          label="Rule Names"
        />
      </TableCell>
      <TableCell align="center" key={`${email.id}-func`}>
        <Tooltip title="Update email">
          <IconButton
            onClick={() => updateEmail(email.email, updatedEmail, selectedRules)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete email">
          <IconButton
            onClick={() => deleteEmail(email.email)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

NotificationEmailTableRow.propTypes = {
  email: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    rulesSubscribedTo: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  updateEmail: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  ruleNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NotificationEmailTableRow;
