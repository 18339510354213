import {
  Box, CircularProgress, Grid, Typography,
} from '@mui/material';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { sortIntegrations } from '../../Utils/IntegrationUtils';
import SelectSortBy from './SelectSortBy';
import IntegrationsContext from '../../Contexts/IntegrationsContext';
import detailsPage from '../../Themes/DetailsPage';
import IntegrationCardDetail from './Integration/IntegrationCardDetail';
import IntegrationArrowLeftIcon from './Icons/IntegrationArrowLeftIcon';
import IntegrationArrowRightIcon from './Icons/IntegrationArrowRightIcon';
import PageContext from '../../Contexts/PageContext';

const useStyles = makeStyles()((theme) => ({
  ...theme.details,
}));

const CardBodyPage = (props) => {
  const { children } = props;
  const { classes } = useStyles();
  const { id } = useParams();

  const [sortedIntegrations, setSortedIntegrations] = useState([]);
  const [integrationIsFailed, setIntegrationIsFailed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedId, setSelectedId] = useState('');

  // Refs and stuff for "responsive" design
  const integrationCardsRef = useRef(null);

  // Buttons
  const [showRightButton, setShowRightButton] = useState(false);
  const [showLeftButton, setShowLeftButton] = useState(false);

  // IntegrationCard pages
  const [numOfShown, setNumOfShown] = useState(0);
  const [cardPage, setCardPage] = useState(0);
  const [totalCardPages, setTotalCardPages] = useState(1);

  const [shouldMoveToPage, setShouldMoveToPage] = useState(false);

  const {
    requestData: integrations, metrics, loading, isError, metricsLoading, errorJson, sortBy,
  } = useContext(IntegrationsContext);

  /* CALLBACKS */

  /**
   * Called when a card is called
   * @param {*} integration the integration
   * @param {*} index what the index is
   * @param {*} failed if it is a failed integration
   */
  const cardCallback = (integration, index, failed) => {
    // This is called when an integration card is called
    setSelectedIndex(index);
    setIntegrationIsFailed(failed);
    setSelectedId(integration.id);
    setShouldMoveToPage(false);
  };

  /* HELPER METHODS */

  /**
    * Gets the card offset (for the integration cards "pagination")
    * @returns
    */
  const getCardOffset = () => cardPage * numOfShown;

  /**
   * Gets the selected integration
   * @returns
   */
  const getSelected = () => sortedIntegrations[selectedIndex];

  /* EVENTLISTENERS */

  /**
   * Sets a resize event listener to calculate the integration cards
   * (pages, how many +++)
   */
  useEffect(() => {
    const calcFits = () => {
      const { width } = detailsPage.integrationCardBox;
      const bigWidth = detailsPage.integrationCardSelected.width;
      const cardsBoxWidth = integrationCardsRef?.current?.offsetWidth ?? width;
      const cardMargin = detailsPage.integrationCardMargin.marginRight;

      // selected visible?
      if (selectedIndex < numOfShown) {
        // viss ^ = 1 big, resten small, så kalkuler kor mange small? kan vere?
        // fjern big + margin
        let restWidth = cardsBoxWidth - bigWidth - cardMargin;
        // sjekk om det er plass
        if (restWidth < width) return 1; // only fits the selected
        // fjern small (siste uten margin)
        restWidth -= width;
        // plass til 2 - kalkuler resten
        const restSpaceFor = restWidth / (width + cardMargin);
        return Math.trunc(2 + restSpaceFor);
      }
      // viss ikkje = all small
      const restWidth = cardsBoxWidth - width;
      if (restWidth < (width + cardMargin)) return 1; // only fits 1
      // kalkuler resten
      const restSpaceFor = restWidth / (width + cardMargin);
      return Math.trunc(1 + restSpaceFor);
    };
    const numOf = calcFits();
    setNumOfShown(numOf);

    const numOfPages = Math.ceil(sortedIntegrations.length / numOf);
    setTotalCardPages(numOfPages);
    if (numOfPages > 1 && cardPage < numOfPages - 1) setShowRightButton(true);

    const handleResizeFits = () => {
      const numOf2 = calcFits();
      setNumOfShown(numOf2);
      const numOfPages2 = Math.ceil(sortedIntegrations.length / numOf2);
      setTotalCardPages(numOfPages2);

      // Move to last page + fix buttons on resize as well
      if (cardPage >= numOfPages2) {
        setCardPage(numOfPages2 - 1);
        setShowRightButton(false);
        if (numOfPages2 - 1 === 0) setShowLeftButton(false);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (cardPage < numOfPages2 - 1) {
          setShowRightButton(true);
        } else {
          setShowRightButton(false);
        }
      }
    };

    window.addEventListener('resize', handleResizeFits);
    return () => {
      window.removeEventListener('resize', handleResizeFits);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationCardsRef, cardPage, selectedIndex, loading, metricsLoading, sortedIntegrations]);

  /* OTHERS */

  /**
   * Will sort integrations if integrations / metrics are changed
   */
  useEffect(() => {
    if (integrations && metrics) {
      if (sortedIntegrations && selectedIndex && sortedIntegrations[selectedIndex]) {
        const { name } = sortedIntegrations[selectedIndex];
        const { sorted, newIndex } = sortIntegrations(sortBy, integrations, metrics, name);
        setSortedIntegrations(sorted);
        setSelectedIndex(newIndex);
      } else {
        // initial
        const { sorted } = sortIntegrations(sortBy, integrations, metrics);
        setSortedIntegrations(sorted);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, metrics, sortBy]);

  /**
   * Will select the right card
   */
  useEffect(() => {
    if (id) {
      const index = sortedIntegrations.findIndex((item) => item.id === id) ?? -1;
      if (index !== -1) {
        setSelectedIndex(index);
        setSelectedId(id);
        setIntegrationIsFailed(sortedIntegrations[index].totalFailed > 0);
        // find the page it is on
        setShouldMoveToPage(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sortedIntegrations]);

  useEffect(() => {
    if (shouldMoveToPage && totalCardPages > 1) {
      // find the page;
      // ex: 9 items, 3 pages (3*3)
      // 0-2 = page 1
      // 3-5 = page 2
      // 6-8 = page 3
      // index + 1 (no /0)
      let maxForThisPage = numOfShown - 1;
      for (let i = 0; i < totalCardPages; i += 1) {
        if (selectedIndex <= maxForThisPage) {
          setCardPage(i);
          if (i !== 0 && i <= totalCardPages - 1) setShowLeftButton(true);
          if (i === totalCardPages - 1) setShowRightButton(false);
          if (i === 0) setShowLeftButton(false);
          break;
        } else {
          maxForThisPage += numOfShown;
        }
      }
      setShouldMoveToPage(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldMoveToPage, totalCardPages]);

  /* BUTTON HANDLERS */
  const onRightArrowBtnClick = () => {
    const oldPage = cardPage;
    setCardPage((p) => p + 1);
    if (oldPage + 2 >= totalCardPages) setShowRightButton(false);
    if (oldPage + 1 > 0) setShowLeftButton(true);
  };

  const onLeftArrowBtnClick = () => {
    const oldPage = cardPage;
    setCardPage((p) => p - 1);
    if (oldPage - 1 <= 0) setShowLeftButton(false);
    if (oldPage - 1 + 1 < totalCardPages) setShowRightButton(true);
  };

  return (
    <Box>
      <SelectSortBy />
      <Box className={classes.detailsIntegrationsBox}>
        {loading || metricsLoading ? (
          <CircularProgress />
        ) : (
          <>
            {isError && (
              <Typography className={classes.detailsIntegrationErrorText}>
                {`${errorJson?.message}`}
              </Typography>
            )}
            <Grid container ref={integrationCardsRef}>
              {sortedIntegrations?.map((item, index) => {
                if (index < getCardOffset() || index >= (numOfShown + getCardOffset())) {
                  return null;
                }
                return (
                  <IntegrationCardDetail
                    selected={index === selectedIndex}
                    integration={item}
                    cardCallback={
                      (integration, failed) => cardCallback(integration, index, failed)
                    }
                    last={index === (numOfShown + getCardOffset()) - 1}
                    key={item.id}
                  />
                );
              })}
            </Grid>
          </>
        )}
        {showRightButton && (
          <IntegrationArrowRightIcon
            onClick={onRightArrowBtnClick}
            className={classes.integrationCardRightArrow}
          />
        )}
        {showLeftButton && (
          <IntegrationArrowLeftIcon
            onClick={onLeftArrowBtnClick}
            className={classes.integrationCardLeftArrow}
          />
        )}
      </Box>
      <Box className={`${classes.detailsMainBox} ${integrationIsFailed ? classes.detailsMainFailedBox : ''}`}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <PageContext.Provider value={{
          selectedId,
          selected: getSelected(),
          setIntegrationIsFailed,
          integrationIsFailed,
        }}
        >
          {children}
        </PageContext.Provider>
      </Box>
    </Box>
  );
};

CardBodyPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardBodyPage;
