import {
  grey, green, yellow, red, blueGrey,
} from '@mui/material/colors';
import { createTheme } from '@mui/material';
import sidebar from './Themes/Sidebar';
import header from './Themes/Header';
import detailsPage from './Themes/DetailsPage';
import runsDetail from './Themes/RunsDetail';
import loginPage from './Themes/Login';
import overviewPage from './Themes/Overview';
import statistics from './Themes/Statistics';

// Common Theme settings

const commonTheme = {
  button: {
    marginRight: 10,
    marginLeft: 10,
  },
  buttonGrid: {
    marginTop: 10,
  },
  paper: {
    position: 'absolute',
    width: 500,
    border: '2px solid #000',
  },

  integrationCard: {
    textAlign: 'left',
    color: 'grey',
  },

  dataVisualization: {
    component: {
      labelTop: 'translateY(-10px)',
      labelBottom: 'translateY(10px)',
    },
  },

  tables: {
    activeRowColor: yellow[100],
    subRowColor: yellow[50],
    subRowHeaderColor: blueGrey[400],
  },

  statusIconAndText: { marginLeft: 20 },

  logStatus: { marginRight: 20 },

  statusColors: {
    ok: green[900],
    info: blueGrey[400],
    neutral: yellow[900],
    warning: red[900],
  },

  header: {
    marginTop: 80,
    marginBottom: 50,
  },

  drawer: {
    width: 240,
    iconMinWidth: '10px',
    margin: '25px',
  },

  navError: {
    fontThickness: 'bold',
    paddingTop: 20,
  },

  connectionTextFields: {
    width: 380,
    marginTop: 5,
    marginBottom: 5,
  },

  nodes: {
    background: 'white',
    color: 'black',
    padding: 10,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 3,
    miniMap: {
      defaultColor: '#eee',
      caseDefaultColor: '#0f0',
    },
    data: {
      sourceColor: 'steelblue',
      targetColor: 'darkorange',
      minWidth: 80,
      inputHandle: 'steelblue',
      outputHandle: 'darkorange',
    },
    connector: {
      minWidth: 100,
      dataColor: 'green',
      inputColor: 'blue',
      outputColor: 'red',
    },
    bus: {
      busColor: '#555',
      minHeight: 100,
      inputHandle: 'blue',
      outputHandle: 'red',
    },
    edge: {
      defaultEdgeColor: '#777',
    },
    inputNode: {
      color: 'black',
      background: 'white',
      padding: 10,
      border: '1px solid blue',
    },
  },
  headerPoweredByText: {
    paddingRight: 7,
  },
  headerVersionText: {
    textAlign: 'center',
    alignContent: 'center',
  },
  contentBody: {
    marginRight: '83px',
    marginLeft: '83px',
  },
  pageHeader: {
    height: '43px',
  },
  pageHeaderIconBox: {
    height: '36px',
    width: '36px',
  },
  pageHeaderText: {
    height: '43px',
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    lineHeight: '43px',
    letterSpacing: '0.3px',
    color: '#333333',
    marginLeft: '28px',
  },
};

// Light Theme

const lightThemeSettings = {
  tableHeader: {
    backgroundColor: grey[400],
  },
  title: {
    backgroundColor: grey[300],
  },
  ...commonTheme,
  // Add other js-css (jss) stuff here: (move up if more settings are used)
  sidebar,
  header,
  details: detailsPage,
  runsDetail,
  loginPage,
  overviewPage,
  statistics,
};

const theme = createTheme(lightThemeSettings);

// Dark Theme

// const darkThemeSettings = {
//   palette: {
//     mode: 'dark',
//   },
//   tableHeader: {
//     backgroundColor: grey[900],
//   },
//   title: {
//     backgroundColor: grey[900],
//   },
//   ...commonTheme,
// },

// const darkTheme = createTheme(darkThemeSettings),

export default theme;
