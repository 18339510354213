import PropTypes from 'prop-types';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyle = makeStyles()((theme) => ({
  ...theme.overviewPage,
  ...theme.details,
}));

const DEFAULT_IMAGE_SRC = `${process.env.PUBLIC_URL}/defaultIcon.svg`;

const IntegrationPanel = (props) => {
  const { classes } = useStyle();

  const {
    old, integration,
  } = props;
  const {
    id, name, totalOk, totalFailed, displayName,
  } = integration;
  const navigate = useNavigate();

  const isFailed = totalFailed > 0;

  return (
    <Link
      to={old ? `/integration/${id}` : `/details/${id}`}
      className={classes.ovIntegrationCardLink}
    >
      <Box
        onClick={() => navigate(`/details/${id}`)}
        // eslint-disable-next-line max-len
        className={`${classes.ovIntegrationCardBox} ${isFailed ? classes.ovIntegrationCardBoxFailed : classes.ovIntegrationCardBoxOk}`}
      >
        <Box className={classes.ovIntegrationCardIconBox}>
          <img
            src={integration?.iconUrl ?? DEFAULT_IMAGE_SRC}
            alt=""
            className={classes.integrationCardIcon}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null;
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = DEFAULT_IMAGE_SRC;
            }}
          />
        </Box>
        <Box className={`${classes.ovIntegrationCardHeaderTextBox}`}>
          <Typography
            className={classes.integrationCardText}
          >
            {displayName?.toUpperCase() ?? name?.toUpperCase()}
          </Typography>
        </Box>
        <Box
          className={classes.ovIntegrationCardTextBox}
        >
          <Typography className={classes.ovIntegrationCardText}>
            {isFailed ? `${totalFailed} failed` : 'Successful'}
          </Typography>
          <Typography className={classes.ovIntegrationCardSubText}>
            {`${totalOk ?? 0} of ${(totalOk ?? 0) + (totalFailed ?? 0)} successful`}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

IntegrationPanel.propTypes = {
  old: PropTypes.bool,
  integration: PropTypes.shape({
    id: PropTypes.string,
    lastUpdatedDateTime: PropTypes.string,
    name: PropTypes.string,
    totalOk: PropTypes.number,
    totalFailed: PropTypes.number,
    iconUrl: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
};

IntegrationPanel.defaultProps = {
  old: false,
};

export default IntegrationPanel;
