import { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import { backendTokenRequest } from '../authConfig';

const useAxios = (axiosParams, refresh) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [requestData, setRequestData] = useState();
  const [errorJson, setErrorJson] = useState();
  const [errorRequest, setErrorRequest] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorResponse, setErrorResponse] = useState();

  const { instance, accounts } = useMsal();

  const xmlHttpRequest = async (params) => {
    // TODO: Set error-handling as a service util?
    await
    axios(params)
      .then((data) => {
        setRequestData(data);
      }).catch((error) => {
        setIsError(true);
        setErrorJson(error.toJSON());
        if (error.response) {
          setErrorResponse(error.response);
          // The request was made and the server responded with a status code (i.e 404)
          // that falls out of the range of 2xx
        } else if (error.request) {
          // The request was made but no response was received
          // "Id does not exist" or "network down"
          setErrorRequest(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage(error.message);
        }
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(
    () => {
      const request = {
        ...backendTokenRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request)
        .then(
          (response) => {
          // eslint-disable-next-line no-param-reassign
            axiosParams.headers.Authorization = `Bearer ${response.accessToken}`;
            xmlHttpRequest(axiosParams);
          },
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh],
  );

  return {
    requestData, loading, isError, errorResponse, errorJson, errorRequest, errorMessage,
  };
};

useAxios.propTypes = {
  axiosParams: PropTypes.shape({
    url: PropTypes.string,
    method: PropTypes.string,
    headers: PropTypes.shape({
      accept: PropTypes.string,
    }),
  }).isRequired,
  refresh: PropTypes.bool.isRequired,
};

export default useAxios;
