import { Box } from '@mui/material';
import React from 'react';

const ResolveCheckedIcon = () => (
  <Box style={{ width: 29, height: 28 }}>
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5001 25.6667C7.82664 25.6667 2.41675 20.4434 2.41675 14C2.41675 7.55672 7.82664 2.33337 14.5001 2.33337C21.1735 2.33337 26.5834 7.55672 26.5834 14C26.5761 20.4404 21.1705 25.6596 14.5001 25.6667ZM14.4807 23.3334H14.5001C19.8369 23.3282 24.1597 19.1482 24.1571 13.9954C24.1544 8.84254 19.8273 4.66671 14.4904 4.66671C9.15355 4.66671 4.82642 8.84254 4.82375 13.9954C4.82108 19.1482 9.14389 23.3282 14.4807 23.3334ZM12.0834 19.8334L7.25008 15.1667L8.95383 13.5217L12.0834 16.5317L20.0463 8.84337L21.7501 10.5L12.0834 19.8334Z" fill="#003366" fillOpacity="0.6" />
    </svg>
  </Box>
);

export default ResolveCheckedIcon;
