import React from 'react';
import {
  IconButton, TableCell, TableRow, Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Mail } from '@mui/icons-material';
import { generateTableCells } from '../../../Utils/TableUtils';
import { subscriptionDataDefinition, targetSubscriptionDataDefinition } from '../../../Constants/ServiceBusConstants';

const SubscriptionTableRow = (props) => {
  const {
    subscription, showTopicName, btnSubDeadLetterOnClick,
  } = props;
  const {
    subscriptionName, topicName, deadLetterMessageCount,
  } = subscription;
  const maxMsg = deadLetterMessageCount < 10 ? deadLetterMessageCount : 10;

  return (
    <TableRow hover>
      {showTopicName ? generateTableCells(targetSubscriptionDataDefinition, subscription)
        : generateTableCells(subscriptionDataDefinition, subscription)}
      <TableCell key="btn">
        {deadLetterMessageCount > 0 && (
          <Tooltip title="Show dead letters">
            <IconButton size="small" color="primary" onClick={() => btnSubDeadLetterOnClick(topicName, subscriptionName, maxMsg)}>
              <Mail />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

SubscriptionTableRow.propTypes = {
  subscription: PropTypes.shape({
    subscriptionName: PropTypes.string,
    topicName: PropTypes.string,
    activeMessageCount: PropTypes.number,
    deadLetterMessageCount: PropTypes.number,
    accessedAt: PropTypes.string,
  }).isRequired,
  showTopicName: PropTypes.bool,
  btnSubDeadLetterOnClick: PropTypes.func.isRequired,
};

SubscriptionTableRow.defaultProps = {
  showTopicName: false,
};

export default SubscriptionTableRow;
