import React from 'react';

import {
  List, ListItem, ListItemText, Typography,
} from '@mui/material';

const StatusToolTip = (status) => {
  const {
    hasError, wasDry, wasAborted, itemCount,
  } = status;
  return (
    <div>
      <Typography variant="h6">Status</Typography>
      <List>
        <ListItem>
          <ListItemText>
            <b>Has Error: </b>
            {hasError ? 'Yes' : 'No'}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Was Aborted: </b>
            {wasAborted ? 'Yes' : 'No'}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Was Dry: </b>
            {wasDry ? 'Yes' : 'No'}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Amount of Tasks: </b>
            {itemCount}
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

export default StatusToolTip;
