import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

// Component using this needs to have:
// const [open, setOpen] = useState(false);
// handleOpen method which sets open = true (for opening)
// handleClose method which sets open = false (for closing)
// See IntegrationsPage or ServicesPage for example

/**
 * Modified version of InfoModal that allows components as children
 */
const CustomModal = ({
  open, handleClose, title, text, children, maxWidth,
  dialogProps, customActions, alert, alertFunction,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth={maxWidth}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...dialogProps}
  >
    <DialogTitle color={alert ? 'red' : 'inherit'} id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      {text && (
        <DialogContentText color={alert ? 'red' : 'inherit'} whiteSpace="pre-wrap" id="alert-dialog-description">
          {text}
        </DialogContentText>
      )}
      {children}
    </DialogContent>
    <DialogActions>
      {alert ? (
        <>
          <Button
            onClick={() => {
              handleClose();
              alertFunction();
            }}
            color="error"
          >
            Yes
          </Button>
          <Button onClick={handleClose} autoFocus>No</Button>
        </>
      ) : (
        <>
          {customActions}
          <IconButton onClick={handleClose} color="inherit">
            <Close />
          </IconButton>
        </>
      )}

    </DialogActions>
  </Dialog>
);

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.string,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  dialogProps: PropTypes.shape(),
  customActions: PropTypes.node,
  alert: PropTypes.bool,
  alertFunction: PropTypes.func,
};

CustomModal.defaultProps = {
  title: '',
  text: null,
  // eslint-disable-next-line react/jsx-no-useless-fragment
  children: (<></>),
  maxWidth: 'xl',
  dialogProps: {},
  // eslint-disable-next-line react/jsx-no-useless-fragment
  customActions: (<></>),
  alert: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  alertFunction: () => {},
};

export default CustomModal;
