import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import SignInButton from '../Component/Authentication/SignInButton';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { version } from '../autobuild_version';

const useStyles = makeStyles()((theme) => ({
  ...theme.loginPage,
}));

const HomePage = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.loginPageBox}>
      <Box
        className={classes.loginCenterBox}
      >
        <Typography className={classes.loginHeaderText}>
          Miles Connect
        </Typography>
        <SignInButton />
        {version && (
          <Typography className={classes.loginVersionNumberText}>
            {`Version ${version}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default HomePage;
