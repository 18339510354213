import { makeStyles } from 'tss-react/mui';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Box, Button, TextField, LinearProgress, Grid, Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import TimePicker from '@mui/lab/TimePicker';
import CustomModal from '../Modal/CustomModal';
import ApiRuleIntegration from '../../Api/ApiRuleIntegration';
import IntegrationsContext from '../../Contexts/IntegrationsContext';
import MultipleSelect from '../Select/MultipleSelect';
import AlertContext from '../../Contexts/AlertContext';

const useStyles = makeStyles()(() => ({
  boldText: {
    fontWeight: 'bold',
  },
  expressionText: {
    backgroundColor: grey[200],
    borderRadius: 5,
  },
  errorText: {
    color: 'red',
    marginTop: 10,
  },
  loading: {
    marginTop: 5,
  },
  warningText: {
    color: 'red',
    marginRight: 10,
  },
  editInputFields: {
    marginTop: 5,
    marginBottom: 10,
  },
  multipleSelect: {
    marginTop: 5,
    marginBottom: 20,
  },
  multiText: {
    marginRight: 10,
  },
}));

const RuleDialog = (props) => {
  const {
    open, handleClose, rule, create, view, setView, setRefreshWorkflows, workflowName,
  } = props;
  const { classes } = useStyles();
  const isEdit = !view;
  const [ruleName, setRuleName] = useState(rule?.ruleName);
  const [successEvent, setSuccessEvent] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [ruleTimeout, setRuleTimeout] = useState(new Date());

  const [selectedItems, setSelectedItems] = useState([]);
  const [actualExpression, setActualExpression] = useState(''); // Use a method to "extract" the actual expression
  const [integrationsPartExpression, setIntegrationsPartExpression] = useState(''); // Use a method to "extract" ^
  const { requestData: integrations } = useContext(IntegrationsContext);
  const { ruleApiKey } = useContext(AlertContext);

  /**
   * Extracts only the names from the list of integrations retrieved from the backend
   * @returns {Array<string>} the array of integration names
   */
  const extractIntegrationNames = () => {
    const data = integrations?.data?.map((integration) => integration.name);
    if (!data) return [];
    return data;
  };

  /**
   * Creates the "integration name" part of the expression
   * Adds "()" around and "&&"" at the end, plus combines each with "||"
   * and adds "input1.Name == {name}"
   * @param {Array<string>} selected the list of seleced integration names
   */
  const createNamesPartExpression = (selected) => {
    setSelectedItems(selected);
    if (Array.isArray(selected) && selected.length > 0) {
      const result = selected.reduce((prev, item, index) => `${prev}${index !== 0 ? ' || ' : ''}input1.Name == "${item}"`, '(');
      setIntegrationsPartExpression(`${result }) && `);
    } else {
      setIntegrationsPartExpression('');
    }
  };

  /**
   * Extracts names from expression and populates the multiselect
   */
  const extractNamesFromExpression = () => {
    // Gets everything between the first (*) &&
    const result = rule?.expression.match(/(?<=\(\s*).*?(?=\s*\)\s&&)/gs);

    const checkTxt = 'input1.Name ==';

    // Will still be an "error" if the user types in something like "(input1.Name ==*) &&"
    // Tooltip saying do NOT write this has been added^
    if (result && result.length > 0 && result[0].includes(checkTxt)) {
      const withoutNamesPartStr = rule?.expression.replace(result[0], '');
      const finalStr = withoutNamesPartStr.substring(6);

      const integrationNames = [];
      // regex match between "*", could also have used split?
      const names = result[0].match(/(?<="\s*).*?(?=\s*")/gs);
      // need to extract every other match in names
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < names.length; i++) {
        if (i % 2 === 0) {
          integrationNames.push(names[i]);
        }
      }

      setActualExpression(finalStr);
      setSelectedItems(integrationNames);
      createNamesPartExpression(integrationNames);
    } else {
      setActualExpression(rule?.expression);
      createNamesPartExpression([]);
    }
  };

  /**
   * Creates a title based on create/isEdit
   * @returns {string|Element}
   */
  const createTitle = () => {
    if (create) {
      return 'Adding new rule';
    }
    if (rule) {
      if (isEdit) {
        return (<Typography fontSize={20} color="green">{`Editing rule - ${rule.ruleName}`}</Typography>);
      }
      return rule.ruleName;
    }
    return 'Error';
  };

  /**
   * Edit button click handler
   * Sets it to edit view and sets some fields/states
   */
  const onEditBtnClick = () => {
    setView(false);
    setSuccessEvent(rule?.successEvent);
    setErrorMessage(rule?.errorMessage);
    setRuleName(rule?.ruleName);

    const date = new Date();
    const split = rule?.timeout?.split(':');
    if (split) {
      date.setHours(split[0], split[1], 0);
    } else {
      // Rule was null or something
      date.setHours(0, 0, 0);
    }
    setRuleTimeout(date);
    setIntegrationsPartExpression('');
    extractNamesFromExpression();
  };

  /**
   * View button click handler
   * Sets it back to view mode
   */
  const onViewBtnClick = () => {
    setView(true);
  };

  const getRuleTimeoutString = () => {
    // TODO TimePicker does not allow > 23 hours 59 minutes (wraps around)
    // Make custom timepicker in the future / allow days as well. Up to 24 hours is fine for now?
    let hours = 0;
    let minutes = 0;
    if (ruleTimeout.$d) {
      hours = ruleTimeout.$H;
      minutes = ruleTimeout.$m;
    } else {
      // timeout not updated with the TimePicker
      hours = ruleTimeout.getHours();
      minutes = ruleTimeout.getMinutes();
    }
    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;
    const timeout = `${hours}:${minutes}:00`;
    return timeout;
  };

  /**
   * Save button click handler
   * Updates rule on backend, and then refreshes rules and workflows
   */
  const onSaveBtnClick = () => {
    // Update rule + refresh
    setLoading(true);

    const timeout = getRuleTimeoutString();

    if (ruleName !== rule?.ruleName) {
      const ruleWithUpdatedName = {
        ruleName,
        successEvent,
        errorMessage,
        expression: `${integrationsPartExpression}${actualExpression}`,
        timeout,
      };
      let newRuleNameExists = false;
      ApiRuleIntegration.getRule(ruleName, ruleApiKey)
        .then((response) => {
          if (response?.data) newRuleNameExists = true;
        })
        .catch((err) => {
          // Necessary to catch the error?
          if (err?.response?.status !== 400) {
            setIsError(true);
            setApiErrorMsg(`${err?.response?.data}`);
            setLoading(true);
            newRuleNameExists(true);
          }
        });

      if (!newRuleNameExists) {
        // First delete rule
        ApiRuleIntegration.deleteRule(rule?.ruleName, ruleApiKey)
          .then(() => {
            setLoading(true);

            // Add it back with a new name
            ApiRuleIntegration.addRule(workflowName, ruleWithUpdatedName, ruleApiKey)
              .then(() => {
                // Refresh
                setRefreshWorkflows((ref) => !ref);
              })
              .catch((err) => {
                setIsError(true);
                setApiErrorMsg(`${err?.response?.data}`);
                setLoading(true);

                // Need to add rule back again
                // if there was an error adding it so it does not stay deleted
                ApiRuleIntegration.addRule(workflowName, rule, ruleApiKey)
                  .catch((err2) => {
                    setIsError(true);
                    setApiErrorMsg(`${err2?.response?.data}`);
                  })
                  .finally(() => setLoading(false));
              })
              .finally(() => setLoading(false));
          })
          .catch((err) => {
            setIsError(true);
            setApiErrorMsg(`${err?.response?.data}`);
          })
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
        setIsError(true);
        setApiErrorMsg(`The new rule name, ${ruleName}, already exists!`);
      }
    } else {
      // Not a new rulename, just update it!
      const updatedRule = {
        ruleName: rule?.ruleName,
        successEvent,
        errorMessage,
        expression: `${integrationsPartExpression}${actualExpression}`,
        timeout,
      };
      ApiRuleIntegration.updateRule(rule?.ruleName, updatedRule, ruleApiKey)
        .then(() => {
          setRefreshWorkflows((ref) => !ref);
        })
        .catch((err) => {
          setIsError(true);
          setApiErrorMsg(`${err?.response?.data}`);
        })
        .finally(() => setLoading(false));
    }
  };

  /**
   * Add button click handler
   * Creates rule on backend, and then refreshes rules and workflows
   */
  const onAddBtnClick = () => {
    // Add rule + refresh
    setLoading(true);
    const newRule = {
      ruleName,
      successEvent,
      errorMessage,
      expression: `${integrationsPartExpression}${actualExpression}`,
      timeout: getRuleTimeoutString(),
    };
    ApiRuleIntegration.addRule(workflowName, newRule, ruleApiKey)
      .then(() => {
        setRefreshWorkflows((ref) => !ref);
      })
      .catch((err) => {
        setIsError(true);
        setApiErrorMsg(`${err?.response?.data}`);
      })
      .finally(() => setLoading(false));
  };

  /**
   * First delete button click handler
   * Adds a warning and a second delete button
   */
  const onFirstDeleteBtnClick = () => {
    setDeleteWarning(true);
  };

  /**
   * Second delete button click handler
   * Deletes rule and refreshes rules and workflows
   */
  const onSecondDeleteBtnClick = () => {
    // Delete rule + refresh
    setLoading(true);
    ApiRuleIntegration.deleteRule(rule?.ruleName, ruleApiKey)
      .then(() => {
        setRefreshWorkflows((ref) => !ref);
      })
      .catch((err) => {
        setIsError(true);
        setApiErrorMsg(`${err?.response?.data}`);
      })
      .finally(() => setLoading(false));
  };

  /**
   * No button click handler
   * Hides the final delete button and the warning
   */
  const onNoBtnClick = () => {
    setDeleteWarning(false);
  };

  /**
   * useEffect that will reset errors/warnings on open/close
   */
  useEffect(() => {
    setDeleteWarning(false);
    setIsError(false);
  }, [open]);

  /**
   * useEffect that will reset states for textfields on create
   */
  useEffect(() => {
    setRuleName('');
    setSuccessEvent('');
    setErrorMessage('');
    setSelectedItems([]);
    setActualExpression('');
    const date = new Date();
    date.setHours(0, 0, 0);
    setRuleTimeout(date);
    setIntegrationsPartExpression('');
  }, [create]);

  /**
   * Creates custom actions on the dialog (the bottom)
   * @returns {Element}
   */
  const customActions = () => {
    if (deleteWarning) {
      return (
        <>
          <Typography className={classes.warningText}>
            Are you sure you want to delete the rule?
          </Typography>
          <Button variant="outlined" onClick={onNoBtnClick}>No</Button>
          <Button variant="outlined" color="error" onClick={onSecondDeleteBtnClick}>Delete</Button>
        </>
      );
    }
    if (view) {
      return (
        <>
          <Button variant="outlined" onClick={onEditBtnClick}>Edit</Button>
          <Button variant="outlined" color="error" onClick={onFirstDeleteBtnClick}>Delete</Button>
        </>
      );
    }
    if (create) {
      return (<Button variant="outlined" onClick={onAddBtnClick}>Add</Button>);
    }
    if (isEdit) {
      return (
        <>
          <Button variant="outlined" onClick={onViewBtnClick}>View</Button>
          <Button variant="outlined" color="secondary" onClick={onSaveBtnClick}>Save</Button>
        </>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (<></>);
  };

  const viewRuleTimeoutString = () => {
    const split = rule?.timeout.split(':');
    if (split) {
      return `${split[0]} hours, ${split[1]} minutes`;
    }
    return rule?.timeout;
  };

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title={createTitle()}
      // text={text}
      customActions={customActions()}
      dialogProps={{ fullWidth: true }}
    >
      <Box>
        {isEdit && (
          <>
            <Typography className={classes.boldText}>Rule name:</Typography>
            <Tooltip
              title="The name of the rule (needs to be unique)"
            >
              <TextField
                className={classes.editInputFields}
                autoFocus
                fullWidth
                multiline
                value={ruleName}
                onChange={(e) => setRuleName(e.target.value)}
              />
            </Tooltip>
          </>
        )}
        <Typography className={classes.boldText}>Success message:</Typography>
        {view && (<Typography>{rule?.successEvent}</Typography>)}
        {isEdit && (
          <Tooltip
            title="The success message of the rule (included in the notifications on rule trigger)"
          >
            <TextField
              className={classes.editInputFields}
              fullWidth
              multiline
              value={successEvent}
              onChange={(e) => setSuccessEvent(e.target.value)}
            />
          </Tooltip>
        )}
        <Typography className={classes.boldText}>Error message:</Typography>
        {view && (<Typography>{rule?.errorMessage}</Typography>)}
        {isEdit && (
          <Tooltip
            title="The error message of the rule (message when rule did not trigger, not used except internally)"
          >
            <TextField
              className={classes.editInputFields}
              fullWidth
              multiline
              value={errorMessage}
              onChange={(e) => setErrorMessage(e.target.value)}
            />
          </Tooltip>
        )}
        <Typography className={classes.boldText}>Timeout:</Typography>
        {view && (<Typography>{viewRuleTimeoutString()}</Typography>)}
        {isEdit && (
          <Tooltip
            title="The timeout of the rule (how long to wait until sending a new notification)"
            // Timepicker does not forward its props properly? (so tooltip on the box for now)
          >
            <Box className={classes.editInputFields}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  openTo="hours"
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  ampm={false}
                  value={ruleTimeout}
                  clearable
                  onChange={(e) => setRuleTimeout(e)}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Tooltip>
        )}
        <Typography className={classes.boldText}>Expression:</Typography>
        {view && (
          <Typography className={classes.expressionText}>
            <code>
              {rule?.expression}
            </code>
          </Typography>
        )}
        {isEdit && (
          <Tooltip
            title="The expression for the rule in C# code (can use normal methods and classes, see Microsoft.RulesEngine documentation). Avoid using '(input1.Name ==<*some-code*>) &&' in the expression if rule should trigger on all integrations."
          >
            <TextField
              className={classes.editInputFields}
              fullWidth
              multiline
              value={actualExpression}
              onChange={(e) => {
                setActualExpression(e.target.value);
              }}
            />
          </Tooltip>
        )}
        {isEdit && (
          <Grid container alignContent="center" alignItems="center" className={classes.multipleSelect}>
            <Typography className={`${classes.multiText}`}>Rule only triggers on:</Typography>
            <MultipleSelect
              data={extractIntegrationNames()}
              selectedItems={selectedItems}
              setSelectedItems={createNamesPartExpression}
              label="Name"
            />
          </Grid>
        )}
        {isEdit && (
          <>
            <Typography className={classes.boldText}>
              Final expression:
            </Typography>
            <Tooltip
              title="The final expression with the user typed part and the integrations it should only trigger on"
            >
              <TextField
                className={classes.editInputFields}
                fullWidth
                multiline
                disabled
                value={`${integrationsPartExpression}${actualExpression}`}
              />
            </Tooltip>
          </>
        )}
        {isError && (
          <Typography className={`${classes.errorText} ${classes.multiText}`}>
            {apiErrorMsg}
          </Typography>
        )}
        {loading && (<LinearProgress className={classes.loading} />)}
      </Box>
    </CustomModal>
  );
};

RuleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setRefreshWorkflows: PropTypes.func.isRequired,
  workflowName: PropTypes.string.isRequired,
  rule: PropTypes.shape({
    ruleName: PropTypes.string,
    errorMessage: PropTypes.string,
    expression: PropTypes.string,
    successEvent: PropTypes.string,
    timeout: PropTypes.string,
  }),
  view: PropTypes.bool,
  setView: PropTypes.func,
  create: PropTypes.bool,
};

RuleDialog.defaultProps = {
  rule: null,
  view: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setView: () => {},
  create: false,
};

export default RuleDialog;
