import React from 'react';

const DialogCloseIcon = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.59424 8.04163L23.1694 23.2232" stroke="#003366" strokeWidth="2.2" strokeMiterlimit="10" />
    <path d="M22.9725 8.2406L7.79102 23.0385" stroke="#003366" strokeWidth="2.2" strokeMiterlimit="10" />
  </svg>
);

export default DialogCloseIcon;
