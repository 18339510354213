/* eslint-disable react/jsx-filename-extension */
import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

export const generateTableCells = (itemDefinition, item) => itemDefinition.filter((i) => i.key)
  .map((itemDef) => (
    <TableCell key={itemDef.key} style={itemDef.style ?? {}}>
      {itemDef.format(item[itemDef.key])}
    </TableCell>
  ));

/**
 * DataDefinition needs to have: key (the keys for the data objects), format, style {} as default
 * Should maybe be a jsx component?
 * @param {*} dataDefinition
 * @param {*} data
 * @returns
 */
export const generateTableBody = (dataDefinition, data) => data?.map((item, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <TableRow hover key={index}>
    {dataDefinition.filter((i) => i.key).map((dataDef) => (
      <TableCell key={dataDef.key} style={dataDef.style ?? {}}>
        {dataDef.format(item[dataDef.key])}
      </TableCell>
    ))}
  </TableRow>
));

/**
 * DataDefinition needs to have: key (the keys for the data objects), header
 * headerStyle is optional
 * Should maybe be a jsx component?
 * @param {*} dataDefinition
 * @param {*} bold
 * @param {*} nowrap
 * @param {*} customStyle
 * @returns
 */
export const generateTableHeaders = (
  dataDefinition,
  bold = true,
  nowrap = false,
  customStyle = null,
) => (
  <TableHead>
    <TableRow>
      {dataDefinition.map((item) => {
        let newStyle = {};
        if (item.headerStyle) newStyle = { ...item.headerStyle };
        if (bold) newStyle = { ...newStyle, fontWeight: 'bold' };
        if (nowrap) newStyle = { ...newStyle, whiteSpace: 'nowrap' };
        if (customStyle) newStyle = customStyle;

        return (
          <TableCell key={item.key} style={newStyle}>
            {item.header}
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);
