/**
 *
 * @param {(String} integrationId
 * @param {*} integrations
 * @returns
 */
export const findIntegration = (integrationId, integrations) => integrations?.data.find(
  ({ id }) => id === integrationId,
);

/**
 * Find the integration with the supplied name
 * @param {String} integrationName
 * @param {Array} integrations
 * @returns the integration
 */
export const findIntegrationWithName = (integrationName, integrations) => integrations?.data.find(
  ({ name }) => name === integrationName,
);

/**
 *
 * @param {(String)} integrationId
 * @param {*} integrations
 * @param {Function} setIntegration
 */
export const findAndSetIntegration = (integrationId, integrations, setIntegration) => {
  if (integrations?.data) {
    const entity = integrations?.data.find(
      ({ id }) => id === integrationId,
    );
    setIntegration(entity);
  }
};

/**
 * Find the metrics corresponding to the integration name provided
 * @param {string} name Integration Name
 * @param {Array} metrics
 * @returns the metrics on the integration name
 */
export const findMetrics = (name, metrics) => metrics?.find(
  ({ integrationName }) => name === integrationName,
);

/**
   * Sort the integrations by name / failed
   * (probably needs to be its own component alongside the React parts)
   * @param {string} sort What to sort by
   * @param {Object} integrations The integrations to sort (NB! the object from the api)
   * @param {Array} metrics The metrics for the integrations
   * @param {Array} selectedName The name of the selected integration (for details page)
   * @returns A object with the sorted array and the new index for the selected in that array
   */
export const sortIntegrations = (sort, integrations, metrics, selectedName = null) => {
  const { data } = integrations;

  // Merge metrics and integrations, do this when integrations / metrics are changed instead?
  const map = new Map();
  data.forEach((item) => map.set(item.name, item));
  metrics.data.forEach(
    (item) => map.set(item.integrationName, {
      ...map.get(item.integrationName),
      ...item,
    }),
  );
  const merged = Array.from(map.values());

  let sorted = [];
  switch (sort) {
  case 'failed':
    sorted = merged?.sort(
      (a, b) => {
        const aF = a.totalFailed / a.total;
        const bF = b.totalFailed / b.total;
        if (!Number.isFinite(aF) && bF > 0) return 1;
        if (!Number.isFinite(bF) && aF > 0) return -1;
        return bF - aF || (a.displayName ?? a.name)?.localeCompare(b.displayName ?? b.name);
      },
    );
    break;

  default: // name
    sorted = merged?.sort(
      (a, b) => (a.displayName ?? a.name)?.localeCompare(b.displayName ?? b.name),
    );
    break;
  }

  let newIndex = -1;
  if (selectedName) {
    newIndex = sorted?.findIndex(({ name }) => name === selectedName) ?? -1;
  }

  return { sorted, newIndex };
};
