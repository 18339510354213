import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'react-flow-renderer';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ConnectorNodeContent from './Common/ConnectorNodeContent';

const useStyles = makeStyles()((theme) => ({
  inputConnector: {
    background: theme.nodes.background,
    color: theme.nodes.color,
    borderWidth: theme.nodes.borderWidth,
    borderStyle: theme.nodes.borderStyle,
    borderColor: theme.nodes.connector.inputColor,
    borderRadius: theme.nodes.borderRadius,
  },
  inputHandle: {
    background: theme.nodes.connector.inputColor,
  },
}));

const InputConnectorNode = memo(({ data, isConnectable }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inputConnector}>
      <ConnectorNodeContent data={data} />
      <Handle
        className={classes.inputHandle}
        type="source"
        position={Position.Right}
        id="s"
        isConnectable={isConnectable}
      />
    </Box>
  );
});

InputConnectorNode.propTypes = {
  isConnectable: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
    integrationId: PropTypes.string,
  }),
};

InputConnectorNode.defaultProps = {
  data: {
    label: 'Input',
    integrationId: '-1',
  },
};

export default InputConnectorNode;
