import React from 'react';
import PropTypes from 'prop-types';

const StatusFailedIcon = ({ color }) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4879 26.5833C7.81601 26.5766 2.41218 21.1638 2.41663 14.4919C2.42108 7.82004 7.83212 2.41441 14.504 2.41663C21.1759 2.41885 26.5833 7.82809 26.5833 14.5C26.5793 21.1765 21.1644 26.586 14.4879 26.5833ZM4.8333 14.7078C4.89047 20.026 9.23297 24.299 14.5514 24.2705C19.8698 24.2418 24.1661 19.9223 24.1661 14.6039C24.1661 9.2854 19.8698 4.96593 14.5514 4.9372C9.23297 4.90876 4.89047 9.18179 4.8333 14.5V14.7078ZM15.7083 20.5416H13.2916V18.125H15.7083V20.5416ZM15.7083 15.7083H13.2916V8.45829H15.7083V15.7083Z" fill={color} />
  </svg>
);

StatusFailedIcon.propTypes = {
  color: PropTypes.string,
};

StatusFailedIcon.defaultProps = {
  color: '#E45B43',
};

export default StatusFailedIcon;
