import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import theme from '../theme';

const useStyles = makeStyles()(() => ({
  button: {
    marginRight: theme.button.marginRight,
    marginLeft: theme.button.marginLeft,
    fontFamily: 'Mulish',
    color: 'black',
    background: '#dfe0dc',
    fontWeight: 'bold',
  },
  body: {
    marginTop: 30,
  },
  text: {
    fontFamily: 'Mulish',
    fontSize: '24px',
    lineHeight: '18px',
    letterSpacing: '0.3px',
  },
}));

const NotFoundPage = () => {
  const { classes } = useStyles();
  const currentPage = useLocation();
  const navigate = useNavigate();

  return (
    <Box margin={0}>
      <Grid container direction="column" spacing={5} justifyContent="center" alignItems="center">
        <Grid container direction="row" className={classes.body} justifyContent="center">
          <Typography className={classes.text}>The requested URL&nbsp;</Typography>
          <Typography className={classes.text} color="textSecondary">{currentPage.pathname}</Typography>
          <Typography className={classes.text}>&nbsp;was not found.</Typography>
        </Grid>
        <Grid container item direction="row" justifyContent="center">
          <Button component={Link} className={classes.button} variant="contained" color="primary" to="/">Home</Button>
          <Button className={classes.button} variant="contained" onClick={() => navigate(-1)}>Go back</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFoundPage;
