import React from 'react';
import {
  Drawer,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

// eslint-disable-next-line import/no-unresolved, import/extensions
import { Logout, MiscellaneousServices, Person } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';
import findRoute from '../../Utils/RouteUtils';
import SidebarListItem from '../Redesign/Sidebar/SidebarListItem';
import OverviewFlowIcon from '../Redesign/Icons/OverviewFlowIcon';
import DetailsIcon from '../Redesign/Icons/DetailsIcon';
import StatisticsIcon from '../Redesign/Icons/StatisticsIcon';
import SettingsIcon from '../Redesign/Icons/SettingsIcon';
import NotificationsIcon from '../Redesign/Icons/NotificationsIcon';
import { ROUTE_NAMES } from '../../Constants/Routes';
import useAcquireToken from '../../Hooks/useAcquireToken';
import { handleLogoutRedirect } from '../../Utils/AuthUtils';

const useStyles = makeStyles()((theme, { navWidth }) => ({
  ...theme.sidebar,
  drawer: {
    width: `${navWidth}px`,
    flexShrink: 0,
    transition: 'all 0.5s ease-in-out',
  },
  upperSidebarList: {
    position: 'absolute',
    top: '169px',
  },
  lowerSidebarList: {
    position: 'absolute',
    top: '537px',
    left: '0px',
  },
  lowestList: {
    position: 'absolute',
    bottom: 5,
    left: 0,
  },
  drawerPaper: {
    backgroundColor: theme.sidebar.sidebar.background,
    width: `${navWidth}px`,
    overflowX: 'hidden',
    transition: 'all 0.5s ease-in-out',
  },
  closeNavIcon: {
    marginLeft: '90%',
  },
  stickyUpper: {
    position: 'absolute',
    top: 5,
    left: 0,
  },
}));

// eslint-disable-next-line no-unused-vars
const NavigationMenu = ({ handleDrawer, navWidth, open }) => {
  const { classes } = useStyles({ navWidth });
  const { instance } = useMsal();
  const { account } = useAcquireToken();

  // Should the sidebar be collapsable ? (no design for this though - so wait?)
  return (
    <nav>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open
      >
        <>
          <Grid container className={classes.stickyUpper}>
            <Grid item className={classes.sidebarListItemBoxNoHover}>
              <Box
                className={classes.sidebarListItemIcon}
              >
                <Person htmlColor="white" />
              </Box>
              <Typography className={`${classes.sidebarListItemText} ${classes.leftAlignedText}`}>
                {account?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.upperSidebarList}>
            <SidebarListItem
              text="Overview"
              to={findRoute(ROUTE_NAMES.overviewFlow).path}
              title={open ? '' : 'Overview'}
            >
              <OverviewFlowIcon />
            </SidebarListItem>
            <SidebarListItem
              text="Details"
              to={findRoute('details').path}
              title={open ? '' : 'Details'}
            >
              <DetailsIcon />
            </SidebarListItem>
            <SidebarListItem
              text="Statistics"
              to={findRoute('statistics').path}
              title={open ? '' : 'Statistics'}
            >
              <StatisticsIcon />
            </SidebarListItem>
            <SidebarListItem
              text="Services"
              to={findRoute(ROUTE_NAMES.services).path}
              title={open ? '' : 'Services'}
            >
              <MiscellaneousServices sx={{ color: 'white' }} />
            </SidebarListItem>
          </Grid>
          <Box className={classes.line}>
            <Box className={classes.divider} />
          </Box>
          <Grid container className={classes.lowerSidebarList}>
            <SidebarListItem
              text="Settings"
              to={findRoute('settings').path}
              title={open ? '' : 'Settings'}
            >
              <SettingsIcon />
            </SidebarListItem>
            <SidebarListItem
              text="Notifications"
              to={findRoute('notifications').path}
              title={open ? '' : 'Notifications'}
            >
              <NotificationsIcon />
            </SidebarListItem>

          </Grid>
          <Grid
            container
            className={classes.lowestList}
          >
            <Grid
              item
              className={`${classes.sidebarListItemBox}`}
              onClick={() => handleLogoutRedirect(instance, account)}
              title={open ? '' : 'Logout'}
            >
              <Box
                className={classes.sidebarListItemIcon}
              >
                <Logout htmlColor="white" />
              </Box>
              <Typography className={`${classes.sidebarListItemText} ${classes.leftAlignedText}`}>
                Logout
              </Typography>
            </Grid>
            <Grid
              item
              className={`${classes.sidebarListItemBox}`}
              onClick={handleDrawer}
              title={open ? 'Close navigation' : 'Open navigation'}
            >
              <Box
                className={classes.sidebarListItemIconClose}
              >
                {open ? (
                  <ArrowBackIosNewIcon htmlColor="white" />
                ) : (
                  <ArrowBackIosNewIcon htmlColor="white" style={{ transform: 'scale(-1,1)' }} />
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      </Drawer>
    </nav>
  );
};

NavigationMenu.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
  navWidth: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NavigationMenu;
