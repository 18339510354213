import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Box, Dialog, DialogTitle, IconButton, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import defaultStyle from 'react-syntax-highlighter/dist/esm/styles/hljs/default-style';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { DataErrorCauseTooltip } from '../../DataErrorTableRow/DataErrorTableRow';
import DialogCloseIcon from '../Icons/DialogCloseIcon';

SyntaxHighlighter.registerLanguage('json', json);

const useStyles = makeStyles()((theme) => ({
  ...theme.runsDetail,
  ...theme.details,
}));

const DetailsRunDetailModal = (props) => {
  const { classes } = useStyles();
  const {
    dataObject, open, handleClose, errorInfo, header,
  } = props;

  const isErrorArray = Array.isArray(errorInfo);

  const handleData = (obj = dataObject) => {
    if (typeof obj === 'object') {
      return JSON.stringify(obj, null, 2);
    }
    return obj;
  };

  const getValue = (error = errorInfo) => {
    const { fieldName } = error;
    try {
      const obj = dataObject[fieldName];
      if (!obj) return `${obj}`; // null is still an object
      if (typeof obj === 'object') {
        const jsonStr = JSON.stringify(obj, null, 2);
        return (
          <SyntaxHighlighter
            className={classes.runsDetailBoxViewer}
            language="json"
            wrapLongLines
            style={defaultStyle}
          >
            {jsonStr}
          </SyntaxHighlighter>
        );
      }
      return `${obj}`;
    } catch (err) {
      return 'Error getting value';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.runsDetailModalBox,
      }}
    >
      <DialogTitle>
        <Typography className={`${classes.runsTableHeaderText} ${classes.runsDetailHeaderText}`}>
          {header}
        </Typography>
        <IconButton className={classes.runsDetailExitBtn} onClick={handleClose}>
          <DialogCloseIcon />
        </IconButton>
      </DialogTitle>
      <Box className={classes.runsDetailBox}>
        {isErrorArray ? (
          <>
            {errorInfo.map((item) => (
              <DataErrorCauseTooltip
                dataError={item}
                value={getValue(item)}
              />
            ))}
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {errorInfo && (
              <DataErrorCauseTooltip
                dataError={errorInfo}
                value={getValue()}
              />
            )}
          </>
        )}
        {errorInfo && (
          <Typography className={classes.runsTooltipTextHeader}>
            Data Object
          </Typography>
        )}
        <SyntaxHighlighter
          language="json"
          wrapLongLines
          className={classes.runsDetailBoxViewer}
          style={defaultStyle}
        >
          {handleData()}
        </SyntaxHighlighter>
      </Box>
    </Dialog>
  );
};

DetailsRunDetailModal.propTypes = {
  dataObject: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  errorInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({
    expectedValue: PropTypes.string,
    fieldName: PropTypes.string,
    integrationRunDataErrorCode: PropTypes.string,
    message: PropTypes.string,
  })]),
  header: PropTypes.string,
};

DetailsRunDetailModal.defaultProps = {
  errorInfo: null,
  header: '',
};

export default DetailsRunDetailModal;
