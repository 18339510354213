import React from 'react';
import PropTypes from 'prop-types';

// Taken from https://github.com/wbkd/react-flow/issues/915
// https://gist.github.com/ambroseus/2f1ea898f39e8460e49cd80c9b5e9f5c
// And https://github.com/wbkd/react-flow/blob/main/src/container/EdgeRenderer/MarkerDefinitions.tsx

const Marker = ({ id, className, children }) => (
  <marker
    className={className}
    id={id}
    markerWidth="12.5"
    markerHeight="12.5"
    // eslint-disable-next-line react/no-unknown-property
    viewBox="-10 -10 20 20"
    orient="auto"
    refX="0"
    refY="0"
  >
    {children}
  </marker>
);

Marker.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Marker.defaultProps = {
  className: 'react-flow__arrowhead',
};

const MarkerDefinition = ({ color, id, closed }) => (
  <svg>
    <defs>
      {closed ? (
        <Marker id={id}>
          <polyline
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            fill={color}
            points="-5,-4 0,0 -5,4 -5,-4"
          />
        </Marker>
      ) : (
        <Marker id={id}>
          <polyline
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            fill="none"
            points="-5,-4 0,0 -5,4"
          />
        </Marker>
      )}

    </defs>
  </svg>
);

MarkerDefinition.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  closed: PropTypes.bool,
};

MarkerDefinition.defaultProps = {
  color: 'black',
  closed: false,
};

export default MarkerDefinition;
