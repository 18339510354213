import {
  Box, Grid, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { nodeTypeNames } from '../../Constants/FlowConstants';

const useStyles = makeStyles()((theme) => ({
  node: {
    minWidth: 100,
    borderWidth: '2px',
    borderStyle: theme.nodes.borderStyle,
    borderRadius: theme.nodes.borderRadius + 2,
    padding: theme.nodes.padding / 2,
    margin: 15,
    '&:hover': {
      cursor: 'grab',
    },
  },
  input: {
    borderColor: theme.nodes.connector.inputColor,
  },
  dataConnector: {
    borderColor: theme.nodes.connector.dataColor,
  },
  output: {
    borderColor: theme.nodes.connector.outputColor,
  },
  sourceData: {
    borderColor: theme.nodes.data.sourceColor,
  },
  targetData: {
    borderColor: theme.nodes.data.targetColor,
  },
  bus: {
    borderColor: theme.nodes.bus.busColor,
  },
  commonText: {
    fontFamily: 'Open Sans',
  },
}));

const ElementPicker = () => {
  const { classes } = useStyles();

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <Box>
      <Typography variant="h6" className={classes.commonText}>Add nodes by dragging:</Typography>
      <Grid container alignContent="center" justifyContent="center">
        <Box
          className={`${classes.input} ${classes.node}`}
          onDragStart={(event) => onDragStart(event, nodeTypeNames.input)}
          draggable
        >
          <Typography variant="body1" className={classes.commonText}>
            Input
          </Typography>
        </Box>
        <Box
          className={`${classes.output} ${classes.node}`}
          onDragStart={(event) => onDragStart(event, nodeTypeNames.output)}
          draggable
        >
          <Typography variant="body1" className={classes.commonText}>
            Output
          </Typography>
        </Box>
        <Box
          className={`${classes.bus} ${classes.node}`}
          onDragStart={(event) => onDragStart(event, nodeTypeNames.bus)}
          draggable
        >
          <Typography variant="body1" className={classes.commonText}>
            Bus
          </Typography>
        </Box>
        <Box
          className={`${classes.dataConnector} ${classes.node}`}
          onDragStart={(event) => onDragStart(event, nodeTypeNames.dataConnector)}
          draggable
        >
          <Typography variant="body1" className={classes.commonText}>
            Data Connector
          </Typography>
        </Box>
        <Box
          className={`${classes.sourceData} ${classes.node}`}
          onDragStart={(event) => onDragStart(event, nodeTypeNames.sourceData)}
          draggable
        >
          <Typography variant="body1" className={classes.commonText}>
            Source Data
          </Typography>
        </Box>
        <Box
          className={`${classes.targetData} ${classes.node}`}
          onDragStart={(event) => onDragStart(event, nodeTypeNames.targetData)}
          draggable
        >
          <Typography variant="body1" className={classes.commonText}>
            Target Data
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default ElementPicker;
