// const backend = window.ENV.BACKEND_URL;
const uiController = `${window.ENV.BACKEND_URL}/api/milesconnectui`;
const ruleIntegrationApi = `${window.ENV.RULE_INTEGRATION_URL}/api/rulesengine`;

const END_POINTS = {
  INTEGRATIONS: `${uiController}/integrations`,
  SERVICEBUS_PROPS_SOURCE_EDGE: `${uiController}/ServiceBusProperties/Edge/Source`,
  SERVICEBUS_PROPS_SOURCE_OVERVIEW: `${uiController}/ServiceBusProperties/Overview/Source`,
  SERVICEBUS_PROPS_TARGET_EDGE: `${uiController}/ServiceBusProperties/Edge/Target`,
  SERVICEBUS_PROPS_TARGET_OVERVIEW: `${uiController}/ServiceBusProperties/Overview/Target`,
  SERVICEBUS_QUEUE_DEADLETTERS: `${uiController}/ServiceBus/Queue/DeadLetters`,
  SERVICEBUS_SUBSCRIPTION_DEADLETTERS: `${uiController}/ServiceBus/Subscription/DeadLetters`,
  SERVICEBUS_DEADLETTER: `${uiController}/ServiceBus/DeadLetter`,
  GET_WORKFLOWS: `${ruleIntegrationApi}/workflows`,
  GET_RULES: `${ruleIntegrationApi}/rules`,
  WORKFLOW: `${ruleIntegrationApi}/workflow`,
  RULE: `${ruleIntegrationApi}/rule`,
  INTEGRATION_METRICS: `${uiController}/integration/metrics`,
  RUN: `${uiController}/run`,
  UI_CONTROLLER: `${uiController}`,
  CONNECTOR_API: `${uiController}/connector`,
};

/**
 * @param {string} workflowName
 * @returns {string}
 */
// eslint-disable-next-line max-len
export const GET_RULES_ON_WORKFLOW = (workflowName) => `${END_POINTS.WORKFLOW}/${workflowName}/rules`;
/**
 * @param {string} workflowName
 * @returns {string}
 */
export const CREATE_RULE = (workflowName) => `${END_POINTS.WORKFLOW}/${workflowName}/rule`;

export default END_POINTS;
