const header = {
  logoBox: {
    marginTop: 10,
    height: '42px',
    marginLeft: 35,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  // helper/img code inspired by:
  // https://stackoverflow.com/questions/7273338/how-to-vertically-align-an-image-inside-a-div
  logoHelper: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle',
  },
  logoImg: {
    maxHeight: '42px',
    verticalAlign: 'middle',
  },
  headerBar: {
    height: '136px',
    transition: 'width .2s',
    zIndex: '998',
  },
  searchBox: {
    width: '278px',
    height: 'auto',
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  searchBoxAutocompletePaper: {
    boxSizing: 'border-box',
    borderRadius: '0px 0px 8px 8px',
    boxShadow: 'none',
    background: '#FFFFFF',
    width: 276,
    overflow: 'hidden',
  },
  searchBoxAutocompleteListbox: {
    paddingTop: 0,
    // paddingBottom: 0,
  },
  searchInput: {
    minHeight: '42px',
    width: '278px',
    paddingLeft: '14px',
    paddingRight: '14px',
  },
  searchPlaceholderText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.05em',
    color: '#333333',
  },
  searchIcon: {
    marginRight: '19px',
    transition: 'all 0.5s linear',
  },
  searchIconHidden: {
    marginRight: '0px',
    visibility: 'hidden',
    opacity: 0,
    width: 0,
    height: 0,
    transition: 'all 0.5s linear',
  },
};

export default header;
