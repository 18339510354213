import React from 'react';

const OverviewFlowIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_1179)">
      <path d="M1 12.02C0.734784 12.02 0.480429 11.9147 0.292892 11.7271C0.105356 11.5396 0 11.2852 0 11.02C0 10.7548 0.105356 10.5005 0.292892 10.3129C0.480429 10.1254 0.734784 10.02 1 10.02C9 10.02 14.9 5.27002 18.33 2.02002H15.1C14.8348 2.02002 14.5804 1.91466 14.3929 1.72713C14.2054 1.53959 14.1 1.28524 14.1 1.02002C14.1 0.754803 14.2054 0.500452 14.3929 0.312916C14.5804 0.125379 14.8348 0.0200195 15.1 0.0200195L20.76 0.0900192C20.8907 0.0915992 21.0198 0.11878 21.14 0.170021C21.2548 0.216885 21.36 0.284706 21.45 0.370022C21.5351 0.463998 21.6028 0.572349 21.65 0.690022C21.6887 0.785806 21.7123 0.887005 21.72 0.990021V1.05002L21.65 6.71002C21.65 6.97524 21.5446 7.22959 21.3571 7.41713C21.1696 7.60467 20.9152 7.71002 20.65 7.71002C20.3848 7.71002 20.1304 7.60467 19.9429 7.41713C19.7554 7.22959 19.65 6.97524 19.65 6.71002V3.46002C16.11 6.89002 9.8 12.02 1 12.02Z" fill="white" />
      <path d="M15.11 21.22C14.8448 21.22 14.5904 21.1146 14.4029 20.9271C14.2153 20.7395 14.11 20.4852 14.11 20.22C14.11 19.9548 14.2153 19.7004 14.4029 19.5129C14.5904 19.3253 14.8448 19.22 15.11 19.22H18.38C16.1607 16.9746 13.8241 14.8483 11.38 12.85C11.1678 12.6908 11.0275 12.4539 10.99 12.1914C10.9525 11.9288 11.0209 11.6621 11.18 11.45C11.3406 11.2393 11.5782 11.101 11.8407 11.0654C12.1032 11.0298 12.369 11.0998 12.58 11.26C15.0764 13.2987 17.46 15.4719 19.72 17.77V14.55C19.72 14.2848 19.8253 14.0304 20.0129 13.8429C20.2004 13.6553 20.4548 13.55 20.72 13.55C20.9852 13.55 21.2396 13.6553 21.4271 13.8429C21.6146 14.0304 21.72 14.2848 21.72 14.55L21.79 20.2V20.27C21.7821 20.3762 21.7585 20.4807 21.72 20.58C21.6731 20.6948 21.6053 20.7999 21.52 20.89C21.4321 20.978 21.3264 21.0462 21.21 21.09L21.07 21.14H20.84L15.18 21.2L15.11 21.22Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1_1179">
        <rect width="21.77" height="21.22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default OverviewFlowIcon;
