import {
  Box, CircularProgress, Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IntegrationPanel from '../Component/IntegrationPanel/IntegrationPanel';
import IntegrationsContext from '../Contexts/IntegrationsContext';
import overviewPage from '../Themes/Overview';
import SelectSortBy from '../Component/Redesign/SelectSortBy';
import { sortIntegrations } from '../Utils/IntegrationUtils';

const useStyles = makeStyles()((theme) => ({
  ...theme.overviewPage,
}));

const OverviewIntegrationsPage = (props) => {
  const { contentBoxRef } = props;
  const { classes } = useStyles();
  const [boxWidth, setBoxWidth] = useState('100%');
  const [sortedIntegrations, setSortedIntegrations] = useState([]);

  const {
    requestData: integrations, metrics, loading, metricsLoading, sortBy,
  } = useContext(IntegrationsContext);

  useEffect(() => {
    const onResize = () => {
      // eslint-disable-next-line react/prop-types
      const width = contentBoxRef?.current?.offsetWidth ?? 0;
      setBoxWidth(width + overviewPage.ovIntegrationCardGridItem.marginRight);
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [contentBoxRef]);

  /**
   * Will sort integrations if integrations / metrics / sortby are changed
   */
  useEffect(() => {
    if (integrations && metrics) {
      const { sorted } = sortIntegrations(sortBy, integrations, metrics);
      setSortedIntegrations(sorted);
    }
  }, [integrations, metrics, sortBy]);

  return (
    <Box>
      <SelectSortBy />
      <Box className={classes.ovIntegrationsBox} style={{ width: boxWidth }}>
        {loading || metricsLoading ? (
          <CircularProgress />
        ) : (
          <Grid container direction="row" justifyContent="flex-start">
            {sortedIntegrations?.map(
              (integration) => (
                <Grid
                  item
                  key={integration.id}
                  className={classes.ovIntegrationCardGridItem}
                >
                  <IntegrationPanel integration={integration} />
                </Grid>
              ),
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

OverviewIntegrationsPage.propTypes = {
  contentBoxRef: PropTypes.shape({}).isRequired,
};

export default OverviewIntegrationsPage;
