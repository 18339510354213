import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase, Paper, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  ruleCard: {
    maxWidth: 250,
    minWidth: 100,
    margin: 10,
    padding: 10,
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
}));

const RuleCard = (props) => {
  const { rule, onRuleCardClick } = props;
  const { classes } = useStyles();

  return (
    <Paper
      elevation={2}
      className={classes.ruleCard}
      component={ButtonBase}
      onClick={() => onRuleCardClick(rule)}
    >
      <Typography>{rule.ruleName}</Typography>

    </Paper>
  );
};

RuleCard.propTypes = {
  rule: PropTypes.shape({
    ruleName: PropTypes.string,
  }).isRequired,
  onRuleCardClick: PropTypes.func.isRequired,
};

export default RuleCard;
