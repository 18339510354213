import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableHead, TableRow, TableCell,
  TableSortLabel, TableBody, Typography,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { headerNameVariableNameMap, ORDER_DIRECTION } from '../../../Constants/Constants';
import DetailsRunTableRow from './DetailsRunTableRow';

const useStyle = makeStyles()((theme) => ({
  ...theme.details,
}));

const DetailsRunsTable = (props) => {
  const {
    data,
    setAscending,
    orderBy,
    setOrderBy,
    runsLoading,
    tableOverflowRef,
    refreshRuns,
    setShowResolveBtn,
    selectedRows,
    setSelectedRows,
    onDLBtnClick,
  } = props;

  const { classes } = useStyle();
  const [order, setOrder] = useState(ORDER_DIRECTION.DESC);

  const handleTableSortLableClicked = (headerName) => {
    if (orderBy === headerName) {
      setOrder((prev) => (prev === ORDER_DIRECTION.ASC
        ? ORDER_DIRECTION.DESC : ORDER_DIRECTION.ASC));
      setAscending((prev) => (!prev));
    } else {
      setOrderBy(headerName);
      setOrder(ORDER_DIRECTION.ASC);
      setAscending(true);
    }
  };

  const onRowClick = (rowId) => {
    setSelectedRows((rows) => {
      const newTable = rows.slice();
      const index = newTable.indexOf(rowId);
      if (index <= -1) {
        newTable.push(rowId);
      } else {
        newTable.splice(index, 1);
      }
      if (newTable.length > 0) setShowResolveBtn(true);
      else setShowResolveBtn(false);
      return newTable;
    });
  };

  const getHeaderAlign = (headerName) => (headerName === 'DL' ? 'center' : 'inherit');
  const getHeaderClassName = (headerName) => {
    let classNames = `${classes.runsTableRowCells}`;
    if (headerName === 'DL') {
      classNames += ` ${classes.runsTableDLCell}`;
    }
    return classNames;
  };

  const handleSortHeaders = (name) => {
    if (name === 'Status' || name === 'Started' /* || name === 'Finished' */) {
      return (
        <TableSortLabel
          active={headerNameVariableNameMap[name] && orderBy === name}
          direction={orderBy === name ? order : ORDER_DIRECTION.ASC}
          onClick={() => handleTableSortLableClicked(name)}
        >
          <Typography className={classes.runsTableHeaderText}>
            {name}
          </Typography>
        </TableSortLabel>
      );
    }
    return (
      <Typography className={classes.runsTableHeaderText}>
        {name}
      </Typography>
    );
  };

  return (
    <Table stickyHeader size="small">
      <TableHead className={classes.runsTableHeader}>
        <TableRow>
          {Object.keys(headerNameVariableNameMap).map(
            (headerName) => (
              <TableCell
                align={getHeaderAlign(headerName)}
                key={headerName}
                className={getHeaderClassName(headerName)}
              >
                {handleSortHeaders(headerName)}
              </TableCell>
            ),
          )}
        </TableRow>
      </TableHead>
      <TableBody className={classes.runsTableBody}>
        {runsLoading ? (
          <TableRow>
            <TableCell colSpan={6}>
              <LinearProgress variant="query" className={classes.runsTableLoadingBar} />
            </TableCell>
          </TableRow>
        ) : (
          <>
            {data.map((d) => (
              <DetailsRunTableRow
                run={d}
                key={d.id}
                tableOverflowRef={tableOverflowRef}
                refreshRuns={refreshRuns}
                onRowClick={onRowClick}
                isSelected={selectedRows?.includes(d.id)}
                onDLBtnClick={onDLBtnClick}
              />
            ))}
          </>
        )}
      </TableBody>
    </Table>
  );
};

DetailsRunsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setAscending: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  runsLoading: PropTypes.bool.isRequired,
  tableOverflowRef: PropTypes.shape().isRequired,
  refreshRuns: PropTypes.func.isRequired,
  setShowResolveBtn: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  onDLBtnClick: PropTypes.func.isRequired,
};

export default DetailsRunsTable;
