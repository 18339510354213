import React, { useEffect } from 'react';
import {
  Route, Routes, Navigate,
} from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { MiscellaneousServices } from '@mui/icons-material';
import theme from './theme';
import findRoute from './Utils/RouteUtils';
import NotFoundPage from './Page/NotFoundPage';
import ServicesPage from './Page/ServicesPage';
import NavPage from './Component/NavPage/NavPage';
import IntegrationsProvider from './Providers/IntegrationsProvider';
import DetailsPage from './Page/DetailsPage';
import StatisticsPage from './Page/StatisticsPage';
import SettingsPage from './Page/SettingsPage';
import NotificationsPage from './Page/NotificationsPage';
import HomePage from './Page/HomePage';
import AlertNotificationPage from './Page/AlertNotificationPage';
import OverviewIntegrationsPage from './Page/OverviewIntegrationsPage';
import OverviewFlowPage from './Page/OverviewFlowPage';
import { ROUTE_NAMES } from './Constants/Routes';
import { PageHeader, PageHeaderIcon } from './Component/Redesign/PageHeader';
import DetailsIconDark from './Component/Redesign/Icons/DetailsIconDark';
import { checkLoggedInElapsed } from './Utils/LocalStorageUtils';
import { handleLogoutRedirect } from './Utils/AuthUtils';
import useAcquireToken from './Hooks/useAcquireToken';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const CheckUserLogin = () => {
  const { instance } = useMsal();
  const { account } = useAcquireToken();
  useEffect(() => {
    const hasElapsed = checkLoggedInElapsed();

    if (hasElapsed) {
      // logout
      handleLogoutRedirect(instance, account);
    }
  }, [account, instance]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (<></>);
};

function App() {
  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <AuthenticatedTemplate>
            <CheckUserLogin />
            <IntegrationsProvider>
              <Routes>
                <Route
                  path={findRoute(ROUTE_NAMES.overviewIntegrations).path}
                  element={(
                    <NavPage
                      PageComponent={OverviewIntegrationsPage}
                      PageHeader={(
                        <PageHeader text="Overview">
                          <PageHeaderIcon
                            button
                            to={findRoute(ROUTE_NAMES.overviewFlow).path}
                          >
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.24024 19.7031C4.91131 19.7031 4.59585 19.574 4.36326 19.3442C4.13067 19.1145 4 18.8028 4 18.4779C4 18.1529 4.13067 17.8412 4.36326 17.6115C4.59585 17.3817 4.91131 17.2526 5.24024 17.2526C15.1622 17.2526 22.4796 11.4326 26.7336 7.45052H22.7276C22.3987 7.45052 22.0832 7.32143 21.8506 7.09165C21.618 6.86187 21.4874 6.55022 21.4874 6.22526C21.4874 5.9003 21.618 5.58865 21.8506 5.35887C22.0832 5.12909 22.3987 5 22.7276 5L29.7474 5.08577C29.9094 5.0877 30.0695 5.12101 30.2186 5.18379C30.3611 5.24121 30.4914 5.32431 30.6031 5.42884C30.7087 5.54399 30.7927 5.67675 30.8512 5.82093C30.8992 5.93829 30.9284 6.06228 30.938 6.1885V6.26202L30.8512 13.197C30.8512 13.5219 30.7205 13.8336 30.4879 14.0634C30.2553 14.2932 29.9399 14.4222 29.6109 14.4222C29.282 14.4222 28.9665 14.2932 28.734 14.0634C28.5014 13.8336 28.3707 13.5219 28.3707 13.197V9.21489C23.9803 13.4175 16.1543 19.7031 5.24024 19.7031Z" fill="#C4C4C4" />
                              <path d="M22.74 30.9755C22.4111 30.9755 22.0956 30.8465 21.863 30.6167C21.6304 30.3869 21.4997 30.0752 21.4997 29.7503C21.4997 29.4253 21.6304 29.1137 21.863 28.8839C22.0956 28.6541 22.4111 28.525 22.74 28.525H26.7956C24.0431 25.7739 21.1451 23.1686 18.1139 20.7201C17.8508 20.5252 17.6768 20.2349 17.6303 19.9132C17.5837 19.5915 17.6685 19.2647 17.8659 19.0048C18.0651 18.7467 18.3598 18.5772 18.6853 18.5336C19.0108 18.49 19.3406 18.5757 19.6022 18.772C22.6984 21.27 25.6545 23.9327 28.4575 26.7484V22.8031C28.4575 22.4781 28.5882 22.1665 28.8207 21.9367C29.0533 21.7069 29.3688 21.5778 29.6977 21.5778C30.0267 21.5778 30.3421 21.7069 30.5747 21.9367C30.8073 22.1665 30.938 22.4781 30.938 22.8031L31.0248 29.7258V29.8116C31.015 29.9417 30.9857 30.0697 30.938 30.1914C30.8798 30.3321 30.7957 30.4609 30.6899 30.5712C30.581 30.6791 30.4499 30.7627 30.3055 30.8163L30.1318 30.8775H29.8466L22.8268 30.951L22.74 30.9755Z" fill="#C4C4C4" />
                            </svg>
                          </PageHeaderIcon>
                          <PageHeaderIcon>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.3938 6.70549C11.4869 6.49238 9.51308 6.49238 7.60625 6.70549C7.14292 6.75728 6.77752 7.12416 6.72541 7.56975C6.4977 9.51666 6.4977 11.4835 6.72541 13.4304C6.77752 13.876 7.14292 14.2429 7.60626 14.2946C9.51308 14.5078 11.4869 14.5078 13.3938 14.2946C13.8571 14.2429 14.2225 13.876 14.2746 13.4304C14.5023 11.4835 14.5023 9.51666 14.2746 7.56975C14.2225 7.12416 13.8571 6.75728 13.3938 6.70549ZM7.35634 4.46941C9.42926 4.23773 11.5708 4.23773 13.6437 4.46941C15.1299 4.63553 16.3336 5.80551 16.5094 7.30837C16.7574 9.42894 16.7574 11.5712 16.5094 13.6918C16.3336 15.1946 15.1299 16.3646 13.6437 16.5307C11.5708 16.7624 9.42926 16.7624 7.35634 16.5307C5.87008 16.3646 4.66642 15.1946 4.49064 13.6918C4.24262 11.5712 4.24262 9.42894 4.49064 7.30837C4.66642 5.80551 5.87008 4.63553 7.35634 4.46941Z" fill="#333333" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.3938 21.7055C11.4869 21.4924 9.51308 21.4924 7.60625 21.7055C7.14292 21.7573 6.77752 22.1242 6.72541 22.5697C6.4977 24.5167 6.4977 26.4835 6.72541 28.4304C6.77752 28.876 7.14292 29.2429 7.60626 29.2946C9.51308 29.5078 11.4869 29.5078 13.3938 29.2946C13.8571 29.2429 14.2225 28.876 14.2746 28.4304C14.5023 26.4835 14.5023 24.5167 14.2746 22.5697C14.2225 22.1242 13.8571 21.7573 13.3938 21.7055ZM7.35634 19.4694C9.42926 19.2377 11.5708 19.2377 13.6437 19.4694C15.1299 19.6355 16.3336 20.8055 16.5094 22.3084C16.7574 24.4289 16.7574 26.5712 16.5094 28.6918C16.3336 30.1946 15.1299 31.3646 13.6437 31.5307C11.5708 31.7624 9.42926 31.7624 7.35634 31.5307C5.87008 31.3646 4.66642 30.1946 4.49064 28.6918C4.24262 26.5712 4.24262 24.4289 4.49064 22.3084C4.66642 20.8055 5.87008 19.6355 7.35634 19.4694Z" fill="#333333" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M28.3938 6.70549C26.4869 6.49238 24.5131 6.49238 22.6063 6.70549C22.1429 6.75728 21.7775 7.12416 21.7254 7.56975C21.4977 9.51666 21.4977 11.4835 21.7254 13.4304C21.7775 13.876 22.1429 14.2429 22.6063 14.2946C24.5131 14.5078 26.4869 14.5078 28.3938 14.2946C28.8571 14.2429 29.2225 13.876 29.2746 13.4304C29.5023 11.4835 29.5023 9.51666 29.2746 7.56975C29.2225 7.12416 28.8571 6.75728 28.3938 6.70549ZM22.3563 4.46941C24.4293 4.23773 26.5708 4.23773 28.6437 4.46941C30.1299 4.63553 31.3336 5.80551 31.5094 7.30837C31.7574 9.42894 31.7574 11.5712 31.5094 13.6918C31.3336 15.1946 30.1299 16.3646 28.6437 16.5307C26.5708 16.7624 24.4293 16.7624 22.3563 16.5307C20.8701 16.3646 19.6664 15.1946 19.4906 13.6918C19.2426 11.5712 19.2426 9.42894 19.4906 7.30837C19.6664 5.80551 20.8701 4.63553 22.3563 4.46941Z" fill="#333333" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M28.3938 21.7055C26.4869 21.4924 24.5131 21.4924 22.6063 21.7055C22.1429 21.7573 21.7775 22.1242 21.7254 22.5697C21.4977 24.5167 21.4977 26.4835 21.7254 28.4304C21.7775 28.876 22.1429 29.2429 22.6063 29.2946C24.5131 29.5078 26.4869 29.5078 28.3938 29.2946C28.8571 29.2429 29.2225 28.876 29.2746 28.4304C29.5023 26.4835 29.5023 24.5167 29.2746 22.5697C29.2225 22.1242 28.8571 21.7573 28.3938 21.7055ZM22.3563 19.4694C24.4293 19.2377 26.5708 19.2377 28.6437 19.4694C30.1299 19.6355 31.3336 20.8055 31.5094 22.3084C31.7574 24.4289 31.7574 26.5712 31.5094 28.6918C31.3336 30.1946 30.1299 31.3646 28.6437 31.5307C26.5708 31.7624 24.4293 31.7624 22.3563 31.5307C20.8701 31.3646 19.6664 30.1946 19.4906 28.6918C19.2426 26.5712 19.2426 24.4289 19.4906 22.3084C19.6664 20.8055 20.8701 19.6355 22.3563 19.4694Z" fill="#333333" />
                            </svg>
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute(ROUTE_NAMES.overviewFlow).path}
                  element={(
                    <NavPage
                      PageComponent={OverviewFlowPage}
                      PageHeader={(
                        <PageHeader text="Overview">
                          <PageHeaderIcon>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.24024 19.7031C4.91131 19.7031 4.59585 19.574 4.36326 19.3442C4.13067 19.1145 4 18.8028 4 18.4779C4 18.1529 4.13067 17.8412 4.36326 17.6115C4.59585 17.3817 4.91131 17.2526 5.24024 17.2526C15.1622 17.2526 22.4796 11.4326 26.7336 7.45052H22.7276C22.3987 7.45052 22.0832 7.32143 21.8506 7.09165C21.618 6.86187 21.4874 6.55022 21.4874 6.22526C21.4874 5.9003 21.618 5.58865 21.8506 5.35887C22.0832 5.12909 22.3987 5 22.7276 5L29.7474 5.08577C29.9094 5.0877 30.0695 5.12101 30.2186 5.18379C30.3611 5.24121 30.4914 5.32431 30.6031 5.42884C30.7087 5.54399 30.7927 5.67675 30.8512 5.82093C30.8992 5.93829 30.9284 6.06228 30.938 6.1885V6.26202L30.8512 13.197C30.8512 13.5219 30.7205 13.8336 30.4879 14.0634C30.2553 14.2932 29.9399 14.4222 29.6109 14.4222C29.282 14.4222 28.9665 14.2932 28.734 14.0634C28.5014 13.8336 28.3707 13.5219 28.3707 13.197V9.21489C23.9803 13.4175 16.1543 19.7031 5.24024 19.7031Z" fill="#333333" />
                              <path d="M22.74 30.9755C22.4111 30.9755 22.0956 30.8464 21.863 30.6166C21.6304 30.3868 21.4997 30.0752 21.4997 29.7502C21.4997 29.4253 21.6304 29.1136 21.863 28.8838C22.0956 28.6541 22.4111 28.525 22.74 28.525H26.7956C24.0431 25.7739 21.1451 23.1686 18.1139 20.7201C17.8508 20.5251 17.6768 20.2348 17.6303 19.9131C17.5837 19.5914 17.6685 19.2647 17.8659 19.0047C18.0651 18.7466 18.3598 18.5772 18.6853 18.5335C19.0108 18.4899 19.3406 18.5756 19.6022 18.7719C22.6984 21.2699 25.6545 23.9326 28.4575 26.7483V22.803C28.4575 22.4781 28.5882 22.1664 28.8207 21.9366C29.0533 21.7068 29.3688 21.5778 29.6977 21.5778C30.0267 21.5778 30.3421 21.7068 30.5747 21.9366C30.8073 22.1664 30.938 22.4781 30.938 22.803L31.0248 29.7257V29.8115C31.015 29.9416 30.9857 30.0696 30.938 30.1913C30.8798 30.332 30.7957 30.4608 30.6899 30.5712C30.581 30.679 30.4499 30.7626 30.3055 30.8162L30.1318 30.8775H29.8466L22.8268 30.951L22.74 30.9755Z" fill="#333333" />
                            </svg>
                          </PageHeaderIcon>
                          <PageHeaderIcon
                            button
                            to={findRoute(ROUTE_NAMES.overviewIntegrations).path}
                          >
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.3938 6.70543C11.4869 6.49231 9.51308 6.49231 7.60625 6.70543C7.14292 6.75721 6.77752 7.1241 6.72541 7.56969C6.4977 9.5166 6.4977 11.4834 6.72541 13.4303C6.77752 13.8759 7.14292 14.2428 7.60626 14.2946C9.51308 14.5077 11.4869 14.5077 13.3938 14.2946C13.8571 14.2428 14.2225 13.8759 14.2746 13.4303C14.5023 11.4834 14.5023 9.5166 14.2746 7.56969C14.2225 7.1241 13.8571 6.75721 13.3938 6.70543ZM7.35634 4.46935C9.42926 4.23767 11.5708 4.23767 13.6437 4.46935C15.1299 4.63546 16.3336 5.80544 16.5094 7.30831C16.7574 9.42888 16.7574 11.5711 16.5094 13.6917C16.3336 15.1946 15.1299 16.3645 13.6437 16.5307C11.5708 16.7623 9.42926 16.7623 7.35634 16.5307C5.87008 16.3645 4.66642 15.1946 4.49064 13.6917C4.24262 11.5711 4.24262 9.42888 4.49064 7.30831C4.66641 5.80545 5.87008 4.63546 7.35634 4.46935Z" fill="#C4C4C4" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.3938 21.7054C11.4869 21.4923 9.51308 21.4923 7.60625 21.7054C7.14292 21.7572 6.77752 22.1241 6.72541 22.5697C6.4977 24.5166 6.4977 26.4834 6.72541 28.4303C6.77752 28.8759 7.14292 29.2428 7.60626 29.2946C9.51308 29.5077 11.4869 29.5077 13.3938 29.2946C13.8571 29.2428 14.2225 28.8759 14.2746 28.4303C14.5023 26.4834 14.5023 24.5166 14.2746 22.5697C14.2225 22.1241 13.8571 21.7572 13.3938 21.7054ZM7.35634 19.4694C9.42926 19.2377 11.5708 19.2377 13.6437 19.4694C15.1299 19.6355 16.3336 20.8054 16.5094 22.3083C16.7574 24.4289 16.7574 26.5711 16.5094 28.6917C16.3336 30.1946 15.1299 31.3645 13.6437 31.5307C11.5708 31.7623 9.42926 31.7623 7.35634 31.5307C5.87008 31.3645 4.66642 30.1946 4.49064 28.6917C4.24262 26.5711 4.24262 24.4289 4.49064 22.3083C4.66641 20.8054 5.87008 19.6355 7.35634 19.4694Z" fill="#C4C4C4" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M28.3938 6.70543C26.4869 6.49231 24.5131 6.49231 22.6063 6.70543C22.1429 6.75721 21.7775 7.1241 21.7254 7.56969C21.4977 9.5166 21.4977 11.4834 21.7254 13.4303C21.7775 13.8759 22.1429 14.2428 22.6063 14.2946C24.5131 14.5077 26.4869 14.5077 28.3938 14.2946C28.8571 14.2428 29.2225 13.8759 29.2746 13.4303C29.5023 11.4834 29.5023 9.5166 29.2746 7.56969C29.2225 7.1241 28.8571 6.75721 28.3938 6.70543ZM22.3563 4.46935C24.4293 4.23767 26.5708 4.23767 28.6437 4.46935C30.1299 4.63546 31.3336 5.80544 31.5094 7.30831C31.7574 9.42888 31.7574 11.5711 31.5094 13.6917C31.3336 15.1946 30.1299 16.3645 28.6437 16.5307C26.5708 16.7623 24.4293 16.7623 22.3563 16.5307C20.8701 16.3645 19.6664 15.1946 19.4906 13.6917C19.2426 11.5711 19.2426 9.42888 19.4906 7.30831C19.6664 5.80545 20.8701 4.63546 22.3563 4.46935Z" fill="#C4C4C4" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M28.3938 21.7054C26.4869 21.4923 24.5131 21.4923 22.6063 21.7054C22.1429 21.7572 21.7775 22.1241 21.7254 22.5697C21.4977 24.5166 21.4977 26.4834 21.7254 28.4303C21.7775 28.8759 22.1429 29.2428 22.6063 29.2946C24.5131 29.5077 26.4869 29.5077 28.3938 29.2946C28.8571 29.2428 29.2225 28.8759 29.2746 28.4303C29.5023 26.4834 29.5023 24.5166 29.2746 22.5697C29.2225 22.1241 28.8571 21.7572 28.3938 21.7054ZM22.3563 19.4694C24.4293 19.2377 26.5708 19.2377 28.6437 19.4694C30.1299 19.6355 31.3336 20.8054 31.5094 22.3083C31.7574 24.4289 31.7574 26.5711 31.5094 28.6917C31.3336 30.1946 30.1299 31.3645 28.6437 31.5307C26.5708 31.7623 24.4293 31.7623 22.3563 31.5307C20.8701 31.3645 19.6664 30.1946 19.4906 28.6917C19.2426 26.5711 19.2426 24.4289 19.4906 22.3083C19.6664 20.8054 20.8701 19.6355 22.3563 19.4694Z" fill="#C4C4C4" />
                            </svg>
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute(ROUTE_NAMES.services).path}
                  element={(
                    <NavPage
                      includeCardBodyPage
                      PageComponent={ServicesPage}
                      PageHeader={(
                        <PageHeader text="Services">
                          <PageHeaderIcon>
                            <MiscellaneousServices fontSize="large" />
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute(ROUTE_NAMES.servicesWithId).path}
                  element={(
                    <NavPage
                      includeCardBodyPage
                      PageComponent={ServicesPage}
                      PageHeader={(
                        <PageHeader text="Services">
                          <PageHeaderIcon>
                            <MiscellaneousServices fontSize="large" />
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute('alerts').path}
                  element={<NavPage PageComponent={AlertNotificationPage} />}
                />
                <Route
                  path={findRoute('details').path}
                  element={(
                    <NavPage
                      includeCardBodyPage
                      PageComponent={DetailsPage}
                      PageHeader={(
                        <PageHeader text="Details">
                          <PageHeaderIcon>
                            <DetailsIconDark />
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute('detailsWithId').path}
                  element={(
                    <NavPage
                      includeCardBodyPage
                      PageComponent={DetailsPage}
                      PageHeader={(
                        <PageHeader text="Details">
                          <PageHeaderIcon>
                            <DetailsIconDark />
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute('statistics').path}
                  element={(
                    <NavPage
                      includeCardBodyPage
                      PageComponent={StatisticsPage}
                      PageHeader={(
                        <PageHeader text="Statistics">
                          <PageHeaderIcon>
                            <svg width="27" height="36" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M27 26H1.5C0.671573 26 0 25.3533 0 24.5556V0H3V23.1111H27V26ZM8.0595 18.7778L6 16.8364L13.284 9.96667C13.8574 9.43002 14.7701 9.43002 15.3435 9.96667L18.684 13.117L24.9405 7.22222L27 9.16356L19.716 16.0333C19.1426 16.57 18.2299 16.57 17.6565 16.0333L14.3145 12.8816L8.061 18.7778H8.0595Z" fill="#333333" />
                            </svg>
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute('statisticsWithId').path}
                  element={(
                    <NavPage
                      includeCardBodyPage
                      PageComponent={StatisticsPage}
                      PageHeader={(
                        <PageHeader text="Statistics">
                          <PageHeaderIcon>
                            <svg width="27" height="36" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M27 26H1.5C0.671573 26 0 25.3533 0 24.5556V0H3V23.1111H27V26ZM8.0595 18.7778L6 16.8364L13.284 9.96667C13.8574 9.43002 14.7701 9.43002 15.3435 9.96667L18.684 13.117L24.9405 7.22222L27 9.16356L19.716 16.0333C19.1426 16.57 18.2299 16.57 17.6565 16.0333L14.3145 12.8816L8.061 18.7778H8.0595Z" fill="#333333" />
                            </svg>
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute('settings').path}
                  element={(
                    <NavPage
                      PageComponent={SettingsPage}
                      PageHeader={(
                        <PageHeader text="Settings">
                          {/* TODO Use the right icon
                      might be possible to drop some duplicate SVG code
                      -> change fill value + width/height / viewBox?
                    */}
                          <PageHeaderIcon>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M7.43571 7.7886C6.8183 9.78974 6.8183 11.9603 7.43571 13.9614C14.4622 14.6386 21.5375 14.6386 28.564 13.9614C29.1814 11.9603 29.1814 9.78974 28.564 7.7886C21.5375 7.1114 14.4622 7.1114 7.43571 7.7886ZM7.06298 5.56421C14.3369 4.85286 21.6628 4.85286 28.9367 5.56421C29.7386 5.64262 30.4125 6.19206 30.6587 6.95072C31.4832 9.49035 31.4832 12.2597 30.6587 14.7993C30.4125 15.558 29.7386 16.1074 28.9367 16.1858C21.6628 16.8972 14.3369 16.8972 7.06298 16.1858C6.26115 16.1074 5.58725 15.558 5.34097 14.7993C4.51655 12.2597 4.51655 9.49035 5.34097 6.95072C5.58725 6.19206 6.26115 5.64262 7.06298 5.56421Z" fill="#333333" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M7.43571 22.0386C6.8183 24.0397 6.8183 26.2103 7.43571 28.2114C14.4622 28.8886 21.5375 28.8886 28.564 28.2114C29.1814 26.2103 29.1814 24.0397 28.564 22.0386C21.5375 21.3614 14.4622 21.3614 7.43571 22.0386ZM7.06298 19.8142C14.3369 19.1029 21.6628 19.1029 28.9367 19.8142C29.7386 19.8926 30.4125 20.4421 30.6587 21.2007C31.4832 23.7404 31.4832 26.5097 30.6587 29.0493C30.4125 29.808 29.7386 30.3574 28.9367 30.4358C21.6628 31.1472 14.3369 31.1472 7.06298 30.4358C6.26115 30.3574 5.58725 29.808 5.34097 29.0493C4.51655 26.5097 4.51655 23.7404 5.34097 21.2007C5.58725 20.4421 6.26115 19.8926 7.06298 19.8142Z" fill="#333333" />
                            </svg>
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route
                  path={findRoute('notifications').path}
                  element={(
                    <NavPage
                      PageComponent={NotificationsPage}
                      PageHeader={(
                        <PageHeader text="Notifications">
                          {/* TODO Use the right icon
                  might be possible to drop some duplicate SVG code
                  -> change fill value + width/height / viewBox?
                */}
                          <PageHeaderIcon>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M7.43571 7.7886C6.8183 9.78974 6.8183 11.9603 7.43571 13.9614C14.4622 14.6386 21.5375 14.6386 28.564 13.9614C29.1814 11.9603 29.1814 9.78974 28.564 7.7886C21.5375 7.1114 14.4622 7.1114 7.43571 7.7886ZM7.06298 5.56421C14.3369 4.85286 21.6628 4.85286 28.9367 5.56421C29.7386 5.64262 30.4125 6.19206 30.6587 6.95072C31.4832 9.49035 31.4832 12.2597 30.6587 14.7993C30.4125 15.558 29.7386 16.1074 28.9367 16.1858C21.6628 16.8972 14.3369 16.8972 7.06298 16.1858C6.26115 16.1074 5.58725 15.558 5.34097 14.7993C4.51655 12.2597 4.51655 9.49035 5.34097 6.95072C5.58725 6.19206 6.26115 5.64262 7.06298 5.56421Z" fill="#333333" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M7.43571 22.0386C6.8183 24.0397 6.8183 26.2103 7.43571 28.2114C14.4622 28.8886 21.5375 28.8886 28.564 28.2114C29.1814 26.2103 29.1814 24.0397 28.564 22.0386C21.5375 21.3614 14.4622 21.3614 7.43571 22.0386ZM7.06298 19.8142C14.3369 19.1029 21.6628 19.1029 28.9367 19.8142C29.7386 19.8926 30.4125 20.4421 30.6587 21.2007C31.4832 23.7404 31.4832 26.5097 30.6587 29.0493C30.4125 29.808 29.7386 30.3574 28.9367 30.4358C21.6628 31.1472 14.3369 31.1472 7.06298 30.4358C6.26115 30.3574 5.58725 29.808 5.34097 29.0493C4.51655 26.5097 4.51655 23.7404 5.34097 21.2007C5.58725 20.4421 6.26115 19.8926 7.06298 19.8142Z" fill="#333333" />
                            </svg>
                          </PageHeaderIcon>
                        </PageHeader>
                      )}
                    />
                  )}
                />
                <Route element={<HomePage />} path="/login" />
                <Route
                  path="*"
                  element={(
                    <NavPage
                      PageComponent={NotFoundPage}
                      PageHeader={(
                        <PageHeader text="404 Not found" />
                      )}
                    />
                  )}
                />
              </Routes>
            </IntegrationsProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route element={<HomePage />} path="/" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </UnauthenticatedTemplate>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
