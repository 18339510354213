import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Table, TableBody, TableCell, TableRow, Collapse, Tooltip,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { subscriptionDataDefinition, topicDataDefinition } from '../../../Constants/ServiceBusConstants';
import SubscriptionTableRow from './SubscriptionTableRow';
import { generateTableCells, generateTableHeaders } from '../../../Utils/TableUtils';

const useStyles = makeStyles()(() => ({
  collapseTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

const TopicTableRow = (props) => {
  const { topic, btnSubDeadLetterOnClick } = props;
  const { classes } = useStyles();
  const [openSubscriptionsTable, setOpenSubscriptionsTable] = useState(false);

  return (
    <>
      <TableRow hover key={topic.topicRuntimeProperties.name}>
        {generateTableCells(topicDataDefinition, topic.topicRuntimeProperties)}
        <TableCell key="expand">
          {topic?.topicRuntimeProperties?.subscriptionCount > 0 && (
            <Tooltip title="Show subscriptions">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenSubscriptionsTable(!openSubscriptionsTable)}
              >
                {openSubscriptionsTable ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow key={`${topic.topicRuntimeProperties.name}-sub`}>
        <TableCell className={classes.collapseTableCell} colSpan={5}>
          <Collapse in={openSubscriptionsTable} timeout="auto" unmountOnExit>
            <Table size="small">
              {generateTableHeaders(subscriptionDataDefinition, false)}
              <TableBody>
                {topic?.subscriptionsRuntimeProperties?.map((item) => (
                  <SubscriptionTableRow
                    key={`${item.topicName}-${item.subscriptionName}`}
                    subscription={item}
                    btnSubDeadLetterOnClick={btnSubDeadLetterOnClick}
                  />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

TopicTableRow.propTypes = {
  topic: PropTypes.shape({
    topicRuntimeProperties: PropTypes.shape({
      name: PropTypes.string,
      accessedAt: PropTypes.string,
      scheduledMessageCount: PropTypes.number,
      subscriptionCount: PropTypes.number,
    }),
    subscriptionsRuntimeProperties: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  btnSubDeadLetterOnClick: PropTypes.func.isRequired,
};

export default TopicTableRow;
