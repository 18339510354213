import React, {
  useContext, useState, useEffect,
} from 'react';
import {
  AppBar, Box, InputBase, InputAdornment, Autocomplete, createFilterOptions, Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import InputSearchIcon from '../Redesign/Icons/InputSearchIcon';
import IntegrationsContext from '../../Contexts/IntegrationsContext';
import findRoute from '../../Utils/RouteUtils';
import { ROUTE_NAMES } from '../../Constants/Routes';

const useStyles = makeStyles()((theme, { navWidth }) => ({
  header: {
    width: `calc(100% - ${navWidth}px)`,
    ...theme.header.headerBar,
  },
  logo: {
    marginLeft: 10,
  },
  menuBtn: {
    marginBottom: 15,
  },
  poweredText: theme.headerPoweredByText,
  ...theme.header,
  gridMarginTopFix: {
    marginTop: 10,
  },
  gridHeaderFix: {
    marginTop: 31,
    paddingLeft: 84,
    paddingRight: 50,
  },
}));
const OPTIONS_LIMIT = 3;
const TEXT_HEIGHT = 34;
const SEARCHBOX_HEIGHT = 42;

const MainHeader = ({
  navWidth, PageHeader,
}) => {
  const { classes } = useStyles({ navWidth });
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(false);
  const [selected, setSelected] = useState(null);
  const [height, setHeight] = useState(SEARCHBOX_HEIGHT);

  const { requestData } = useContext(IntegrationsContext);
  const [integrations, setIntegrations] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (requestData) {
      const sorted = requestData.data?.sort(
        (a, b) => (a.displayName ?? a.name)?.localeCompare(b.displayName ?? b.name),
      );
      setIntegrations(sorted);
    }
  }, [requestData]);

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    const array = defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    const extra = array.length === 0 ? 0 : 2;
    const length = array.length > OPTIONS_LIMIT ? OPTIONS_LIMIT : array.length;
    // This will give a warning, unsure how to fix...
    setHeight(SEARCHBOX_HEIGHT + extra + length * TEXT_HEIGHT);
    return array;
  };

  const isOpen = focus && search?.length > 0;

  const onSelect = (e, v, r) => {
    if (r === 'selectOption') {
      const { id } = v;
      setHeight(SEARCHBOX_HEIGHT);
      setSearch('');
      setSelected(null);
      navigate(`${findRoute(ROUTE_NAMES.details).path}/${id}`);
    }
  };

  const logoUrl = (window.ENV.LOGO_URL && window.ENV.LOGO_URL !== '') ? window.ENV.LOGO_URL : `${process.env.PUBLIC_URL}/headerLogo.svg`;

  return (
    <AppBar
      color="transparent"
      className={classes.header}
      enableColorOnDark
      elevation={0}
      position="relative"
    >
      <Grid
        container
        justifyContent="space-between"
        className={classes.gridHeaderFix}
      >
        <Grid item container xs={4.8} className={classes.gridMarginTopFix}>
          {PageHeader}
        </Grid>
        <Grid item xs={7.1}>
          <Grid container justifyContent="right">
            <Grid item className={classes.gridMarginTopFix}>
              <Box
                className={`${classes.searchBox}`}
                style={{ height }}
              >
                <Autocomplete
                  freeSolo
                  filterOptions={filterOptions}
                  options={integrations}
                  getOptionLabel={(option) => option.displayName ?? option.name}
                  onChange={onSelect}
                  clearOnEscape
                  value={selected}
                  classes={{
                    paper: classes.searchBoxAutocompletePaper,
                    option: `${classes.searchPlaceholderText}`,
                    listbox: classes.searchBoxAutocompleteListbox,
                  }}
                  open={isOpen}
                  onFocus={() => setFocus(true)}
                  onBlur={() => {
                    setHeight(SEARCHBOX_HEIGHT);
                    setFocus(false);
                  }}
                  onInputChange={(e) => {
                    if (e.target.value === '') setHeight(SEARCHBOX_HEIGHT);
                    setSearch(e.target.value);
                  }}
                  renderInput={(params) => (
                    <InputBase
                      ref={params.InputProps.ref}
                      fullWidth
                      placeholder="Integration"
                      className={classes.searchInput}
                      startAdornment={(
                        <InputAdornment
                          position="start"
                          className={classes.searchIcon}
                        >
                          <InputSearchIcon />
                        </InputAdornment>
                      )}
                      inputProps={{
                        ...params.inputProps,
                        className: classes.searchPlaceholderText,
                        onSelect: () => null,
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.logoBox}>
                <span className={classes.logoHelper} />
                <img className={classes.logoImg} src={logoUrl} alt="Logo" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

MainHeader.propTypes = {
  navWidth: PropTypes.number,
  PageHeader: PropTypes.node.isRequired,
};

MainHeader.defaultProps = {
  navWidth: 0,
};

export default MainHeader;
