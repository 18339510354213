import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';
import { makeStyles } from 'tss-react/mui';
import { IconButton } from '@mui/material';
import { Info } from '@mui/icons-material';
import { getEdgeStrokeColor, getMarkerEndId } from '../../../Utils/FlowUtils';
import { edgeTypeNames } from '../../../Constants/FlowConstants';
import IntegrationsContext from '../../../Contexts/IntegrationsContext';
import { findAndSetIntegration } from '../../../Utils/IntegrationUtils';
import ConnectorFlowContext from '../../../Contexts/ConnectorFlowContext';

const useStyles = makeStyles()(() => ({
  textPath: {
    fontSize: '9px',
    fontFamily: 'Mulish',
    lineHeight: '18px',
  },
  activeMsg: {
    r: 3,
  },
  link: {
    pointerEvents: 'all',
  },
  button: {
    zIndex: 2,
  },
  icon: {
    backgroundColor: '#f0f0F0',
    borderRadius: 35,
  },
}));

const foreignObjectSize = 31;

const CustomEdge = memo(({
  id,
  sourceX, sourceY,
  targetX, targetY,
  sourcePosition, targetPosition,
  data,
  arrowHeadType,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  animated,
}) => {
  const edgePath = getBezierPath({
    sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const { classes } = useStyles();

  const { requestData: integrations } = useContext(IntegrationsContext);
  const { isEditMode, handleOpen, setSelectedIntegrationId } = useContext(ConnectorFlowContext);
  const [integration, setIntegration] = useState(null);
  const [showServiceBusError, setShowServiceBusError] = useState(false);

  // Use this to show service bus status on input edges (blue)
  const [showInputStatus, setShowInputStatus] = useState(false);
  // Set the service bus properties for input edges (blue)
  const [serviceBusInputStatus, setServiceBusInputStatus] = useState({});

  // Use this to show service bus status on output edges (red)
  const [showOutputStatus, setShowOutputStatus] = useState(false);
  // Set the runtime properties for output edges (red)
  const [serviceBusOutputStatus, setServiceBusOutputStatus] = useState({});

  const [numOfMessages, setNumOfMessages] = useState(0);

  const { api } = useContext(IntegrationsContext);

  const animateMessages = () => {
    if (numOfMessages > 10) {
      setNumOfMessages(10);
    }
    const messages = [];
    let start = 0.0;
    for (let i = 0; i < numOfMessages; i += 1) {
      messages.push(
        <circle className={classes.activeMsg} key={i}>
          <animateMotion dur="3" repeatCount="indefinite" path={edgePath} begin={start} />
        </circle>,
      );
      start += 0.2;
    }
    return messages;
  };

  useEffect(() => {
    if (data.integrationId && api) {
      findAndSetIntegration(data.integrationId, integrations, setIntegration);

      // TODO Retrieve and set all info in a context instead?

      // Input edge
      if (data.type === edgeTypeNames.input) {
        if (integration) {
          api.getSourceEdgeServiceBusProperties(integration.id)
            .then((response) => {
              setShowServiceBusError(false);
              setServiceBusInputStatus(response.data);
              // TODO might be wrong info, show some error?
              if (response.data?.error) {
                // setShowServiceBusError(true);
                // setShowInputStatus(false);
              }
              setShowInputStatus(true);
              setNumOfMessages(response.data?.totalActiveMessageCount ?? 0);
            })
            .catch(() => {
              // Change backend to not return 400 code when nothing was found?
              // setShowServiceBusError(true);
            });
        }
      }

      // Output edge
      if (data.type === edgeTypeNames.output) {
        if (integration) {
          api.getTargetEdgeServiceBusProperties(integration.id)
            .then((response) => {
              setShowServiceBusError(false);
              setServiceBusOutputStatus(response.data);
              // TODO might be wrong info, show some error?
              if (response.data?.error) {
                // setShowServiceBusError(true);
                // setShowOutputStatus(false);
              }
              setShowOutputStatus(true);
              setNumOfMessages(response.data?.totalActiveMessageCount ?? 0);
            })
            .catch(() => {
              // Change backend to not return 400 code when nothing was found?
              // setShowServiceBusError(true);
            });
        }
      }
    }
  }, [api, data.integrationId, data.type, integration, integrations]);

  const onEdgeBtnClick = () => {
    // Open modal
    if (!isEditMode) {
      setSelectedIntegrationId(data.integrationId);
      handleOpen();
    }
  };

  const markerEnd = getMarkerEnd(arrowHeadType, getMarkerEndId(data.type));
  const strokeColor = getEdgeStrokeColor(data.type);
  const style = {
    stroke: strokeColor,
  };

  const createOutputStatus = () => (
    <>
      <text dy="-5">
        <textPath href={`#${id}`} className={classes.textPath} startOffset="90%" textAnchor="end">
          {`Active: ${serviceBusOutputStatus?.totalActiveMessageCount}`}
        </textPath>
      </text>
      <text dy="10">
        <textPath href={`#${id}`} className={classes.textPath} startOffset="90%" textAnchor="end">
          {`Dead: ${serviceBusOutputStatus?.totalDeadLetterMessageCount}`}
        </textPath>
      </text>
    </>
  );

  const createInputStatus = () => (
    <>
      <text dy="-5">
        <textPath href={`#${id}`} className={classes.textPath} startOffset="5%" textAnchor="start">
          {`Scheduled: ${serviceBusInputStatus?.totalScheduledMessageCount}`}
        </textPath>
      </text>
      <text dy="10">
        <textPath href={`#${id}`} className={classes.textPath} startOffset="5%" textAnchor="start">
          {`Subs: ${serviceBusInputStatus?.totalSubscriptionCount}`}
        </textPath>
      </text>
    </>
  );

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      {showServiceBusError ? (
        <text dy="-5">
          <textPath href={`#${id}`} className={classes.textPath} startOffset="50%" textAnchor="middle">
            Error loading info
          </textPath>
        </text>
      ) : (
        <>
          {animateMessages(numOfMessages)}
          {showOutputStatus && createOutputStatus()}
          {showInputStatus && createInputStatus()}
        </>
      )}
      {integration && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          className={classes.link}
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <IconButton
            color="primary"
            onClick={onEdgeBtnClick}
            className={classes.button}
            size="small"
          >
            <Info className={classes.icon} fontSize="small" />
          </IconButton>
        </foreignObject>
      )}
    </>
  );
});

export default CustomEdge;

CustomEdge.propTypes = {
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  sourcePosition: PropTypes.string.isRequired,
  targetPosition: PropTypes.string.isRequired,
  data: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string,
    integrationId: PropTypes.string,
  }),
  arrowHeadType: PropTypes.string,
  animated: PropTypes.bool,
};

CustomEdge.defaultProps = {
  data: {
    label: '',
    type: '',
    integrationId: '-1',
  },
  arrowHeadType: 'arrow',
  animated: true,
};
