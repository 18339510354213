const runsDetail = {
  runsDetailModalBox: {
    width: 'auto',
    maxWidth: 'calc(100% - 200px)',
    background: '#FFFFFF',
    border: '1px solid #F1F4E4',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  runsDetailBoxViewer: {
    borderRadius: 8,
  },
  runsDetailBox: {
    padding: '10px 20px 20px 20px',
  },
  runsDetailExitBtn: {
    position: 'absolute',
    top: 8, // '23.45px',
    right: 8, // '33.44px',
  },
  // TODO Need to change these after design is in place
  runsResolveBox: {
    width: 500,
  },
  runResolveErrorText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: 'red',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  runResolveHeaderText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#003366',
    marginTop: 10,
  },
  runResolveInputText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '220%',
    letterSpacing: '0.1em',
    color: '#2E3A59',
  },
  runResolveInput: {
    background: '#FFFFFF',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    borderRadius: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'rgba(0, 0, 0, 1)',
    },
  },
  runResolveInputField: {
    width: 300,
    height: '40px',
  },
  runResolveTextArea: {
  },
  runResolveSaveBtn: {
    width: '74px',
    height: '40px',
    // background: '#F6F8F2',
    background: '#dfe0dc',
    borderRadius: '5px',
    marginBottom: 5,
    marginRight: 5,
  },
  runsDLContent: {
    marginTop: 25,
    minWidth: 500,
  },
  runsDLSelect: {
    width: 300,
  },
  runDLInput: {
    height: 40,
    paddingLeft: 10,
    padding: 0,
  },
  runsDLSelectText: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  runsDLButton: {
    width: 160,
    marginLeft: 20,
  },
  runsDLResendDeleteBtn: {
    width: 100,
  },
  runsDLBodyTextarea: {
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
    whiteSpace: 'pre-wrap',
    letterSpacing: 'normal',
    lineHeight: '120%',
    fontSize: 'inherit',
    padding: '0',
  },
  runsDLActionText: {
    color: 'green',
    whiteSpace: 'pre-wrap',
  },
  runsDLConfirmText: {
    marginRight: 10,
  },
  runsDetailHeaderText: {
    position: 'absolute',
    top: 13,
    left: 20,
    color: '#333333',
    fontWeight: 'bold',
  },
};

export default runsDetail;
