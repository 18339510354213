import React from 'react';
import {
  IconButton, TableCell, TableRow, Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Mail } from '@mui/icons-material';
import { generateTableCells } from '../../../Utils/TableUtils';
import { queueDataDefinition } from '../../../Constants/ServiceBusConstants';

const QueueTableRow = (props) => {
  const { queue, btnQueueDeadLetterOnClick } = props;
  const {
    name, deadLetterMessageCount,
  } = queue;
  const maxMsg = deadLetterMessageCount < 10 ? deadLetterMessageCount : 10;

  return (
    <TableRow hover>
      {generateTableCells(queueDataDefinition, queue)}
      <TableCell key="btn">
        {deadLetterMessageCount > 0 && (
          <Tooltip title="Show dead letters">
            <IconButton size="small" color="primary" onClick={() => btnQueueDeadLetterOnClick(name, maxMsg)}>
              <Mail />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

QueueTableRow.propTypes = {
  queue: PropTypes.shape({
    name: PropTypes.string,
    activeMessageCount: PropTypes.number,
    deadLetterMessageCount: PropTypes.number,
    accessedAt: PropTypes.string,
  }).isRequired,
  btnQueueDeadLetterOnClick: PropTypes.func.isRequired,
};

export default QueueTableRow;
