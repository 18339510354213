import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { ROUTE_NAMES } from '../../../Constants/Routes';
import findRoute from '../../../Utils/RouteUtils';

const useStyles = makeStyles()((theme) => ({
  ...theme.sidebar,
}));

/**
 * Should be used inside a MUI \<Grid container\>
 */
const SidebarListItem = (props) => {
  const {
    text, children, to, title,
  } = props;
  const { classes } = useStyles();
  const [isHere, setIsHere] = useState(false);
  const match = useMatch(`${to}/*`);
  const location = useLocation();

  useEffect(() => {
    const pathnameBase = match?.pathnameBase;
    const flowPath = findRoute(ROUTE_NAMES.overviewFlow).path;
    const integrationsPath = findRoute(ROUTE_NAMES.overviewIntegrations).path;
    const isOnOverview = location.pathname === integrationsPath && to === flowPath;
    setIsHere(pathnameBase || isOnOverview);
  }, [location, match?.pathnameBase, to]);

  return (
    <Grid
      item
      component={Link}
      to={to}
      className={`${classes.sidebarListItemBox} ${isHere ? classes.sidebarListItemSelected : ''}`}
      title={title}
    >
      {isHere && (
        <Box className={classes.selectedVertBar} />
      )}
      <Box className={classes.sidebarListItemIcon}>
        {children}
      </Box>
      <Typography className={`${classes.sidebarListItemText} ${classes.leftAlignedText}`}>
        {text}
      </Typography>
    </Grid>
  );
};

SidebarListItem.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
};

SidebarListItem.defaultProps = {
  text: '',
  title: '',
};

export default SidebarListItem;
