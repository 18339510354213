import {
  Box, InputBase, MenuItem, Select, Stack, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useContext } from 'react';
import { INTEGRATIONS_SORT_BY_NAMES } from '../../Constants/Constants';
import IntegrationsContext from '../../Contexts/IntegrationsContext';

const useStyles = makeStyles()((theme) => ({
  ...theme.details,
}));

const SelectSortBy = () => {
  const { classes } = useStyles();
  const { sortBy, setSortBy } = useContext(IntegrationsContext);

  return (
    <Box className={classes.detailsSortByBox}>
      <Stack
        direction="row"
        alignItems="center"
      >
        <Select
          fullWidth
          onChange={((e) => {
            setSortBy(e.target.value);
          })}
          value={sortBy}
          renderValue={(value) => `Sort by ${value}`}
          className={classes.detailsSortBySelect}
          input={<InputBase className={classes.detailsSortByText} />}
          inputProps={{ className: classes.detailsSortByInput }}
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent={() => (
            <svg className={classes.detailsSortByIcon} width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.00005 11.7848L13.5076 7.27725L12.4478 6.216L9.00005 9.666L5.55305 6.216L4.49255 7.2765L9.00005 11.7848Z" fill="#333333" />
            </svg>
          )}
          MenuProps={{
            classes: {
              paper: classes.detailsSortByPaper,
            },
          }}
        >
          {INTEGRATIONS_SORT_BY_NAMES.map((item) => (
            <MenuItem
              key={item}
              className={classes.detailsSortByMenuItem}
              value={item}
              style={item === sortBy ? { display: 'none' } : {}}
            >
              <Typography className={classes.detailsSortByText}>
                {`Sort by ${item}`}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Box>
  );
};

export default SelectSortBy;
