import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  Area,
  AreaChart,
  CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import dayjs from 'dayjs';
import { fillInDateTimeChartData, fillInDateTimeCHartDataDays } from '../../Utils/DateTimeUtils';
import statistics from '../../Themes/Statistics';

const useStyles = makeStyles()((theme) => ({
  ...theme.statistics,
}));

// From https://github.com/recharts/recharts/issues/275
const CustomTooltip = (props) => {
  const { classes } = useStyles();
  // eslint-disable-next-line react/prop-types
  const { active, payload, label } = props;
  if (!active) {
    return null;
  }
  // eslint-disable-next-line react/prop-types
  const totalFailed = payload && payload[0]?.payload?.Failed;
  // eslint-disable-next-line react/prop-types
  const total = payload && payload[0]?.payload?.Total;
  // eslint-disable-next-line react/prop-types
  const filtered = payload && payload[0]?.payload?.Filtered;
  return (
    <Box className={classes.statsChartToolTipBox}>
      <Typography className={classes.statsChartToolTipText}>
        {label}
      </Typography>
      <Typography className={classes.statsChartToolTipText}>
        {`Total: ${total}`}
      </Typography>
      <Typography className={classes.statsChartToolTipText}>
        {`Failed: ${totalFailed}`}
      </Typography>
      <Typography className={classes.statsChartToolTipText}>
        {`Filtered: ${filtered}`}
      </Typography>
    </Box>
  );
};

/**
 *
 * @param {array} chartDataList
 * @param {number} intervalNum
 * @returns {array}
 */
const convertDataToLineData = (chartDataList, useDays) => {
  if (Array.isArray(chartDataList) && chartDataList?.length > 0) {
    if (useDays) return fillInDateTimeCHartDataDays(chartDataList);
    return fillInDateTimeChartData(chartDataList);
  }
  return [];
};

const convertDataToMetrics = (chartDataList) => {
  if (Array.isArray(chartDataList) && chartDataList.length > 0) {
    const totalFailed = chartDataList.reduce((prev, cur) => prev + cur.totalFailed, 0);
    const total = chartDataList.reduce((prev, cur) => prev + cur.totalRuns, 0);
    return { totalFailed, total };
  }
  return [];
};

const calcTickGap = (intervalDay) => {
  const extraGap = Math.ceil(1800 / window.innerWidth) ** 2;
  if (intervalDay <= 14) {
    return { gap: 23, tInterval: 23 };
  }
  if (intervalDay <= 30) {
    return { gap: 0, tInterval: 0 + extraGap };
  }
  return { gap: intervalDay / 30, tInterval: intervalDay / 30 + extraGap };
};

const DataVisualization = (props) => {
  const {
    chartData, interval,
  } = props;
  const [lineData, setLineData] = useState({});
  const [otherMetrics, setOtherMetrics] = useState({});
  const [tickGap, setTickGap] = useState(23);
  const [tickInterval, setTickInterval] = useState(23);
  const { classes } = useStyles();

  const convertIntervalToNumber = () => Number.parseInt(interval?.split('.')[0], 10);

  useEffect(() => {
    const intervalNum = convertIntervalToNumber();
    const { gap, tInterval } = calcTickGap(intervalNum);
    setTickGap(gap);
    setTickInterval(tInterval);
    setLineData(convertDataToLineData(chartData, intervalNum > 14));
    setOtherMetrics(convertDataToMetrics(chartData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const tickFormatter = (value) => {
    if (value) {
      return value.substring(0, 7);
    }
    return '';
  };

  const calcFailedPercent = () => {
    const percent = (otherMetrics.totalFailed / otherMetrics.total) * 100;
    if (Number.isFinite(percent)) {
      const decimal = Math.round(percent * 10) / 10;
      return `${decimal}%`;
    }
    return '0%';
  };

  const calcSinceLastFailed = () => {
    if (Array.isArray(chartData) && chartData.length > 0) {
      let start = '';
      for (let index = chartData.length - 1; index >= 0; index -= 1) {
        const element = chartData[index];
        if (element?.totalFailed > 1) start = element.start;
      }
      if (start) {
        const now = dayjs();
        const date = dayjs(start);
        return now.diff(date, 'hours');
      }
    }
    return 'None';
  };

  const calcAvgPerDay = () => {
    if (Array.isArray(chartData) && chartData.length > 0) {
      const total = chartData.reduce((prev, cur) => prev + cur.totalRuns, 0);
      // Divides on the interval instead now (i. e. 20 runs / 14 days)
      const days = convertIntervalToNumber();
      const avg = total / days;
      if (Number.isFinite(avg)) {
        return Math.round(avg * 10) / 10;
      }
    }
    return '0';
  };

  return (
    <Box className={classes.statDataVisualizationBox}>
      <Grid container direction="row">
        <Grid item xs={9} className={classes.statsGridItemChart}>
          <Typography
            className={classes.statsChartHeaderText}
            align="left"
          >
            Integration runs
          </Typography>
          <ResponsiveContainer>
            <AreaChart
              data={lineData}
            >
              <defs>
                <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#60A04C" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#60A04C" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorFailed" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#ff2424" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#ff2424" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorFilter" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0062A4" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#0062A4" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                style={{
                  background: '#EBEDF0',
                  color: '#EBEDF0',
                }}
                vertical={false}
              />
              <XAxis
                xAxisId={0}
                dataKey="name"
                minTickGap={tickGap}
                interval={tickInterval}
                tickFormatter={tickFormatter}
                style={{
                  ...statistics.statsAxisTickText,
                }}
              />
              <YAxis
                allowDecimals={false}
                orientation="right"
                style={{
                  ...statistics.statsAxisTickText,
                }}
                tickCount={7}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                wrapperStyle={{
                  ...statistics.statsChartLegendText,
                }}
              />
              <Area type="monotoneX" dataKey="Total" stackId="t" stroke="#60A04C" fill="url(#colorTotal)" dot={false} />
              <Area type="monotoneX" dataKey="Failed" stackId="f" stroke="#ff2424" fill="url(#colorFailed)" dot={false} />
              <Area type="monotoneX" dataKey="Filtered" stackId="filter" stroke="#0062A4" fill="url(#colorFilter)" dot={false} />
            </AreaChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs>
          <Grid
            container
            className={classes.statsGridItemContainer}
            direction="column"
          >
            <Grid item className={classes.statsGridItemBorder}>
              <Typography className={classes.statsRightSideHeader}>
                Total
              </Typography>
              <Typography className={classes.statsRightSideContent}>
                {otherMetrics?.total ?? 0}
              </Typography>
            </Grid>
            <Grid item className={classes.statsGridItemBorder}>
              <Typography className={classes.statsRightSideHeader}>
                Failed
              </Typography>
              <Typography className={`${classes.statsRightSideContent} ${otherMetrics?.totalFailed > 0 ? classes.statsFailedText : ''}`}>
                {otherMetrics?.totalFailed ?? 0}
              </Typography>
            </Grid>
            <Grid item className={classes.statsGridItemBorder}>
              <Typography className={classes.statsRightSideHeader}>
                Failed %
              </Typography>
              <Typography className={`${classes.statsRightSideContent} ${otherMetrics?.totalFailed > 0 ? classes.statsFailedText : ''}`}>
                {calcFailedPercent()}
              </Typography>
            </Grid>
            <Grid item className={classes.statsGridItemBorder}>
              <Typography className={classes.statsRightSideHeader}>
                Hours since last failed
              </Typography>
              <Typography className={classes.statsRightSideContent}>
                {calcSinceLastFailed()}
              </Typography>
            </Grid>
            <Grid
              item
              className={`${classes.statsGridItemBorder} ${classes.statsGridItemNoBorder}`}
            >
              <Typography className={classes.statsRightSideHeader}>
                Average runs per day
              </Typography>
              <Typography className={classes.statsRightSideContent}>
                {calcAvgPerDay()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

DataVisualization.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string,
      totalFailed: PropTypes.number,
      totalRuns: PropTypes.number,
    }),
  ),
  interval: PropTypes.string,
};

DataVisualization.defaultProps = {
  chartData: [],
  interval: '14:00:00',
};

export default DataVisualization;
