/* tslint:disable */
/* eslint-disable */
/**
 * Miles.Connect.Integration.Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface API
 */
export interface API {
    /**
     * 
     * @type {string}
     * @memberof API
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof API
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof API
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface AdvancedSearchTuple
 */
export interface AdvancedSearchTuple {
    /**
     * 
     * @type {string}
     * @memberof AdvancedSearchTuple
     */
    'field'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdvancedSearchTuple
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiModel
 */
export interface ApiModel {
    /**
     * 
     * @type {string}
     * @memberof ApiModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiModel
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiModel
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface ChartData
 */
export interface ChartData {
    /**
     * 
     * @type {string}
     * @memberof ChartData
     */
    'dateHour'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChartData
     */
    'totalFailed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartData
     */
    'totalFiltered'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartData
     */
    'totalRuns'?: number;
}
/**
 * 
 * @export
 * @interface ChartDataViewModel
 */
export interface ChartDataViewModel {
    /**
     * 
     * @type {string}
     * @memberof ChartDataViewModel
     */
    'start'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChartDataViewModel
     */
    'totalRuns'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartDataViewModel
     */
    'totalFailed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartDataViewModel
     */
    'totalFiltered'?: number;
}
/**
 * 
 * @export
 * @interface CombinedRunViewModel
 */
export interface CombinedRunViewModel {
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'integrationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'integrationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'startedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'finishedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'dataObjectsJson'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CombinedRunViewModel
     */
    'errorCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<RejectedRunDataErrorViewModel>}
     * @memberof CombinedRunViewModel
     */
    'dataErrors'?: Array<RejectedRunDataErrorViewModel> | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'exceptionString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'resolvedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'resolveReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'resolvedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'deadLetterStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'queue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'topic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedRunViewModel
     */
    'subscription'?: string | null;
}
/**
 * 
 * @export
 * @interface ConnectorFlowConfigViewModel
 */
export interface ConnectorFlowConfigViewModel {
    /**
     * 
     * @type {any}
     * @memberof ConnectorFlowConfigViewModel
     */
    'nodes'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ConnectorFlowConfigViewModel
     */
    'edges'?: any | null;
}
/**
 * 
 * @export
 * @interface DataErrorModel
 */
export interface DataErrorModel {
    /**
     * 
     * @type {string}
     * @memberof DataErrorModel
     */
    'fieldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataErrorModel
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataErrorModel
     */
    'expectedValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataErrorModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof DataErrorModel
     */
    'entity'?: any | null;
}
/**
 * 
 * @export
 * @interface DataVisualisationViewModel
 */
export interface DataVisualisationViewModel {
    /**
     * 
     * @type {Array<ChartDataViewModel>}
     * @memberof DataVisualisationViewModel
     */
    'chartData'?: Array<ChartDataViewModel> | null;
}
/**
 * 
 * @export
 * @interface IntegrationDetailViewModel
 */
export interface IntegrationDetailViewModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationDetailViewModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationDetailViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationDetailViewModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {Array<CombinedRunViewModel>}
     * @memberof IntegrationDetailViewModel
     */
    'combinedRuns'?: Array<CombinedRunViewModel> | null;
}
/**
 * 
 * @export
 * @interface IntegrationMetricsViewModel
 */
export interface IntegrationMetricsViewModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationMetricsViewModel
     */
    'integrationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationMetricsViewModel
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationMetricsViewModel
     */
    'totalOk'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationMetricsViewModel
     */
    'totalFailed'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationMetricsViewModel
     */
    'totalResolved'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationMetricsViewModel
     */
    'totalFiltered'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationMetricsViewModel
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof IntegrationMetricsViewModel
     */
    'chartData'?: Array<ChartData> | null;
}
/**
 * 
 * @export
 * @interface IntegrationViewModel
 */
export interface IntegrationViewModel {
    /**
     * 
     * @type {string}
     * @memberof IntegrationViewModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationViewModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationViewModel
     */
    'iconUrl'?: string | null;
    /**
     * 
     * @type {Array<API>}
     * @memberof IntegrationViewModel
     */
    'apis'?: Array<API> | null;
    /**
     * 
     * @type {ServiceBusSettings}
     * @memberof IntegrationViewModel
     */
    'serviceBusSettings'?: ServiceBusSettings;
}
/**
 * 
 * @export
 * @interface QueueRuntimeProperties
 */
export interface QueueRuntimeProperties {
    /**
     * 
     * @type {string}
     * @memberof QueueRuntimeProperties
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QueueRuntimeProperties
     */
    'totalMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueRuntimeProperties
     */
    'activeMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueRuntimeProperties
     */
    'deadLetterMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueRuntimeProperties
     */
    'scheduledMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueRuntimeProperties
     */
    'transferMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueRuntimeProperties
     */
    'transferDeadLetterMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueRuntimeProperties
     */
    'sizeInBytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueueRuntimeProperties
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueRuntimeProperties
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueRuntimeProperties
     */
    'accessedAt'?: string;
}
/**
 * 
 * @export
 * @interface QueueTopicResultModel
 */
export interface QueueTopicResultModel {
    /**
     * 
     * @type {string}
     * @memberof QueueTopicResultModel
     */
    'queueName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QueueTopicResultModel
     */
    'topicName'?: string | null;
}
/**
 * 
 * @export
 * @interface RejectedResultModel
 */
export interface RejectedResultModel {
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'integrationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'startedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'finishedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'dataObjectsJson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'queue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'topic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'subscription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RejectedResultModel
     */
    'errorCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'rejectedMessage'?: string | null;
    /**
     * 
     * @type {Array<DataErrorModel>}
     * @memberof RejectedResultModel
     */
    'dataErrors'?: Array<DataErrorModel> | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedResultModel
     */
    'exceptionString'?: string | null;
}
/**
 * 
 * @export
 * @interface RejectedRunDataErrorViewModel
 */
export interface RejectedRunDataErrorViewModel {
    /**
     * 
     * @type {string}
     * @memberof RejectedRunDataErrorViewModel
     */
    'fieldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedRunDataErrorViewModel
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedRunDataErrorViewModel
     */
    'expectedValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedRunDataErrorViewModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedRunDataErrorViewModel
     */
    'dataObjectJson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejectedRunDataErrorViewModel
     */
    'integrationRunDataErrorCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ResolvedResultModel
 */
export interface ResolvedResultModel {
    /**
     * 
     * @type {string}
     * @memberof ResolvedResultModel
     */
    'integrationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResolvedResultModel
     */
    'startedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResolvedResultModel
     */
    'finishedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResolvedResultModel
     */
    'dataObjectsJson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResolvedResultModel
     */
    'queue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResolvedResultModel
     */
    'topic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResolvedResultModel
     */
    'subscription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResolvedResultModel
     */
    'resolvedMessage'?: string | null;
}
/**
 * 
 * @export
 * @interface SBDeadletterResultModel
 */
export interface SBDeadletterResultModel {
    /**
     * 
     * @type {string}
     * @memberof SBDeadletterResultModel
     */
    'topicName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SBDeadletterResultModel
     */
    'subscriptionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SBDeadletterResultModel
     */
    'queueName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SBDeadletterResultModel
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SBDeadletterResultModel
     */
    'runId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SBDeadletterResultModel
     */
    'keepInDLQ'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SBDeadletterResultModel
     */
    'body'?: string | null;
}
/**
 * 
 * @export
 * @interface ServiceBusDeadLetterMessageVM
 */
export interface ServiceBusDeadLetterMessageVM {
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'body'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'deadLetterReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'deadLetterErrorDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'deliveryCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'enqueuedTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'scheduledEnqueueTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'queue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'topic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusDeadLetterMessageVM
     */
    'subscription'?: string | null;
}
/**
 * 
 * @export
 * @interface ServiceBusSettings
 */
export interface ServiceBusSettings {
    /**
     * 
     * @type {string}
     * @memberof ServiceBusSettings
     */
    'connectionString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusSettings
     */
    'subscriptionName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceBusSettings
     */
    'queueNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceBusSettings
     */
    'topicNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ServiceBusSettingsModel
 */
export interface ServiceBusSettingsModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceBusSettingsModel
     */
    'connectionString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceBusSettingsModel
     */
    'subscriptionName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceBusSettingsModel
     */
    'queueNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceBusSettingsModel
     */
    'topicNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ServiceHostResultModel
 */
export interface ServiceHostResultModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceHostResultModel
     */
    'integrationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHostResultModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHostResultModel
     */
    'integrationIconUrl'?: string | null;
    /**
     * 
     * @type {Array<ApiModel>}
     * @memberof ServiceHostResultModel
     */
    'apis'?: Array<ApiModel> | null;
    /**
     * 
     * @type {ServiceBusSettingsModel}
     * @memberof ServiceHostResultModel
     */
    'serviceBusSettings'?: ServiceBusSettingsModel;
    /**
     * 
     * @type {SourceServiceBusSettingsResultModel}
     * @memberof ServiceHostResultModel
     */
    'sourceServiceBusSettings'?: SourceServiceBusSettingsResultModel;
    /**
     * 
     * @type {TargetServiceBusSettingsResultModel}
     * @memberof ServiceHostResultModel
     */
    'targetServiceBusSettings'?: TargetServiceBusSettingsResultModel;
    /**
     * 
     * @type {string}
     * @memberof ServiceHostResultModel
     */
    'serviceHostUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHostResultModel
     */
    'serviceHostApiKey'?: string | null;
}
/**
 * 
 * @export
 * @interface SourceEdgeServiceBusPropertiesVM
 */
export interface SourceEdgeServiceBusPropertiesVM {
    /**
     * 
     * @type {number}
     * @memberof SourceEdgeServiceBusPropertiesVM
     */
    'totalScheduledMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceEdgeServiceBusPropertiesVM
     */
    'totalSubscriptionCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceEdgeServiceBusPropertiesVM
     */
    'totalActiveMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceEdgeServiceBusPropertiesVM
     */
    'totalDeadLetterMessageCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceEdgeServiceBusPropertiesVM
     */
    'topicErrors'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SourceEdgeServiceBusPropertiesVM
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface SourceOverviewServiceBusPropertiesVM
 */
export interface SourceOverviewServiceBusPropertiesVM {
    /**
     * 
     * @type {Array<QueueRuntimeProperties>}
     * @memberof SourceOverviewServiceBusPropertiesVM
     */
    'queuesProperties'?: Array<QueueRuntimeProperties> | null;
    /**
     * 
     * @type {Array<TopicOverviewPropertiesVM>}
     * @memberof SourceOverviewServiceBusPropertiesVM
     */
    'topicsProperties'?: Array<TopicOverviewPropertiesVM> | null;
    /**
     * 
     * @type {string}
     * @memberof SourceOverviewServiceBusPropertiesVM
     */
    'queueError'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceOverviewServiceBusPropertiesVM
     */
    'topicError'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceOverviewServiceBusPropertiesVM
     */
    'subTopicErrors'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SourceServiceBusSettingsResultModel
 */
export interface SourceServiceBusSettingsResultModel {
    /**
     * 
     * @type {string}
     * @memberof SourceServiceBusSettingsResultModel
     */
    'serviceBusConnectionString'?: string | null;
    /**
     * 
     * @type {Array<QueueTopicResultModel>}
     * @memberof SourceServiceBusSettingsResultModel
     */
    'queueTopicNames'?: Array<QueueTopicResultModel> | null;
}
/**
 * 
 * @export
 * @interface SubscriptionRuntimeProperties
 */
export interface SubscriptionRuntimeProperties {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRuntimeProperties
     */
    'topicName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRuntimeProperties
     */
    'subscriptionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRuntimeProperties
     */
    'totalMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRuntimeProperties
     */
    'activeMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRuntimeProperties
     */
    'deadLetterMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRuntimeProperties
     */
    'transferMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRuntimeProperties
     */
    'transferDeadLetterMessageCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRuntimeProperties
     */
    'accessedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRuntimeProperties
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRuntimeProperties
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface TargetEdgeServiceBusPropertiesVM
 */
export interface TargetEdgeServiceBusPropertiesVM {
    /**
     * 
     * @type {number}
     * @memberof TargetEdgeServiceBusPropertiesVM
     */
    'totalActiveMessageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetEdgeServiceBusPropertiesVM
     */
    'totalDeadLetterMessageCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TargetEdgeServiceBusPropertiesVM
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface TargetOverviewServiceBusPropertiesVM
 */
export interface TargetOverviewServiceBusPropertiesVM {
    /**
     * 
     * @type {Array<SubscriptionRuntimeProperties>}
     * @memberof TargetOverviewServiceBusPropertiesVM
     */
    'subscriptionsProperties'?: Array<SubscriptionRuntimeProperties> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TargetOverviewServiceBusPropertiesVM
     */
    'subTopicErrors'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TargetServiceBusSettingsResultModel
 */
export interface TargetServiceBusSettingsResultModel {
    /**
     * 
     * @type {string}
     * @memberof TargetServiceBusSettingsResultModel
     */
    'serviceBusConnectionString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TargetServiceBusSettingsResultModel
     */
    'subscriptionName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TargetServiceBusSettingsResultModel
     */
    'topicNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'ticks'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'milliseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'minutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMilliseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMinutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalSeconds'?: number;
}
/**
 * 
 * @export
 * @interface TopicOverviewPropertiesVM
 */
export interface TopicOverviewPropertiesVM {
    /**
     * 
     * @type {TopicRuntimeProperties}
     * @memberof TopicOverviewPropertiesVM
     */
    'topicRuntimeProperties'?: TopicRuntimeProperties;
    /**
     * 
     * @type {Array<SubscriptionRuntimeProperties>}
     * @memberof TopicOverviewPropertiesVM
     */
    'subscriptionsRuntimeProperties'?: Array<SubscriptionRuntimeProperties> | null;
}
/**
 * 
 * @export
 * @interface TopicRuntimeProperties
 */
export interface TopicRuntimeProperties {
    /**
     * 
     * @type {string}
     * @memberof TopicRuntimeProperties
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicRuntimeProperties
     */
    'accessedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicRuntimeProperties
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicRuntimeProperties
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TopicRuntimeProperties
     */
    'sizeInBytes'?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicRuntimeProperties
     */
    'subscriptionCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicRuntimeProperties
     */
    'scheduledMessageCount'?: number;
}
/**
 * 
 * @export
 * @interface UpdateRunsResolvedRequest
 */
export interface UpdateRunsResolvedRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRunsResolvedRequest
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRunsResolvedRequest
     */
    'integrationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRunsResolvedRequest
     */
    'resolvedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRunsResolvedRequest
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRunsResolvedRequest
     */
    'resolvedDate'?: string;
}

/**
 * MilesConnectApi - axios parameter creator
 * @export
 */
export const MilesConnectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helloWorld: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a rejected (FAILED) run
         * @param {RejectedResultModel} [rejectedResultModel] The run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRejectedMessage: async (rejectedResultModel?: RejectedResultModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnect/RegisterRejectedMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectedResultModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a resolved (OK) run
         * @param {ResolvedResultModel} [resolvedResultModel] The run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerResolvedMessage: async (resolvedResultModel?: ResolvedResultModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnect/RegisterResolvedMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolvedResultModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers information about an Service Host (integration) in the backend / db
         * @param {ServiceHostResultModel} [serviceHostResultModel] The various information from the Service Host
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerServiceHost: async (serviceHostResultModel?: ServiceHostResultModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnect/RegisterServiceHost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceHostResultModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MilesConnectApi - functional programming interface
 * @export
 */
export const MilesConnectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MilesConnectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helloWorld(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helloWorld(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register a rejected (FAILED) run
         * @param {RejectedResultModel} [rejectedResultModel] The run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerRejectedMessage(rejectedResultModel?: RejectedResultModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerRejectedMessage(rejectedResultModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register a resolved (OK) run
         * @param {ResolvedResultModel} [resolvedResultModel] The run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerResolvedMessage(resolvedResultModel?: ResolvedResultModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerResolvedMessage(resolvedResultModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers information about an Service Host (integration) in the backend / db
         * @param {ServiceHostResultModel} [serviceHostResultModel] The various information from the Service Host
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerServiceHost(serviceHostResultModel?: ServiceHostResultModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerServiceHost(serviceHostResultModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MilesConnectApi - factory interface
 * @export
 */
export const MilesConnectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MilesConnectApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helloWorld(options?: any): AxiosPromise<string> {
            return localVarFp.helloWorld(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a rejected (FAILED) run
         * @param {RejectedResultModel} [rejectedResultModel] The run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRejectedMessage(rejectedResultModel?: RejectedResultModel, options?: any): AxiosPromise<void> {
            return localVarFp.registerRejectedMessage(rejectedResultModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a resolved (OK) run
         * @param {ResolvedResultModel} [resolvedResultModel] The run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerResolvedMessage(resolvedResultModel?: ResolvedResultModel, options?: any): AxiosPromise<void> {
            return localVarFp.registerResolvedMessage(resolvedResultModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers information about an Service Host (integration) in the backend / db
         * @param {ServiceHostResultModel} [serviceHostResultModel] The various information from the Service Host
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerServiceHost(serviceHostResultModel?: ServiceHostResultModel, options?: any): AxiosPromise<void> {
            return localVarFp.registerServiceHost(serviceHostResultModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MilesConnectApi - object-oriented interface
 * @export
 * @class MilesConnectApi
 * @extends {BaseAPI}
 */
export class MilesConnectApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectApi
     */
    public helloWorld(options?: AxiosRequestConfig) {
        return MilesConnectApiFp(this.configuration).helloWorld(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a rejected (FAILED) run
     * @param {RejectedResultModel} [rejectedResultModel] The run
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectApi
     */
    public registerRejectedMessage(rejectedResultModel?: RejectedResultModel, options?: AxiosRequestConfig) {
        return MilesConnectApiFp(this.configuration).registerRejectedMessage(rejectedResultModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a resolved (OK) run
     * @param {ResolvedResultModel} [resolvedResultModel] The run
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectApi
     */
    public registerResolvedMessage(resolvedResultModel?: ResolvedResultModel, options?: AxiosRequestConfig) {
        return MilesConnectApiFp(this.configuration).registerResolvedMessage(resolvedResultModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers information about an Service Host (integration) in the backend / db
     * @param {ServiceHostResultModel} [serviceHostResultModel] The various information from the Service Host
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectApi
     */
    public registerServiceHost(serviceHostResultModel?: ServiceHostResultModel, options?: AxiosRequestConfig) {
        return MilesConnectApiFp(this.configuration).registerServiceHost(serviceHostResultModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MilesConnectUIApi - axios parameter creator
 * @export
 */
export const MilesConnectUIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a deadletter from the DLQ  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {SBDeadletterResultModel} sBDeadletterResultModel Data neccesary to delete deadletters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeadLetter: async (integrationId: string, sBDeadletterResultModel: SBDeadletterResultModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('deleteDeadLetter', 'integrationId', integrationId)
            // verify required parameter 'sBDeadletterResultModel' is not null or undefined
            assertParamExists('deleteDeadLetter', 'sBDeadletterResultModel', sBDeadletterResultModel)
            const localVarPath = `/api/MilesConnectUI/ServiceBus/DeadLetter/{integrationId}/Delete`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sBDeadletterResultModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metrics for the integrations
         * @param {TimeSpan} [interval] The interval for the chartdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIntegrationMetrics: async (interval?: TimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnectUI/integration/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the connector\'s servicehosts
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorData: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getConnectorData', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/connector/{integrationId}/servicehosts`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets connector flow config json data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorFlowConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnectUI/ConnectorFlowConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the deadletter with the messageid from the service bus
         * @param {string} messageId The DL Message ID
         * @param {string} integrationId The id of the integration that ran
         * @param {string} [queue] An optional queue to search for the DL
         * @param {string} [topic] An optional topic to search for the DL. Needs to be combined with subscription!
         * @param {string} [subscription] An optional subscription to search for the DL. Needs to be combined with topic!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeadLetterMessage: async (messageId: string, integrationId: string, queue?: string, topic?: string, subscription?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getDeadLetterMessage', 'messageId', messageId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getDeadLetterMessage', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/ServiceBus/DeadLetter/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (integrationId !== undefined) {
                localVarQueryParameter['integrationId'] = integrationId;
            }

            if (queue !== undefined) {
                localVarQueryParameter['queue'] = queue;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (subscription !== undefined) {
                localVarQueryParameter['subscription'] = subscription;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the integration-entities, given an ID
         * @param {string} integrationId The id for a given integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getIntegration', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/Integration/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data to be used for data visualization
         * @param {string} integrationName The name for a given integration
         * @param {TimeSpan} [interval] The interval for the graph data (the last x hours/days/minutes) -&gt; does not affect the total ok / failed!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationDataVisualization: async (integrationName: string, interval?: TimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationName' is not null or undefined
            assertParamExists('getIntegrationDataVisualization', 'integrationName', integrationName)
            const localVarPath = `/api/MilesConnectUI/integration/{integrationName}/datavisualization`
                .replace(`{${"integrationName"}}`, encodeURIComponent(String(integrationName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metrics for the specified integration
         * @param {string} integrationName The integration name
         * @param {TimeSpan} [interval] The interval for the chartdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationMetrics: async (integrationName: string, interval?: TimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationName' is not null or undefined
            assertParamExists('getIntegrationMetrics', 'integrationName', integrationName)
            const localVarPath = `/api/MilesConnectUI/integration/{integrationName}/metrics`
                .replace(`{${"integrationName"}}`, encodeURIComponent(String(integrationName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns every integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnectUI/integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the integration entities (Both errors and successes) for a given integration
         * @param {string} integrationId 
         * @param {string} [dateStart] 
         * @param {string} [dateEnd] 
         * @param {number} [errorCode] 
         * @param {Array<string>} [status] 
         * @param {string} [searchString] 
         * @param {boolean} [onlyDeadletters] 
         * @param {Array<AdvancedSearchTuple>} [advancedSearch] 
         * @param {Array<string>} [advancedSearchFields] 
         * @param {Array<string>} [advancedSearchValues] 
         * @param {boolean} [advancedSearchMode] 
         * @param {string} [sortBy] 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfRuns: async (integrationId: string, dateStart?: string, dateEnd?: string, errorCode?: number, status?: Array<string>, searchString?: string, onlyDeadletters?: boolean, advancedSearch?: Array<AdvancedSearchTuple>, advancedSearchFields?: Array<string>, advancedSearchValues?: Array<string>, advancedSearchMode?: boolean, sortBy?: string, ascending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getNumberOfRuns', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/integration/{integrationId}/numberOfRunEntities`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateStart !== undefined) {
                localVarQueryParameter['DateStart'] = (dateStart as any instanceof Date) ?
                    (dateStart as any).toISOString() :
                    dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['DateEnd'] = (dateEnd as any instanceof Date) ?
                    (dateEnd as any).toISOString() :
                    dateEnd;
            }

            if (errorCode !== undefined) {
                localVarQueryParameter['ErrorCode'] = errorCode;
            }

            if (status) {
                localVarQueryParameter['Status'] = status;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['SearchString'] = searchString;
            }

            if (onlyDeadletters !== undefined) {
                localVarQueryParameter['OnlyDeadletters'] = onlyDeadletters;
            }

            if (advancedSearch) {
                localVarQueryParameter['AdvancedSearch'] = advancedSearch;
            }

            if (advancedSearchFields) {
                localVarQueryParameter['AdvancedSearchFields'] = advancedSearchFields;
            }

            if (advancedSearchValues) {
                localVarQueryParameter['AdvancedSearchValues'] = advancedSearchValues;
            }

            if (advancedSearchMode !== undefined) {
                localVarQueryParameter['AdvancedSearchMode'] = advancedSearchMode;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (ascending !== undefined) {
                localVarQueryParameter['Ascending'] = ascending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a paginated result for integration entities for an integrations
         * @param {string} integrationId 
         * @param {number} [offset] 
         * @param {number} [count] 
         * @param {string} [dateStart] 
         * @param {string} [dateEnd] 
         * @param {number} [errorCode] 
         * @param {Array<string>} [status] 
         * @param {string} [searchString] 
         * @param {boolean} [onlyDeadletters] 
         * @param {Array<AdvancedSearchTuple>} [advancedSearch] 
         * @param {Array<string>} [advancedSearchFields] 
         * @param {Array<string>} [advancedSearchValues] 
         * @param {boolean} [advancedSearchMode] 
         * @param {string} [sortBy] 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedSearchResult: async (integrationId: string, offset?: number, count?: number, dateStart?: string, dateEnd?: string, errorCode?: number, status?: Array<string>, searchString?: string, onlyDeadletters?: boolean, advancedSearch?: Array<AdvancedSearchTuple>, advancedSearchFields?: Array<string>, advancedSearchValues?: Array<string>, advancedSearchMode?: boolean, sortBy?: string, ascending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getPaginatedSearchResult', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/Integration/search/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['DateStart'] = (dateStart as any instanceof Date) ?
                    (dateStart as any).toISOString() :
                    dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['DateEnd'] = (dateEnd as any instanceof Date) ?
                    (dateEnd as any).toISOString() :
                    dateEnd;
            }

            if (errorCode !== undefined) {
                localVarQueryParameter['ErrorCode'] = errorCode;
            }

            if (status) {
                localVarQueryParameter['Status'] = status;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['SearchString'] = searchString;
            }

            if (onlyDeadletters !== undefined) {
                localVarQueryParameter['OnlyDeadletters'] = onlyDeadletters;
            }

            if (advancedSearch) {
                localVarQueryParameter['AdvancedSearch'] = advancedSearch;
            }

            if (advancedSearchFields) {
                localVarQueryParameter['AdvancedSearchFields'] = advancedSearchFields;
            }

            if (advancedSearchValues) {
                localVarQueryParameter['AdvancedSearchValues'] = advancedSearchValues;
            }

            if (advancedSearchMode !== undefined) {
                localVarQueryParameter['AdvancedSearchMode'] = advancedSearchMode;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (ascending !== undefined) {
                localVarQueryParameter['Ascending'] = ascending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves deadletters from a servicebus using the queuename  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {string} queueName The service bus queue name
         * @param {number} [maxMessages] The number of messages to try to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueDeadLetters: async (integrationId: string, queueName: string, maxMessages?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getQueueDeadLetters', 'integrationId', integrationId)
            // verify required parameter 'queueName' is not null or undefined
            assertParamExists('getQueueDeadLetters', 'queueName', queueName)
            const localVarPath = `/api/MilesConnectUI/ServiceBus/Queue/DeadLetters/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (queueName !== undefined) {
                localVarQueryParameter['queueName'] = queueName;
            }

            if (maxMessages !== undefined) {
                localVarQueryParameter['maxMessages'] = maxMessages;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a run
         * @param {string} runId The id for a given run
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRun: async (runId: string, integrationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('getRun', 'runId', runId)
            const localVarPath = `/api/MilesConnectUI/Run/{runId}`
                .replace(`{${"runId"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (integrationId !== undefined) {
                localVarQueryParameter['integrationId'] = integrationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets source servicebusproperties for the edges on the front page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceEdgeServiceBusProperties: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getSourceEdgeServiceBusProperties', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/ServiceBusProperties/Edge/Source/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets source servicebusproperties for the overview page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceOverviewServiceBusProperties: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getSourceOverviewServiceBusProperties', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/ServiceBusProperties/Overview/Source/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves deadletters from a servicebus using the topicname and subscriptionname pair  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {string} topicName The service bus topic name
         * @param {string} subscriptionName The service bus subscription name (under the topic)
         * @param {number} [maxMessages] The number of messages to try to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionDeadLetters: async (integrationId: string, topicName: string, subscriptionName: string, maxMessages?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getSubscriptionDeadLetters', 'integrationId', integrationId)
            // verify required parameter 'topicName' is not null or undefined
            assertParamExists('getSubscriptionDeadLetters', 'topicName', topicName)
            // verify required parameter 'subscriptionName' is not null or undefined
            assertParamExists('getSubscriptionDeadLetters', 'subscriptionName', subscriptionName)
            const localVarPath = `/api/MilesConnectUI/ServiceBus/Subscription/DeadLetters/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (topicName !== undefined) {
                localVarQueryParameter['topicName'] = topicName;
            }

            if (subscriptionName !== undefined) {
                localVarQueryParameter['subscriptionName'] = subscriptionName;
            }

            if (maxMessages !== undefined) {
                localVarQueryParameter['maxMessages'] = maxMessages;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets target servicebusproperties for the edges on the front page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetEdgeServiceBusProperties: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getTargetEdgeServiceBusProperties', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/ServiceBusProperties/Edge/Target/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets target servicebusproperties for the overview page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetOverviewServiceBusProperties: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getTargetOverviewServiceBusProperties', 'integrationId', integrationId)
            const localVarPath = `/api/MilesConnectUI/ServiceBusProperties/Overview/Target/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resends one or more deadletters from the DLQ  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {SBDeadletterResultModel} sBDeadletterResultModel Data neccesary to resend deadletters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendDeadLetter: async (integrationId: string, sBDeadletterResultModel: SBDeadletterResultModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('resendDeadLetter', 'integrationId', integrationId)
            // verify required parameter 'sBDeadletterResultModel' is not null or undefined
            assertParamExists('resendDeadLetter', 'sBDeadletterResultModel', sBDeadletterResultModel)
            const localVarPath = `/api/MilesConnectUI/ServiceBus/DeadLetter/{integrationId}/Resend`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sBDeadletterResultModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Runs a connector service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConnectorService: async (integrationId: string, serviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('runConnectorService', 'integrationId', integrationId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('runConnectorService', 'serviceId', serviceId)
            const localVarPath = `/api/MilesConnectUI/connector/{integrationId}/service/{serviceId}/run`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Runs a connector task
         * @param {string} integrationId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConnectorTask: async (integrationId: string, taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('runConnectorTask', 'integrationId', integrationId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('runConnectorTask', 'taskId', taskId)
            const localVarPath = `/api/MilesConnectUI/connector/{integrationId}/task/{taskId}/run`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts a connecter service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startConnectorService: async (integrationId: string, serviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('startConnectorService', 'integrationId', integrationId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('startConnectorService', 'serviceId', serviceId)
            const localVarPath = `/api/MilesConnectUI/connector/{integrationId}/service/{serviceId}/start`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stops a connector service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopConnectorService: async (integrationId: string, serviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('stopConnectorService', 'integrationId', integrationId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('stopConnectorService', 'serviceId', serviceId)
            const localVarPath = `/api/MilesConnectUI/connector/{integrationId}/service/{serviceId}/stop`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates (or creates if none exist) the connector flow configuration json data
         * @param {ConnectorFlowConfigViewModel} [connectorFlowConfigViewModel] The connector flow config to update in json (from the body)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectorFlowConfig: async (connectorFlowConfigViewModel?: ConnectorFlowConfigViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnectUI/ConnectorFlowConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectorFlowConfigViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Will resolve one or more runs (or update the info on already resolved runs)
         * @param {UpdateRunsResolvedRequest} [updateRunsResolvedRequest] Object containing list of run id\&#39;s, name of who resolved the runs, a reason and a date for when the runs were resolved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRunResolved: async (updateRunsResolvedRequest?: UpdateRunsResolvedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MilesConnectUI/run/updateResolved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRunsResolvedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MilesConnectUIApi - functional programming interface
 * @export
 */
export const MilesConnectUIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MilesConnectUIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a deadletter from the DLQ  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {SBDeadletterResultModel} sBDeadletterResultModel Data neccesary to delete deadletters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeadLetter(integrationId: string, sBDeadletterResultModel: SBDeadletterResultModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeadLetter(integrationId, sBDeadletterResultModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get metrics for the integrations
         * @param {TimeSpan} [interval] The interval for the chartdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllIntegrationMetrics(interval?: TimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationMetricsViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllIntegrationMetrics(interval, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the connector\'s servicehosts
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorData(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorData(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets connector flow config json data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorFlowConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectorFlowConfigViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorFlowConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the deadletter with the messageid from the service bus
         * @param {string} messageId The DL Message ID
         * @param {string} integrationId The id of the integration that ran
         * @param {string} [queue] An optional queue to search for the DL
         * @param {string} [topic] An optional topic to search for the DL. Needs to be combined with subscription!
         * @param {string} [subscription] An optional subscription to search for the DL. Needs to be combined with topic!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeadLetterMessage(messageId: string, integrationId: string, queue?: string, topic?: string, subscription?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceBusDeadLetterMessageVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeadLetterMessage(messageId, integrationId, queue, topic, subscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the integration-entities, given an ID
         * @param {string} integrationId The id for a given integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegration(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationDetailViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegration(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns data to be used for data visualization
         * @param {string} integrationName The name for a given integration
         * @param {TimeSpan} [interval] The interval for the graph data (the last x hours/days/minutes) -&gt; does not affect the total ok / failed!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationDataVisualization(integrationName: string, interval?: TimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataVisualisationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationDataVisualization(integrationName, interval, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get metrics for the specified integration
         * @param {string} integrationName The integration name
         * @param {TimeSpan} [interval] The interval for the chartdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationMetrics(integrationName: string, interval?: TimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationMetricsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationMetrics(integrationName, interval, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns every integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the integration entities (Both errors and successes) for a given integration
         * @param {string} integrationId 
         * @param {string} [dateStart] 
         * @param {string} [dateEnd] 
         * @param {number} [errorCode] 
         * @param {Array<string>} [status] 
         * @param {string} [searchString] 
         * @param {boolean} [onlyDeadletters] 
         * @param {Array<AdvancedSearchTuple>} [advancedSearch] 
         * @param {Array<string>} [advancedSearchFields] 
         * @param {Array<string>} [advancedSearchValues] 
         * @param {boolean} [advancedSearchMode] 
         * @param {string} [sortBy] 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNumberOfRuns(integrationId: string, dateStart?: string, dateEnd?: string, errorCode?: number, status?: Array<string>, searchString?: string, onlyDeadletters?: boolean, advancedSearch?: Array<AdvancedSearchTuple>, advancedSearchFields?: Array<string>, advancedSearchValues?: Array<string>, advancedSearchMode?: boolean, sortBy?: string, ascending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNumberOfRuns(integrationId, dateStart, dateEnd, errorCode, status, searchString, onlyDeadletters, advancedSearch, advancedSearchFields, advancedSearchValues, advancedSearchMode, sortBy, ascending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a paginated result for integration entities for an integrations
         * @param {string} integrationId 
         * @param {number} [offset] 
         * @param {number} [count] 
         * @param {string} [dateStart] 
         * @param {string} [dateEnd] 
         * @param {number} [errorCode] 
         * @param {Array<string>} [status] 
         * @param {string} [searchString] 
         * @param {boolean} [onlyDeadletters] 
         * @param {Array<AdvancedSearchTuple>} [advancedSearch] 
         * @param {Array<string>} [advancedSearchFields] 
         * @param {Array<string>} [advancedSearchValues] 
         * @param {boolean} [advancedSearchMode] 
         * @param {string} [sortBy] 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedSearchResult(integrationId: string, offset?: number, count?: number, dateStart?: string, dateEnd?: string, errorCode?: number, status?: Array<string>, searchString?: string, onlyDeadletters?: boolean, advancedSearch?: Array<AdvancedSearchTuple>, advancedSearchFields?: Array<string>, advancedSearchValues?: Array<string>, advancedSearchMode?: boolean, sortBy?: string, ascending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationDetailViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedSearchResult(integrationId, offset, count, dateStart, dateEnd, errorCode, status, searchString, onlyDeadletters, advancedSearch, advancedSearchFields, advancedSearchValues, advancedSearchMode, sortBy, ascending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves deadletters from a servicebus using the queuename  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {string} queueName The service bus queue name
         * @param {number} [maxMessages] The number of messages to try to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQueueDeadLetters(integrationId: string, queueName: string, maxMessages?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceBusDeadLetterMessageVM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQueueDeadLetters(integrationId, queueName, maxMessages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a run
         * @param {string} runId The id for a given run
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRun(runId: string, integrationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CombinedRunViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRun(runId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets source servicebusproperties for the edges on the front page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourceEdgeServiceBusProperties(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceEdgeServiceBusPropertiesVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourceEdgeServiceBusProperties(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets source servicebusproperties for the overview page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourceOverviewServiceBusProperties(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceOverviewServiceBusPropertiesVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourceOverviewServiceBusProperties(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves deadletters from a servicebus using the topicname and subscriptionname pair  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {string} topicName The service bus topic name
         * @param {string} subscriptionName The service bus subscription name (under the topic)
         * @param {number} [maxMessages] The number of messages to try to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionDeadLetters(integrationId: string, topicName: string, subscriptionName: string, maxMessages?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceBusDeadLetterMessageVM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionDeadLetters(integrationId, topicName, subscriptionName, maxMessages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets target servicebusproperties for the edges on the front page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetEdgeServiceBusProperties(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TargetEdgeServiceBusPropertiesVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetEdgeServiceBusProperties(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets target servicebusproperties for the overview page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetOverviewServiceBusProperties(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TargetOverviewServiceBusPropertiesVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetOverviewServiceBusProperties(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resends one or more deadletters from the DLQ  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {SBDeadletterResultModel} sBDeadletterResultModel Data neccesary to resend deadletters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendDeadLetter(integrationId: string, sBDeadletterResultModel: SBDeadletterResultModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendDeadLetter(integrationId, sBDeadletterResultModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Runs a connector service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runConnectorService(integrationId: string, serviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runConnectorService(integrationId, serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Runs a connector task
         * @param {string} integrationId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runConnectorTask(integrationId: string, taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runConnectorTask(integrationId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts a connecter service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startConnectorService(integrationId: string, serviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startConnectorService(integrationId, serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stops a connector service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopConnectorService(integrationId: string, serviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopConnectorService(integrationId, serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates (or creates if none exist) the connector flow configuration json data
         * @param {ConnectorFlowConfigViewModel} [connectorFlowConfigViewModel] The connector flow config to update in json (from the body)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectorFlowConfig(connectorFlowConfigViewModel?: ConnectorFlowConfigViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectorFlowConfig(connectorFlowConfigViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Will resolve one or more runs (or update the info on already resolved runs)
         * @param {UpdateRunsResolvedRequest} [updateRunsResolvedRequest] Object containing list of run id\&#39;s, name of who resolved the runs, a reason and a date for when the runs were resolved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRunResolved(updateRunsResolvedRequest?: UpdateRunsResolvedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRunResolved(updateRunsResolvedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MilesConnectUIApi - factory interface
 * @export
 */
export const MilesConnectUIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MilesConnectUIApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a deadletter from the DLQ  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {SBDeadletterResultModel} sBDeadletterResultModel Data neccesary to delete deadletters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeadLetter(integrationId: string, sBDeadletterResultModel: SBDeadletterResultModel, options?: any): AxiosPromise<string> {
            return localVarFp.deleteDeadLetter(integrationId, sBDeadletterResultModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metrics for the integrations
         * @param {TimeSpan} [interval] The interval for the chartdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIntegrationMetrics(interval?: TimeSpan, options?: any): AxiosPromise<Array<IntegrationMetricsViewModel>> {
            return localVarFp.getAllIntegrationMetrics(interval, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the connector\'s servicehosts
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorData(integrationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getConnectorData(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets connector flow config json data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorFlowConfig(options?: any): AxiosPromise<ConnectorFlowConfigViewModel> {
            return localVarFp.getConnectorFlowConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the deadletter with the messageid from the service bus
         * @param {string} messageId The DL Message ID
         * @param {string} integrationId The id of the integration that ran
         * @param {string} [queue] An optional queue to search for the DL
         * @param {string} [topic] An optional topic to search for the DL. Needs to be combined with subscription!
         * @param {string} [subscription] An optional subscription to search for the DL. Needs to be combined with topic!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeadLetterMessage(messageId: string, integrationId: string, queue?: string, topic?: string, subscription?: string, options?: any): AxiosPromise<ServiceBusDeadLetterMessageVM> {
            return localVarFp.getDeadLetterMessage(messageId, integrationId, queue, topic, subscription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the integration-entities, given an ID
         * @param {string} integrationId The id for a given integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(integrationId: string, options?: any): AxiosPromise<IntegrationDetailViewModel> {
            return localVarFp.getIntegration(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data to be used for data visualization
         * @param {string} integrationName The name for a given integration
         * @param {TimeSpan} [interval] The interval for the graph data (the last x hours/days/minutes) -&gt; does not affect the total ok / failed!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationDataVisualization(integrationName: string, interval?: TimeSpan, options?: any): AxiosPromise<DataVisualisationViewModel> {
            return localVarFp.getIntegrationDataVisualization(integrationName, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metrics for the specified integration
         * @param {string} integrationName The integration name
         * @param {TimeSpan} [interval] The interval for the chartdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationMetrics(integrationName: string, interval?: TimeSpan, options?: any): AxiosPromise<IntegrationMetricsViewModel> {
            return localVarFp.getIntegrationMetrics(integrationName, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns every integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrations(options?: any): AxiosPromise<Array<IntegrationViewModel>> {
            return localVarFp.getIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the integration entities (Both errors and successes) for a given integration
         * @param {string} integrationId 
         * @param {string} [dateStart] 
         * @param {string} [dateEnd] 
         * @param {number} [errorCode] 
         * @param {Array<string>} [status] 
         * @param {string} [searchString] 
         * @param {boolean} [onlyDeadletters] 
         * @param {Array<AdvancedSearchTuple>} [advancedSearch] 
         * @param {Array<string>} [advancedSearchFields] 
         * @param {Array<string>} [advancedSearchValues] 
         * @param {boolean} [advancedSearchMode] 
         * @param {string} [sortBy] 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfRuns(integrationId: string, dateStart?: string, dateEnd?: string, errorCode?: number, status?: Array<string>, searchString?: string, onlyDeadletters?: boolean, advancedSearch?: Array<AdvancedSearchTuple>, advancedSearchFields?: Array<string>, advancedSearchValues?: Array<string>, advancedSearchMode?: boolean, sortBy?: string, ascending?: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.getNumberOfRuns(integrationId, dateStart, dateEnd, errorCode, status, searchString, onlyDeadletters, advancedSearch, advancedSearchFields, advancedSearchValues, advancedSearchMode, sortBy, ascending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a paginated result for integration entities for an integrations
         * @param {string} integrationId 
         * @param {number} [offset] 
         * @param {number} [count] 
         * @param {string} [dateStart] 
         * @param {string} [dateEnd] 
         * @param {number} [errorCode] 
         * @param {Array<string>} [status] 
         * @param {string} [searchString] 
         * @param {boolean} [onlyDeadletters] 
         * @param {Array<AdvancedSearchTuple>} [advancedSearch] 
         * @param {Array<string>} [advancedSearchFields] 
         * @param {Array<string>} [advancedSearchValues] 
         * @param {boolean} [advancedSearchMode] 
         * @param {string} [sortBy] 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedSearchResult(integrationId: string, offset?: number, count?: number, dateStart?: string, dateEnd?: string, errorCode?: number, status?: Array<string>, searchString?: string, onlyDeadletters?: boolean, advancedSearch?: Array<AdvancedSearchTuple>, advancedSearchFields?: Array<string>, advancedSearchValues?: Array<string>, advancedSearchMode?: boolean, sortBy?: string, ascending?: boolean, options?: any): AxiosPromise<IntegrationDetailViewModel> {
            return localVarFp.getPaginatedSearchResult(integrationId, offset, count, dateStart, dateEnd, errorCode, status, searchString, onlyDeadletters, advancedSearch, advancedSearchFields, advancedSearchValues, advancedSearchMode, sortBy, ascending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves deadletters from a servicebus using the queuename  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {string} queueName The service bus queue name
         * @param {number} [maxMessages] The number of messages to try to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueDeadLetters(integrationId: string, queueName: string, maxMessages?: number, options?: any): AxiosPromise<Array<ServiceBusDeadLetterMessageVM>> {
            return localVarFp.getQueueDeadLetters(integrationId, queueName, maxMessages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a run
         * @param {string} runId The id for a given run
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRun(runId: string, integrationId?: string, options?: any): AxiosPromise<CombinedRunViewModel> {
            return localVarFp.getRun(runId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets source servicebusproperties for the edges on the front page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceEdgeServiceBusProperties(integrationId: string, options?: any): AxiosPromise<SourceEdgeServiceBusPropertiesVM> {
            return localVarFp.getSourceEdgeServiceBusProperties(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets source servicebusproperties for the overview page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceOverviewServiceBusProperties(integrationId: string, options?: any): AxiosPromise<SourceOverviewServiceBusPropertiesVM> {
            return localVarFp.getSourceOverviewServiceBusProperties(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves deadletters from a servicebus using the topicname and subscriptionname pair  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {string} topicName The service bus topic name
         * @param {string} subscriptionName The service bus subscription name (under the topic)
         * @param {number} [maxMessages] The number of messages to try to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionDeadLetters(integrationId: string, topicName: string, subscriptionName: string, maxMessages?: number, options?: any): AxiosPromise<Array<ServiceBusDeadLetterMessageVM>> {
            return localVarFp.getSubscriptionDeadLetters(integrationId, topicName, subscriptionName, maxMessages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets target servicebusproperties for the edges on the front page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetEdgeServiceBusProperties(integrationId: string, options?: any): AxiosPromise<TargetEdgeServiceBusPropertiesVM> {
            return localVarFp.getTargetEdgeServiceBusProperties(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets target servicebusproperties for the overview page  <br>Need to already exist settings/properties on the given integration
         * @param {string} integrationId The integration id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetOverviewServiceBusProperties(integrationId: string, options?: any): AxiosPromise<TargetOverviewServiceBusPropertiesVM> {
            return localVarFp.getTargetOverviewServiceBusProperties(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resends one or more deadletters from the DLQ  <br>The servicebus connectionstring needs to be saved on the integration already
         * @param {string} integrationId The integration id
         * @param {SBDeadletterResultModel} sBDeadletterResultModel Data neccesary to resend deadletters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendDeadLetter(integrationId: string, sBDeadletterResultModel: SBDeadletterResultModel, options?: any): AxiosPromise<string> {
            return localVarFp.resendDeadLetter(integrationId, sBDeadletterResultModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Runs a connector service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConnectorService(integrationId: string, serviceId: string, options?: any): AxiosPromise<string> {
            return localVarFp.runConnectorService(integrationId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Runs a connector task
         * @param {string} integrationId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConnectorTask(integrationId: string, taskId: string, options?: any): AxiosPromise<string> {
            return localVarFp.runConnectorTask(integrationId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts a connecter service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startConnectorService(integrationId: string, serviceId: string, options?: any): AxiosPromise<string> {
            return localVarFp.startConnectorService(integrationId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stops a connector service
         * @param {string} integrationId 
         * @param {string} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopConnectorService(integrationId: string, serviceId: string, options?: any): AxiosPromise<string> {
            return localVarFp.stopConnectorService(integrationId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates (or creates if none exist) the connector flow configuration json data
         * @param {ConnectorFlowConfigViewModel} [connectorFlowConfigViewModel] The connector flow config to update in json (from the body)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectorFlowConfig(connectorFlowConfigViewModel?: ConnectorFlowConfigViewModel, options?: any): AxiosPromise<string> {
            return localVarFp.updateConnectorFlowConfig(connectorFlowConfigViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Will resolve one or more runs (or update the info on already resolved runs)
         * @param {UpdateRunsResolvedRequest} [updateRunsResolvedRequest] Object containing list of run id\&#39;s, name of who resolved the runs, a reason and a date for when the runs were resolved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRunResolved(updateRunsResolvedRequest?: UpdateRunsResolvedRequest, options?: any): AxiosPromise<string> {
            return localVarFp.updateRunResolved(updateRunsResolvedRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MilesConnectUIApi - object-oriented interface
 * @export
 * @class MilesConnectUIApi
 * @extends {BaseAPI}
 */
export class MilesConnectUIApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a deadletter from the DLQ  <br>The servicebus connectionstring needs to be saved on the integration already
     * @param {string} integrationId The integration id
     * @param {SBDeadletterResultModel} sBDeadletterResultModel Data neccesary to delete deadletters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public deleteDeadLetter(integrationId: string, sBDeadletterResultModel: SBDeadletterResultModel, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).deleteDeadLetter(integrationId, sBDeadletterResultModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metrics for the integrations
     * @param {TimeSpan} [interval] The interval for the chartdata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getAllIntegrationMetrics(interval?: TimeSpan, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getAllIntegrationMetrics(interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the connector\'s servicehosts
     * @param {string} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getConnectorData(integrationId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getConnectorData(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets connector flow config json data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getConnectorFlowConfig(options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getConnectorFlowConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the deadletter with the messageid from the service bus
     * @param {string} messageId The DL Message ID
     * @param {string} integrationId The id of the integration that ran
     * @param {string} [queue] An optional queue to search for the DL
     * @param {string} [topic] An optional topic to search for the DL. Needs to be combined with subscription!
     * @param {string} [subscription] An optional subscription to search for the DL. Needs to be combined with topic!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getDeadLetterMessage(messageId: string, integrationId: string, queue?: string, topic?: string, subscription?: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getDeadLetterMessage(messageId, integrationId, queue, topic, subscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the integration-entities, given an ID
     * @param {string} integrationId The id for a given integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getIntegration(integrationId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getIntegration(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data to be used for data visualization
     * @param {string} integrationName The name for a given integration
     * @param {TimeSpan} [interval] The interval for the graph data (the last x hours/days/minutes) -&gt; does not affect the total ok / failed!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getIntegrationDataVisualization(integrationName: string, interval?: TimeSpan, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getIntegrationDataVisualization(integrationName, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metrics for the specified integration
     * @param {string} integrationName The integration name
     * @param {TimeSpan} [interval] The interval for the chartdata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getIntegrationMetrics(integrationName: string, interval?: TimeSpan, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getIntegrationMetrics(integrationName, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns every integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getIntegrations(options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getIntegrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the integration entities (Both errors and successes) for a given integration
     * @param {string} integrationId 
     * @param {string} [dateStart] 
     * @param {string} [dateEnd] 
     * @param {number} [errorCode] 
     * @param {Array<string>} [status] 
     * @param {string} [searchString] 
     * @param {boolean} [onlyDeadletters] 
     * @param {Array<AdvancedSearchTuple>} [advancedSearch] 
     * @param {Array<string>} [advancedSearchFields] 
     * @param {Array<string>} [advancedSearchValues] 
     * @param {boolean} [advancedSearchMode] 
     * @param {string} [sortBy] 
     * @param {boolean} [ascending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getNumberOfRuns(integrationId: string, dateStart?: string, dateEnd?: string, errorCode?: number, status?: Array<string>, searchString?: string, onlyDeadletters?: boolean, advancedSearch?: Array<AdvancedSearchTuple>, advancedSearchFields?: Array<string>, advancedSearchValues?: Array<string>, advancedSearchMode?: boolean, sortBy?: string, ascending?: boolean, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getNumberOfRuns(integrationId, dateStart, dateEnd, errorCode, status, searchString, onlyDeadletters, advancedSearch, advancedSearchFields, advancedSearchValues, advancedSearchMode, sortBy, ascending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a paginated result for integration entities for an integrations
     * @param {string} integrationId 
     * @param {number} [offset] 
     * @param {number} [count] 
     * @param {string} [dateStart] 
     * @param {string} [dateEnd] 
     * @param {number} [errorCode] 
     * @param {Array<string>} [status] 
     * @param {string} [searchString] 
     * @param {boolean} [onlyDeadletters] 
     * @param {Array<AdvancedSearchTuple>} [advancedSearch] 
     * @param {Array<string>} [advancedSearchFields] 
     * @param {Array<string>} [advancedSearchValues] 
     * @param {boolean} [advancedSearchMode] 
     * @param {string} [sortBy] 
     * @param {boolean} [ascending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getPaginatedSearchResult(integrationId: string, offset?: number, count?: number, dateStart?: string, dateEnd?: string, errorCode?: number, status?: Array<string>, searchString?: string, onlyDeadletters?: boolean, advancedSearch?: Array<AdvancedSearchTuple>, advancedSearchFields?: Array<string>, advancedSearchValues?: Array<string>, advancedSearchMode?: boolean, sortBy?: string, ascending?: boolean, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getPaginatedSearchResult(integrationId, offset, count, dateStart, dateEnd, errorCode, status, searchString, onlyDeadletters, advancedSearch, advancedSearchFields, advancedSearchValues, advancedSearchMode, sortBy, ascending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves deadletters from a servicebus using the queuename  <br>The servicebus connectionstring needs to be saved on the integration already
     * @param {string} integrationId The integration id
     * @param {string} queueName The service bus queue name
     * @param {number} [maxMessages] The number of messages to try to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getQueueDeadLetters(integrationId: string, queueName: string, maxMessages?: number, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getQueueDeadLetters(integrationId, queueName, maxMessages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a run
     * @param {string} runId The id for a given run
     * @param {string} [integrationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getRun(runId: string, integrationId?: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getRun(runId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets source servicebusproperties for the edges on the front page  <br>Need to already exist settings/properties on the given integration
     * @param {string} integrationId The integration id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getSourceEdgeServiceBusProperties(integrationId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getSourceEdgeServiceBusProperties(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets source servicebusproperties for the overview page  <br>Need to already exist settings/properties on the given integration
     * @param {string} integrationId The integration id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getSourceOverviewServiceBusProperties(integrationId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getSourceOverviewServiceBusProperties(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves deadletters from a servicebus using the topicname and subscriptionname pair  <br>The servicebus connectionstring needs to be saved on the integration already
     * @param {string} integrationId The integration id
     * @param {string} topicName The service bus topic name
     * @param {string} subscriptionName The service bus subscription name (under the topic)
     * @param {number} [maxMessages] The number of messages to try to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getSubscriptionDeadLetters(integrationId: string, topicName: string, subscriptionName: string, maxMessages?: number, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getSubscriptionDeadLetters(integrationId, topicName, subscriptionName, maxMessages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets target servicebusproperties for the edges on the front page  <br>Need to already exist settings/properties on the given integration
     * @param {string} integrationId The integration id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getTargetEdgeServiceBusProperties(integrationId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getTargetEdgeServiceBusProperties(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets target servicebusproperties for the overview page  <br>Need to already exist settings/properties on the given integration
     * @param {string} integrationId The integration id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public getTargetOverviewServiceBusProperties(integrationId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).getTargetOverviewServiceBusProperties(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resends one or more deadletters from the DLQ  <br>The servicebus connectionstring needs to be saved on the integration already
     * @param {string} integrationId The integration id
     * @param {SBDeadletterResultModel} sBDeadletterResultModel Data neccesary to resend deadletters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public resendDeadLetter(integrationId: string, sBDeadletterResultModel: SBDeadletterResultModel, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).resendDeadLetter(integrationId, sBDeadletterResultModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Runs a connector service
     * @param {string} integrationId 
     * @param {string} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public runConnectorService(integrationId: string, serviceId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).runConnectorService(integrationId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Runs a connector task
     * @param {string} integrationId 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public runConnectorTask(integrationId: string, taskId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).runConnectorTask(integrationId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts a connecter service
     * @param {string} integrationId 
     * @param {string} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public startConnectorService(integrationId: string, serviceId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).startConnectorService(integrationId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stops a connector service
     * @param {string} integrationId 
     * @param {string} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public stopConnectorService(integrationId: string, serviceId: string, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).stopConnectorService(integrationId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates (or creates if none exist) the connector flow configuration json data
     * @param {ConnectorFlowConfigViewModel} [connectorFlowConfigViewModel] The connector flow config to update in json (from the body)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public updateConnectorFlowConfig(connectorFlowConfigViewModel?: ConnectorFlowConfigViewModel, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).updateConnectorFlowConfig(connectorFlowConfigViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Will resolve one or more runs (or update the info on already resolved runs)
     * @param {UpdateRunsResolvedRequest} [updateRunsResolvedRequest] Object containing list of run id\&#39;s, name of who resolved the runs, a reason and a date for when the runs were resolved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilesConnectUIApi
     */
    public updateRunResolved(updateRunsResolvedRequest?: UpdateRunsResolvedRequest, options?: AxiosRequestConfig) {
        return MilesConnectUIApiFp(this.configuration).updateRunResolved(updateRunsResolvedRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


