/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Button, CircularProgress, LinearProgress, TextField, Tooltip, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Info } from '@mui/icons-material';
import PropTypes from 'prop-types';
import WorkflowCard from './WorkflowCard';
import ApiRuleIntegration from '../../Api/ApiRuleIntegration';
import CustomModal from '../Modal/CustomModal';
import AlertContext from '../../Contexts/AlertContext';
import { isAxiosResponseHTML } from '../../Utils/ApiUtils';

const useStyles = makeStyles()(() => ({
  createWorkflow: {
    marginTop: 10,
  },
  errorText: {
    color: 'red',
  },
  dialogTextField: {
    marginTop: 5,
    minWidth: 350,
    marginBottom: 10,
  },
  workflowInfoText: {
    whiteSpace: 'pre-wrap',
  },
  infoIcon: {
    marginRight: 5,
    marginBottom: -5,
  },
}));

const AlertRules = (props) => {
  const { classes } = useStyles();
  const { refresh, setRefresh } = props;

  const [loading, setLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [workflowName, setWorkflowName] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isDialogError, setIsDialogError] = useState(false);
  const [dialogError, setDialogError] = useState('');

  const { ruleApiKey, useRuleKey } = useContext(AlertContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (ruleApiKey || !useRuleKey) {
      setLoading(true);
      ApiRuleIntegration.getWorkflows(ruleApiKey)
        .then((response) => {
          setError(false);
          setWorkflows(response.data);
          if (isAxiosResponseHTML(response)) {
            setError(true);
            setErrorMsg('Got HTML data instead of JSON. Are the config.js urls correct?');
          }
        })
        .catch((respError) => {
          setError(true);
          setErrorMsg(`Could not retrieve rules from Rule Integration\n${respError}`);
        })
        .finally(() => setLoading(false));
    }
  }, [refresh, ruleApiKey, useRuleKey]);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const onDialogWorkflowBtnClick = () => {
    setWorkflowName('');
    setIsDialogError(false);
    handleClickOpen();
  };

  const onCreateWorkflowBtnClick = () => {
    setDialogLoading(true);
    ApiRuleIntegration.addWorkflow(workflowName, [], ruleApiKey)
      .then(() => {
        setRefresh((ref) => !ref);
        handleClose();
      })
      .catch((resError) => {
        setIsDialogError(true);
        setDialogError(resError?.response?.data);
      })
      .finally(() => setDialogLoading(false));
  };

  const createCustomActions = () => (
    <Button variant="outlined" color="primary" onClick={onCreateWorkflowBtnClick}>Create</Button>
  );

  return (
    <Box>
      {loading ? (<CircularProgress />) : (
        <>
          {error ? (
            <Typography className={`${classes.errorText} ${classes.workflowInfoText}`}>
              {errorMsg}
            </Typography>
          ) : (
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            <AlertContext.Provider value={{ ruleApiKey }}>
              <Typography variant="h5" gutterBottom>Alert Rules</Typography>
              {workflows?.length === 0 && (<Typography color="blue">No data found! Run the RuleEngine integration again, and then refresh the page!</Typography>)}
              {Array.isArray(workflows) && workflows?.map((wfl, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <WorkflowCard key={index} workflow={wfl} setRefreshWorkflows={setRefresh} />
              ))}
              {/* <Button variant="contained" color="primary" className={classes.createWorkflow} onClick={onDialogWorkflowBtnClick}>Create new rule workflow</Button> */}
              <CustomModal
                open={open}
                handleClose={handleClose}
                title="Create new workflow"
                customActions={createCustomActions()}
              >
                <Tooltip
                  title="Needs to be unique"
                  placement="top"
                >
                  <TextField
                    label="Workflow name"
                    autoFocus
                    multiline
                    fullWidth
                    value={workflowName}
                    className={classes.dialogTextField}
                    onChange={(e) => setWorkflowName(e.target.value)}
                  />
                </Tooltip>
                <Typography className={classes.workflowInfoText}>
                  <Info className={classes.infoIcon} />
                  {'The workflow name needs to be the same as the configured\n"'}
                  <code>RulesEngineWorkflowName</code>
                  {'" value in the appsettings/configmap\nfor the RuleConnector / Rule Integration'}
                </Typography>
                {isDialogError && (<Typography color="red">{dialogError}</Typography>)}
                {dialogLoading && (<LinearProgress />)}
              </CustomModal>
            </AlertContext.Provider>
          )}
        </>
      )}
    </Box>
  );
};

AlertRules.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AlertRules;
