import {
  Box, Grid, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  pageHeader: theme.pageHeader,
  pageHeaderText: theme.pageHeaderText,
  pageHeaderIconBox: theme.pageHeaderIconBox,
  iconMargin: {
    marginRight: '13px',
  },
  ...theme.overviewPage,
}));

export const PageHeader = (props) => {
  const { children, text } = props;
  const { length } = children;
  const { classes } = useStyles();
  return (
    <Box className={classes.pageHeader}>
      <Grid container alignContent="center" alignItems="center" justifyItems="center">
        {!Array.isArray(children)
          ? children
          : children?.map((node, index) => (
            <Grid
              item
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${classes.pageHeaderIconBox} ${(length && length - 1 !== index) ? classes.iconMargin : ''}`}
            >
              {node}
            </Grid>
          ))}
        <Grid item>
          <Typography className={classes.pageHeaderText}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

PageHeader.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
};

PageHeader.defaultProps = {
  text: 'Default Text',
  // eslint-disable-next-line react/jsx-no-useless-fragment
  children: <></>,
};

export default PageHeader;

/**
 * SVG Icons should be 36x36 in size (or scale with the parent).
 * Check the theme.pageHeaderIconBox values in theme.js
 */
export const PageHeaderIcon = (props) => {
  const { children, button, to } = props;
  const { classes } = useStyles();

  const buttonProps = button ? {
    component: Link,
    to,
  } : {};

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      className={`${classes.pageHeaderIconBox} ${button ? classes.ovIntegrationCardLink : ''}`}
    >
      {children}
    </Box>
  );
};

PageHeaderIcon.propTypes = {
  button: PropTypes.bool,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
};

PageHeaderIcon.defaultProps = {
  button: false,
  to: '/',
};
