import React, { useContext, useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import ConnectorFlowContext from '../../../../Contexts/ConnectorFlowContext';
import IntegrationsContext from '../../../../Contexts/IntegrationsContext';
import { findIntegration, findMetrics } from '../../../../Utils/IntegrationUtils';

const useStyles = makeStyles()((theme) => ({
  content: {
    padding: theme.nodes.padding,
    minWidth: theme.nodes.connector.minWidth,
    textDecoration: 'none',
    background: (isFailed) => (isFailed ? theme.details.integrationCardFailed.background : 'inherit'),
  },
  link: {
    pointerEvents: 'all',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  ...theme.overviewPage,
}));

const ConnectorNodeContent = ({ data }) => {
  const { isEditMode } = useContext(ConnectorFlowContext);
  const { requestData: integrations, metrics } = useContext(IntegrationsContext);
  const [isFailed, setIsFailed] = useState(false);
  const { classes } = useStyles(isFailed);

  const allyProps = (integrationId) => {
    if (!isEditMode && integrationId && integrationId !== '-1') {
      return {
        component: Link,
        to: `/details/${data.integrationId}`,
        className: `${classes.content} ${classes.link}`,
      };
    }
    return {};
  };

  useEffect(() => {
    if (integrations?.data && data.integrationId && data.integrationId !== '-1' && metrics?.data) {
      const entity = findIntegration(data.integrationId, integrations);
      const metric = findMetrics(entity?.name, metrics.data);
      setIsFailed(metric?.totalFailed > 0);
    }
  }, [integrations, data.integrationId, metrics]);

  return (
    <Grid
      container
      item
      className={classes.content}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...allyProps(data.integrationId)}
      color="inherit"
      alignItems="center"
      justifyContent="center"
      background={isFailed ? 'red' : 'inherit'}
    >
      <Typography className={`${classes.ovIntegrationCardText} ${classes.ovFlowText}`}>
        {data.label}
      </Typography>
    </Grid>
  );
};

ConnectorNodeContent.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    integrationId: PropTypes.string,
  }),
};

ConnectorNodeContent.defaultProps = {
  data: {
    label: 'Label',
    integrationId: '-1',
  },
};

export default ConnectorNodeContent;
