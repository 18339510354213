import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Tooltip, Typography, ButtonBase, InputBase,
  MenuItem, Select, Checkbox, IconButton, Autocomplete,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import dayjs from 'dayjs';
import {
  Add, ArrowDropDown, ArrowDropUp, Remove,
} from '@mui/icons-material';
import naturalSort from 'javascript-natural-sort';
import { getTimeNow, INTEGRATION_RUN_STATUS } from '../../../Constants/Constants';
import capitalizeAndLower from '../../../Utils/Utils';

naturalSort.insensitive = true;

const useStyles = makeStyles()((theme) => ({
  ...theme.details,
}));

const DetailsSearch = (props) => {
  const { classes } = useStyles();
  const {
    searchData, setSearchData, runProperties,
  } = props;

  const runPropArray = useMemo(() => Array.from(runProperties ?? [])
    .sort(naturalSort), [runProperties]);

  const [dateStart, setDateStart] = useState(searchData.dateStart);
  const [dateFinished, setDateFinished] = useState(searchData.dateEnd ?? getTimeNow());
  const [errorCode, setErrorCode] = useState(searchData.errorCode || '');
  const [status, setStatus] = useState(searchData.status ?? []);
  const [searchString, setsearchString] = useState(searchData.searchString || '');
  const [disabled, setDisabled] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [dlChecked, setDLChecked] = useState(searchData.onlyDeadletters ?? false);
  const [selectDisabled, setSelectDisabled] = useState(false);

  // adv. search
  const [showAdvSearch, setShowAdvSearch] = useState(false);
  const [advSearchArr, setAdvSearchArr] = useState(searchData.advSearch ?? [{ field: '', value: '' }]);
  const [advSearchMode, setAdvSearchMode] = useState(searchData.advSearchMode ?? true);
  const [keyValuePairsCount, setKeyValuePairsCount] = useState(1);

  useEffect(() => {
    setAdvSearchArr(searchData.advSearch ?? [{ field: '', value: '' }]);
    setAdvSearchMode(searchData.advSearchMode ?? true);
    setKeyValuePairsCount(searchData.advSearch?.length ?? 1);
    setsearchString(searchData.searchString || '');
  }, [searchData]);

  const checkStartDate = (value, end) => {
    const date = dayjs(value);
    const now = dayjs();
    if (date > now || value > end) setStartDateError(true);
    else setStartDateError(false);
  };

  const checkEndDate = (value, start) => {
    const date = dayjs(value);
    const now = dayjs();
    if (date > now || value < start) setEndDateError(true);
    else setEndDateError(false);
  };

  const checkAndSetStartDate = (value) => {
    checkStartDate(value, dateFinished);
    setDateStart(value);
    checkEndDate(dateFinished, value);
  };

  const checkAndSetEndDate = (value) => {
    checkEndDate(value, dateStart);
    setDateFinished(value);
    checkStartDate(dateStart, value);
  };

  const searchBtnOnClick = (event) => {
    if (event) event.preventDefault();
    setSearchData({
      dateStart,
      dateFinished,
      errorCode,
      status,
      searchString,
      onlyDeadletters: dlChecked,
      advSearch: advSearchArr,
      advSearchMode,
      searchBtnClicked: true,
      doNotSendAdvSearch: !showAdvSearch,
    });
  };

  const handleStatusChange = (event) => {
    const { target: { value } } = event;
    if (value?.length === 1 && value?.includes(INTEGRATION_RUN_STATUS.OK)) {
      setDisabled(true);
      setErrorCode('');
    } else {
      setDisabled(false);
    }
    setStatus(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const onAddKeyValueBtnClick = () => {
    setKeyValuePairsCount((val) => val + 1);
    setAdvSearchArr((val) => [...val, { field: '', value: '' }]);
  };

  const onRemoveKeyValueBtnClick = () => {
    // do not let value go under 1
    if (keyValuePairsCount > 1) {
      setKeyValuePairsCount((val) => val - 1);
      setAdvSearchArr((val) => val.slice(0, -1));
    }
  };

  const updateAdvSearchFieldArray = (index, field) => {
    setAdvSearchArr((val) => {
      const copy = [...val];
      copy[index].field = field;
      return [...copy];
    });
  };

  const updateAdvSearchValueArray = (index, value) => {
    setAdvSearchArr((val) => {
      const copy = [...val];
      copy[index].value = value;
      return [...copy];
    });
  };

  // TODO : update search data when typing / selecting in the inputs (not only on search btn click)

  return (
    <form onSubmit={searchBtnOnClick}>
      <Grid container justifyContent="left" alignItems="center">
        <Grid
          item
          className={`${classes.searchGridItemSpacing} ${classes.searchGridItemWithBorderText}`}
        >
          <InputBase
            fullWidth
            type="datetime-local"
            className={`${classes.searchFieldSize} ${classes.searchFieldDate} ${startDateError ? classes.searchFieldError : ''}`}
            inputProps={{ className: `${classes.searchFieldText} ${classes.searchFieldInput}` }}
            value={dateStart}
            onChange={(e) => checkAndSetStartDate(e.target.value)}
            error={startDateError}
          />
          <Typography className={classes.searchFieldBorderText}>
            From
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.searchGridItemSpacing} ${classes.searchGridItemWithBorderText}`}
        >
          <InputBase
            fullWidth
            type="datetime-local"
            className={`${classes.searchFieldSize} ${classes.searchFieldDate} ${endDateError ? classes.searchFieldError : ''}`}
            inputProps={{ className: `${classes.searchFieldText} ${classes.searchFieldInput}` }}
            value={dateFinished}
            onChange={(e) => checkAndSetEndDate(e.target.value)}
            error={endDateError}
          />
          <Typography className={classes.searchFieldBorderText}>
            To
          </Typography>
        </Grid>
        <Grid item className={classes.searchGridItemSpacing}>
          <Select
            fullWidth
            disabled={selectDisabled}
            onChange={handleStatusChange}
            value={status}
            multiple
            displayEmpty
            className={`${classes.searchFieldSize} ${classes.searchFieldStatus}`}
            input={<InputBase className={`${classes.searchFieldText}`} />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography className={classes.searchFieldText}>
                    All
                  </Typography>
                );
              }
              let str = '';
              for (let i = 0; i < selected.length; i += 1) {
                const s = selected[i];
                str += capitalizeAndLower(s);
                if (i !== selected.length - 1) str += ', ';
              }
              return str;
            }}
            inputProps={{ className: `${classes.searchFieldInput}` }}
            // Issue here? first option is "highlighted" (NOT selected!)
            // See https://github.com/mui/material-ui/issues/23747 (same issue)
          >
            {Object.values(INTEGRATION_RUN_STATUS).map((item) => (
              <MenuItem
                key={item}
                className={classes.searchFieldStatusMenuItem}
                value={item}
              >
                <Typography className={classes.searchFieldText}>
                  {capitalizeAndLower(item)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          item
          className={classes.searchGridItemSpacing}
          // Hide error code => does nothing now (type is better)
          // TODO? (future User Story?) Seach in the dataerror's errorcode (not used for now)
          sx={{ display: 'none' }}
        >
          <InputBase
            disabled={disabled}
            fullWidth
            // type="search"
            placeholder="Error code"
            className={`${classes.searchFieldSize} ${classes.searchFieldErrorCode}`}
            inputProps={{ className: `${classes.searchFieldText} ${classes.searchFieldInput}` }}
            value={errorCode}
            onChange={(e) => setErrorCode(e.target.value)}
          />
        </Grid>
        <Grid item className={classes.searchGridItemSpacing}>
          <Tooltip
            arrow
            placement="top"
            title={(
              <Typography className={classes.searchFieldToolTipText}>
                Search for any pattern in the data object for each run
              </Typography>
            )}
          >
            <InputBase
              fullWidth
              // type="search"
              disabled={showAdvSearch}
              placeholder="Data Search"
              className={classes.searchFieldSize}
              inputProps={{ className: `${classes.searchFieldText} ${classes.searchFieldInput}` }}
              value={searchString}
              onChange={(e) => setsearchString(e.target.value)}
            />
          </Tooltip>
        </Grid>
        <Grid item className={`${classes.searchShowOnlyDLGrid} ${classes.searchGridItemSpacing}`}>
          <Typography display="inline" className={classes.searchButtonText}>
            Only dead letters:
          </Typography>
          <Checkbox
            checked={dlChecked}
            onChange={(event) => {
              setDLChecked(event.target.checked);
              if (event.target.checked === true) {
                // disable dropdown / select as only failed?
                setStatus([INTEGRATION_RUN_STATUS.FAILED]);
                setSelectDisabled(true);
              } else {
                setStatus([]); // ?
                setSelectDisabled(false);
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            color="default"
          />
        </Grid>
        <Grid item>
          <ButtonBase className={classes.searchButton} type="submit">
            <Typography className={classes.searchButtonText}>
              Search
            </Typography>
          </ButtonBase>
        </Grid>
        <Grid item className={classes.advSearchShowIcon}>
          <Tooltip
            title={(
              <Typography className={classes.searchFieldToolTipText}>
                Show advanced search
              </Typography>
            )}
          >
            <IconButton size="small" onClick={() => setShowAdvSearch((val) => !val)} className={classes.advSearchShowIconBtn}>
              {showAdvSearch ? (
                <ArrowDropUp fontSize="large" />
              ) : (
                <ArrowDropDown fontSize="large" />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {showAdvSearch && (
        <Grid container justifyContent="left" alignItems="center">
          {/* eslint-disable-next-line no-unused-vars */}
          {Array(keyValuePairsCount).fill(null).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <Grid
                item
                // className={classes.searchGridItemSpacing}
              >
                <Tooltip
                  arrow
                  placement="top"
                  title={(
                    <Typography className={classes.searchFieldToolTipText}>
                      The field to search in
                      <br />
                      Ex: Country = Norway
                      <br />
                      Ex: Company.Address.ZipCode = 4230
                      <br />
                      <br />
                      If a run has multiple rows, you can search in the other rows by prefixing the
                      field with Noun.Position, where Position = 0 is the first row.
                      <br />
                      Ex: Noun.1.Company.Name = Miles
                    </Typography>
                  )}
                >
                  <Autocomplete
                    freeSolo
                    options={runPropArray}
                    renderInput={(params) => (
                      <InputBase
                        ref={params.InputProps.ref}
                        fullWidth
                        placeholder="Field"
                        className={`${classes.searchFieldSize} ${classes.searchBoxAdvAutocompleteSize}`}
                        inputProps={{
                          ...params.inputProps,
                          className: `${classes.searchFieldText} ${classes.searchFieldInput}`,
                          onSelect: () => null,
                        }}
                      />
                    )}
                    value={advSearchArr[index]?.field}
                    onInputChange={(_e, v) => updateAdvSearchFieldArray(index, v)}
                    classes={{
                      paper: classes.searchBoxAdvAutocompletePaper,
                      option: `${classes.searchFieldText} ${classes.autocompleteText}`,
                      listbox: classes.searchBoxAdvAutocompleteListbox,
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item className={classes.advSearchEqualsGrid}>
                <Typography className={`${classes.searchButtonText} ${classes.advSearchEqualsText}`}>
                  =
                </Typography>
              </Grid>
              <Grid
                item
              >
                <Tooltip
                  arrow
                  placement="top"
                  title={(
                    <Typography className={classes.searchFieldToolTipText}>
                      The value to search for (exact/case sensitive)
                      <br />
                      Use &quot;&quot; to force value as string
                      <br />
                      Ex: NumberString = &quot;123&quot;
                    </Typography>
                  )}
                >
                  <InputBase
                    fullWidth
                    // type="search"
                    placeholder="Value"
                    className={classes.searchFieldSize}
                    inputProps={{ className: `${classes.searchFieldText} ${classes.searchFieldInput}` }}
                    value={advSearchArr[index]?.value}
                    onChange={(e) => updateAdvSearchValueArray(index, e.target.value)}
                  />
                </Tooltip>
              </Grid>
              {keyValuePairsCount > 1 && index !== keyValuePairsCount - 1 && (
                <Grid item className={classes.advSearchEqualsGrid}>
                  <Tooltip title={advSearchMode ? 'AND' : 'OR'}>
                    <IconButton onClick={() => setAdvSearchMode((val) => !val)}>
                      <Typography className={`${classes.searchButtonText} ${classes.advSearchAndText}`}>
                        {advSearchMode ? 'AND' : 'OR'}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item className={`${classes.advSearchPlus} ${keyValuePairsCount === 1 ? classes.searchGridItemSpacing : ''}`}>
            <Tooltip
              placement="bottom"
              title={(
                <Typography className={classes.searchFieldToolTipText}>
                  Add field-value pair
                </Typography>
              )}
            >
              <IconButton onClick={onAddKeyValueBtnClick}>
                <Add />
              </IconButton>
            </Tooltip>
          </Grid>
          {keyValuePairsCount > 1 && (
            <Grid item className={`${classes.searchGridItemSpacing} ${classes.advSearchMinus}`}>
              <Tooltip
                placement="bottom"
                title={(
                  <Typography className={classes.searchFieldToolTipText}>
                    Remove field-value pair
                  </Typography>
                )}
              >
                <IconButton onClick={onRemoveKeyValueBtnClick}>
                  <Remove />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
    </form>
  );
};

DetailsSearch.propTypes = {
  searchData: PropTypes.oneOfType([PropTypes.shape, PropTypes.object]).isRequired,
  setSearchData: PropTypes.func.isRequired,
  runProperties: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.array, PropTypes.shape],
  ).isRequired,
};

export default DetailsSearch;
