/* eslint-disable max-len */
import { backendTokenRequest, loginRequest } from '../authConfig';
import { setLoggedInTime } from './LocalStorageUtils';

// Converts msal access token to @azure/identity credential - not working?
export const convertToTokenCredential = (accessToken) => ({
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  getToken(_scope, _opts) {
    return {
      token: accessToken,
      expiresOnTimestamp: Date.now() + 60 * 60 * 1000,
    };
  },
});

/**
 * Gets token and returns the value for the "Authorization header"
 * @param {import('@azure/msal-browser').IPublicClientApplication} instance
 * @returns {Promise<string>} Promise with the authorization header content as response
 */
export const getBackendAuthHeaderTokenAsync = async (instance) => {
  if (instance) {
    if (!instance.getActiveAccount()) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
    }
    const token = await instance.acquireTokenSilent(backendTokenRequest);
    return `Bearer ${token.accessToken}`;
  }
  return '';
};

export const getAccessTokenAsync = async (instance, account) => {
  if (account) {
    const request = {
      ...backendTokenRequest,
      account,
    };
    const token = await instance.acquireTokenSilent(request);
    return token.accessToken;
  }
  return null;
};

/**
 * Gets token and calls doSomething with the authHeader as a parameter
 * @param {import('@azure/msal-browser').IPublicClientApplication} instance
 * @param {Function(any)} doSomething // jsdoc for func with param?
 */
export const getAndSetBackendAuthHeaderToken = (instance, doSomething) => {
  if (!instance.getActiveAccount()) {
    instance.setActiveAccount(instance.getAllAccounts()[0]);
  }
  instance.acquireTokenSilent(backendTokenRequest)
    .then(
      (response) => {
        const authHeader = `Bearer ${response.accessToken}`;
        doSomething(authHeader);
      },
    );
};

/**
 *
 * @param {import('@azure/msal-browser').IPublicClientApplication} instance
 */
// eslint-disable-next-line no-unused-vars
export const handleLoginRedirect = (instance) => {
  instance.loginRedirect(loginRequest).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
  });
};

/**
 *
 * @param {import('@azure/msal-browser').IPublicClientApplication} instance
 */
export const handleLoginPopup = (instance) => {
  instance.loginPopup(loginRequest)
    .then(() => {
      setLoggedInTime();
    })
    .catch((e) => {
    // eslint-disable-next-line no-console
      console.error(e);
    });
};

/**
 * @param {import('@azure/msal-browser').IPublicClientApplication} instance
 */
// eslint-disable-next-line max-len
// See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#skipping-the-server-sign-out
export const handleLogoutRedirect = (instance, account) => {
  instance.logoutRedirect({
    account,
    postLogoutRedirectUri: window.ENV.MSAL_REDIRECT_URI,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    onRedirectNavigate: (url) => false,

  // eslint-disable-next-line no-console
  }).catch((err) => console.error(err));
};
/**
 * @param {import('@azure/msal-browser').IPublicClientApplication} instance
 * @param {*} loginRequest the loginrequest
 */
// eslint-disable-next-line no-unused-vars
export const handleLogoutPopup = (instance) => {
  instance.logoutPopup(loginRequest).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
  });
};
