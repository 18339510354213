import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  Stack, TableCell, TableRow, Tooltip, Typography,
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { MAX_CHARS_RUN_TABLECELL_EXPANDED } from '../../Constants/Constants';

const useStyles = makeStyles()((theme) => ({
  errorTableCell: {
    whiteSpace: 'pre-wrap',
  },
  whitespaceFix: {
    whiteSpace: 'pre-wrap',
  },
  ...theme.details,
}));

export const DataErrorCauseTooltip = (props) => {
  const { dataError, value } = props;
  const { classes } = useStyles();

  const {
    expectedValue, fieldName, integrationRunDataErrorCode, message,
  } = dataError;

  return (
    <Box>
      <Typography className={classes.runsTooltipTextHeader}>
        Error Cause
      </Typography>
      <Box>
        <Typography className={classes.runsTooltipText}>
          {'Value: '}
        </Typography>
        <Typography className={classes.runsTooltipTextValue}>
          {value}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.runsTooltipText}>
          {'Error code: '}
        </Typography>
        <Typography className={classes.runsTooltipTextValue}>
          {integrationRunDataErrorCode}
        </Typography>
      </Box>
      {expectedValue
                && (
                  <Box>
                    <Typography className={classes.runsTooltipText}>
                      {'Expected value: '}
                    </Typography>
                    <Typography className={classes.runsTooltipTextValue}>
                      {expectedValue}
                    </Typography>
                  </Box>
                )}
      <Box>
        <Typography className={classes.runsTooltipText}>
          {'Field: '}
        </Typography>
        <Typography className={classes.runsTooltipTextValue}>
          {fieldName}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.runsTooltipText}>
          {'Message: '}
        </Typography>
        <Typography className={classes.runsTooltipTextValue}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

DataErrorCauseTooltip.propTypes = {
  dataError: PropTypes.shape({
    expectedValue: PropTypes.string,
    fieldName: PropTypes.string,
    integrationRunDataErrorCode: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  value: PropTypes.string.isRequired,
};

const DataErrorTableRow = (props) => {
  const {
    dataError, headerNames, onClick, showOpenBtn, columnCount,
    combinedObj, combinedRow, dataErrorArray, runDataObj,
  } = props;
  const { classes } = useStyles();
  const [dataObj, setDataObj] = useState(null);

  const extractNoun = () => {
    const parsed = JSON.parse(dataError.dataObjectJson);
    const { Nouns } = parsed;
    if (Nouns && Array.isArray(Nouns) && Nouns.length > 0) {
      return Nouns[0];
    }
    return parsed;
  };

  useEffect(() => {
    try {
      if (dataError?.dataObjectJson) setDataObj(extractNoun());
      else if (runDataObj) setDataObj(runDataObj);
      else if (combinedRow) setDataObj(JSON.parse(combinedObj));
      else setDataObj(extractNoun());
    } catch (e) {
      if (combinedRow) {
        setDataObj({ 'Data Errors': JSON.stringify(dataErrorArray, null, 2), 'Data Object': combinedObj });
      } else {
        const { dataObjectJson, ...rest } = dataError;
        setDataObj({ 'Data Errors': JSON.stringify(rest, null, 2), 'Data Object': dataObjectJson });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedObj, combinedRow, dataError, dataErrorArray]);

  const maxChars = MAX_CHARS_RUN_TABLECELL_EXPANDED;

  const handleHeaderName = (headerName) => {
    let output = '';
    if (dataObj) {
      try {
        if (typeof dataObj[headerName] === 'object') {
          output = JSON.stringify(dataObj[headerName], null, 2);
        } else {
          output = `${dataObj[headerName]}`;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Getting cell data error [DataErrorTableRow]:', e);
      }
    }
    if (output && output.length > maxChars) {
      return (
        <ButtonBase
          onClick={() => onClick(output, headerName)}
          className={`${classes.runsTableExpandedOpenText} ${classes.runsTableRowText}`}
        >
          Show
        </ButtonBase>
      );
    }
    return output;
  };

  return (
    <TableRow>
      {headerNames.map((headerName, index) => {
        if (showOpenBtn && index >= columnCount) {
          return null;
        }

        let combinedCheck = false;
        let selectedError = null;
        if (combinedRow) {
          for (let i = 0; i < dataErrorArray.length; i += 1) {
            const error = dataErrorArray[i];
            if (headerName === error?.fieldName?.split('.')[0]) {
              combinedCheck = true;
              selectedError = error;
              break;
            }
          }
        }

        if (headerName === dataError?.fieldName?.split('.')[0] || combinedCheck) {
          return (
            <Tooltip
              title={(
                <DataErrorCauseTooltip
                  dataError={combinedCheck && selectedError ? selectedError : dataError}
                  value={handleHeaderName(headerName)}
                />
              )}
              key={headerName}
              classes={{
                tooltip: classes.runsToolTipBox,
              }}
            >
              <TableCell className={classes.errorTableCell}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.492 17.4167C5.12078 17.4123 1.58034 13.866 1.58325 9.49473C1.58617 5.12351 5.13133 1.58189 9.50256 1.58334C13.8738 1.5848 17.4166 5.12878 17.4166 9.50001C17.414 13.8743 13.8663 17.4184 9.492 17.4167ZM3.16659 9.63617C3.20405 13.1205 6.04913 15.9201 9.5336 15.9014C13.0181 15.8826 15.8329 13.0526 15.8329 9.56809C15.8329 6.08357 13.0181 3.25358 9.5336 3.23475C6.04913 3.21612 3.20405 6.01569 3.16659 9.50001V9.63617ZM10.2916 13.4583H8.70825V11.875H10.2916V13.4583ZM10.2916 10.2917H8.70825V5.54167H10.2916V10.2917Z" fill="#E45B43" />
                  </svg>
                  <Typography className={classes.runsTableRowErrorText}>
                    Error
                  </Typography>
                </Stack>
              </TableCell>
            </Tooltip>
          );
        }
        return (
          <TableCell
            className={classes.whitespaceFix}
            key={headerName}
          >
            <Typography className={classes.runsTableRowText}>
              {handleHeaderName(headerName)}
            </Typography>
          </TableCell>
        );
      })}
      {showOpenBtn && (
        <TableCell>
          <ButtonBase
            onClick={combinedRow ? () => onClick(dataObj, dataErrorArray)
              : () => onClick(dataObj, 'Run data', dataError)}
            className={`${classes.runsTableExpandedOpenText} ${classes.runsTableRowText}`}
          >
            Open
          </ButtonBase>
        </TableCell>
      )}
    </TableRow>
  );
};

DataErrorTableRow.propTypes = {
  dataError: PropTypes.shape({
    dataObjectJson: PropTypes.string,
    fieldName: PropTypes.string,
  }),
  headerNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  showOpenBtn: PropTypes.bool,
  columnCount: PropTypes.number,
  combinedObj: PropTypes.shape({

  }),
  combinedRow: PropTypes.bool,
  dataErrorArray: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
  })),
  runDataObj: PropTypes.oneOfType([PropTypes.shape({
  }), PropTypes.array]),
};

DataErrorTableRow.defaultProps = {
  showOpenBtn: false,
  columnCount: 0,
  combinedRow: false,
  dataError: {},
  combinedObj: {},
  dataErrorArray: [],
  runDataObj: null,
};

export default DataErrorTableRow;
