import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'react-flow-renderer';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ConnectorNodeContent from './Common/ConnectorNodeContent';

const useStyles = makeStyles()((theme) => ({
  dataConnector: {
    background: theme.nodes.background,
    color: theme.nodes.color,
    borderWidth: theme.nodes.borderWidth,
    borderStyle: theme.nodes.borderStyle,
    borderColor: theme.nodes.connector.dataColor,
    borderRadius: theme.nodes.borderRadius,
  },
  inputHandle: {
    background: theme.nodes.data.inputHandle,
  },
  outputHandle: {
    background: theme.nodes.data.outputHandle,
  },
}));

const DataConnectorNode = memo(({
  data, isConnectable,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.dataConnector}>
      <Handle
        type="target"
        id="t"
        position={Position.Left}
        className={classes.inputHandle}
        isConnectable={isConnectable}
      />
      <ConnectorNodeContent data={data} />
      <Handle
        type="source"
        id="s"
        position={Position.Right}
        className={classes.outputHandle}
        isConnectable={isConnectable}
      />
    </Box>
  );
});

DataConnectorNode.propTypes = {
  isConnectable: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
  }),
};

DataConnectorNode.defaultProps = {
  data: {
    label: 'DataConnector',
  },
};

export default DataConnectorNode;
