import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'react-flow-renderer';
import { Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  targetData: {
    background: theme.nodes.background,
    color: theme.nodes.color,
    padding: theme.nodes.padding,
    borderWidth: theme.nodes.borderWidth,
    borderStyle: theme.nodes.borderStyle,
    borderColor: theme.nodes.data.targetColor,
    borderRadius: theme.nodes.borderRadius,
    minWidth: theme.nodes.data.minWidth,
  },
  outputHandle: {
    background: theme.nodes.data.targetColor,
  },
  ...theme.overviewPage,
}));

const TargetDataNode = memo(({ data, isConnectable }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.targetData}>
      <Handle
        className={classes.outputHandle}
        type="target"
        position={Position.Left}
        id="t"
        isConnectable={isConnectable}
      />
      <Typography className={`${classes.ovIntegrationCardText} ${classes.ovFlowText}`}>
        {data.label}
      </Typography>
    </Box>
  );
});

TargetDataNode.propTypes = {
  isConnectable: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
  }),
};

TargetDataNode.defaultProps = {
  data: {
    label: 'TargetData',
  },
};

export default TargetDataNode;
