import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { backendTokenRequest } from '../authConfig';

/**
 * Use this hook to get the accessToken for authenticating with backend (that uses Azure AD Auth)
 * @returns {{accessToken: string, account: object}} The access token
 */
const useAcquireToken = () => {
  const [accessToken, setAccessToken] = useState();
  const { instance, accounts } = useMsal();

  // eslint-disable-next-line max-len
  // See: https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react#acquire-a-token

  useEffect(() => {
    const request = {
      ...backendTokenRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
      })
      .catch(() => {
        if (request.account) {
          instance.acquireTokenPopup(request)
            .then((response) => {
              setAccessToken(response.accessToken);
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log('An error was thrown when acquiring a token', err?.message);
            });
        }
      });
  }, [accounts, instance]);

  return { accessToken, account: accounts[0] };
};

export default useAcquireToken;
