import * as React from 'react';
import {
  Box, IconButton, Paper, Popover, Typography,
} from '@mui/material';
// TODO refactor this a bit (put buttons etc in DeadLetterModal?)
import { ExpandMore } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

// eslint-disable-next-line max-len
// taken and modified from https://codesandbox.io/s/expandcelldemo-forked-rs0hx?file=/src/renderCellExpand.tsx

const useStyles = makeStyles()(() => ({
  root: {
    alignItems: 'center',
    lineHeight: '24px',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight
    || element.scrollWidth > element.clientWidth
  );
}

const CellExpand = React.memo((props) => {
  // eslint-disable-next-line react/prop-types
  const { value, width } = props;
  const { classes } = useStyles();

  const wrapper = React.useRef(null);
  const cellValueRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [
    isCurrentlyOverflown,
    setCurrentlyOverflown,
  ] = React.useState(null);

  React.useEffect(() => {
    setCurrentlyOverflown(isOverflown(cellValueRef.current));
  }, [isCurrentlyOverflown, value]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div ref={wrapper} className={classes.root}>
      <div ref={cellValueRef} className="cellValue">
        {value}
      </div>
      {isCurrentlyOverflown && (
        <>
          <IconButton aria-label="expand" color="primary" onClick={handleClick}>
            <ExpandMore />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper elevation={1}>
              <Box minWidth={width} maxWidth={window.screen.width} p={2}>
                <Typography variant="body2" whiteSpace="pre-wrap">{value}</Typography>
              </Box>
            </Paper>
          </Popover>
        </>
      )}
    </div>
  );
});

export default function renderCellExpand(params) {
  return (
    <CellExpand
      value={params.formattedValue ?? params.value ?? ''}
      width={params.colDef.width}
    />
  );
}
