import {
  Box,
  ButtonBase,
  capitalize,
  CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import defaultStyle from 'react-syntax-highlighter/dist/esm/styles/hljs/default-style';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import CodeEditor from '@uiw/react-textarea-code-editor';
import axios from 'axios';
import DialogCloseIcon from '../Icons/DialogCloseIcon';
import { toDateTimeStringChart } from '../../../Utils/DateTimeUtils';
import IntegrationsContext from '../../../Contexts/IntegrationsContext';

SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('xml', xml);

const useStyles = makeStyles()((theme) => ({
  ...theme.details,
  ...theme.runsDetail,
  confirmRed: {
    color: '#e45b43',
  },
}));

const DeadLetterMsgDialog = (props) => {
  const { classes } = useStyles();
  const {
    open, handleClose, messageId, integrationId, run,
  } = props;

  const {
    id: runId, queue: runQueue,
    topic: runTopic, subscription: runSubscription,
  } = run;

  const { requestData: integrations, api } = useContext(IntegrationsContext);

  const [deadletter, setDeadletter] = useState(null);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmAction, setConfirmAction] = useState('');
  const [actionLoading, setActionLoading] = useState(false);
  const [showActionResult, setShowActionResult] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [actionResult, setActionResult] = useState('');
  const [cancelToken, setCancelToken] = useState(null);

  const handleBody = (dl = deadletter) => {
    if (!dl?.body) return '';
    if (typeof dl?.body === 'object') {
      return JSON.stringify(dl?.body, null, 2);
    }
    try {
      const obj = JSON.parse(dl?.body);
      return JSON.stringify(obj, null, 2);
    } catch (err) {
      return dl?.body;
    }
  };

  useEffect(() => {
    if (api && open) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      setShowConfirm(false);
      setLoading(true);
      setCancelToken(source);
      setShowActionResult(false);
      setError(false);
      setDeadletter(null);
      setIsEdit(false);
      if (runQueue) {
        api.getDeadLetterMessage(
          messageId,
          integrationId,
          runQueue,
          null,
          null,
          null,
          {
            cancelToken: source.token,
          },
        )
          .then((resp) => {
            setDeadletter(resp.data);
            setBody(handleBody(resp.data));
          })
          .catch((err) => {
            setError(true);
            setErrorMsg(`${err?.response?.data}`);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (runTopic && runSubscription) {
        api.getDeadLetterMessage(
          messageId,
          integrationId,
          null,
          runTopic,
          runSubscription,
          {
            cancelToken: source.token,
          },
        )
          .then((resp) => {
            setDeadletter(resp.data);
            setBody(handleBody(resp.data));
          })
          .catch((err) => {
            setError(true);
            setErrorMsg(`${err?.response?.data}`);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        api.getDeadLetterMessage(
          messageId,
          integrationId,
          null,
          null,
          null,
          {
            cancelToken: source.token,
          },
        )
          .then((resp) => {
            setDeadletter(resp.data);
            setBody(handleBody(resp.data));
          })
          .catch((err) => {
            setError(true);
            setErrorMsg(`${err?.response?.data}`);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else if (!open) {
      setErrorMsg('');
      setActionResult('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, messageId, api, integrationId, integrations]);

  const onFirstActionBtnClick = (action) => {
    setShowConfirm(true);
    setConfirmAction(action);
  };

  const onResendBtnCLick = () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    setCancelToken(source);
    setActionLoading(true);
    setConfirmAction('resending');
    if (deadletter?.queue) {
      api.resendDeadLetter(
        integrationId,
        {
          body: isEdit ? body : null,
          messageId,
          runId,
          queueName: deadletter?.queue,
          keepInDLQ: false,
        },
        {
          cancelToken: source.token,
        },
      )
        .then((resp) => {
        // message was resent
          setActionResult(`${resp?.data}`);
          setShowActionResult(true);
        }).catch((err) => {
          setError(true);
          setErrorMsg(`${err?.response?.data}`);
        }).finally(() => setActionLoading(false));
    } else {
      api.resendDeadLetter(
        integrationId,
        {
          body: isEdit ? body : null,
          keepInDLQ: false,
          messageId,
          runId,
          topicName: deadletter?.topic,
          subscriptionName: deadletter?.subscription,
        },
        {
          cancelToken: source.token,
        },
      )
        .then((resp) => {
        // message was resent
          setActionResult(`${resp?.data}`);
          setShowActionResult(true);
        }).catch((err) => {
          setError(true);
          setErrorMsg(`${err?.response?.data}`);
        }).finally(() => setActionLoading(false));
    }
  };

  const onDeleteBtnClick = () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    setCancelToken(source);
    setActionLoading(true);
    if (deadletter?.queue) {
      api.deleteDeadLetter(
        integrationId,
        {
          queueName: deadletter?.queue,
          messageId,
          runId,
        },
        {
          cancelToken: source.token,
        },
      )
        .then((resp) => {
        // message was deleted
          setActionResult(`${resp?.data}`);
          setShowActionResult(true);
        }).catch((err) => {
          setError(true);
          setErrorMsg(`${err?.response?.data}`);
        }).finally(() => setActionLoading(false));
    } else {
      api.deleteDeadLetter(
        integrationId,
        {
          topicName: deadletter?.topic,
          subscriptionName: deadletter?.subscription,
          messageId,
          runId,
        },
        {
          cancelToken: source.cancel,
        },
      )
        .then((resp) => {
        // message was deleted
          setActionResult(`${resp?.data}`);
          setShowActionResult(true);
        }).catch((err) => {
          setError(true);
          setErrorMsg(`${err?.response?.data}`);
        }).finally(() => setActionLoading(false));
    }
  };

  const onConfirmBtnClick = () => {
    setShowConfirm(false);
    if (confirmAction === 'resending') {
      onResendBtnCLick();
    } else if (confirmAction === 'deleting') {
      onDeleteBtnClick();
    }
  };

  const onDeclineBtnClick = () => {
    setShowConfirm(false);
  };

  const onEditBtnClick = () => {
    setIsEdit((val) => !val);
  };

  const onExitBtnClick = () => {
    cancelToken?.cancel('Canceled');
    handleClose(showActionResult);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: `${classes.runsDetailModalBox}`,
      }}
    >
      <DialogTitle>
        <IconButton className={classes.runsDetailExitBtn} onClick={onExitBtnClick}>
          <DialogCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.runsDetailBox} ${classes.runsDLContent}`}>
        {error && (
          <Typography className={classes.runResolveErrorText}>
            {errorMsg}
          </Typography>
        )}
        {showActionResult && (
          <Typography className={`${classes.runsTooltipTextHeader} ${classes.runsDLActionText}`}>
            {actionResult}
          </Typography>
        )}
        {loading || actionLoading ? (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <CircularProgress />
            <Typography className={classes.runsTooltipTextHeader}>
              {`${actionLoading ? capitalize(confirmAction) : ''}${loading ? 'Loading' : ''} the deadletter might take some time`}
            </Typography>
          </Grid>
        ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            <Typography className={classes.runsTooltipTextHeader}>
              Message ID:
            </Typography>
            <Typography className={classes.runsTooltipTextValue}>
              {messageId}
            </Typography>
            <Typography className={classes.runsTooltipTextHeader}>
              Error Description:
            </Typography>
            <Typography className={classes.runsTooltipTextValue}>
              {deadletter?.deadLetterErrorDescription}
            </Typography>
            <Typography className={classes.runsTooltipTextHeader}>
              Reason:
            </Typography>
            <Typography className={classes.runsTooltipTextValue}>
              {deadletter?.deadLetterReason}
            </Typography>
            <Typography className={classes.runsTooltipTextHeader}>
              Enqueued Time:
            </Typography>
            <Typography className={classes.runsTooltipTextValue}>
              {toDateTimeStringChart(deadletter?.enqueuedTime)}
            </Typography>
            <Typography className={classes.runsTooltipTextHeader}>
              Body:
            </Typography>
            {isEdit ? (
              <Box className={`${classes.runResolveInput} ${classes.runsDLBodyTextarea}`}>
                <CodeEditor
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  language="xml"
                  style={{
                    background: 'white',
                    borderRadius: '5px',
                  }}
                />
              </Box>
            ) : (
              <SyntaxHighlighter
                className={classes.runsDetailBoxViewer}
                language="xml"
                wrapLongLines
                style={defaultStyle}
              >
                {body}
              </SyntaxHighlighter>
            )}
          </>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          {showConfirm ? (
            <>
              <Typography
                className={`${classes.runsTooltipTextValue} ${classes.runsDLConfirmText} ${confirmAction === 'resending' ? classes.confirmRed : ''}`}
              >
                {confirmAction === 'resending' ? 'This will also delete the message, are you sure?' : 'Confirm:'}
              </Typography>
              <ButtonBase
                onClick={onDeclineBtnClick}
                className={`${classes.searchButton} ${classes.runsDLResendDeleteBtn}`}
              >
                <Typography className={classes.searchButtonText}>
                  No
                </Typography>
              </ButtonBase>
              <ButtonBase
                onClick={onConfirmBtnClick}
                className={`${classes.searchButton} ${classes.runsDLResendDeleteBtn}`}
              >
                <Typography className={classes.searchButtonText}>
                  Yes
                </Typography>
              </ButtonBase>
            </>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!actionLoading && !showActionResult && !error && (
                <>
                  <ButtonBase
                    onClick={onEditBtnClick}
                    className={`${classes.searchButton} ${classes.runsDLResendDeleteBtn}`}
                  >
                    <Typography className={classes.searchButtonText}>
                      {isEdit ? 'Close edit' : 'Edit'}
                    </Typography>
                  </ButtonBase>
                  <ButtonBase
                    onClick={onResendBtnCLick}
                    className={`${classes.searchButton} ${classes.runsDLResendDeleteBtn}`}
                  >
                    <Typography className={classes.searchButtonText}>
                      Resend
                    </Typography>
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => onFirstActionBtnClick('deleting')}
                    className={`${classes.searchButton} ${classes.runsDLResendDeleteBtn}`}
                  >
                    <Typography className={classes.searchButtonText}>
                      Delete
                    </Typography>
                  </ButtonBase>
                </>
              )}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

DeadLetterMsgDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
  integrationId: PropTypes.string.isRequired,
  run: PropTypes.shape({
    id: PropTypes.string,
    queue: PropTypes.string,
    topic: PropTypes.string,
    subscription: PropTypes.string,
  }),
};

DeadLetterMsgDialog.defaultProps = {
  run: {},
};

export default DeadLetterMsgDialog;
