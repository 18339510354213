import {
  Box, ButtonBase, Grid,
  TableContainer, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, {
  useState, useContext, useEffect, useRef,
  useMemo,
} from 'react';

import DetailsRunsTable from '../Component/Redesign/Details/DetailsRunsTable';
import DetailsSearch from '../Component/Redesign/Details/DetailsSearch';
import IntegrationsContext from '../Contexts/IntegrationsContext';
import detailsPage from '../Themes/DetailsPage';
import { queryFixDate } from '../Utils/ApiUtils';
import {
  createDefaultSearchData,
  INTEGRATION_RUN_STATUS,
  MAX_RUNS_INFINITE_SCROLL,
  RUNS_INFINITE_SCROLL_RETRIEVEAL_COUNT,
} from '../Constants/Constants';
import RunResolveDialog from '../Component/Redesign/Details/RunResolveDialog';
import DeadLetterMsgDialog from '../Component/Redesign/Details/DeadLetterMsgDialog';
import PageContext from '../Contexts/PageContext';
import { dotList } from '../Utils/RunUtils';

const useStyles = makeStyles()((theme) => ({
  ...theme.details,
}));

const DetailsPage = () => {
  const { classes } = useStyles();
  const {
    selected, selectedId,
    setIntegrationIsFailed,
    integrationIsFailed,
  } = useContext(PageContext);

  const [runError, setRunError] = useState('');
  const [isRunError, setIsRunError] = useState(false);
  const [refreshRuns, setRefreshRuns] = useState(false);
  const [showResolveBtn, setShowResolveBtn] = useState(false);

  // Refs and stuff for "responsive" design
  const tableRef = useRef(null);
  const tableOverflowRef = useRef(null);

  // Table / pagination
  const [orderBy, setOrderBy] = useState('Started');
  const [ascending, setAscending] = useState(false);
  const [hasMoreRuns, setHasMoreRuns] = useState(true);
  // Search
  const [searchData, setSearchData] = useState(createDefaultSearchData());
  const [runsLoading, setRunsLoading] = useState(false);
  const [runs, setRuns] = useState([]);

  // Resolve dialog
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [shouldSelectAll, setShouldSelectAll] = useState(true);

  // Dead letter
  const [dlMessageId, setDLMessageId] = useState('');
  const [dlRun, setDLRun] = useState({});
  const [openDL, setOpenDL] = useState(false);

  // Run properties:
  const [runProperties, setRunProperties] = useState([]);

  const abortControllerRef = useRef(new AbortController());

  /* -------------------- */
  /* -------------------- */
  /* -------------------- */
  /* -------------------- */
  /* -------------------- */
  /* WORK NEEDED */

  // TODO Reload metrics for one integration when selecting it?? (is only retrieved on page reload)
  // ^ Maybe let the refresh button do this as well?

  /* WORK NEEDED */
  /* -------------------- */
  /* -------------------- */
  /* -------------------- */
  /* -------------------- */
  /* -------------------- */

  const {
    refreshAll, api,
  } = useContext(IntegrationsContext);

  /* HELPER METHODS */

  /**
   * Calculate if there are more runs for the infinite scroll
   * @returns
   */
  const calcHasMoreRuns = useMemo(
    () => () => hasMoreRuns && runs.length < MAX_RUNS_INFINITE_SCROLL,
    [hasMoreRuns, runs],
  );

  /* CALLBACKS */

  const onDLBtnClick = (messageId, run) => {
    setDLMessageId(messageId);
    setDLRun(run);
    setOpenDL(true);
  };

  useEffect(() => {
    if (Array.isArray(selectedRows) && selectedRows.length === 0) {
      setShouldSelectAll(true);
    }
  }, [selectedRows]);

  /* API CALLS - SEARCH / GET RUNS */

  /**
   * Will load more (x) runs for the infinite scrolling
   */
  const loadMoreRuns = async (count = RUNS_INFINITE_SCROLL_RETRIEVEAL_COUNT) => {
    if (api && selectedId) {
      const offset = runs.length;

      api.getPaginatedSearchResult(
        selectedId,
        offset,
        count,
        queryFixDate(searchData.dateStart),
        queryFixDate(searchData.dateFinished),
        undefined,
        searchData.status,
        // eslint-disable-next-line no-nested-ternary
        searchData.doNotSendAdvSearch ? (searchData.searchString ? searchData.searchString : undefined) : undefined,
        searchData.onlyDeadletters,
        undefined,
        searchData.doNotSendAdvSearch ? undefined : searchData.advSearch.map((o) => o.field),
        searchData.doNotSendAdvSearch ? undefined : searchData.advSearch.map((o) => o.value),
        searchData.doNotSendAdvSearch ? undefined : searchData.advSearchMode,
        orderBy,
        ascending,
        {
          signal: abortControllerRef.current.signal,
        },
      )
        .then((resp) => {
          if (Array.isArray(resp.data.combinedRuns)) {
            if (resp.data.combinedRuns.length === 0
              || resp.data.combinedRuns.length < count) {
              setHasMoreRuns(false);
            }
            setRuns((old) => old.concat(resp.data.combinedRuns));
            setRunProperties((prev) => {
              const set = new Set(prev);
              return dotList(resp.data.combinedRuns, set);
            });
          }
        })
        .catch((error) => {
          setIsRunError(true);
          setRunError(`${error}`);
        });
    }
  };

  useEffect(() => {
    // Find some better way to reset search data ???
    // Defaults search data and triggers a search
    setSearchData((prev) => {
      const {
        dateFinished, dateStart, status, onlyDeadletters,
      } = prev;
      const copy = createDefaultSearchData();
      const newSearchData = {
        ...copy,
        dateFinished,
        dateStart,
        status,
        onlyDeadletters,
      };
      return newSearchData;
    });
    setRunsLoading(true);
  }, [selectedId]);

  /**
   * Gets the initial runs + 1 more (to get a scrollbar)
   * Will run if selected integration is changed,
   * or the sorting on the table + search
   * Will also run if run signed by is saved/updated
   */
  useEffect(() => {
    const controller = new AbortController();
    const fetchRuns = async () => {
      if (api && selectedId && tableRef) {
        try {
          abortControllerRef?.current.abort();
          abortControllerRef.current = new AbortController();
        } catch (_) {
          // do nothing
        }
        setSelectedRows([]);
        setRunsLoading(true);
        setIsRunError(false);
        setShowResolveBtn(false);
        setShouldSelectAll(true);
        setHasMoreRuns(true);

        const height = tableRef.current.offsetHeight;
        const firstRetrievalCount = Math.trunc((height / detailsPage.runsTableRow.height) + 1);

        api.getPaginatedSearchResult(
          selectedId,
          0,
          firstRetrievalCount,
          queryFixDate(searchData.dateStart),
          queryFixDate(searchData.dateFinished),
          undefined,
          searchData.status,
          // eslint-disable-next-line no-nested-ternary
          searchData.doNotSendAdvSearch ? (searchData.searchString ? searchData.searchString : undefined) : undefined,
          searchData.onlyDeadletters,
          undefined,
          searchData.doNotSendAdvSearch ? undefined : searchData.advSearch.map((o) => o.field),
          searchData.doNotSendAdvSearch ? undefined : searchData.advSearch.map((o) => o.value),
          searchData.doNotSendAdvSearch ? undefined : searchData.advSearchMode,
          orderBy,
          ascending,
          {
            signal: controller.signal,
          },
        )
          .then((resp) => {
            if (resp.data.combinedRuns?.length === 0
              || resp.data.combinedRuns.length < firstRetrievalCount) {
              setHasMoreRuns(false);
            }
            setRuns(resp.data.combinedRuns);
            if (searchData.searchBtnClicked) {
              setRunProperties((prev) => {
                const set = new Set(prev);
                return dotList(resp.data.combinedRuns, set);
              });
            } else {
              setRunProperties(dotList(resp.data.combinedRuns, new Set()));
            }
            setIsRunError(false);
            setRunsLoading(false);
          })
          .catch((error) => {
            if (error?.code !== 'ERR_CANCELED') {
              setIsRunError(true);
              setRunError(`${error}`);
              setRunsLoading(false);
            }
          });
      }
    };
    fetchRuns();
    return () => {
      controller.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, searchData, orderBy, ascending, refreshRuns]);

  const onRefreshRuns = () => {
    if (selected?.totalFailed === 1) setIntegrationIsFailed(false);
    refreshAll();
    setRefreshRuns((ref) => !ref);
    setShowResolveBtn(false);
    setSelectedRows([]);
  };

  const onResolveAllBtnClick = () => {
    if (shouldSelectAll) {
      if (Array.isArray(runs) && runs.length > 0) {
        const runIds = runs.filter((run) => run.type === INTEGRATION_RUN_STATUS.FAILED)
          .map((run) => run.id);
        setSelectedRows(runIds);
        if (runIds.length > 0) {
          setShouldSelectAll(false);
          setShowResolveBtn(true);
        }
      }
    } else {
      setSelectedRows([]);
      setShowResolveBtn(false);
      setShouldSelectAll(true);
    }
  };

  return (
    <Box>
      <Grid
        container
        className={classes.detailsMainBoxSearchHeader}
        justifyContent="space-between"
      >
        <Grid xs={9.5} item className={classes.detailsSearchBox}>
          <DetailsSearch
            searchData={searchData}
            setSearchData={setSearchData}
            runProperties={runProperties}
          />
        </Grid>
        <Grid xs={1.5} item className={classes.detailsSearchHeaderInfoBox}>
          <Typography className={classes.detailsSearchHeaderInfoText}>
            {`${selected?.totalFailed ?? 0} failed`}
          </Typography>
          <Typography className={classes.detailsSearchHeaderInfoSubText}>
            {/* eslint-disable-next-line max-len */}
            {`${(selected?.total ?? 0) - (selected?.totalFailed ?? 0)} of ${selected?.total ?? 0} successful`}
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <TableContainer
          className={classes.runsTableContainer}
          ref={tableRef}
        >
          <InfiniteScroll
            ref={tableOverflowRef}
            className={classes.runsTableScroller}
            dataLength={runs.length}
            next={loadMoreRuns}
            hasMore={calcHasMoreRuns()}
            loader={(
              <Typography
                className={`${classes.runsTableInfiniteScrollFooter} ${classes.runsTableRowText}`}
              >
                Loading some data ...
              </Typography>
            )}
            endMessage={(
              <Box className={classes.runsTableInfiniteScrollFooter}>
                {integrationIsFailed && (
                  <ButtonBase
                    onClick={onResolveAllBtnClick}
                    className={classes.resolveAllButton}
                  >
                    <Typography className={classes.searchButtonText}>
                      {`${shouldSelectAll ? 'Select' : 'Deselect'} all failed runs to resolve`}
                    </Typography>
                  </ButtonBase>
                )}
                <Typography
                  className={`${classes.runsTableRowText}`}
                >
                  {!hasMoreRuns ? 'No more data' : `Limit (${MAX_RUNS_INFINITE_SCROLL}) was reached, try using search to narrow it down`}
                </Typography>
              </Box>
            )}
            height={detailsPage.runsTableScroller.height}
            scrollThreshold={0.95}
          >
            <DetailsRunsTable
              data={runs}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              setAscending={setAscending}
              runsLoading={runsLoading}
              tableOverflowRef={tableOverflowRef}
              refreshRuns={onRefreshRuns}
              setShowResolveBtn={setShowResolveBtn}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              onDLBtnClick={onDLBtnClick}
            />
          </InfiniteScroll>
        </TableContainer>
      </Box>
      {showResolveBtn && (
        <Box className={classes.resolvedRunButtonBox}>
          <ButtonBase
            onClick={() => setOpen(true)}
            className={classes.resolvedRunButton}
          >
            <Typography className={classes.searchButtonText}>
              Resolve
            </Typography>
          </ButtonBase>
          <RunResolveDialog
            open={open}
            handleClose={() => setOpen(false)}
            selectedRows={selectedRows}
            onRunFinishedUpdating={onRefreshRuns}
          />
        </Box>
      )}
      {isRunError && (
        <Box>
          <Typography className={classes.runsTableRowErrorText}>
            {`Error retrieving runs: ${runError}`}
          </Typography>
        </Box>
      )}
      <DeadLetterMsgDialog
        open={openDL}
        handleClose={(shouldRefresh) => {
          setOpenDL(false);
          if (shouldRefresh) onRefreshRuns();
        }}
        messageId={dlMessageId}
        integrationId={selectedId}
        run={dlRun}
      />
    </Box>
  );
};

export default DetailsPage;
