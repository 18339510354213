import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useEdgesState, useNodesState } from 'react-flow-renderer';
import ConnectorFlowProvider from '../Component/ConnectorFlow/ConnectorFlowProvider';
import InfoModal from '../Component/Modal/InfoModal';
import { dummyElements } from '../Constants/FlowConstants';
import IntegrationsContext from '../Contexts/IntegrationsContext';

const OverviewFlowPage = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(dummyElements);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [open, setOpen] = useState(false);
  const [configLoading, setConfigLoading] = useState(true);
  const [dialogText, setDialogText] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const { api } = useContext(IntegrationsContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateAndOpenInfoModal = (title, text) => {
    setDialogTitle(title);
    setDialogText(text);
    handleClickOpen();
  };

  const migrateToV10 = (data) => {
    const nodesTemp = [];
    const edgesTemp = [];
    const array = JSON.parse(data?.oldConfig?.body);
    if (Array.isArray(array)) {
      array.forEach((item) => {
        if (item.id?.includes('edge')) {
          // is an edge
          edgesTemp.push(item);
        } else {
          nodesTemp.push(item);
        }
      });
    }

    setNodes(nodesTemp);
    setEdges(edgesTemp);

    api.updateConnectorFlowConfig({
      edges: edgesTemp,
      nodes: nodesTemp,
    })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    // eslint-disable-next-line no-console
    console.table(nodesTemp);
    // eslint-disable-next-line no-console
    console.table(edgesTemp);
  };

  // TODO Cache / use a hook to get the configuration on page load (as with metrics / integrations)
  // Do it when redesigning the flow elements / page
  // Maybe use sessionStorage? (not updated often, so this is ok?)
  useEffect(() => {
    if (api) {
      api.getConnectorFlowConfig()
        .then((response) => {
          if (!response.data || response.data === '') {
            setNodes([]);
            setEdges([]);
          } else if (!response.data.nodes || !response.data.edges) {
            migrateToV10(response.data);
          } else {
            setNodes(response.data.nodes);
            setEdges(response.data.edges);
          }
        }).catch((error) => {
          updateAndOpenInfoModal('Error retrieving config', `Error was ${error.message}`);
        })
        .finally(() => setConfigLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <Box>
      <Box>
        <ConnectorFlowProvider
          loading={configLoading}
          updateAndOpenInfoModal={updateAndOpenInfoModal}
          nodes={nodes}
          setNodes={setNodes}
          edges={edges}
          setEdges={setEdges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
        />
      </Box>
      <InfoModal
        open={open}
        handleClose={handleClose}
        title={dialogTitle}
        text={dialogText}
      />
    </Box>
  );
};

export default OverviewFlowPage;
