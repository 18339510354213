import {
  React, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import MainHeader from '../Headers/MainHeader';
import { PageHeader as PageHeader1 } from '../Redesign/PageHeader';
import { getIsDrawerOpen, setIsDrawerOpen } from '../../Utils/LocalStorageUtils';
import CardBodyPage from '../Redesign/CardBodyPage';

const useStyles = makeStyles()((theme, { navWidth }) => ({
  content: {
    transition: 'all 0.5s ease-in-out',
    position: 'relative',
    left: `${navWidth}px`,
    width: `calc(100% - ${navWidth}px)`,
    height: '100%',
  },
  contentBody: {
    ...theme.contentBody,
  },
}));

const NAV_WIDTH = 231;
const NAV_WIDTH_CLOSED = 72;

const NavPage = ({ PageComponent, PageHeader, includeCardBodyPage }) => {
  const [navWidth, setNavWidth] = useState(getIsDrawerOpen() ? NAV_WIDTH : NAV_WIDTH_CLOSED);
  const [open, setOpen] = useState(getIsDrawerOpen());
  const { classes } = useStyles({ navWidth });
  const contentBoxRef = useRef(null);

  const handleDrawer = () => {
    setOpen((val) => !val);
  };

  useEffect(() => {
    if (open) {
      setNavWidth(NAV_WIDTH); // some other value (icon size?)
      setIsDrawerOpen(true);
    } else {
      setNavWidth(NAV_WIDTH_CLOSED);
      setIsDrawerOpen(false);
    }
  }, [open]);

  return (
    <Box>
      <NavigationMenu open={open} navWidth={navWidth} handleDrawer={handleDrawer} />
      <Box className={classes.content}>
        <MainHeader
          navWidth={0}
          PageHeader={PageHeader}
        />
        <Box className={classes.contentBody} ref={contentBoxRef}>
          {includeCardBodyPage ? (
            <CardBodyPage>
              <PageComponent
                contentBoxRef={contentBoxRef}
              />
            </CardBodyPage>
          ) : (
            <PageComponent
              contentBoxRef={contentBoxRef}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

NavPage.propTypes = {
  PageComponent: PropTypes.elementType.isRequired,
  PageHeader: PropTypes.node,
  includeCardBodyPage: PropTypes.bool,
};

NavPage.defaultProps = {
  PageHeader: (
    <PageHeader1 />
  ),
  includeCardBodyPage: false,
};

export default NavPage;
