export const edgeTypeNames = {
  input: 'inputConnector', // from input to bus
  output: 'outputConnector', // from bus to output
  sourceData: 'sourceData', // from data source to *something*
  targetData: 'targetData', // from *something* to data target
  custom: 'custom', // what the actual edge type for react-flow-renderer is called
};

export const markerIds = {
  input: 'edge-marker-input',
  output: 'edge-marker-output',
  sourceData: 'edge-marker-dataSource',
  targetData: 'edge-marker-dataTarget',
};

export const nodeTypeNames = {
  input: 'inputConnector',
  output: 'outputConnector',
  sourceData: 'sourceData',
  targetData: 'targetData',
  dataConnector: 'dataConnector',
  bus: 'busConnector',
};

// Dummy elements for when the config does not load from Azure Storage
export const dummyElements = [
  {
    id: '1', data: { label: 'Error loading config' }, position: { x: 50, y: 25 }, type: 'default',
  },
];

export const dummyEdges = [];
