import React from 'react';

const StatusFilteredIcon = () => (
  <svg style={{ paddingLeft: 2.415, paddingRight: 2.415 }} width="24.17" height="24.17" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">
    <path fill="#0062a4" d="M24.5,4.62c10.96,0,19.88,8.92,19.88,19.88s-8.92,19.88-19.88,19.88S4.62,35.46,4.62,24.5,13.54,4.62,24.5,4.62M24.5,.12C11.04,.12,.12,11.04,.12,24.5s10.91,24.38,24.38,24.38,24.38-10.91,24.38-24.38S37.96,.12,24.5,.12h0Z" />
    <polygon fill="#0062a4" points="13.63 15.61 21.85 28.11 21.85 37.16 27.14 33.99 27.14 28.13 35.37 15.61 13.63 15.61" />
  </svg>
);

export default StatusFilteredIcon;
