import React from 'react';

const DetailsIconDark = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.43584 7.7886C6.81843 9.78974 6.81843 11.9603 7.43584 13.9614C14.4623 14.6386 21.5376 14.6386 28.5641 13.9614C29.1815 11.9603 29.1815 9.78974 28.5641 7.7886C21.5376 7.1114 14.4623 7.1114 7.43584 7.7886ZM7.0631 5.56421C14.337 4.85286 21.6629 4.85286 28.9368 5.56421C29.7387 5.64262 30.4126 6.19206 30.6589 6.95072C31.4833 9.49035 31.4833 12.2597 30.6589 14.7993C30.4126 15.558 29.7387 16.1074 28.9368 16.1858C21.663 16.8972 14.337 16.8972 7.0631 16.1858C6.26127 16.1074 5.58737 15.558 5.34109 14.7993C4.51667 12.2597 4.51667 9.49035 5.34109 6.95072C5.58737 6.19206 6.26127 5.64262 7.0631 5.56421Z" fill="#333333" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.43584 22.0386C6.81843 24.0397 6.81843 26.2103 7.43584 28.2114C14.4623 28.8886 21.5376 28.8886 28.5641 28.2114C29.1815 26.2103 29.1815 24.0397 28.5641 22.0386C21.5376 21.3614 14.4623 21.3614 7.43584 22.0386ZM7.0631 19.8142C14.337 19.1029 21.6629 19.1029 28.9368 19.8142C29.7387 19.8926 30.4126 20.4421 30.6589 21.2007C31.4833 23.7404 31.4833 26.5097 30.6589 29.0493C30.4126 29.808 29.7387 30.3574 28.9368 30.4358C21.663 31.1472 14.337 31.1472 7.0631 30.4358C6.26127 30.3574 5.58737 29.808 5.34109 29.0493C4.51667 26.5097 4.51667 23.7404 5.34109 21.2007C5.58737 20.4421 6.26127 19.8926 7.0631 19.8142Z" fill="#333333" />
  </svg>
);

export default DetailsIconDark;
