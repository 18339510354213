import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Mail from '@mui/icons-material/Mail';
import { FeedbackOutlined } from '@mui/icons-material';
import { toDateTimeNoSecStandardString } from '../../../Utils/DateTimeUtils';
import { INTEGRATION_RUN_STATUS } from '../../../Constants/Constants';
import StatusFailedIcon from '../Icons/RunsTable/StatusFailedIcon';
import StatusOkIcon from '../Icons/RunsTable/StatusOkIcon';
import ExpandMinusIcon from '../Icons/RunsTable/ExpandMinusIcon';
import ExpandPlusIcon from '../Icons/RunsTable/ExpandPlusIcon';
import ResolveCheckedIcon from '../Icons/RunsTable/ResolveCheckedIcon';
import ResolveUncheckedIcon from '../Icons/RunsTable/ResolveUncheckedIcon';
import StatusSolvedIcon from '../Icons/RunsTable/StatusSolvedIcon';
import DetailsRunDetailModal from './DetailsRunDetailModal';
import DataErrorTableRow from '../../DataErrorTableRow/DataErrorTableRow';
import DataResolvedTableRow from '../../DataResolvedTableRow/DataResolvedTableRow';
import capitalizeAndLower from '../../../Utils/Utils';
import RunResolveDialog from './RunResolveDialog';
import { checkForDeadLetter } from '../../../Utils/RunUtils';
import { isRunOkOrFilter } from '../../../Utils/IntegrationStatusUtils';
import StatusFilteredIcon from '../Icons/RunsTable/StatusFilteredIcon';
import ResolveSelectedIcon from '../Icons/RunsTable/ResolveSelectedIcon';
import CustomTooltip from '../CustomTooltip.tsx';

const useStyle = makeStyles()((theme) => ({
  ...theme.details,
}));

const DetailsRunTableRow = (props) => {
  const {
    run, tableOverflowRef, refreshRuns, onRowClick, isSelected, onDLBtnClick,
  } = props;
  const {
    id, type, startedDateTime, /* finishedDateTime, */ resolvedDate,
    message, dataObjectsJson, dataErrors, resolveReason, resolvedBy,
    exceptionString,
  } = run;
  const [expanded, setExpanded] = useState(false);

  // Run details modal:
  const [open, setOpen] = useState(false);
  const [hasRunData, setHasRunData] = useState(true);
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [modalObject, setModalObject] = useState('');
  const [modalErrorInfo, setModalErrorInfo] = useState(null);
  const [modalHeader, setModalHeader] = useState('');
  const [hasDeadLetter, setHasDeadLetter] = useState(false);

  const [shouldCombineDataErrors, setShouldCombineDataErrors] = useState(false);
  const [combinedObj, setCombinedObj] = useState({});

  const { classes } = useStyle();

  const [showOpenBtn, setShowOpenBtn] = useState(true);
  const [columnCount, setColumnCount] = useState(0);
  const [checkAgain, setCheckAgain] = useState(false);

  // Resolved run modal
  const [resolvedOpen, setResolvedOpen] = useState(false);

  useEffect(() => {
    if (expanded) {
      // BUG: if you expand -> resize (smaller) => expand, it will only show "open"
      // Not sure why...
      // If you expand again it works...
      // Works "fine enough" for now...
      // Live resize of the expanded row does NOT work -> ok?
      // Resize outside expand works^
      // TODO? See #612
      const width = tableOverflowRef?.current?.el?.offsetWidth ?? 0;

      // this will be true if a scrollbar has appeared in the table (i.e too long expanded)
      const isOverflow = width < tableOverflowRef?.current?.el?.scrollWidth ?? 0;

      let newColumnCount = columnCount;
      if (isOverflow) newColumnCount = Math.max(0, columnCount - 1);

      setColumnCount(newColumnCount);
      if (isOverflow && newColumnCount !== 0) {
        setCheckAgain((val) => !val);
      } else {
        if (headers.length > newColumnCount) {
          setShowOpenBtn(true);
        }
        if (headers.length === newColumnCount) setShowOpenBtn(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAgain]);

  const expandBtnOnClick = () => {
    setExpanded((value) => !value); // Maybe collapse all others when one is opened?
  };

  useEffect(() => {
    if (expanded) {
      setColumnCount(headers.length);
      setCheckAgain((val) => !val);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const decideStatusIcon = () => {
    switch (type) {
    case INTEGRATION_RUN_STATUS.FAILED:
      if (resolvedBy) return (<StatusSolvedIcon />);
      return (<StatusFailedIcon />);
    case INTEGRATION_RUN_STATUS.FILTER:
      return (<StatusFilteredIcon />);
    case INTEGRATION_RUN_STATUS.OK:
      return (<StatusOkIcon />);
    case INTEGRATION_RUN_STATUS.RESOLVED:
      return (<StatusSolvedIcon />);
    default:
      return (<StatusSolvedIcon />);
    }
  };

  /**
   * Generates headers and data for the expanded table row
   * @returns
   */
  const handleRunJsonData = () => {
    let runData = dataObjectsJson;
    let runHeaders = null;
    let parsed = null;
    let multipleErrorRows = false;

    if (type !== INTEGRATION_RUN_STATUS.OK) {
      if (dataErrors && Array.isArray(dataErrors)) {
        const { length } = dataErrors;
        if (length > 1) {
          const filteredObjs = dataErrors.map((err) => err.dataObjectJson)
            .filter((obj) => obj !== null);
          if (filteredObjs.length > 0) {
            const obj = filteredObjs[0];
            let sameObj = true;
            for (let i = 1; i < filteredObjs.length; i += 1) {
              sameObj = sameObj && obj === filteredObjs[i];
            }
            multipleErrorRows = !sameObj;
            setShouldCombineDataErrors(sameObj);
            setCombinedObj(obj);
            runData = obj;
          }
        } else if (length === 1) {
          if (dataErrors[0].dataObjectJson) {
            const sameObj = dataErrors[0].dataObjectJson === dataObjectsJson;
            if (!sameObj) runData = dataErrors[0].dataObjectJson;
          }
        }
      }
    }

    let hasData = false;
    let dontShow = false;
    // generate headers + the data the rows will use
    try {
      parsed = JSON.parse(runData);
      // need to check for nouns:
      const { Nouns } = parsed;
      const { jsonarray } = parsed;
      if (Nouns && Array.isArray(Nouns) && Nouns.length >= 1) {
        [parsed] = Nouns;
      } else if (jsonarray && Array.isArray(jsonarray)) {
        if (jsonarray.length === 0) dontShow = true;
        parsed = jsonarray;
      }
      if (Array.isArray(parsed)) {
        runHeaders = Object.keys(parsed[0]);
      } else {
        runHeaders = Object.keys(parsed);
      }

      if (multipleErrorRows) {
        parsed = dataErrors.map((item) => JSON.parse(item.dataObjectJson));
      }
    } catch (error) {
      runHeaders = ['Error', 'Data Object', 'Data Errors'];
      parsed = { Error: error, DataObject: dataObjectsJson, DataErrors: dataErrors };
    }
    hasData = (dataObjectsJson || dataErrors?.length > 0) && !dontShow;

    return { rowHeaders: runHeaders, rowData: parsed, hasData };
  };

  /**
   * Reorders headers so that the first ones are the ones with errors
   * @param {Array} headerArray
   */
  const reorderHeaders = (headerArray = headers) => {
    if (type !== INTEGRATION_RUN_STATUS.OK && dataErrors) {
      /** @type {Array} */
      const fieldNames = dataErrors.map((err) => err?.fieldName?.split('.')[0]);
      // sorts the header array so that the "failed fields" are first!
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      headerArray.sort((a, _b) => (fieldNames.includes(a) ? -1 : 0));
    }
  };

  const getBODID = () => {
    try {
      const obj = JSON.parse(dataObjectsJson);
      return obj?.ApplicationArea?.BODID;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const { rowHeaders, rowData, hasData } = handleRunJsonData();
    setHasRunData(hasData);
    const hasDL = checkForDeadLetter(run);
    setHasDeadLetter(hasDL);
    if (hasData) {
      setHeaders(rowHeaders);
      reorderHeaders(rowHeaders);
      setColumnCount(rowHeaders.length);
      setData(rowData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run]);

  const handleRowButtonClick = (object, headerName, errorInfo = null) => {
    setModalHeader(headerName);
    setModalObject(object);
    setModalErrorInfo(errorInfo);
    setOpen(true);
  };

  const handleRowClick = () => {
    if (type === INTEGRATION_RUN_STATUS.FAILED) {
      onRowClick(id);
    }
  };

  const handleResolveCellClick = () => {
    if (type === INTEGRATION_RUN_STATUS.FAILED) {
      handleRowClick();
    } else if (type === INTEGRATION_RUN_STATUS.RESOLVED) {
      setResolvedOpen(true);
    }
  };

  const getResolvedColSpan = () => {
    if (hasDeadLetter) return 1;
    if (hasRunData) return 2;
    return 3;
  };

  const createExpandedRows = () => {
    let dataArray = data;
    if (!Array.isArray(data)) dataArray = [data];
    if (dataErrors?.length === 0) {
      return dataArray.map((item, key) => (
        <DataResolvedTableRow
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          data={item}
          headerNames={headers}
          onClick={handleRowButtonClick}
          showOpenBtn={showOpenBtn}
          columnCount={columnCount}
        />
      ));
    }
    if (shouldCombineDataErrors) {
      return (
        <DataErrorTableRow
          combinedRow
          combinedObj={combinedObj}
          dataErrorArray={dataErrors}
          headerNames={headers}
          onClick={handleRowButtonClick}
          showOpenBtn={showOpenBtn}
          columnCount={columnCount}
          runDataObj={data}
        />
      );
    }
    return dataErrors?.map((item, key) => (
      <DataErrorTableRow
        // eslint-disable-next-line react/no-array-index-key
        key={key}
        dataError={item}
        headerNames={headers}
        onClick={handleRowButtonClick}
        showOpenBtn={showOpenBtn}
        columnCount={columnCount}
        runDataObj={data}
      />
    ));
  };

  return (
    <>
      <TableRow
        key={id}
        hover
        className={`${classes.runsTableRow} ${isSelected ? classes.runsTableRowSelected : ''}`}
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell
          className={`${classes.runsTableRowCells} ${classes.runsTableStatusCell}`}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            {decideStatusIcon()}
            <Typography className={classes.runsTableRowText}>
              {capitalizeAndLower(type)}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          className={`${classes.runsTableRowCells} ${classes.runsTableDateCell}`}
        >
          <Typography
            className={classes.runsTableRowText}
          >
            {toDateTimeNoSecStandardString(startedDateTime)}
          </Typography>
        </TableCell>
        {/* <TableCell
          className={`${classes.runsTableRowCells} ${classes.runsTableDateCell}`}
        >
          <Typography
            className={classes.runsTableRowText}
          >
            {toDateTimeNoSecStandardString(finishedDateTime)}
          </Typography>
        </TableCell> */}
        <TableCell
          className={`${classes.runsTableRowCells}`}
        >
          {exceptionString ? (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography className={`${classes.runsTableRowText} ${classes.runsTableMessageText}`}>
                {message}
              </Typography>
              <IconButton onClick={() => handleRowButtonClick(exceptionString, 'Exception')}>
                <CustomTooltip
                  title="Exception details"
                  placement="left"
                >
                  <FeedbackOutlined htmlColor="#003366" />
                </CustomTooltip>
              </IconButton>
            </Stack>
          ) : (
            <Typography className={`${classes.runsTableRowText} ${classes.runsTableMessageText}`}>
              {message}
            </Typography>
          )}
        </TableCell>
        <TableCell
          colSpan={getResolvedColSpan()}
          // eslint-disable-next-line max-len
          className={`${classes.runsTableRowCells} ${classes.runsTableResolvedCell} ${!isRunOkOrFilter(type) && classes.runsTableResolvedCellCursor}`}
          onClick={handleResolveCellClick}
        >
          <Stack direction="row" alignItems="center" gap={1.5}>
            {!isRunOkOrFilter(type) && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {type === INTEGRATION_RUN_STATUS.RESOLVED ? (
                  <>
                    <ResolveCheckedIcon />
                    <Typography className={classes.runsTableRowText}>
                      {resolvedBy}
                    </Typography>
                  </>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {/* TODO Fix selected icon (need design) */}
                    {isSelected ? (
                      <ResolveSelectedIcon />
                    ) : (
                      <ResolveUncheckedIcon />
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </TableCell>
        {hasDeadLetter && (
          <TableCell
            colSpan={hasRunData ? 1 : 2}
            className={`${classes.runsTableRowCells} ${classes.runsTableDLCell}`}
          >
            <IconButton size="small" onClick={() => onDLBtnClick(getBODID(), run)}>
              <Mail color="primary" />
            </IconButton>
          </TableCell>
        )}
        {hasRunData && (
          <TableCell align="center" className={`${classes.runsTableExpandTableCell} ${classes.runsTableRowCells}`} onClick={expandBtnOnClick}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              {expanded ? (
                <ExpandMinusIcon />
              ) : (
                <ExpandPlusIcon />
              )}
            </Stack>
          </TableCell>
        )}
      </TableRow>
      <TableRow key="collapse">
        <TableCell
          style={{ paddingTop: 0, paddingBottom: 0 }}
          colSpan={7}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box className={classes.runsTableExpandedBox}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {headers.map((item, index) => {
                        if (showOpenBtn && index >= columnCount) {
                          return null;
                        }
                        return (
                        // eslint-disable-next-line react/no-array-index-key
                          <TableCell key={index}>
                            <Typography className={classes.runsTableHeaderText}>
                              {item}
                            </Typography>
                          </TableCell>

                        );
                      })}
                      {showOpenBtn && (
                        <TableCell key="open" />
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {createExpandedRows()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
          <DetailsRunDetailModal
            open={open}
            handleClose={() => setOpen(false)}
            run={run}
            dataObject={modalObject}
            errorInfo={modalErrorInfo}
            header={modalHeader}
          />
          <RunResolveDialog
            open={resolvedOpen}
            handleClose={() => setResolvedOpen(false)}
            runId={id}
            onRunFinishedUpdating={refreshRuns}
            resolvedBy={resolvedBy}
            resolveReason={resolveReason}
            resolvedDate={resolvedDate}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

DetailsRunTableRow.propTypes = {
  run: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    startedDateTime: PropTypes.string,
    finishedDateTime: PropTypes.string,
    message: PropTypes.string,
    resolvedBy: PropTypes.string,
    resolveReason: PropTypes.string,
    resolvedDate: PropTypes.string,
    errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataObjectsJson: PropTypes.string,
    dataErrors: PropTypes.arrayOf(PropTypes.shape({
      dataObjectJson: PropTypes.string,
    })),
    deadLetterStatus: PropTypes.string,
    exceptionString: PropTypes.string,
  }).isRequired,
  tableOverflowRef: PropTypes.shape().isRequired,
  refreshRuns: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onDLBtnClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

DetailsRunTableRow.defaultProps = {
  isSelected: false,
};

export default DetailsRunTableRow;
