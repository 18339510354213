import React from 'react';

const InputSearchIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.3125" cy="7.3125" r="6.5625" stroke="#C4C4C4" strokeWidth="1.5" />
    <path d="M12.375 12.375L16.875 16.875" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default InputSearchIcon;
