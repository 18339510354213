import React from 'react';

const SettingsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9998 3.07922L9.78697 5.36872C9.55145 5.6124 9.22707 5.75002 8.88816 5.75002H5.74977V8.8884C5.74977 9.22731 5.61216 9.55169 5.36847 9.78722L3.07897 12L5.36847 14.2128C5.61216 14.4483 5.74977 14.7727 5.74977 15.1116V18.25H8.88816C9.22707 18.25 9.55145 18.3876 9.78697 18.6313L11.9998 20.9208L14.2126 18.6313C14.4481 18.3876 14.7725 18.25 15.1114 18.25H18.2498V15.1116C18.2498 14.7727 18.3874 14.4483 18.6311 14.2128L20.9206 12L18.6311 9.78722C18.3874 9.5517 18.2498 9.22732 18.2498 8.88841V5.75002H15.1114C14.7725 5.75002 14.4481 5.61241 14.2126 5.36872L11.9998 3.07922ZM11.101 1.85078C11.5923 1.34238 12.4072 1.34238 12.8986 1.85078L15.2174 4.25002H18.4998C19.1901 4.25002 19.7498 4.80966 19.7498 5.50002V8.78235L22.149 11.1012C22.6574 11.5926 22.6574 12.4075 22.149 12.8988L19.7498 15.2177V18.5C19.7498 19.1904 19.1901 19.75 18.4998 19.75H15.2174L12.8986 22.1492C12.4072 22.6576 11.5923 22.6576 11.101 22.1492L8.7821 19.75H5.49977C4.80942 19.75 4.24977 19.1904 4.24977 18.5V15.2177L1.85054 12.8988C1.34214 12.4075 1.34214 11.5926 1.85054 11.1012L4.24977 8.78235V5.50002C4.24977 4.80966 4.80942 4.25002 5.49977 4.25002H8.7821L11.101 1.85078Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.24977 12C7.24977 9.37666 9.37642 7.25002 11.9998 7.25002C14.6231 7.25002 16.7498 9.37666 16.7498 12C16.7498 14.6234 14.6231 16.75 11.9998 16.75C9.37642 16.75 7.24977 14.6234 7.24977 12ZM11.9998 8.75002C10.2048 8.75002 8.74977 10.2051 8.74977 12C8.74977 13.7949 10.2048 15.25 11.9998 15.25C13.7947 15.25 15.2498 13.7949 15.2498 12C15.2498 10.2051 13.7947 8.75002 11.9998 8.75002Z" fill="white" />
  </svg>
);

export default SettingsIcon;
