import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'react-flow-renderer';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ConnectorNodeContent from './Common/ConnectorNodeContent';

const useStyles = makeStyles()((theme) => ({
  outputConnector: {
    background: theme.nodes.background,
    color: theme.nodes.color,
    borderWidth: theme.nodes.borderWidth,
    borderStyle: theme.nodes.borderStyle,
    borderColor: theme.nodes.connector.outputColor,
    borderRadius: theme.nodes.borderRadius,
  },
  outputHandle: {
    background: theme.nodes.connector.outputColor,
  },
}));

const OutputConnectorNode = memo(({ data, isConnectable }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.outputConnector}>
      <Handle
        className={classes.outputHandle}
        type="target"
        position={Position.Left}
        id="t"
        isConnectable={isConnectable}
      />
      <ConnectorNodeContent data={data} />
    </Box>
  );
});

OutputConnectorNode.propTypes = {
  isConnectable: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
  }),
};

OutputConnectorNode.defaultProps = {
  data: {
    label: 'Output',
  },
};

export default OutputConnectorNode;
