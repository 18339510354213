import React from 'react';

import dayjs from 'dayjs';
import {
  Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import relativeTime from 'dayjs/plugin/relativeTime';
import ServicesTableRow from './ServicesTableRow';

import { servicesHeaderNameVariableMap } from '../../Constants/Constants';

const useStyle = makeStyles()((theme) => ({
  tableHeader: {
    backgroundColor: theme.tableHeader.backgroundColor,
  },
}));

const ServicesTable = (props) => {
  const {
    services, refreshServices, updateModalAndOpen,
  } = props;

  dayjs.extend(relativeTime);
  const { classes } = useStyle();

  return (
    <Table>
      <TableHead className={classes.tableHeader}>
        <TableRow>
          <TableCell>
            <TableSortLabel>
              Tasks
            </TableSortLabel>
          </TableCell>
          {Object.keys(servicesHeaderNameVariableMap).map(
            (headerName) => (
              <TableCell key={headerName}>
                <TableSortLabel>
                  {headerName}
                </TableSortLabel>
              </TableCell>
            ),
          )}
          <TableCell key="controls" />
        </TableRow>
      </TableHead>
      <TableBody>
        {services?.map((service) => (
          <ServicesTableRow
            service={service}
            key={service.guid}
            refreshServices={refreshServices}
            updateModalAndOpen={updateModalAndOpen}
          />
        ))}
      </TableBody>
    </Table>
  );
};

ServicesTable.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape).isRequired,
  refreshServices: PropTypes.func.isRequired,
  updateModalAndOpen: PropTypes.func.isRequired,
};

ServicesTable.defaultProps = {
};

export default ServicesTable;
