import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

const IntegrationArrowLeftIcon = ({ className, onClick }) => (
  <IconButton size="small" onClick={onClick} className={className}>
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="35" fill="#D8DCDD" />
      <path d="M19.9393 33.9393C19.3536 34.5251 19.3536 35.4749 19.9393 36.0607L29.4853 45.6066C30.0711 46.1924 31.0208 46.1924 31.6066 45.6066C32.1924 45.0208 32.1924 44.0711 31.6066 43.4853L23.1213 35L31.6066 26.5147C32.1924 25.9289 32.1924 24.9792 31.6066 24.3934C31.0208 23.8076 30.0711 23.8076 29.4853 24.3934L19.9393 33.9393ZM50 33.5L21 33.5L21 36.5L50 36.5L50 33.5Z" fill="black" />
    </svg>
  </IconButton>
);

IntegrationArrowLeftIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

IntegrationArrowLeftIcon.defaultProps = {
  className: '',
};

export default IntegrationArrowLeftIcon;
