/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Table, TableBody, TableContainer, Box, Typography,
  CircularProgress, IconButton, Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Sync } from '@mui/icons-material';
import { STATUS_COLORS } from '../../Constants/Constants';
import { queueDataDefinition, targetSubscriptionDataDefinition, topicDataDefinition } from '../../Constants/ServiceBusConstants';
import QueueTableRow from './Table/QueueTableRow';
import TopicTableRow from './Table/TopicTableRow';
import { generateTableHeaders } from '../../Utils/TableUtils';
import SubscriptionTableRow from './Table/SubscriptionTableRow';
import IntegrationsContext from '../../Contexts/IntegrationsContext';

const useStyles = makeStyles()(() => ({
  table: {
    marginBottom: 10,
  },
  errorTypography: {
    color: STATUS_COLORS.FAILED,
    whiteSpace: 'pre-wrap',
  },
}));

const ServiceBusIntegrationStatus = (props) => {
  const {
    integrationId, btnQueueDeadLetterOnClick, btnSubDeadLetterOnClick, integration,
  } = props;
  const { classes } = useStyles();
  const [showExtraError, setShowExtraErrro] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [queues, setQueues] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [refreshed, setRefreshed] = useState(false);
  const [showSource, setShowSource] = useState(false);
  const [showTarget, setShowTarget] = useState(false);

  // TODO Fix to only one endpoint to get info!
  const [wasSourceError, setWasSourceError] = useState(false);
  const [wasTargetError, setWasTargetError] = useState(false);

  const { api } = useContext(IntegrationsContext);

  const onRefreshBtnClick = () => {
    setRefreshed(!refreshed);
  };

  const checkSourceError = (data) => {
    if (data.queueError || data.topicError) {
      setShowExtraErrro(true);
      /* eslint-disable-next-line max-len */
      const msg = `${data.queueError && `Error on retrieving queue properties:\n${data.queueError}`}\n${data.topicError && `Error on retrieving topic properties:\n${data.topicError}`}`;
      setErrorMsg(msg);
    }
  };

  const checkTargetError = (data) => {
    if (data.subTopicErrors?.length > 0) {
      setShowExtraErrro(true);
      const msg = `There was an error retrieving properties on the given topics: ${data.subTopicErrors.join(',')}`;
      setErrorMsg(msg);
    }
  };

  useEffect(() => {
    setLoading(true);
    setShowTarget(false);
    setShowSource(false);
    setWasSourceError(false);
    setWasTargetError(false);
    if (api) {
      // TODO Change to retrieve all info (only 1 endpoint really)
      // if (integration?.serviceBusType === 'Source') {
      api.getSourceOverviewServiceBusProperties(integrationId)
        .then((response) => {
          setQueues(response.data.queuesProperties);
          setTopics(response.data.topicsProperties);
          checkSourceError(response.data);
          setShowSource(true);
        })
        .catch((error) => {
          setWasSourceError(true);
          setErrorMsg(`\n${error}`);
        })
        .finally(() => setLoading(false));
      // TODO Change to retrieve all info (only 1 endpoint really)
      // } else if (integration?.serviceBusType === 'Target') {
      api.getTargetOverviewServiceBusProperties(integrationId)
        .then((response) => {
          setSubscriptions(response.data.subscriptionsProperties);
          checkTargetError(response.data);
          setShowTarget(true);
        })
        .catch((error) => {
          setWasTargetError(true);
          setErrorMsg((msg) => `${msg}\n${error}`);
        })
        .finally(() => setLoading(false));
      // }
    }
  }, [api, integration, integrationId, refreshed]);

  const refreshSBElement = (
    <Tooltip title="Refresh servicebus data">
      <IconButton color="secondary" onClick={onRefreshBtnClick}>
        <Sync />
      </IconButton>
    </Tooltip>
  );

  return (
    <Box>
      {loading ? (<CircularProgress />)
        : ((wasSourceError && wasTargetError) ? (
          <>
            <Typography className={classes.errorTypography}>
              Error loading service bus queue and topic information
              {errorMsg}
            </Typography>
            {refreshSBElement}
          </>
        ) : (
          <>
            {showSource && (
              <>
                <Typography gutterBottom>Queues</Typography>
                <TableContainer component={Paper} className={classes.table}>
                  <Table size="small">
                    {generateTableHeaders(queueDataDefinition)}
                    <TableBody>
                      {queues?.map((item) => (
                        <QueueTableRow
                          key={item.name}
                          queue={item}
                          btnQueueDeadLetterOnClick={btnQueueDeadLetterOnClick}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography gutterBottom>Topics</Typography>
                <TableContainer component={Paper} className={classes.table}>
                  <Table size="small">
                    {generateTableHeaders(topicDataDefinition)}
                    <TableBody>
                      {topics?.map((item) => (
                        <TopicTableRow
                          key={item.topicRuntimeProperties.name}
                          topic={item}
                          btnSubDeadLetterOnClick={btnSubDeadLetterOnClick}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {showTarget && (
              <TableContainer component={Paper}>
                <Table size="small">
                  {generateTableHeaders(targetSubscriptionDataDefinition, true, true)}
                  <TableBody>
                    {subscriptions.map((item) => (
                      <SubscriptionTableRow
                        key={item.topicName}
                        subscription={item}
                        showTopicName
                        btnSubDeadLetterOnClick={btnSubDeadLetterOnClick}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {refreshSBElement}
            {showExtraError && (
              <Typography className={classes.errorTypography}>
                {errorMsg}
              </Typography>
            )}
          </>
        ))}
    </Box>
  );
};

ServiceBusIntegrationStatus.propTypes = {
  serviceBusSettings: PropTypes.shape({
    connectionString: PropTypes.string,
    queueTopicNames: PropTypes.arrayOf(PropTypes.shape({
      queueName: PropTypes.string,
      topicName: PropTypes.string,
    })),
  }),
  integrationId: PropTypes.string,
  btnQueueDeadLetterOnClick: PropTypes.func.isRequired,
  btnSubDeadLetterOnClick: PropTypes.func.isRequired,
  integration: PropTypes.shape({}).isRequired,
};

ServiceBusIntegrationStatus.defaultProps = {
  serviceBusSettings: null,
  integrationId: '0',
};

export default ServiceBusIntegrationStatus;
