import dayjs from 'dayjs';

const LOGGED_IN_KEY = 'lastLoggedInTime';
const TIME_ELAPSED_HOURS = 24;
const DRAWER_KEY = 'isDrawerOpen';

export const getLocalStorageItem = (key) => window.localStorage.getItem(key);

export const getLocalStorageObject = (key) => {
  const item = getLocalStorageItem(key);
  try {
    return JSON.parse(item);
  } catch (error) {
    return null;
  }
};

export const setLocalStorageItem = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const setLocalStorageObject = (key, value) => {
  setLocalStorageItem(key, JSON.stringify(value));
};

export const getLastLoggedInTime = () => window.localStorage.getItem(LOGGED_IN_KEY);

export const setLoggedInTime = () => {
  window.localStorage.setItem(LOGGED_IN_KEY, JSON.stringify(dayjs()));
};

// Unnecessary? - Only works on page load
// (could make on page exit, ie the return function of the useEffect?)
/**
 * Checks if the user has been logged in longer than a set period (ex. a day)
 * @returns false if time has not elapsed, true if the time has elapsed
 */
export const checkLoggedInElapsed = () => {
  const item = getLastLoggedInTime();
  if (!item) return false;
  try {
    const time = dayjs(JSON.parse(item));
    const now = dayjs();
    const diffHour = now.diff(time, 'hour');
    if (diffHour > TIME_ELAPSED_HOURS) return true;
  } catch (error) {
    return true; // force sign out in case of "tamper"
  }
  return false;
};

export const getIsDrawerOpen = () => {
  const isOpen = getLocalStorageItem(DRAWER_KEY);
  if (isOpen && isOpen === 'true') return true;
  return false;
};

export const setIsDrawerOpen = (open) => {
  setLocalStorageItem(DRAWER_KEY, `${open}`);
};
