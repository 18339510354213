import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Divider, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IntegrationsContext from '../../Contexts/IntegrationsContext';
import { findAndSetIntegration, findIntegration } from '../../Utils/IntegrationUtils';
import DeadLetterModal from '../Modal/DeadLetterModal';
import ServiceBusIntegrationStatus from './ServiceBusIntegrationStatus';

const useStyles = makeStyles()(() => ({
  box: {
    marginTop: 10,
    marginBottom: 10,
  },
  dividerUnder: {
    marginTop: 10,
    marginBottom: 10,
  },
  modalNoInfoText: {
    whiteSpace: 'pre-wrap',
  },
}));

const ServiceBusStatus = (props) => {
  const { integrationId, modalView } = props;

  const { classes } = useStyles();

  const { requestData: integrations } = useContext(IntegrationsContext);
  const [integration, setIntegration] = useState(null);
  const [showSourceStatus, setShowSourceStatus] = useState(false);
  const [showTargetStatus, setShowTargetStatus] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [isQueue, setIsQueue] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [apiProperties, setApiProperties] = useState({});
  const [maxDLMessages, setMaxDLMessages] = useState(10);
  const [showDeadletters, setShowDeadletters] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const btnQueueDeadLetterOnClick = (queueName, maxMessages = 10) => {
    setMaxDLMessages(maxMessages);
    setApiProperties({ queueName });
    setShowDeadletters(false);
    setIsQueue(true);
    setIsSubscription(false);
    handleOpen();
  };

  const btnSubDeadLetterOnClick = (topicName, subscriptionName, maxMessages = 10) => {
    setMaxDLMessages(maxMessages);
    setApiProperties({ topicName, subscriptionName });
    setShowDeadletters(false);
    setIsSubscription(true);
    setIsQueue(false);
    handleOpen();
  };

  useEffect(() => {
    findAndSetIntegration(integrationId, integrations, setIntegration);

    setShowSourceStatus(true);
    setShowTargetStatus(true);
  }, [integration, integrationId, integrations]);

  return (
    <Box className={classes.box}>
      {(showSourceStatus || showTargetStatus) && (
        <ServiceBusIntegrationStatus
          integrationId={integrationId}
          btnQueueDeadLetterOnClick={btnQueueDeadLetterOnClick}
          btnSubDeadLetterOnClick={btnSubDeadLetterOnClick}
          integration={findIntegration(integrationId, integrations)}
        />
      )}
      <DeadLetterModal
        open={open}
        handleClose={handleClose}
        isQueue={isQueue}
        isSubscription={isSubscription}
        apiProperties={apiProperties}
        integrationId={integrationId}
        maxDLMessages={maxDLMessages}
        setMaxDLMessages={setMaxDLMessages}
        showDeadletters={showDeadletters}
        setShowDeadletters={setShowDeadletters}
      />
      {(!modalView && (showSourceStatus || showTargetStatus)) && (
        <Divider className={classes.dividerUnder} />
      )}
      {(modalView && !showSourceStatus && !showTargetStatus) && (
        // eslint-disable-next-line max-len
        <Typography className={classes.modalNoInfoText}>{'No Service Bus Info on this Integration\n(missing information when it was registered to the backend)'}</Typography>
      )}
    </Box>
  );
};

ServiceBusStatus.propTypes = {
  integrationId: PropTypes.string.isRequired,
  modalView: PropTypes.bool,
};

ServiceBusStatus.defaultProps = {
  modalView: false,
};

export default ServiceBusStatus;
