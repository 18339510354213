import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'react-flow-renderer';
import { Mail } from '@mui/icons-material';
import {
  Typography, Box, Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  busConnector: {
    background: theme.nodes.background,
    color: theme.nodes.color,
    padding: theme.nodes.padding,
    borderWidth: theme.nodes.borderWidth,
    borderStyle: theme.nodes.borderStyle,
    borderColor: theme.nodes.bus.busColor,
    borderRadius: theme.nodes.borderRadius,
    minHeight: theme.nodes.bus.minHeight,
  },
  inputHandle: {
    background: theme.nodes.bus.inputHandle,
  },
  outputHandle: {
    background: theme.nodes.bus.outputHandle,
  },
  ...theme.overviewPage,
}));

const BusConnectorNode = memo(({
  data, isConnectable,
}) => {
  const { classes } = useStyles();

  const createTargetHandles = () => {
    let topPos = 20;
    const items = [];

    for (let i = 0; i < data.numOfTargets; i += 1) {
      items.push(<Handle
        type="target"
        position={Position.Left}
        id={i.toString()}
        key={i}
        className={classes.inputHandle}
        style={{ top: topPos }}
        isConnectable={isConnectable}
      />);
      topPos += 20;
    }

    return items;
  };

  const createSourceHandles = () => {
    let bottomPos = 10;
    const items = [];

    for (let i = 0; i < data.numOfSources; i += 1) {
      items.push(<Handle
        type="source"
        position={Position.Right}
        id={i.toString()}
        key={i}
        className={classes.outputHandle}
        style={{ bottom: bottomPos, top: 'auto' }}
        isConnectable={isConnectable}
      />);
      bottomPos += 20;
    }

    return items;
  };

  return (
    <Box className={classes.busConnector}>
      {createTargetHandles()}
      <Grid container direction="column" spacing={5}>
        <Grid item xs={6}>
          <Typography className={`${classes.ovIntegrationCardText} ${classes.ovFlowText}`}>
            {data.label}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Mail fontSize="small" />
        </Grid>
      </Grid>
      {createSourceHandles()}
    </Box>
  );
});

BusConnectorNode.propTypes = {
  isConnectable: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
    numOfTargets: PropTypes.number,
    numOfSources: PropTypes.number,
  }),
};

BusConnectorNode.defaultProps = {
  data: {
    label: 'Bus',
    numOfTargets: 1,
    numOfSources: 1,
  },
};

export default BusConnectorNode;
