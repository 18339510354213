/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import axios from 'axios';
import dayjs from 'dayjs';
import END_POINTS from '../Constants/EndPoints';

export default class ApiBackend {
  /**
   * Gets the integration with the id
   * @param {string} id
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data
   */
  static getIntegration(id, accessToken) {
    const config = this.createHeaders(accessToken);
    return fetch(`${window.ENV.BACKEND_URL_PREFIX}/integration/${id}`, config).then((res) => res.json());
  }

  /**
   * Gets the number of runs on the integration with the id and that matched the query
   * @param {string} id
   * @param {string} sortBy
   * @param {bool} ascending
   * @param {String} searchQuery
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data
   */
  static getNumberOfRunEntities(id, accessToken, sortBy, ascending, searchQuery = '') {
    const config = this.createHeaders(accessToken);
    return axios.get(`${window.ENV.BACKEND_URL_PREFIX}/integration/${id}/numberOfRunEntities?sortBy=${sortBy}&ascending=${ascending}&${searchQuery}`, config);
  }

  /**
   * Gets the paginated runs on the integration with the id and that matched the query
   * @param {string} id
   * @param {string} sortBy
   * @param {bool} ascending
   * @param {String} searchQuery
   * @param {string|number} offset
   * @param {string|number} count
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data
   */
  static getPaginationSearch(id, offset, count, accessToken, sortBy = 'dateStart', ascending = true, searchQuery = '', signal = null) {
    const config = this.createHeaders(accessToken, false, null, signal);
    return axios.get(`${window.ENV.BACKEND_URL_PREFIX}/integration/search/${id}/offset${offset}/${count}?sortBy=${sortBy}&ascending=${ascending}&${searchQuery}`, config);
  }

  /**
   * Gets data visualization data
   * @param {string} name the integration name
   * @param {string} interval the date/time interval (represented as a C# TimeSpan string)
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data
   */
  static getDataVisualization(name, interval, accessToken) {
    const config = this.createHeaders(accessToken);
    const query = interval ? `?interval=${interval}` : '';
    return axios.get(`${window.ENV.BACKEND_URL_PREFIX}/integration/${name}/datavisualization${query}`, config);
  }

  /**
   * Gets run data with the id
   * @param {string} id the run id
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data as a js object
   */
  static getRun(id, accessToken) {
    const config = this.createHeaders(accessToken);
    return axios.get(`${window.ENV.BACKEND_URL_PREFIX}/run/${id}`, config);
  }

  /**
   * Adds the service connection to the given integration
   * @deprecated
   * @param {string} integrationId The id for the integration to add a connection string
   * @param {string} connectionString The connection string to add
   * @param {string} key The Api Key to add
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data as a js object
   */
  static addServiceConnection(integrationId, connectionString, key, accessToken) {
    const config = this.createHeaders(accessToken);
    const data = { integrationId: parseInt(integrationId, 10), connectionString, key };
    return fetch(`${window.ENV.BACKEND_URL_PREFIX}/integration/RegisterServiceConnection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: config?.headers?.Authorization,
      },
      body: JSON.stringify(data),
    }).then((res) => res.json());
  }

  /**
   * Gets the service connection with the id
   * @deprecated
   * @param {string} connectionId The connection id (in the serviceconnection table)
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data as a js object
   */
  static getServiceConnection(connectionId, accessToken) {
    const config = this.createHeaders(accessToken);
    return fetch(`${window.ENV.BACKEND_URL_PREFIX}/integration/serviceconnection/${connectionId}`, config).then((res) => res.json());
  }

  /**
   * Gets the service connection with the id
   * @deprecated
   * @param {string} integrationId The connection id (in the serviceconnection table)
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data as a js object
   */
  static getServiceConnectionWithIntegrationId(integrationId, accessToken) {
    const config = this.createHeaders(accessToken);
    return fetch(`${window.ENV.BACKEND_URL_PREFIX}/integration/${integrationId}/serviceconnection`, config).then((res) => res.json());
  }

  /**
   * Updates the service connection on the with the given connection string and Api Key
   * @deprecated
   * @param {{}} oldServiceConnection The old service connection to update
   * @param {string} connectionString The connection string to update
   * @param {string} key The Api Key to update
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<string>} response data as a string
   */
  static updateServiceConnection(oldServiceConnection, connectionString, key, accessToken) {
    const config = this.createHeaders(accessToken);
    oldServiceConnection.connectionString = connectionString;
    oldServiceConnection.key = key;
    return fetch(`${window.ENV.BACKEND_URL_PREFIX}/integration/serviceconnection/${oldServiceConnection.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: config?.headers?.Authorization,
      },
      body: JSON.stringify(oldServiceConnection),
    }).then((res) => res.text());
  }

  /**
   * Gets Elasticsearch and Kibana info from the backend
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>} response data as a js object
   */
  static getElasticsearchInfo(accessToken) {
    const config = this.createHeaders(accessToken);
    return axios.get(`${window.ENV.BACKEND_URL_PREFIX}/elasticsearch`, config);
  }

  /**
   * Gets the connetor flow configuration data
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>}
   */
  static async getConnectorFlowConfig(accessToken) {
    const config = this.createHeaders(accessToken);
    return axios.get(`${window.ENV.BACKEND_URL_PREFIX}/connectorflowconfig`, config);
  }

  /**
   * Updates the connector flow configuration data
   * @param {{[nodes]}} connectorFlowConfig the config data
   * @param {string} accessToken The access token for authentication with backend
   * @param {Array} nodes
   * @param {Array} edges
   * @returns {Promise<any>}
   */
  static updateConnectorFlowConfig(connectorFlowConfig, accessToken, nodes, edges) {
    const config = this.createHeaders(accessToken);
    return axios.put(`${window.ENV.BACKEND_URL_PREFIX}/connectorflowconfig`, {
      oldConfig: {
        body: JSON.stringify(connectorFlowConfig),
      },
      nodes,
      edges,
    }, config);
  }

  /**
   * Gets source servicebus properties for edges
   * @param {string} integrationId
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>}
   */
  static getSourceEdgeServiceBusProperties(integrationId, accessToken) {
    const config = this.createHeaders(accessToken);
    return axios.get(`${END_POINTS.SERVICEBUS_PROPS_SOURCE_EDGE}/${integrationId}`, config);
  }

  /**
   * Get source servicebus properties for the overview page
   * @param {string} integrationId
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>}
   */
  static getSourceOverviewServiceBusProperties(integrationId, accessToken) {
    const config = this.createHeaders(accessToken);
    return axios.get(`${END_POINTS.SERVICEBUS_PROPS_SOURCE_OVERVIEW}/${integrationId}`, config);
  }

  /**
   * Gets target servicebus propeties for edges
   * @param {string} integrationId
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>}
   */
  static getTargetEdgeServiceBusProperties(integrationId, accessToken) {
    const config = this.createHeaders(accessToken);
    return axios.get(`${END_POINTS.SERVICEBUS_PROPS_TARGET_EDGE}/${integrationId}`, config);
  }

  /**
   * Gets target servicebus properties for the overview page
   * @param {string} integrationId
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>}
   */
  static getTargetOverviewServiceBusProperties(integrationId, accessToken) {
    const config = this.createHeaders(accessToken);
    return axios.get(`${END_POINTS.SERVICEBUS_PROPS_TARGET_OVERVIEW}/${integrationId}`, config);
  }

  /**
   * Gets the dead letters for the queue
   * @param {string} integrationId
   * @param {string} queueName
   * @param {string|number} maxMessages How many messages to retrieve
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>}
   */
  static getQueueDeadLetters(integrationId, queueName, maxMessages, accessToken) {
    const config = this.createHeaders(accessToken);
    const query = `?queueName=${queueName}${maxMessages && `&maxMessages=${maxMessages}`}`;
    return axios.get(`${END_POINTS.SERVICEBUS_QUEUE_DEADLETTERS}/${integrationId}${query}`, config);
  }

  /**
   * Gets the dead letters for the topic-subscription pair
   * @param {string} integrationId
   * @param {string} topicName
   * @param {string} subscriptionName
   * @param {string|number} maxMessages How many messages to retrieve
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>}
   */
  static getSubscriptionDeadLetters(
    integrationId,
    topicName,
    subscriptionName,
    maxMessages,
    accessToken,
  ) {
    const config = this.createHeaders(accessToken);
    const query = `?topicName=${topicName}&subscriptionName=${subscriptionName}${maxMessages && `&maxMessages=${maxMessages}`}`;
    return axios.get(`${END_POINTS.SERVICEBUS_SUBSCRIPTION_DEADLETTERS}/${integrationId}${query}`, config);
  }

  /**
   * Deletes the provided messageids from the deadletter queue on a queue
   * @param {string} integrationId
   * @param {string} queueName
   * @param {string} messageId The id of the message to delete
   * @param {string} runId
   * @param {string} accessToken The access token for authentication with backend
   * @returns {Promise<any>}
   */
  static deleteQueueDeadLetters(
    integrationId,
    queueName,
    messageId,
    runId,
    accessToken,
    cancelToken = null,
  ) {
    const config = this.createHeaders(accessToken, true, cancelToken);
    const data = { queueName, messageId, runId };
    return axios.post(
      `${END_POINTS.SERVICEBUS_DEADLETTER}/${integrationId}/Delete`,
      JSON.stringify(data),
      config,
    );
  }

  /**
   * Deletes the provided messageids from the deadletter queue on a topic-subscription
   * @param {string} integrationId
   * @param {string} topicName
   * @param {string} subscriptionName
   * @param {string} messageId
   * @param {string} runId
   * @param {string} accessToken
   * @returns {Promise<any>}
   */
  static deleteSubscriptionDeadLetters(
    integrationId,
    topicName,
    subscriptionName,
    messageId,
    runId,
    accessToken,
    cancelToken = null,
  ) {
    const config = this.createHeaders(accessToken, true, cancelToken);
    const data = {
      topicName, subscriptionName, messageId, runId,
    };
    return axios.post(
      `${END_POINTS.SERVICEBUS_DEADLETTER}/${integrationId}/Delete`,
      JSON.stringify(data),
      config,
    );
  }

  /**
   * Resends the provided messageids from the deadletter queue again on the queue
   * @param {string} integrationId
   * @param {string} queueName
   * @param {string} messageId
   * @param {string} runId
   * @param {string} accessToken
   * @param {string} body The new body of the message (only use with one message Id)
   * @param {boolean} keepInDLQ Whether to keep the message in the DLQ
   * @returns {Promise<any>}
   */
  static resendQueueDeadLetters(
    integrationId,
    queueName,
    messageId,
    runId,
    accessToken,
    body = null,
    keepInDLQ = false,
    cancelToken = null,
  ) {
    const config = this.createHeaders(accessToken, true, cancelToken);
    const data = {
      queueName, messageId, runId, body, keepInDLQ,
    };
    return axios.post(
      `${END_POINTS.SERVICEBUS_DEADLETTER}/${integrationId}/Resend`,
      JSON.stringify(data),
      config,
    );
  }

  /**
   * Resends the provided messageids from the deadletter queue again on the topic
   * NB! All subscriptions will get the message again
   * @param {string} integrationId
   * @param {string} topicName
   * @param {string} subscriptionName
   * @param {string} messageId
   * @param {string} runId
   * @param {string} accessToken
   * @param {string} body The new body of the message (only use with one message Id)
   * @param {boolean} keepInDLQ Whether to keep the message in the DLQ
   * @returns {Promise<any>}
   */
  static resendSubscriptionDeadLetters(
    integrationId,
    topicName,
    subscriptionName,
    messageId,
    runId,
    accessToken,
    body = null,
    keepInDLQ = false,
    cancelToken = null,
  ) {
    const config = this.createHeaders(accessToken, true, cancelToken);
    const data = {
      topicName, subscriptionName, messageId, runId, body, keepInDLQ,
    };
    return axios.post(
      `${END_POINTS.SERVICEBUS_DEADLETTER}/${integrationId}/Resend`,
      JSON.stringify(data),
      config,
    );
  }

  /**
   * Get integration metrics
   * @param {string} accessToken
   * @param {*} interval
   * @returns {Promise<any>}
   */
  // eslint-disable-next-line no-unused-vars
  static getIntegrationMetrics(accessToken, interval) {
    const config = this.createHeaders(accessToken);
    const query = interval ? `?interval=${interval}` : '';
    return axios.get(`${window.ENV.BACKEND_URL_PREFIX}/integration/metrics${query}`, config);
  }

  /**
   * Updates a run with resolved info
   * @param {string} accessToken
   * @param {Array} ids the ids of the runs to update
   * @param {string} resolvedBy whom it was resolved by
   * @param {string} reason the reason it was resolved
   * @returns {Promise<any>}
   */
  static updateRunResolvedInfo(accessToken, ids, integrationId, resolvedBy, reason = null) {
    const config = this.createHeaders(accessToken);
    const url = `${END_POINTS.RUN}/updateResolved`;
    if (!reason) reason = '';
    const resolvedDate = dayjs();
    const runsToBeResolved = {
      ids, resolvedBy, reason, resolvedDate, integrationId,
    };
    return axios.put(url, runsToBeResolved, config);
  }

  /**
   * Tries to get a deadletter message from the servicebus
   *
   * Queue / topic+subscription are optional, but only use one of them
   * @param {string} accessToken
   * @param {string} messageId the message id for the message
   * @param {string} integrationId the integration the run was ran (for config settings)
   * @param {string} queue the queue
   * @param {string} topic the topic
   * @param {string} subscription the subscription
   * @returns {Promise<any>}
   */
  static getDeadletterMessage(
    accessToken,
    messageId,
    integrationId,
    queue = null,
    topic = null,
    subscription = null,
    cancelToken = null,
  ) {
    const config = this.createHeaders(accessToken, false, cancelToken);
    let url = `${END_POINTS.SERVICEBUS_DEADLETTER}/${messageId}?integrationId=${integrationId}`;
    if (queue) {
      url += `&queue=${queue}`;
    } else if (topic && subscription) {
      url += `&topic=${topic}&subscription=${subscription}`;
    }
    return axios.get(url, config);
  }

  /*
    -----------------
    -----------------
         HELPERS
    -----------------
    -----------------
  */

  /**
   * Creates object with authorization headers (if accesstoken is not null/undefined)
   * @param {string} accessToken
   * @returns {{headers: {Authorization: string}}}
   */
  static createHeaders(accessToken, applicationTypeJson = false, cancelToken = null, signal = null) {
    if (accessToken) {
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      if (applicationTypeJson) headers = { ...headers, 'Content-Type': 'application/json' };
      if (cancelToken) return { headers, cancelToken };
      if (signal) return { headers, signal };
      return { headers };
    }
    return {};
  }
}
