import BusConnectorNode from '../Component/ConnectorFlow/CustomNodes/BusConnectorNode';
import DataConnectorNode from '../Component/ConnectorFlow/CustomNodes/DataConnectorNode';
import InputConnectorNode from '../Component/ConnectorFlow/CustomNodes/InputConnectorNode';
import OutputConnectorNode from '../Component/ConnectorFlow/CustomNodes/OutputConnectorNode';
import SourceDataNode from '../Component/ConnectorFlow/CustomNodes/SourceDataNode';
import TargetDataNode from '../Component/ConnectorFlow/CustomNodes/TargetDataNode';
import CustomEdge from '../Component/ConnectorFlow/CustomEdges/CustomEdge';

// Its own file to avoid dependency cycle

// Configure the custom node types here: typeName: module
export const nodeTypes = {
  busConnector: BusConnectorNode,
  inputConnector: InputConnectorNode,
  outputConnector: OutputConnectorNode,
  sourceData: SourceDataNode,
  targetData: TargetDataNode,
  dataConnector: DataConnectorNode,
};

export const edgeTypes = {
  custom: CustomEdge,
};
