import { INTEGRATION_RUN_STATUS } from '../Constants/Constants';

const capitalizeAndLower = (string) => {
  // Because I don't want to change the value in the backend as well...
  // Only visual change
  let copy = `${string}`;
  if (copy === INTEGRATION_RUN_STATUS.FILTER) copy += 'ed';
  return copy[0].toUpperCase() + copy.slice(1).toLowerCase();
};

export default capitalizeAndLower;
