import axios from 'axios';
// eslint-disable-next-line max-len
const notificationIntegrationUrl = `${window.ENV.NOTIFICATION_INTEGRATION_API_URL}/api/notification`;

export default class ApiNotificationIntegration {
  /**
   * Gets a list of all emails from the database
   * @param {string} apiKey
   * @returns {Promise<any>}
   */
  static getEmails(apiKey) {
    const config = this.createHeaders(false, apiKey);
    return axios.get(`${notificationIntegrationUrl}/GetEmails`, config);
  }

  /**
   * Registers a new email to the database
   * @param {string} email a NotificationEmailModel object (see Alert project)
   * @param {string} apiKey
   * @returns {Promise<any>}
   */
  static registerEmail(email, apiKey) {
    const config = this.createHeaders(true, apiKey);
    return axios.post(`${notificationIntegrationUrl}/RegisterEmail`, JSON.stringify(email), config);
  }

  /**
   * Removes an email from the database if it exists
   * @param {string} email an email string
   * @param {string} apiKey
   * @returns {Promise<any>}
   */
  static removeEmail(email, apiKey) {
    const config = this.createHeaders(true, apiKey);
    return axios.post(
      `${notificationIntegrationUrl}/RemoveEmail?email=${email}`,
      JSON.stringify({}),
      config,
    );
  }

  /**
   * Updates an email in the database
   * @param {string} oldEmail an email string (the email to update)
   * @param {*} newEmail a NotificationEmailModel object (see Alert project) (the new email data)
   * @param {string} apiKey
   * @returns {Promise<any>}
   */
  static updateEmail(oldEmail, newEmail, apiKey) {
    const config = this.createHeaders(true, apiKey);
    return axios.put(
      `${notificationIntegrationUrl}/UpdateEmail?oldEmail=${oldEmail}`,
      JSON.stringify(newEmail),
      config,
    );
  }

  static createHeaders(isJsonData, apiKey) {
    let headers = {};
    if (isJsonData) {
      headers = { 'Content-Type': 'application/json' };
    }
    if (apiKey) {
      headers = { ...headers, ApiKey: apiKey };
    }
    return { headers };
  }
}
