import React from 'react';
import { MilesConnectUIApi } from '../Generated/Backend';

// TODO Add correct types for these...
export interface IIntegrationsContext {
  api: MilesConnectUIApi | null,
  metrics: unknown | undefined,
  requestData: unknown | undefined,
  isError: boolean,
  loading: boolean,
  metricsLoading: boolean | null,
  errorResponse: unknown | undefined,
  errorJson: string | undefined,
  errorRequest: unknown | undefined,
  errorMessage: string | null,
  refreshMetrics(): void,
  refreshIntegrations(): void,
  refreshAll(): void,
  sortBy: string,
  setSortBy: React.Dispatch<React.SetStateAction<string>>,
  abortControllerRef: React.MutableRefObject<AbortController> | null,
}

export const defaultContext: IIntegrationsContext = {
  api: null,
  metrics: null,
  requestData: null,
  isError: false,
  loading: false,
  metricsLoading: null,
  errorResponse: null,
  errorJson: '',
  errorRequest: null,
  errorMessage: '',
  refreshMetrics: () => undefined,
  refreshIntegrations: () => undefined,
  refreshAll: () => undefined,
  sortBy: '',
  setSortBy: () => undefined,
  abortControllerRef: null,
};

export interface IPageContext {
  selected: boolean
}

export const defaultPageContext: IPageContext = {
  ...defaultContext,
  selected: true,
};
