/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Grid, LinearProgress, Paper, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import RuleCard from './RuleCard';
import CustomModal from '../Modal/CustomModal';
import RuleDialog from './RuleDialog';
import ApiRuleIntegration from '../../Api/ApiRuleIntegration';
import AlertContext from '../../Contexts/AlertContext';

const useStyles = makeStyles()(() => ({
  workflowCard: {
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
  },
  header: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  buttonsGrid: {
    marginTop: 10,
    marginBottom: 10,
  },
  rulesGrid: {
    marginTop: 10,
  },
  progressBar: {
    marginTop: 5,
  },
}));

const WorkflowCard = (props) => {
  const { workflow, setRefreshWorkflows } = props;
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [create, setCreate] = useState(false);
  const [view, setView] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [openRuleDialog, setOpenRuleDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { ruleApiKey } = useContext(AlertContext);

  const handleClickOpen = () => {
    setOpen(true);
    setIsError(false);
  };

  const handleClickOpenRuleDialog = () => {
    setOpenRuleDialog(true);
    setIsError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRuleDialog(false);
  };

  const handleCloseRuleDialog = () => {
    setOpenRuleDialog(false);
  };

  const onAddRuleBtnClick = () => {
    setCreate(true);
    setView(false);
    setSelectedRule(null);
    handleClickOpenRuleDialog();
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const onDeleteWorkflowBtnClick = () => {
    setDialogTitle('Deleting workflow');
    setDialogText(`Are you sure you want to delete workflow "${workflow.workflowName}"?\nAll rules will also be deleted.`);
    handleClickOpen();
  };

  const onRuleCardClick = (rule) => {
    setSelectedRule(rule);
    setView(true);
    setCreate(false);
    handleClickOpenRuleDialog();
  };

  const deleteWorkflow = () => {
    setLoading(true);
    ApiRuleIntegration.deleteWorkflow(workflow?.workflowName, ruleApiKey)
      .then(() => {
        setRefreshWorkflows((ref) => !ref);
      })
      .catch((err) => {
        setIsError(true);
        setErrorMsg(err?.response?.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box
      component={Paper}
      elevation={3}
      className={classes.workflowCard}
    >
      {/* <Typography className={classes.header} variant="h6" gutterBottom>{workflow.workflowName}</Typography> */}
      {/* <Divider /> */}
      <Grid container className={classes.buttonsGrid} justifyContent="space-evenly">
        <Grid item>
          <Button variant="contained" color="primary" onClick={onAddRuleBtnClick}>
            Add rule
          </Button>
        </Grid>
        {/* <Grid item>
          <Button size="small" variant="outlined" color="error" onClick={onDeleteWorkflowBtnClick}>
            Delete workflow
          </Button>
        </Grid> */}
      </Grid>
      <Divider />
      <Grid container className={classes.rulesGrid}>
        {workflow?.rules.map((rule) => (
          <Grid key={rule.ruleName} item>
            <RuleCard
              rule={rule}
              onRuleCardClick={onRuleCardClick}
            />
          </Grid>
        ))}
      </Grid>
      <CustomModal
        open={open}
        handleClose={handleClose}
        title={dialogTitle}
        text={dialogText}
        alert
        alertFunction={deleteWorkflow}
      >
        {loading && (<LinearProgress className={classes.progressBar} />)}
        {isError && (<Typography color="red">{errorMsg}</Typography>)}
      </CustomModal>
      <RuleDialog
        open={openRuleDialog}
        handleClose={handleCloseRuleDialog}
        create={create}
        view={view}
        setView={setView}
        rule={selectedRule}
        setRefreshWorkflows={setRefreshWorkflows}
        workflowName={workflow?.workflowName}
      />
    </Box>
  );
};

WorkflowCard.propTypes = {
  workflow: PropTypes.shape({
    workflowName: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  setRefreshWorkflows: PropTypes.func.isRequired,
};

export default WorkflowCard;
