import { toYearDateTimeStandardString } from '../Utils/DateTimeUtils';

const rightAlign = { textAlign: 'right' };
const centerAlign = { textAlign: 'center' };
const greyColor = { color: 'grey' };
const whitespaceNowrap = { whiteSpace: 'nowrap' };
const whitespacePrewrap = { whiteSpace: 'pre-wrap' };

export const queueDataDefinition = [
  {
    header: 'Queue Name', headerStyle: {}, key: 'name', style: {}, format: (value) => value,
  },
  {
    header: 'Accessed at', headerStyle: {}, key: 'accessedAt', style: {}, format: (value) => toYearDateTimeStandardString(value),
  },
  {
    header: 'Active Messages', headerStyle: rightAlign, key: 'activeMessageCount', style: rightAlign, format: (value) => value,
  },
  {
    header: 'Dead Letters', headerStyle: rightAlign, key: 'deadLetterMessageCount', style: rightAlign, format: (value) => value,
  },
  {
    header: '', headerStyle: {}, key: null, style: {}, format: (value) => value,
  },
];

export const topicDataDefinition = [
  {
    header: 'Topic Name', headerStyle: {}, key: 'name', style: {}, format: (value) => value,
  },
  {
    header: 'Accessed at', headerStyle: {}, key: 'accessedAt', style: {}, format: (value) => toYearDateTimeStandardString(value),
  },
  {
    header: 'Scheduled Messages', headerStyle: rightAlign, key: 'scheduledMessageCount', style: rightAlign, format: (value) => value,
  },
  {
    header: 'Subscriptions', headerStyle: rightAlign, key: 'subscriptionCount', style: rightAlign, format: (value) => value,
  },
  {
    header: '', headerStyle: {}, key: null, style: {}, format: (value) => value,
  },
];

export const subscriptionDataDefinition = [
  {
    header: 'Subscription Name', headerStyle: greyColor, key: 'subscriptionName', style: {}, format: (value) => value,
  },
  {
    header: 'Accessed at', headerStyle: greyColor, key: 'accessedAt', style: {}, format: (value) => toYearDateTimeStandardString(value),
  },
  {
    header: 'Active Messages', headerStyle: { ...greyColor, ...rightAlign }, key: 'activeMessageCount', style: rightAlign, format: (value) => value,
  },
  {
    header: 'Dead Letters', headerStyle: { ...greyColor, ...rightAlign }, key: 'deadLetterMessageCount', style: rightAlign, format: (value) => value,
  },
  {
    header: '', headerStyle: greyColor, key: null, style: {}, format: (value) => value,
  },
];

export const targetSubscriptionDataDefinition = [
  {
    header: 'Subscription Name', headerStyle: {}, key: 'subscriptionName', style: {}, format: (value) => value,
  },
  {
    header: 'Topic Name', headerStyle: {}, key: 'topicName', style: {}, format: (value) => value,
  },
  {
    header: 'Accessed at', headerStyle: {}, key: 'accessedAt', style: {}, format: (value) => toYearDateTimeStandardString(value),
  },
  {
    header: 'Active Messages', headerStyle: rightAlign, key: 'activeMessageCount', style: rightAlign, format: (value) => value,
  },
  {
    header: 'Dead Letters', headerStyle: rightAlign, key: 'deadLetterMessageCount', style: rightAlign, format: (value) => value,
  },
  {
    header: '', headerStyle: {}, key: null, style: {}, format: (value) => value,
  },
];

export const deadlettersDataDefinition = [
  {
    header: 'Body', headerStyle: {}, key: 'body', style: whitespacePrewrap, format: (value) => value.value,
  },
  {
    header: 'Id', headerStyle: {}, key: 'messageId', style: {}, format: (value) => value.value,
  },
  {
    header: 'Error Description', headerStyle: {}, key: 'deadLetterErrorDescription', style: {}, format: (value) => value.value,
  },
  {
    header: 'Reason', headerStyle: {}, key: 'deadLetterReason', style: whitespaceNowrap, format: (value) => value.value,
  },
  {
    header: 'Delivery Count', headerStyle: {}, key: 'deliveryCount', style: rightAlign, format: (value) => value.value,
  },
  {
    header: 'Enqueued Time', headerStyle: centerAlign, key: 'enqueuedTime', style: centerAlign, format: (value) => toYearDateTimeStandardString(value.value),
  },
  {
    header: 'Expires At', headerStyle: centerAlign, key: 'expiresAt', style: centerAlign, format: (value) => toYearDateTimeStandardString(value.value),
  },
  {
    header: 'Scheduled Enqueue Time', headerStyle: centerAlign, key: 'scheduledEnqueueTime', style: centerAlign, format: (value) => toYearDateTimeStandardString(value.value),
  },
];
