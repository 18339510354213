const sidebar = {
  /* Sidebar */
  sidebar: {
    position: 'absolute',
    width: '231px',
    height: '1080px',
    left: '0px',
    top: '0px',
    background: '#282828',
  },

  /* Line (divider) */
  line: {
    position: 'absolute',
    width: '231px',
    height: '0px',
    left: '0px',
    top: '521px',
    opacity: 0.06,
  },

  /* divider */
  divider: {
    position: 'absolute',
    height: '0px',
    left: '0px',
    right: '0px',
    top: 'calc(50% - 0px/2)',
    /* grayscale / divider */
    border: '1px solid #DFE0EB',
  },

  /* selected vertical bar */
  selectedVertBar: {
    position: 'absolute',
    width: '3px',
    left: '0px',
    top: '0px',
    bottom: '0px',
    /* accent / light */
    background: '#DDE2FF',
  },

  /* Common stylings */
  sidebarListItemBox: {
    // position: 'absolute',
    position: 'relative',
    width: '231px',
    height: '56px',
    // left: '0px',
    '&:hover': {
      background: 'rgb(159, 162, 180, 0.08)',
      cursor: 'pointer',
      transition: 'all 0.15s ease-in-out',
    },
    // top: '169px', // will be supplied to the component
  },

  sidebarListItemBoxNoHover: {
    position: 'relative',
    width: '231px',
    height: '56px',
  },

  sidebarListItemSelected: {
    background: 'rgb(159, 162, 180, 0.08)',
  },

  // There are some different styles used, but this was used the most..?
  sidebarListItemIcon: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    left: '24px',
    top: '16px',
  },

  sidebarListItemIconClose: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    right: '24px',
    top: '16px',
  },

  sidebarListItemText: {
    position: 'absolute',
    height: '20px',
    left: '72px',
    right: '24px',
    top: 'calc(50% - 20px/2)',
    /* Reg 16 (0.2 px) */
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    /* identical to box height */
    letterSpacing: '0.2px',

    color: '#E5E5E5',
  },
  leftAlignedText: {
    textAlign: 'left',
  },
};

export default sidebar;
