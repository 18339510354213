const statistics = {
  statIntervalSelect: {
    width: 150,
    textAlign: 'left',
  },
  statDataVisualizationBox: {
    background: '#FFFFFF',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: '8px',
    margin: '10px 20px 20px 20px',
    height: 550,
  },
  statGridContainer: {
    height: 500,
  },
  statServicesLinkBtn: {
    width: 300,
    marginTop: 10,
    marginBottom: 10,
  },
  statsRightSideHeader: {
    fontFamily: "'Mulish'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.3px',
    color: '#9FA2B4',
  },
  statsRightSideContent: {
    fontFamily: "'Mulish'",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    letterSpacing: '0.3px',
    color: '#252733',
  },
  statsGridContainer: {

  },
  statsGridItemChart: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 500,
    marginTop: 20,
    marginBottom: 30,
    paddingRight: 0,
    paddingLeft: 15,
  },
  statsChartHeaderText: {
    fontFamily: "'Mulish'",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '19px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#252733',
  },
  statsGridItemContainer: {
    borderLeft: '1px solid #DFE0EB',
  },
  statsGridItemBorder: {
    height: 110,
    width: '100%',
    borderBottom: '1px solid #DFE0EB',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  statsGridItemNoBorder: {
    borderBottom: 'none',
  },
  statsChartToolTipBox: {
    background: '#FFFFFF',
    borderRadius: 4,
    border: '0.5px solid #DFE0EB',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
    padding: 10,
  },
  statsChartToolTipText: {
    fontFamily: "'Mulish'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.2px',
    color: '#252733',
  },
  statsAxisTickText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    textAlign: 'right',
    letterSpacing: '0.1px',
    color: '#9FA2B4',
  },
  statsChartLegendText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.1px',
    // color: '#9FA2B4',
  },
  statsFailedText: {
    // color: '#ff2424',
    color: '#e45b43',
  },
};

export default statistics;
