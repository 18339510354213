/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useContext,
  useEffect, useRef, useState,
} from 'react';
import {
  Box, Button, CircularProgress, Typography,
  Paper,
} from '@mui/material';

import {
  FileDownload, Upload, PanTool, EditOff, Edit,
} from '@mui/icons-material';
import ReactFlow, {
  addEdge,
  Background, ControlButton, Controls, isEdge, MiniMap, useUpdateNodeInternals,
} from 'react-flow-renderer';
import { makeStyles } from 'tss-react/mui';

import { useTimer } from 'react-timer-hook';
import PropTypes from 'prop-types';
import {
  edgeTypeNames, markerIds, nodeTypeNames,
} from '../../Constants/FlowConstants';
import { getMiniMapNodeColor, getNextNodeId } from '../../Utils/FlowUtils';
import theme from '../../theme';
import MarkerDefinition from './Marker/MarkerDefinition';
import { edgeTypes, nodeTypes } from '../../Constants/FlowTypes';
import ElementPicker from './ElementPicker';
import ElementEditor from './ElementEditor';
import { SAVE_CONFIG_TIMER } from '../../Constants/Constants';
import ConnectorFlowContext from '../../Contexts/ConnectorFlowContext';
import { createTimerDate } from '../../Utils/DateTimeUtils';
import CustomModal from '../Modal/CustomModal';
import ServiceBusStatus from '../ServiceBus/ServiceBusStatus';
import IntegrationsContext from '../../Contexts/IntegrationsContext';

const useStyles = makeStyles()(() => ({
  saveRestoreButton: {
    marginLeft: 10,
  },
  connectorFlowBox: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  reactFlowWrapper: {
    position: 'absolute',
    top: -100,
    left: -50,
    height: 'calc(100vh - 65px)',
    width: 'calc(100% + 100px)',
  },
  miniMap: {
    width: 150,
    height: 100,
  },
  input: {
    display: 'none',
  },
  sideBar: {
    padding: 2,
    position: 'absolute',
    bottom: 0,
    marginLeft: 190,
    marginRight: 190,
    width: 'calc(100% - 380px)',
    alignItems: 'center',
    alignContent: 'center',
    zIndex: 10,
  },
  icon: {
    marginLeft: 5,
  },
  saveStatus: {
    position: 'absolute',
    bottom: 10,
    left: 50,
    color: 'darkgreen',
    zIndex: 4,
    textTransform: 'none',
    fontFamily: 'Mulish',
  },
  modal: {
    textAlign: 'center',
  },
  commonText: {
    fontFamily: 'Open Sans',
  },
  editBtnsBox: {
    width: '100%',
    position: 'absolute',
    top: -20,
    zIndex: 10,
  },
}));

const defaultTimerSeconds = SAVE_CONFIG_TIMER;

const ConnectorFlow = (props) => {
  const {
    loading, updateAndOpenInfoModal,
    nodes, setNodes, edges, setEdges,
    onNodesChange, onEdgesChange,
  } = props;
  const { classes } = useStyles();
  const reactFlowWrapper = useRef(null);
  const updateNodeInternals = useUpdateNodeInternals();
  const { api } = useContext(IntegrationsContext);

  const [rfInstance, setRfInstance] = useState(null);
  const [paneMovable, setPaneMovable] = useState(true);
  const [selectedElement, setSelectedElement] = useState(null);

  const [showEditor, setShowEditor] = useState(false);

  // Context states
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState('');
  const [open, setOpen] = useState(false);
  const [updateConfig, setUpdateConfig] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onTimerEnd = () => {
    setUpdateConfig(true);
  };

  const {
    isRunning, restart, pause,
  } = useTimer(
    { expiryTimestamp: new Date(), autoStart: false, onExpire: onTimerEnd },
  );

  const restartTimer = (timerSec) => {
    const time = createTimerDate(timerSec ?? defaultTimerSeconds);
    restart(time);
  };

  // Calls the fitView function on react-flow to fit the entire flow diagram in the view on load
  const onInit = (reactFlowInstance) => {
    setRfInstance(reactFlowInstance);
  };

  const onConnect = useCallback((params) => {
    restartTimer();
    // set up edge params
    const edge = { ...params };
    edge.data = { type: edgeTypeNames.input };
    edge.type = 'custom';
    edge.animated = false;

    // find the type for the edge
    let checkTarget = false;
    nodes.map((node) => {
      if (checkTarget) return null;
      // find the source element
      if (node.id === edge.source) {
        // if source == bus || dataConnector
        // need to check target element type
        if (node.type === nodeTypeNames.bus || node.type === nodeTypeNames.dataConnector) {
          checkTarget = true;
        } else {
          edge.data.type = node.type;
          // foreach loop + break?
        }
      }
      return null;
    });
    // if source was bus || dataConnector
    if (checkTarget) {
      let finished = false;
      nodes.map((node) => {
        if (finished) return null;
        // find target element and set the type
        if (node.id === edge.target) {
          edge.data.type = node.type;
          finished = true;
        }
        return null;
      });
    }

    // add edge and update the elements
    setEdges((els) => addEdge(edge, els));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, setEdges]);

  const onDragOver = useCallback((event) => {
    if (isEditMode) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
    }
  }, [isEditMode]);

  const onDrop = useCallback((event) => {
    if (isEditMode) {
      event.preventDefault();
      restartTimer();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      if (type && type !== '' && type !== 'default') {
        const position = rfInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });
        const data = {
          label: `${type} node`,
        };
        if (type === nodeTypeNames.bus) {
          data.numOfSources = 1;
          data.numOfTargets = 1;
        }
        const newNode = {
          id: getNextNodeId(nodes),
          type,
          position,
          data,
        };

        setNodes((es) => es.concat(newNode));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, rfInstance, nodes, setNodes]);

  const updateElement = (updatedElement) => {
    restartTimer();
    let setElements = null;

    if (isEdge(updatedElement)) {
      setElements = setEdges;
    } else {
      setElements = setNodes;
    }

    // Updates elements data
    setElements((els) => els.map((el) => {
      if (el.id === updatedElement.id) {
        el.data = {
          ...updatedElement.data,
        };
      }
      return el;
    }));

    if (updatedElement.type === nodeTypeNames.bus) {
      updateNodeInternals(updatedElement.id);
    }

    // Add integration id to edge of output node
    if (updatedElement.type === nodeTypeNames.output) {
      setEdges((els) => els.map((el) => {
        if (el.target === updatedElement.id) {
          el.data.integrationId = updatedElement.data.integrationId;
        }
        return el;
      }));
    }

    // Add integration id to edge of input node
    if (updatedElement.type === nodeTypeNames.input) {
      setEdges((els) => els.map((el) => {
        if (el.source === updatedElement.id) {
          el.data.integrationId = updatedElement.data.integrationId;
        }
        return el;
      }));
    }

    // Hide update/delete
    setShowEditor(false);
    setSelectedElement(null);
  };

  const deleteElement = (deletedElement) => {
    if (isEditMode) {
      restartTimer();

      if (isEdge(deletedElement)) {
        setEdges((els) => els.filter((el) => el.id !== deletedElement.id));
      } else {
        // delete node from nodes
        setNodes((els) => els.filter((el) => el.id !== deletedElement.id));
        // delete edges connected to node from edges
        setEdges((els) => els.filter(
          (el) => el.source !== deletedElement.id && el.target !== deletedElement.id,
        ));
      }

      // Hide update/delete
      setShowEditor(false);
      setSelectedElement(null);
    }
  };

  const onSelectionChange = (els) => {
    const nodesLength = els?.nodes.length;
    const edgesLength = els?.edges.length;
    const total = nodesLength + edgesLength;
    setShowEditor(els && total === 1);

    const element = els?.nodes[0] ?? els?.edges[0];
    setSelectedElement(null);
    setSelectedElement(element);
  };

  useEffect(() => {
    if (rfInstance) {
      // fitview was called on init
      rfInstance.fitView();
    }
  }, [rfInstance]);

  useEffect(() => {
    if (api && updateConfig) {
      api.updateConnectorFlowConfig({ edges, nodes })
        .then(() => setUpdateConfig(false))
        .catch((error) => {
          updateAndOpenInfoModal('Error saving config', `Error was ${error.message}`);
        });
    }
    // Removed callback in useeffect to avoid double http calls +++
    // If user deloads the page, the config is not updated

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, updateConfig]);

  // Download elements array to json file (locally)
  const onDownloadBtnClick = () => {
    const obj = { nodes, edges };
    const element = document.createElement('a');
    const textFile = new Blob([JSON.stringify(obj, null, 2)], { type: 'application/json' });
    element.href = URL.createObjectURL(textFile);
    element.download = 'connectorFlowConfig.json';
    document.body.appendChild(element);
    element.click();
  };

  const onSaveStatusBtnClick = () => {
    if (isRunning) pause();
    else {
      restartTimer();
      pause();
    }
    onTimerEnd();
  };

  const onEditBtnClick = () => {
    setIsEditMode((edit) => !edit);
  };

  // Reads file uploaded from computer and converts to the elements
  const handleCaptureReadFile = ({ target }) => {
    let title = '';
    let text = '';
    const fileReader = new FileReader();
    fileReader.readAsText(target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      const object = JSON.parse(e.target.result);
      setNodes(object.nodes);
      setEdges(object.edges);
      title = 'Completed uploading config file';
      text = 'File was successfully uploaded and set. Changes have been saved.';
      onTimerEnd();
    };
    fileReader.onerror = (error) => {
      title = 'Error uploading config file';
      text = `The error is: ${error}`;
    };
    fileReader.onloadend = () => updateAndOpenInfoModal(title, text);
  };

  return (
    <Box className={classes.connectorFlowBox}>
      {loading ? (<CircularProgress />) : (
        <>
          {isEditMode && (
            <Box className={classes.editBtnsBox}>
              <Button
                className={classes.saveRestoreButton}
                variant="contained"
                color="primary"
                onClick={onDownloadBtnClick}
              >
                <Typography variant="body2" className={classes.commonText}>
                  Download config
                </Typography>
                <FileDownload className={classes.icon} />
              </Button>
              <input
                accept="application/json"
                className={classes.input}
                id="configFile"
                type="file"
                onChange={handleCaptureReadFile}
              />
              { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="configFile">
                <Button
                  className={`${classes.saveRestoreButton} ${classes.commonText}`}
                  variant="contained"
                  color="secondary"
                  component="span"
                >
                  <Typography variant="body2">
                    Upload local file
                  </Typography>
                  <Upload className={classes.icon} />
                </Button>
              </label>
            </Box>
          )}
          <Box className={classes.reactFlowWrapper} ref={reactFlowWrapper}>
            <ConnectorFlowContext.Provider value={{
              isEditMode, handleOpen, setSelectedIntegrationId,
            }}
            >
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                panOnDrag={paneMovable}
                zoomOnScroll
                // edit
                nodesDraggable={isEditMode}
                nodesConnectable={isEditMode}
                elementsSelectable={isEditMode}
                // types
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                // event handlers
                onInit={onInit}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onConnect={onConnect}
                onSelectionChange={onSelectionChange}
                onNodeDragStop={() => restartTimer()}
              >
                <MarkerDefinition id={markerIds.input} color={theme.nodes.connector.inputColor} />
                <MarkerDefinition id={markerIds.output} color={theme.nodes.connector.outputColor} />
                <MarkerDefinition id={markerIds.sourceData} color={theme.nodes.data.sourceColor} />
                <MarkerDefinition id={markerIds.targetData} color={theme.nodes.data.targetColor} />
                <MiniMap
                  className={classes.miniMap}
                  nodeColor={getMiniMapNodeColor}
                  nodeStrokeWidth={3}
                />
                <Controls showInteractive={false}>
                  <ControlButton title="Move pane" onClick={() => setPaneMovable(!paneMovable)}>
                    <PanTool fontSize="small" color={paneMovable ? 'primary' : 'inherit'} />
                  </ControlButton>
                  <ControlButton title="Edit mode" onClick={onEditBtnClick}>
                    {isEditMode ? (
                      <EditOff fontSize="small" color="primary" />
                    ) : (
                      <Edit fontSize="small" color="inherit" />
                    )}
                  </ControlButton>
                </Controls>
                {isEditMode && (
                  <Typography className={classes.saveStatus} variant="body2" component={Button} onClick={onSaveStatusBtnClick}>
                    {isRunning ? 'Changes unsaved' : 'Saved'}
                  </Typography>
                )}
                <Background color="#aaa" gap={16} />
              </ReactFlow>
            </ConnectorFlowContext.Provider>
            {isEditMode && (
              <Box component={Paper} className={classes.sideBar}>
                {showEditor ? (
                  <ElementEditor
                    updateElement={updateElement}
                    deleteElement={deleteElement}
                    selectedElement={selectedElement}
                  />
                ) : (
                  <ElementPicker />
                )}
              </Box>
            )}
          </Box>
          <CustomModal
            open={open}
            handleClose={handleClose}
            title={`Service Bus Status for Integration ${selectedIntegrationId}`}
          >
            <Box className={classes.modal}>
              <ServiceBusStatus modalView integrationId={selectedIntegrationId} />
            </Box>
          </CustomModal>
        </>
      )}
    </Box>
  );
};

ConnectorFlow.propTypes = {
  updateAndOpenInfoModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  nodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setNodes: PropTypes.func.isRequired,
  edges: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setEdges: PropTypes.func.isRequired,
  onEdgesChange: PropTypes.func.isRequired,
  onNodesChange: PropTypes.func.isRequired,
};

export default ConnectorFlow;
