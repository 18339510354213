import React, { useContext, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  TextField,
  Button,
  Grid,
  Tooltip,
  Typography,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Table,
  Paper,
  CircularProgress,
  Box,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import ApiNotificationIntegration from '../../Api/ApiNotificationIntegration';
import { EMAIL_HEADER } from '../../Constants/Constants';
import AlertContext from '../../Contexts/AlertContext';
import ApiRuleIntegration from '../../Api/ApiRuleIntegration';
import NotificationEmailTableRow from './NotificationEmailTableRow';
import { isAxiosResponseHTML } from '../../Utils/ApiUtils';

const useStyle = makeStyles()((theme) => ({
  tooltipInfo: {
    marginLeft: 5,
    marginTop: 15,
  },
  tableHeader: {
    backgroundColor: theme.tableHeader.backgroundColor,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 5,
  },
  addEmailTxtField: {
    width: 350,
  },
  boldFont: {
    fontWeight: 'bold',
  },
  whiteSpaceFix: {
    whiteSpace: 'pre-wrap',
  },
}));

const NotificationEmails = (props) => {
  const { classes } = useStyle();
  const { refresh, setRefresh } = props;

  const [newEmail, setNewEmail] = useState('');
  const [listEmails, setListEmails] = useState();
  const [responseMsg, setResponseMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ruleNames, setRuleNames] = useState([]);

  const {
    notifApiKey, useNotifKey, ruleApiKey, useRuleKey,
  } = useContext(AlertContext);

  const registerEmail = () => {
    const data = { email: newEmail }; // "NotificationEmailModel"
    ApiNotificationIntegration.registerEmail(data, notifApiKey)
      .then((response) => {
        setResponseMsg(response?.data);
        setRefresh(!refresh);
      })
      .catch((error) => {
        setResponseMsg(error.response?.data);
        setIsError(true);
      })
      .finally(setNewEmail(''));
  };

  const deleteEmail = (email) => {
    ApiNotificationIntegration.removeEmail(email, notifApiKey)
      .then((response) => {
        setResponseMsg(`${response?.data}`);
        setRefresh(!refresh);
      }).catch((error) => {
        setResponseMsg(`${error.response?.data}`);
        setIsError(true);
      });
  };

  const updateEmail = (oldEmail, updatedEmail, rulesSubscribedTo) => {
    let data = { rulesSubscribedTo };
    if (updatedEmail) data = { email: updatedEmail, ...data };
    else data = { email: oldEmail, ...data };
    ApiNotificationIntegration.updateEmail(oldEmail, data, notifApiKey)
      .then((response) => {
        setResponseMsg(`${response?.data}`);
        setRefresh(!refresh);
      })
      .catch((error) => {
        setResponseMsg(`${error.response?.data}`);
        setIsError(true);
      })
      .finally();
  };

  useEffect(() => {
    setLoading(true);
    if (notifApiKey || !useNotifKey) {
      ApiNotificationIntegration.getEmails(notifApiKey)
        .then((response) => {
          setIsError(false);
          setResponseMsg('');
          if (response?.data.length < 1) {
            setResponseMsg('There are no emails to be notified');
          }
          setListEmails(response.data);
          if (isAxiosResponseHTML(response)) {
            setIsError(true);
            setResponseMsg('Got HTML data instead of JSON. Are the config.js urls correct?');
          }
        })
        .catch((error) => {
          setIsError(true);
          setResponseMsg(`Error retrieving emails. ${error?.message}`);
        })
        .finally();
    }
    if (ruleApiKey || !useRuleKey) {
      // TODO Use workflows with rules instead!
      ApiRuleIntegration.getRules(ruleApiKey)
        .then((response) => {
          setIsError(false);
          const rules = response.data.map((r) => r.ruleName);
          setRuleNames(rules);
          if (isAxiosResponseHTML(response)) {
            setIsError(true);
            setResponseMsg('Got HTML data instead of JSON from the Rule Integration. Are the config.js urls correct?');
          }
        })
        .catch((respError) => {
          setIsError(true);
          setResponseMsg(`Could not retrieve rule names from Rule Integration\n${respError}`);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [notifApiKey, useNotifKey, refresh, ruleApiKey, useRuleKey]);

  return (
    <Box>
      {loading ? (<CircularProgress />) : (
        <>
          <Typography variant="h5">Notification Emails</Typography>
          <Grid container alignItems="center" spacing={2} direction="column">
            <Grid item>
              <Paper className={classes.paper}>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      {EMAIL_HEADER.map((name) => <TableCell className={classes.boldFont} align="center" key={name}>{name}</TableCell>)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(listEmails) && listEmails?.map((email) => (
                      <NotificationEmailTableRow
                        key={email.id}
                        email={email}
                        updateEmail={updateEmail}
                        deleteEmail={deleteEmail}
                        ruleNames={ruleNames}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item>
              <Typography color={isError ? 'red' : 'inherit'} className={classes.whiteSpaceFix}>{responseMsg}</Typography>
            </Grid>
            <Grid item>
              <TextField
                className={classes.addEmailTxtField}
                label="Add Email"
                variant="outlined"
                value={newEmail}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                }}
              />
              <Tooltip
                className={classes.tooltipInfo}
                title={(
                  <Typography>
                    Add emails to be notified when rules are broken on integrations
                  </Typography>
                )}
                placement="right"
                arrow
              >
                <InfoIcon />
              </Tooltip>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary" onClick={() => registerEmail()}>Add email</Button>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

NotificationEmails.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default NotificationEmails;
