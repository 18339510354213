import {
  ButtonBase, Dialog, DialogActions, DialogContent,
  DialogTitle, IconButton, InputBase, Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import dayjs from 'dayjs';
import DialogCloseIcon from '../Icons/DialogCloseIcon';
import { toDateTimeStringChart } from '../../../Utils/DateTimeUtils';
import PageContext from '../../../Contexts/PageContext';
import useAcquireToken from '../../../Hooks/useAcquireToken';
import IntegrationsContext from '../../../Contexts/IntegrationsContext';

const useStyles = makeStyles()((theme) => ({
  ...theme.runsDetail,
}));

const RunResolveDialog = (props) => {
  const { classes } = useStyles();
  const {
    open, handleClose, runId, resolvedBy, resolveReason,
    onRunFinishedUpdating, resolvedDate, selectedRows,
  } = props;
  const { selectedId } = useContext(PageContext);
  const { api } = useContext(IntegrationsContext);

  const { account } = useAcquireToken();

  const [name, setName] = useState('');
  const [reason, setReason] = useState(resolveReason ?? '');
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (open) {
      setIsError(false);
      if (resolvedBy) setName(resolvedBy);
      else setName(account?.name ?? ''); // Get name of the account
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSaveBtnClick = () => {
    let runIds = [];
    if (runId) {
      runIds.push(runId);
    } else if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      runIds = selectedRows;
    }
    api.updateRunResolved({
      ids: runIds,
      integrationId: selectedId,
      resolvedBy: name,
      reason,
      resolvedDate: dayjs(),
    })
      .then(() => {
        handleClose();
        onRunFinishedUpdating();
        setIsError(false);
      })
      .catch((error) => {
        setIsError(true);
        setErrorMsg(error?.message);
      });
  };

  const checkDisabledInput = () => {
    if (resolvedBy) return true;
    return false;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: `${classes.runsDetailModalBox} ${classes.runsResolveBox}`,
      }}
    >
      <DialogTitle>
        <IconButton className={classes.runsDetailExitBtn} onClick={handleClose}>
          <DialogCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.runsDetailBox}>
        {isError && (
          <Typography className={classes.runResolveErrorText}>
            {errorMsg}
          </Typography>
        )}
        <Typography className={classes.runResolveHeaderText}>
          Name
        </Typography>
        <InputBase
          value={name}
          placeholder="Name"
          disabled={checkDisabledInput()}
          fullWidth
          onChange={(e) => setName(e.target.value)}
          className={`${classes.runResolveInputField} ${classes.runResolveInput}`}
          inputProps={{ className: classes.runResolveInputText }}
        />
        <Typography className={classes.runResolveHeaderText}>
          Reason
        </Typography>
        <InputBase
          value={reason}
          placeholder="Reason"
          disabled={checkDisabledInput()}
          multiline
          fullWidth
          minRows={3}
          maxRows={3}
          onChange={(e) => setReason(e.target.value)}
          className={`${classes.runResolveTextArea} ${classes.runResolveInput}`}
          inputProps={{ className: classes.runResolveInputText }}
        />
        {resolvedBy && resolvedDate && (
          <Typography className={classes.runResolveHeaderText}>
            {`Date resolved: ${toDateTimeStringChart(resolvedDate)}`}
          </Typography>
        )}
      </DialogContent>
      {!resolvedBy && (
        <DialogActions>
          <ButtonBase
            className={classes.runResolveSaveBtn}
            onClick={onSaveBtnClick}
          >
            <Typography className={classes.runResolveInputText}>
              Save
            </Typography>
          </ButtonBase>
        </DialogActions>
      )}
    </Dialog>
  );
};

RunResolveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  runId: PropTypes.string,
  onRunFinishedUpdating: PropTypes.func.isRequired,
  resolvedBy: PropTypes.string,
  resolveReason: PropTypes.string,
  resolvedDate: PropTypes.string,
  selectedRows: PropTypes.arrayOf(PropTypes.string),
};

RunResolveDialog.defaultProps = {
  runId: '',
  resolvedBy: '',
  resolveReason: '',
  resolvedDate: null,
  selectedRows: [],
};

export default RunResolveDialog;
