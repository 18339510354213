/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';

import {
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';

import { Sync } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { useTimer } from 'react-timer-hook';
import ServicesTable from '../Component/ServicesAndTasksTable/ServicesTable';
import InfoModal from '../Component/Modal/InfoModal';
import { createTimerDate } from '../Utils/DateTimeUtils';
import { REFRESH_SERVICES_TIMER } from '../Constants/Constants';
import PageContext from '../Contexts/PageContext';
import IntegrationsContext from '../Contexts/IntegrationsContext';

const useStyles = makeStyles()((theme) => ({
  refresh: {
    marginLeft: 10,
    marginBottom: 5,
  },
  refreshtext: {
    marginTop: 20,
  },
  ...theme.details,
  notFoundText: {
    fontSize: '22px',
  },
  errorText: {
    color: '#de0000',
    marginBottom: 10,
  },
  infoTextMargin: {
    marginBottom: 10,
  },
}));

const refreshTime = REFRESH_SERVICES_TIMER;

const ServicesPage = () => {
  const [loading, setLoading] = useState(true);
  const [serviceHosts, setServiceHosts] = useState();
  const [isError, setIsError] = useState(false);
  const [errorResponse, setErrorResponse] = useState({});
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showNoApis, setShowNoApis] = useState(false);

  const {
    selectedId,
  } = useContext(PageContext);

  const { api, abortControllerRef } = useContext(IntegrationsContext);

  const onTimerEnd = () => {
    // eslint-disable-next-line no-use-before-define
    refreshServiceHostData();
  };

  const {
    isRunning, restart, pause,
  } = useTimer({
    expiryTimestamp: createTimerDate(refreshTime),
    autoStart: true,
    onExpire: onTimerEnd,
  });

  const restartTimer = (timerSec) => {
    const time = createTimerDate(timerSec);
    restart(time, true);
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const updateModalAndOpen = (title, text) => {
    setModalTitle(title);
    setModalText(text);
    handleModalOpen();
  };

  useEffect(() => {
    // Reset states on pane change
    setShowNoApis(false);
    setIsError(false);
    setErrorResponse({});
  }, [selectedId]);

  useEffect(() => {
    if (!isRunning) {
      restartTimer(refreshTime);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning]);

  const retrieveAndSetServiceData = (response) => {
    const serviceData = response?.data;
    if (Array.isArray(serviceData)) {
      setServiceHosts(...serviceData);
    }
  };

  useEffect(() => {
    if (selectedId && api) {
      try {
        abortControllerRef?.current?.abort();
        abortControllerRef.current = new AbortController();
      } catch {
        // do nothing
      }
      setLoading(true);
      api.getConnectorData(selectedId, { signal: abortControllerRef?.current?.signal })
        .then((response) => {
          retrieveAndSetServiceData(response);
          setIsError(false);
          setShowNoApis(false);
          setLoading(false);
        })
        .catch((error) => {
          if (error?.code !== 'ERR_CANCELED') {
            if (error?.response?.status === 404) {
              setShowNoApis(true);
            } else {
              setIsError(true);
            }
            setErrorResponse(error.response);
            pause();
            setLoading(false);
          }
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, api]);

  const refreshServiceHostData = () => {
    if (!showNoApis) {
      if (isRunning) restartTimer(refreshTime);
      try {
        abortControllerRef?.current?.abort();
        abortControllerRef.current = new AbortController();
      } catch {
        // do nothing
      }
      api.getConnectorData(selectedId, { signal: abortControllerRef?.current?.signal })
        .then((response) => {
          retrieveAndSetServiceData(response);
          setIsError(false);
          setShowNoApis(false);
        })
        .catch((error) => {
          if (error?.code !== 'ERR_CANCELED') {
            if (error?.response?.status === 404) {
              setShowNoApis(true);
            } else {
              setIsError(true);
            }
            setErrorResponse(error.response);
          }
        });
    }
  };

  return (
    <Box margin={2}>
      {loading ? <CircularProgress /> : (
        isError ? (
          <Box>
            <Typography className={`${classes.detailsSearchHeaderInfoText} ${classes.notFoundText} ${classes.errorText}`}>
              An error occurred while retrieving the data:
            </Typography>
            <Typography className={`${classes.detailsSearchHeaderInfoText} ${classes.notFoundText}`}>
              {errorResponse?.data}
            </Typography>
          </Box>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {showNoApis ? (
              <Box>
                {/* eslint-disable-next-line max-len */}
                <Typography className={`${classes.detailsSearchHeaderInfoText} ${classes.notFoundText} ${classes.infoTextMargin}`}>
                  No APIs found on this Integration:
                </Typography>
                <Typography className={`${classes.detailsSearchHeaderInfoText} ${classes.notFoundText}`}>
                  {errorResponse?.data}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="h4" align="left" gutterBottom>
                  {/* Move refresh icon to table instead? */}
                  <Tooltip title="Refresh ServiceHost data" className={classes.refresh}>
                    <IconButton color="secondary" onClick={refreshServiceHostData}>
                      <Sync />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Typography align="left">
                  <b>State: </b>
                  {serviceHosts?.state}
                </Typography>
                <ServicesTable
                  services={serviceHosts?.services}
                  refreshServices={refreshServiceHostData}
                  updateModalAndOpen={updateModalAndOpen}
                />
                <InfoModal
                  open={open}
                  handleClose={handleModalClose}
                  title={modalTitle}
                  text={modalText}
                />

              </Box>

            )}
          </>
        )
      )}
    </Box>
  );
};

export default ServicesPage;
