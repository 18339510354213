/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box, CircularProgress, Divider, Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import AlertRules from '../Component/AlertRules/AlertRules';
import NotificationEmails from '../Component/Notification/NotificationEmails';
import { findIntegrationWithName } from '../Utils/IntegrationUtils';
import IntegrationsContext from '../Contexts/IntegrationsContext';
import AlertContext from '../Contexts/AlertContext';
import useAcquireToken from '../Hooks/useAcquireToken';
import ApiBackend from '../Api/ApiBackend';

const useStyles = makeStyles()(() => ({
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

/**
 *
 * NOT USED!
 *
 */

const AlertNotificationPage = () => {
  // eslint-disable-next-line no-unused-vars
  const { classes } = useStyles();

  const [loading, setLoading] = useState(false);

  const [useNotifKey, setUseNotifKey] = useState(true);
  const [notifApiKey, setNotifApiKey] = useState('');
  const [useRuleKey, setUseRuleKey] = useState(true);
  const [ruleApiKey, setRuleApiKey] = useState('');
  const [refresh, setRefresh] = useState(false);

  const { requestData: integrations } = useContext(IntegrationsContext);
  const { accessToken } = useAcquireToken();

  // Get api keys for both notification integration and rule integration
  useEffect(() => {
    setUseRuleKey(true);
    setUseNotifKey(true);
    setLoading(true);
    if (integrations && accessToken) {
      // Rule integration
      const ruleIntegrationName = window.ENV.RULE_INTEGRATION_NAME;
      const integration = findIntegrationWithName(ruleIntegrationName, integrations);
      if (integration) {
        // TODO Get from the apis
        // const api = integration.apis.find({name} => name === 'Rules')
        // const notifApi = notifIntegration.apis.find({name} => name === 'Notification')
        // const url = api.url
        // const apikey = api.apikey

        const { id } = integration;
        // Get api key -> switch to use the integration's context/provider in the future?
        ApiBackend.getServiceConnectionWithIntegrationId(id, accessToken)
          .then((response) => {
            setRuleApiKey(response?.key);
          })
          .catch(() => {
            setRuleApiKey(''); // set empty -> do not use apikey header (or an empty one)
            setUseRuleKey(false);
          });
      } else {
        setRuleApiKey(''); // TODO do not send api key?
        setUseRuleKey(false);
      }

      // Notification integration
      const notificationIntegrationName = window.ENV.NOTIFICATION_INTEGRATION_NAME;
      const notifIntegration = findIntegrationWithName(notificationIntegrationName, integrations);
      if (notifIntegration) {
        const { id } = notifIntegration;
        // Get api key -> switch to use the integration's context/provider in the future?
        ApiBackend.getServiceConnectionWithIntegrationId(id, accessToken)
          .then((response) => {
            setNotifApiKey(response?.key);
          })
          .catch(() => {
            setRuleApiKey(''); // set empty -> do not use apikey header (or an empty one)
            setUseNotifKey(false);
          })
          .finally(() => setLoading(false));
      } else {
        setNotifApiKey(''); // TODO do not send api key?
        setUseNotifKey(false);
        setLoading(false);
      }
    } else if (!integrations && accessToken) {
      // Backend not up and running
      setRuleApiKey('');
      setNotifApiKey('');
      setUseRuleKey(false);
      setUseNotifKey(false);
      setLoading(false);
    }
  }, [accessToken, integrations]);

  // eslint-disable-next-line max-len
  // TODO fix? https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-constructed-context-values.md
  return (
    <Box margin={2}>
      <AlertContext.Provider value={{
        ruleApiKey, notifApiKey, useRuleKey, useNotifKey,
      }}
      >
        <Typography variant="h4" gutterBottom align="left">
          <Link to="/">
            <ArrowBackIos />
          </Link>
          Alert and notification settings
        </Typography>
        {loading ? (<CircularProgress />) : (
          <>
            <NotificationEmails refresh={refresh} setRefresh={setRefresh} />
            <Divider className={classes.divider} />
            <AlertRules refresh={refresh} setRefresh={setRefresh} />
          </>
        )}
      </AlertContext.Provider>
    </Box>
  );
};

export default AlertNotificationPage;
