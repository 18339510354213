import { INTEGRATION_RUN_STATUS, STATUS_COLORS } from '../Constants/Constants';

export const getStatusColor = (status) => {
  switch (status) {
  case INTEGRATION_RUN_STATUS.OK:
    return STATUS_COLORS.OK; // primary material ui color: #3f51b5
  case INTEGRATION_RUN_STATUS.FAILED:
    return STATUS_COLORS.FAILED; // error material ui color: #f44336
  // case INTEGRATION_RUN_STATUS.WARNING:
  //   return STATUS_COLORS.WARNING;
  default:
    return STATUS_COLORS.WARNING;
  }
};

export const calculatePercentageOk = (totalOk, totalFailed) => {
  if (totalOk === 0 && totalFailed === 0) {
    return 0;
  }
  const total = (totalOk + totalFailed) / 100;
  return Math.round((totalOk / total) * 10) / 10;
};

export const calculateStatus = (totalOK, totalFailed) => {
  // const total = totalOK + totalFailed;
  if (totalFailed === 0) {
    return INTEGRATION_RUN_STATUS.OK;
  }
  // if (totalFailed / total < 0.8) { // 0.8 threshold
  //   return INTEGRATION_RUN_STATUS.WARNING;
  // }
  return INTEGRATION_RUN_STATUS.FAILED;
};

export const getServiceConnectionStringStatus = (serviceConnection) => {
  if (serviceConnection) {
    const { connectionString } = serviceConnection;
    if (connectionString && connectionString !== '') {
      return true;
    }
  }
  return false;
};

export const setServiceStatuses = (
  serviceConnection,
  setServiceExists,
  setConnectionStringExists,
  setShowServicesBtn,
) => {
  let serviceExists = false;
  let connectionStringExists = false;
  if (serviceConnection) {
    serviceExists = true;
    connectionStringExists = getServiceConnectionStringStatus(serviceConnection);
  }

  setServiceExists(serviceExists);
  setConnectionStringExists(connectionStringExists);
  setShowServicesBtn(connectionStringExists);
};

export const isRunFailedOrResolved = (status) => {
  if (status === INTEGRATION_RUN_STATUS.FAILED || status === INTEGRATION_RUN_STATUS.RESOLVED) {
    return true;
  }
  return false;
};

export const isRunOkOrFilter = (status) => {
  if (status === INTEGRATION_RUN_STATUS.OK || status === INTEGRATION_RUN_STATUS.FILTER) {
    return true;
  }
  return false;
};
