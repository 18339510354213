const loginPage = {
  loginPageBox: {
    background: '#282828',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  loginCenterBox: {
    background: '#F0F0F0',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: '8px',
    width: 430,
    height: 378,
    position: 'fixed',
    top: '50%',
    left: '50%',
    WebkitTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center',
  },
  loginHeaderText: {
    marginTop: 86,
    marginBottom: 91,
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    letterSpacing: '0.3px',
    color: '#252733',
  },
  loginBtn: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: 222,
    height: 48,
    marginBottom: 80,
  },
  loginBtnText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.2px',
    color: '#333333',
  },
  loginVersionNumberText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.2px',
    color: '#333333',
  },
};

export default loginPage;
