import React from 'react';

const DetailsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.95714 5.19242C4.54554 6.52651 4.54554 7.97355 4.95714 9.30764C9.64146 9.7591 14.3583 9.7591 19.0427 9.30764C19.4543 7.97355 19.4543 6.52651 19.0427 5.19242C14.3583 4.74095 9.64146 4.74095 4.95714 5.19242ZM4.70865 3.70949C9.55792 3.23526 14.4419 3.23526 19.2912 3.70949C19.8257 3.76177 20.275 4.12806 20.4392 4.63383C20.9888 6.32692 20.9888 8.17314 20.4392 9.86623C20.275 10.372 19.8257 10.7383 19.2912 10.7906C14.4419 11.2648 9.55792 11.2648 4.70865 10.7906C4.1741 10.7383 3.72483 10.372 3.56065 9.86623C3.01103 8.17314 3.01103 6.32692 3.56065 4.63383C3.72483 4.12806 4.1741 3.76177 4.70865 3.70949Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.95714 14.6924C4.54554 16.0265 4.54554 17.4735 4.95714 18.8076C9.64146 19.2591 14.3583 19.2591 19.0427 18.8076C19.4543 17.4735 19.4543 16.0265 19.0427 14.6924C14.3583 14.241 9.64146 14.241 4.95714 14.6924ZM4.70865 13.2095C9.55792 12.7353 14.4419 12.7353 19.2912 13.2095C19.8257 13.2618 20.275 13.6281 20.4392 14.1338C20.9888 15.8269 20.9888 17.6731 20.4392 19.3662C20.275 19.872 19.8257 20.2383 19.2912 20.2906C14.4419 20.7648 9.55792 20.7648 4.70865 20.2906C4.1741 20.2383 3.72483 19.872 3.56065 19.3662C3.01103 17.6731 3.01103 15.8269 3.56065 14.1338C3.72483 13.6281 4.1741 13.2618 4.70865 13.2095Z" fill="white" />
  </svg>
);

export default DetailsIcon;
