import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';

// Component using this needs to have:
// const [open, setOpen] = useState(false);
// handleOpen method which sets open = true (for opening)
// handleClose method which sets open = false (for closing)
// See IntegrationsPage or ServicesPage for example

const InfoModal = ({
  open, handleClose, title, text,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Ok</Button>
    </DialogActions>
  </Dialog>
);

InfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};

InfoModal.defaultProps = {
  title: '',
  text: '',
};

export default InfoModal;
